import React, { useEffect, useContext, useState, useRef } from 'react'
import { LocationServicesChartsCtx } from '../LocationServices'
import styles from '../dashboard.module.css'
import Loading from '../Loading'
import { getMonthlyCompleted, getMonthlyLost, getMonthlyOngoing } from '../../../../controllers/dashboardController'
import useResizeObserver from '../../../../hooks/useResizeObserver'
import { select, arc, pie } from 'd3'
import { randomColor } from '../../../helper'

//Monthly Services (Value) chart

const Chart = React.memo(({ data }) => {
    const chartWrapperRef = useRef()
    const svgRef = useRef()
    const dimensions = useResizeObserver(chartWrapperRef)

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const counts = data.map((v) => v[1])
        const services = data.map((v) => v[0])
        const radius = Math.min(dimensions.width, dimensions.height) / 2 - 50
        svg
            .append("g")
            .attr("transform", "translate(" + dimensions.width / 2 + "," + dimensions.height / 2 + ")");

        const arcGenerator = arc()
            .innerRadius(20)
            .outerRadius(radius)

        const pieGenerator = pie();
        const instructions = pieGenerator(counts)
        const colors = data.map(d => randomColor())
        svg.selectAll(".slice")
            .data(instructions)
            .join("path")
            .attr("class", "slice")
            .attr("fill", (instruction, index) => colors[index])
            .attr("transform", "translate(" + dimensions.width / 2 + "," + dimensions.height / 2 + ")")
            .on("mouseenter", (event, instruction) => {
                svg.selectAll(".tooltip")
                    .data([instruction])
                    .join("text")
                    .attr("class", "tooltip")
                    .text(`${services[instruction.index]}: ${instruction.data}`)
                    .style("transform", `translate(${event.offsetX}px, ${event.offsetY}px)`)
                    .style("font", "14px sans-serif")
                    .transition()
                    .attr("opacity", 1)
            })
            .on("mouseleave", () => svg.select('.tooltip').remove())
            .transition()
            .attr("d", instruction => arcGenerator(instruction))

        // Add one dot in the legend for each name.
        svg.selectAll("mydots")
            .data(services)
            .enter()
            .append("circle")
            .attr("cx", 550)
            .attr("cy", function (d, i) { return 10 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .attr("r", 7)
            .style("fill", function (d, i) { return colors[i] })

        // Add one dot in the legend for each name.
        svg.selectAll("mylabels")
            .data(services)
            .enter()
            .append("text")
            .attr("x", 570)
            .attr("y", function (d, i) { return 10 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .style("fill", function (d, i) { return colors[i] })
            .text(function (d) { return d })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")

    }, [data, dimensions])

    const pieStyles = { width: "70%", height: '620px', overflow: 'visible' }

    return (
        <div ref={chartWrapperRef} style={pieStyles}>
            <svg style={{ height: "100%", width: "100%", overflow: 'visible' }} ref={svgRef}>
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
        </div>
    )
})


export default function CHART_3() {

    const ctx = useContext(LocationServicesChartsCtx)
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        const { isMounted, date } = ctx
        const firstDay = date.firstDay
        const lastDay = date.lastDay

        const initFetch = async (func) => {
            const res = await func().catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })
            if (isMounted.current && res) {
                if (res.size !== 0) {
                    return {
                        arr: res.docs.map(doc => ({service:doc.get('dealServices'),value: doc.get('value') ?? 0})),
                        total: res.size
                    }
                }
            }
            return {
                arr: [],
                total: 0
            }
        }

        const results = async () => {
            if (isMounted.current) setIsFetching(true)
            const completes = await initFetch(() => getMonthlyCompleted(firstDay, lastDay))
            const losts = await initFetch(() => getMonthlyLost(firstDay, lastDay))
            const ongoings = await initFetch(() => getMonthlyOngoing(firstDay, lastDay))

            const services = [
                ...completes.arr,
                ...losts.arr,
                ...ongoings.arr
            ]

            if (isMounted.current) setIsFetching(false)

            //Categorize
            const counter = {}
            services.forEach(function ({service,value}) {
                const servicesArr = service.split(',')
                servicesArr.map(s => {
                    var keyjson = JSON.stringify(s)
                    const key = keyjson.replace(/"/g, '')
                    counter[key] = (counter[key] || 0) + parseFloat(value)
                    return null
                })
            })

            const arrayPairs = Object.entries(counter)
            if (isMounted.current) {
                setData(arrayPairs)
            }
        }
        results()
    }, [ctx])

    const chartStyles = {
        height: '100%',
        width: '70%'
    }

    return (

        <div style={chartStyles} className={styles.container}>
            <p className={styles.title}>Services (Value)</p>
            {error && <div style={{ width: '60%', margin: "0 auto" }} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {isFetching ?
                <Loading /> :
                <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                    <Chart data={data} type={'total'} />
                </div>}
            <p style={{ paddingTop: '0' }} className={styles.tips}>Services (Value) chart is generated from <strong>monthly deal completed + monthly deal lost + monthly ongoing deals (sorted by services)</strong>.</p>
        </div>
    )
}
