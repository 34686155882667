import React, { useEffect, useContext, useState, useRef } from 'react'
import { SummaryChartsCtx } from '../Summary'
import styles from '../dashboard.module.css'
import Loading from '../Loading'
import { getMonthlyCompleted, getMonthlyLost } from '../../../../controllers/dashboardController'
import { MONTHS } from '../../../configuration'
import useResizeObserver from '../../../../hooks/useResizeObserver'
import { select, axisBottom, axisRight, line, scaleBand, scaleLinear, min, max } from 'd3'
import { randomColor } from '../../../helper'

//Annual Closed Deals Chart

const Chart = React.memo(({ data }) => {
    const chartWrapperRef = useRef()
    const svgRef = useRef()
    const dimensions = useResizeObserver(chartWrapperRef)

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const xScale = scaleBand()
            .domain(MONTHS)
            .range([0, dimensions.width])

        const xAxis = axisBottom(xScale)
            .ticks(12)

        svg.select(".x-axis")
            .style("transform", `translateY(${dimensions.height}px)`)
            .call(xAxis)
            .selectAll("text")//text styles
            .style("text-anchor", "end")
            .style("font", "13px sans-serif")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-30)");

        const combinedArray = [...data.won, ...data.lost]

        const yScale = scaleLinear()
            .domain([min(combinedArray) - 5, max(combinedArray) + 10])//hardcoded number is for offset
            .range([dimensions.height, 0])

        const yAxis = axisRight(yScale);

        svg.select(".y-axis")
            .style("transform", `translateX(${dimensions.width}px)`)
            .call(yAxis)

        const colors = [randomColor(), randomColor()]

        const myLine = line()
            .x((value, index) => xScale(MONTHS[index]))
            .y(yScale)

        svg.selectAll(".line-won")
            .data([data.won])
            .join("path")
            .attr("class", "line-won")
            .style("transform", `translateX(4%)`)
            .attr("d", myLine)
            .attr("fill", "none")
            .attr("stroke", colors[0] + 80)
            .attr("stroke-width", 1.5)

        svg.selectAll(".circle-won")
            .data(data.won)
            .join("circle")
            .attr("class", "circle-won")
            .style("transform", `translateX(4%)`)
            .attr("cx", function (d, i) { return xScale(MONTHS[i]); })
            .attr("cy", yScale)
            .attr("fill", colors[0])
            .attr("r", 4)
            .each(function (d, i) {
                select(this)
                    .on("mouseenter", function () {
                        svg.selectAll(".tooltip")
                            .data([d])
                            .join("text")
                            .attr("class", "tooltip")
                            .text(d)
                            .attr("x", xScale(MONTHS[i]))
                            .attr("y", yScale(d) - 15)
                            .attr("fill", colors[0])
                            .attr("text-anchor", "middle")
                            .style("font", "14px sans-serif")
                            .style("transform", `translateX(4%)`)
                            .transition()
                            .attr("opacity", 1)
                    })
                    .on("mouseleave", () => svg.select(".tooltip").remove())
            })

        svg.selectAll(".line-lost")
            .data([data.lost])
            .join("path")
            .attr("class", "line-lost")
            .style("transform", `translateX(4%)`)
            .attr("d", myLine)
            .attr("fill", "none")
            .attr("stroke", colors[1] + 80)
            .attr("stroke-width", 1.5)

        svg.selectAll(".circle-lost")
            .data(data.lost)
            .join("circle")
            .attr("class", "circle-lost")
            .style("transform", `translateX(4%)`)
            .attr("cx", function (d, i) { return xScale(MONTHS[i]); })
            .attr("cy", yScale)
            .attr("fill", colors[1])
            .attr("r", 4)
            .each(function (d, i) {
                select(this)
                    .on("mouseenter", function () {
                        svg.selectAll(".tooltip")
                            .data([d])
                            .join("text")
                            .attr("class", "tooltip")
                            .text(d)
                            .attr("x", xScale(MONTHS[i]))
                            .attr("y", yScale(d) - 15)
                            .attr("fill", colors[1])
                            .attr("text-anchor", "middle")
                            .style("font", "14px sans-serif")
                            .style("transform", `translateX(4%)`)
                            .transition()
                            .attr("opacity", 1)
                    })
                    .on("mouseleave", () => svg.select(".tooltip").remove())
            })

        svg.selectAll("mydots")
            .data(["Won", "Lost"])
            .enter()
            .append("circle")
            .attr("cx", 0)
            .attr("cy", function (d, i) { return 0 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .attr("r", 7)
            .style("fill", function (d, i) { return colors[i] })

        svg.selectAll("mylabels")
            .data(["Won", "Lost"])
            .enter()
            .append("text")
            .attr("x", 20)
            .attr("y", function (d, i) { return 0 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .style("fill", function (d, i) { return colors[i] })
            .text(function (d) { return d })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")

    }, [data, dimensions])

    const pieStyles = { width: "95%", height: '550px', overflow: 'visible' }

    return (
        <div ref={chartWrapperRef} style={pieStyles}>
            <svg style={{ height: "100%", width: "100%", overflow: 'visible' }} ref={svgRef}>
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
        </div>
    )
})


export default function CHART_4() {

    const ctx = useContext(SummaryChartsCtx)
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        const { isMounted, date } = ctx
        const firstDay = date.firstDay
        const lastDay = date.lastDay

        const initFetch = async (func) => {
            const res = await func().catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })
            if (isMounted.current && res) {
                if (res.size !== 0) {
                    const total = res.docs.map(doc => doc.get('value') ?? 0).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr))
                    return {
                        arr: res.docs.map(doc => ({ month:MONTHS[doc.get('closingDate').toDate().getMonth()],date: doc.get('closingDate').toDate(), value: doc.get('value') ?? 0 })),
                        amount: total,
                        total: res.size
                    }
                }
            }
            return {
                arr: [],
                amount: 0,
                total: 0
            }
        }

        const results = async () => {
            if (ctx.isMounted.current) setIsFetching(true)
            const completes = await initFetch(() => getMonthlyCompleted(firstDay, lastDay))
            const losts = await initFetch(() => getMonthlyLost(firstDay, lastDay))
            if (ctx.isMounted.current) setIsFetching(false)
            //Sort
            completes.arr.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return a.date - b.date;
            });
            //Categorize
            const counter1 = {}
            completes.arr.forEach(function ({ month, value }) {
                var keyjson = JSON.stringify(month)
                const key = keyjson.replace(/"/g, '')
                counter1[key] = {
                    count: (counter1[key]?.count || 0) + 1,
                    value: (counter1[key]?.value || 0) + parseFloat(value),
                }
            })

            const counter2 = {}
            losts.arr.forEach(function ({ month, value }) {
                var keyjson = JSON.stringify(month)
                const key = keyjson.replace(/"/g, '')
                counter2[key] = {
                    count: (counter2[key]?.count || 0) + 1,
                    value: (counter2[key]?.value || 0) + parseFloat(value),
                }
            })

            const won = []
            const lost = []
            MONTHS.forEach(m => {
                if(counter1[m]){
                    won.push(counter1[m].count)
                }else{
                    won.push(0)
                }

                if(counter2[m]){
                    lost.push(counter2[m].count)
                }else{
                    lost.push(0)
                }
            })

            // console.log(won,lost)

            if (isMounted.current) {
                // const temp = {
                //     won: [15, 22, 35, 67, 76, 82, 25, 35, 71, 33, 45, 52],
                //     lost: [12, 23, 45, 67, 76, 22, 45, 65, 76, 3, 45, 32]
                // }
                setData({won,lost})
            }
        }
        results()
    }, [ctx])

    const chartStyles = {
        height: '100%',
        width: '100%'
    }

    return (

        <div style={chartStyles} className={styles.container}>
            <p className={styles.title}>Closed Deals</p>
            {error && <div style={{ width: '60%', margin: "0 auto" }} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {isFetching ?
                <Loading /> :
                <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                    <Chart data={data} type={'total'} />
                </div>}
            <p style={{ paddingTop: '0' }} className={styles.tips}>Closed deals are generated from <strong>annual deals completed & lost</strong>.</p>
        </div>
    )
}
