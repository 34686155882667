import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import NotFoundIcon from '../404.svg'

export default function NotFound() {

    useEffect(() => {
        document.getElementById("initLoader")?.remove();
        document.querySelector('title').textContent = "404 Not Found"
    }, [])
    
    return (
        <div className="not-found">
            <img src={NotFoundIcon} alt="not found"/>
            <h2 style={{marginTop:"20px"}}>Sorry, the page you are looking for doesn't exist or the link you have used is invalid.</h2>
            <Link to="/">
            <button className="rounded-btn-primary" style={{marginTop:"20px"}}>
                <span style={{marginRight:"12px"}}>Go to Homepage</span>
                <span className="material-icons">arrow_forward</span>
            </button>
            </Link>
        </div>
    )
}
