import React, { useEffect, useRef, useState } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import styles from './las.module.css'
import { useAuth } from '../../contexts/AuthContext'
import { cancelReq, decrementStaffStats, updateSelfRead } from '../../controllers/lasController'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import { getLeaveDuration } from './Bloc/lasbloc'

export default function ViewDetailsDialog({ data, onClose, setIsCancelled, setIsRead }) {
    const isMounted = useRef()
    const { currentUser } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState(data.status)
    const duration = getLeaveDuration(data.leaveDate.toDate(),data.leaveDateEnd ? data.leaveDateEnd.toDate() : data.leaveDate.toDate())

    const statusStyles = {
        backgroundColor: status === 'approved' ? 'green' :
            status === 'rejected' ? 'red' :
                status === 'cancelled' ? 'orange' :
                    'var(--sky-blue)',
        padding: '10px 5px',
        width: 'min-content',
        borderRadius: '10px',
        color: 'white',
        letterSpacing: '1px',
        textTransform: 'uppercase'
    }

    const onCancelReq = async () => {
        setIsLoading(true)
        await cancelReq(data.reqPath).catch(err => console.error(err))
        await cancelReq(data.deptPath).catch(err => console.error(err))

        //TODO: Increase leave balance
        await decrementStaffStats(currentUser.uid, new Date().getFullYear().toString(), data.leaveType === 'medical',duration).catch(err => console.error(err))

        setIsLoading(false)
        setStatus('cancelled')
        setIsCancelled(data.id)
    }

    useEffect(() => {
        isMounted.current = true
        if (!currentUser) return

        if (!data.read.self) {
            const updateRead = async () => {
                await updateSelfRead(data.reqPath, true).catch(err => {
                    console.error(err)
                })
            }
            updateRead()
            setIsRead(data.id)
        }

        return () => isMounted.current = false
    }, [currentUser, data, setIsRead])

    return (
        <DialogTemplate title='Details' toggle={true} width={600} height='max-content'>
            <div>

                <div className={styles.details}>
                    <div>
                        <p>Status :</p>
                        <p style={statusStyles}>{status}</p>
                    </div>

                     {
                        status === 'rejected' &&
                        <div>
                            <p>Remarks :</p>
                            <p>{data.remarks ?? 'N/A'}</p>
                        </div>
                    }

                    <div>
                        <p>Requestor :</p>
                        <p>{data.requestor}</p>
                    </div>

                    <div>
                        <p>Request Date :</p>
                        <p>{data.requestDate.toDate().toDateString()}</p>
                    </div>

                    <div>
                        <p>Leave Date :</p>
                        <p>{data.leaveDate.toDate().toDateString() + " - " + (data.leaveDateEnd ? 
                            data.leaveDateEnd.toDate().toDateString() : data.leaveDate.toDate().toDateString())}</p>
                    </div>

                    <div>
                        <p>Duration :</p>
                        <p>{duration} day(s)</p>
                    </div>

                    <div>
                        <p>Leave Type :</p>
                        <p>{data.leaveType}</p>
                    </div>

                    <div>
                        <p>Department :</p>
                        <p>{data.dept}</p>
                    </div>

                    <div>
                        <p>Officer :</p>
                        <p>{data.officer}</p>
                    </div>

                    <div>
                        <p>Attachment : </p>
                        {
                            data.img ?
                                <img width={'100%'} style={{ objectFit: 'contain', margin: '10px 0px' }} src={data.img} alt="mc" /> :
                                <p>Attachment unavailable.</p>
                        }
                    </div>

                    <div >
                        <p>Actions :</p>
                        <p className={styles.tips}>Warning, cancelling cannot be undone.</p>
                        <div className={styles.actions}>
                            {isLoading && <MiniSpinner wh={30} />}
                            <button onClick={onCancelReq} disabled={status !== 'new' || isLoading} className="btn-red">Cancel</button>
                        </div>
                    </div>
                </div>

                <div>
                    <button style={{ marginLeft: 'auto' }} onClick={onClose} className="btn-white">Close</button>
                </div>
            </div>
        </DialogTemplate>
    )
}
