import React, { useEffect, useRef, useState } from 'react'
import NoResult from './NoResult'
import styles from './report.module.css'
import { db } from '../../../firebase'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import { CSVLink } from "react-csv";
import useSortableData from '../../../hooks/useSortableData'
import Tip from './Tip'
import { useReactToPrint } from 'react-to-print';
import { useUsers } from '../../../contexts/UsersContext'
import { STAGES } from '../../configuration'

const Table = React.forwardRef(({ result }, ref) => {

    const { items, requestSort } = useSortableData(result);

    const onHighlight = (e) => {
        const target = e.currentTarget
        target.classList.toggle("highlight")
    }

    const pageStyle = `
    @media print {
        @page {
            size: landscape;
            margin: 10mm !important;
        }
        th,td{
            font-size: 10pt;
            padding: 0 2mm !important;
        }
      }`;

    return (
        <table ref={ref} className={`table ${styles.table}`} cellSpacing="0" cellPadding="0">
            <style>{pageStyle}</style>
            <thead>
                <tr>
                    <th >Num.</th>
                    <th onClick={() => requestSort('status')}>Status</th>
                    <th onClick={() => requestSort('stage')}>Stage</th>
                    <th onClick={() => requestSort('name')}>Prospect</th>
                    <th onClick={() => requestSort('phoneNumber')}>Phone Number</th>
                    <th onClick={() => requestSort('owner')}>Owner</th>
                    <th onClick={() => requestSort('value')}>Value (RM)</th>
                    <th onClick={() => requestSort('paymentStatus')}>Payment Status</th>
                    <th onClick={() => requestSort('paymentMethod')}>Payment Method</th>
                    <th onClick={() => requestSort('dateCreated')}>Date Created</th>
                    <th onClick={() => requestSort('dealSource')}>Source</th>
                    <th onClick={() => requestSort('dealServices')}>Service</th>
                    <th onClick={() => requestSort('premiseType')}>Premise Type</th>
                    <th >Location Overview</th>
                </tr>
            </thead>
            <tbody>

                {
                    items.map((res, i) => <tr onClick={onHighlight} key={res.id}>
                        <td>{i + 1}</td>
                        <td>{res.status}</td>
                        <td>{res.stage}</td>
                        <td>{res.name}</td>
                        <td>{res.phoneNumber}</td>
                        <td>{res.owner}</td>
                        <td>{res.value ?? 0}</td>
                        <td>{res.paymentStatus}</td>
                        <td>{res.paymentMethod}</td>
                        <td>{res.dateCreated.toDateString()}</td>
                        <td>{res.dealSource}</td>
                        <td>{res.dealServices}</td>
                        <td>{res.premiseType}</td>
                        <td>{res.locationOverview ?? "N/A"}</td>
                    </tr>)
                }

            </tbody>
        </table>
    )
}
)

export default function Pipeline() {
    const { users } = useUsers()
    const isMounted = useRef(false)
    const [error, setError] = useState(null)
    const [result, setResult] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const excelResult = useRef([])
    const salesRep = useRef()
    const stage = useRef()

    const printComp = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComp.current,
    });

    const getOwner = (oid) => {
        return users.filter(u => u.id === oid)[0].name
    }

    const generate = async () => {
        const sr = salesRep.current.value
        const st = stage.current.value
        let ref = db.collection("Deals")
        let resultArray = []

        if(sr !== "all"){
            ref = ref.where("owner","==",sr)
        }

        if(st !== "all"){
            ref = ref.where("stage","==",st)
        }

        setIsFetching(true)
        const res = await ref.orderBy("dateCreated","desc").get().catch(err => {
            setIsFetching(false)
            console.error(err)
            setError(err)
        })
        resultArray = res.docs.map(d => ({ ...d.data(), id: d.id }))

        resultArray.sort((a, b) => b.dateCreated.toDate() - a.dateCreated.toDate())

        resultArray = resultArray.map((data, i) => ({
            ...data,
            owner: getOwner(data.owner),
            dateCreated: data.dateCreated.toDate(),
            status: data.status !== "Completed" && data.status !== "Lost" ? "Ongoing" : data.status,
            num: i + 1
        }))

        excelResult.current = resultArray.map((r, idx) => ({
            Num: idx + 1,
            Status: r.status,
            Stage: r.stage,
            Prospect: r.name,
            Phone_Number: r.phoneNumber,
            Owner: r.owner,
            Value_RM: r.value,
            Payment_Status: r.paymentStatus,
            Payment_Method: r.paymentMethod,
            Date_Created: r.dateCreated.toDateString(),
            Source: r.dealSource,
            Service: r.dealServices,
            PremiseType: r.premiseType,
            Location_Overview: r.locationOverview
        }))

        setIsFetching(false)
        setResult(resultArray)
    }

    useEffect(() => {
        document.querySelector('title').textContent = 'Pipeline - Report'
        isMounted.current = true
        return () => isMounted.current = false
    }, [])

    return (
        <div>
            <div className="option-tab">
                <h3 className="option-tab-name">Pipeline</h3>
                <div className="v-seperator"></div>
                <label htmlFor="salesRep">Sales reps.: </label>
                <select ref={salesRep} name="salesRep" id="salesRep">
                    <option value="all">All</option>
                    {users.map(u => <option key={u.id} value={u.id}>{u.name}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="stage">Stage: </label>
                <select ref={stage} name="stage" id="stage">
                    <option value="all">All</option>
                    {STAGES.map(s => <option key={s} value={s}>{s}</option>)}
                </select>
                <div className="v-seperator"></div>
                <button onClick={generate} className="btn-green">
                    <span style={{ marginRight: "10px" }}>Generate</span>
                    {isFetching && <MiniSpinner wh={20} />}
                </button>
                {result.length > 0 &&
                    <>
                        <div className="v-seperator"></div>
                        <CSVLink data={excelResult.current} filename={`Pipeline_Report_${new Date().toString()}.csv`}>
                            <button className="btn-green">
                                <span>Export to MS Excel</span>
                            </button>
                        </CSVLink>
                        <div className="v-seperator"></div>
                        <button onClick={handlePrint} className="btn-white">
                            <span>Print report</span>
                        </button>
                    </>
                }
            </div>
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {result.length === 0 ?
                <NoResult /> :
                <div className={styles.container}>
                    <Tip />
                    <Table ref={printComp} result={result} />
                </div>
            }
        </div>
    )
}
