import styles from './prospect.module.css'

export default function SearchHit({ hits,viewCallback,historyCallback }) {
    return (
        <>{hits.length === 0 && <p className={styles.nomatch}>No prospect found</p>}
            <ul>
                <div >
                    {hits.map(hit =>{ 
                        hit.id = hit.objectID
                        return (
                        <li className={styles.hit} key={hit.objectID} onMouseDown={()=>viewCallback(hit)} >
                            <div>
                                <h4>{hit.name}</h4>
                                <p>{hit.phoneNumber}</p>
                                <p>{hit.organisation}</p>
                            </div>
                            <div className="buttons">
                                <button 
                                    onMouseDown={(e)=>{e.stopPropagation();historyCallback({pid:hit.objectID,name:hit.name})}} 
                                    className="btn-purple">Deal History</button>
                            </div>
                        </li>
                    )})}
                </div>
            </ul>
        </>
    )
}
