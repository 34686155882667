import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useUsers } from '../../contexts/UsersContext'
import { getRfpListManager,updateRfp } from '../../controllers/rfpController'
import styles from './rfp.module.css'
import { openInNewTab,getOrigin } from '../helper'

const AccessDenied = () => {
    return (
        <div className={styles.access}>
            <span className="material-icons">admin_panel_settings</span>
            <div>
                <h3>Insufficient Permission</h3>
                <p>You need to be a <strong>Rfp Manager (2nd Level)</strong> or <strong>Account Manager</strong> to view the list.</p>
            </div>
        </div>
    )
}

const Table = ({ list,role }) => {
    const { users } = useUsers()

    const getOwner = (id) => {
        return users.filter(u => u.id === id)[0].name
    }

    const onView = (id,e,reads) => {
        const row = e.currentTarget
        row.className = styles.read
        openInNewTab(`${getOrigin()}/rfp/${id}`)
        if(!reads){
           updateRfp(id, {[`reads.${role}`]:true}) 
        }
    }

    return (
        <table className="table" cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    <th>Requester</th>
                    <th>Subject</th>
                    <th>Submission Date</th>
                    <th>Status</th>
                    <th>Applicant</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map(l => (
                        <tr onClick={(e)=>onView(l.id,e,l.reads[role])} className={l.reads[role] ? styles.read : undefined} key={l.id}>
                            <td>{l.requester}</td>
                            <td>{l.subject}</td>
                            <td>{l.dateCreated.toDate().toDateString()}</td>
                            <td>{l.status}</td>
                            <td>{getOwner(l.createdBy)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default function List() {

    const { currentUser } = useAuth()
    const isMounted = useRef()
    const [list, setList] = useState([])
    const [role,setRole] = useState(null)

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = "List - RFP"
        const init = async () => {
            const { claims } = await currentUser.getIdTokenResult()
            if (claims.RfpManager) {
                const result = await getRfpListManager(null);
                if (isMounted.current) {
                    setRole('manager')
                    setList(result.docs.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref })))
                }
            } else if (claims.AccountManager) {
                const result = await getRfpListManager(true);
                if (isMounted.current) {
                    setRole('account')
                    setList(result.docs.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref })))
                }
            } else {
                if (isMounted.current) setList(0)
            }
        }
        init()
        return () => isMounted.current = false
    }, [currentUser])
    return (
        <div>
            {
                list === 0 ?
                    <AccessDenied /> :
                    <>
                        <p>Viewing List as <strong>{role === "manager" ? "RFP Manager (2nd Level)" : "Account Manager" }</strong></p>
                        <Table list={list} role={role} />
                    </>
            }
        </div>
    )
}
