import { db } from "../firebase";

const ref = db.collection('Payment')

export const getPayment = (id) => {
    return ref.doc(id).get()
} 

export const resetPayment = (id) => {
    return ref.doc(id).delete()
}

export const updatePayment = (id,data) => {
    return ref.doc(id).update(data)
}

export const setPayment = (id,data) => {
    return ref.doc(id).set(data)
}