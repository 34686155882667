import NoteComponent from "./NoteComponent"
import ActivityComponent from "./ActivityComponent"

export default function Records({dataArray}) {

    const render = (data) => {
        switch (data.type) {
            case "Note":
                return <NoteComponent key={data.id} data={data}/>
            default:
                return <ActivityComponent key={data.id} data={data}/>
        }
    }

    return (
        <div>
            {dataArray.map(d => render(d))}
        </div>
    )
}
