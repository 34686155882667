import styles from './wall.module.css'
import DetailContainer from './DetailContainer'
import PaymentContainer from './PaymentContainer'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import DealInformation from './DealInformation'
import LoadingContainer from './LoadingContainer'
import ProspectInformation from './ProspectInformation'
import Records from './Records'
import { createContext, useState, useEffect } from 'react'
import DialogAddNote from './DialogAddNote'
import DialogAddActivity from './DialogAddActivity'
import ServiceOrderComponent from './ServiceOrderComponent'
import StageHistoryComponent from './StageHistoryComponent'
import EditProspectDialog from './EditProspectDialog'
import EditDealDialog from './EditDealDialog'
import { updateDeal } from '../../../controllers/dealController'
import AssignPerson from './AssignPerson'
import StatusComponent from './StatusComponent'
import CloseDealComponent from './CloseDealComponent'
import { useParams } from 'react-router-dom'

export const SharedFunctions = createContext(null)

const NoRecordComp = () => {
    return (
        <div className={styles.no__record}>
            <p>This deal does not have any activity yet.. <strong>Put something on the wall !</strong></p>
        </div>
    )
}

const WallActions = ({ setAddActivity, setAddNote, id }) => {
    return (
        <div className={styles.action}>
            <h3>Put something on the Wall...</h3>

            <div className={styles.label2} onClick={() => setAddNote(id)}>
                <span className="material-icons">add</span>
                <span>Add Note</span>
            </div>

            <div className={styles.label2} onClick={() => setAddActivity(id)}>
                <span className="material-icons">add</span>
                <span>Add Activity</span>
            </div>
        </div>
    )
}

export default function MainContainer({ prefs, setDeal, setProspect, data, id, uid, setActivities }) {

    const { status } = useParams()
    const { prospect, deal, activities } = data
    const [addNote, setAddNote] = useState(null)
    const [addActivity, setAddActivity] = useState(null)
    const [toggleProspect, setToggleProspect] = useState(false)
    const [toggleDeal, setToggleDeal] = useState(false)
    const [mcs, setMcs] = useState(deal?.isConfirmedSale ?? false)
    const [toggleAP, setToggleAP] = useState(false)

    useEffect(() => {
        setMcs(deal?.isConfirmedSale ?? false);
    }, [deal])

    const onCheckMcs = async (e) => {
        setMcs(!mcs)
        await updateDeal(id, { isConfirmedSale: e.target.checked }).catch(err => console.error(err))
    }

    const ppCallback = (newData) => {
        setProspect(prev => ({ ...prev, ...newData }))
        setDeal(prev => ({
            ...prev,
            email: newData.email,
            name: newData.name,
            organisation: newData.organisation,
            phoneNumber: newData.phoneNumber
        })
        )
        setToggleProspect(false)
    }

    const dealCallback = (newData) => {
        setToggleDeal(false)
        setDeal(prev => ({
            ...prev,
            ...newData,
        }))
    }

    return (
        <div className={styles.main}>
            <div>
                <div className={styles.left}>
                    <PaymentContainer data={{ did: deal?.id, value: deal?.value, paymentMethod: deal?.paymentMethod }} />
                    <DetailContainer editable={status === undefined} onClick={() => setToggleProspect(true)} title="Prospect">
                        {prospect === null ? <LoadingContainer text="Loading prospect information..." /> : <ProspectInformation data={prospect} />}
                    </DetailContainer>
                    <DetailContainer editable={status === undefined} onClick={() => setToggleDeal(true)} title="Deal Information">
                        {deal === null ? <LoadingContainer text="Loading deal information.." /> : <DealInformation deal={deal} />}
                    </DetailContainer>
                </div>
                <div className={styles.middle}>
                    {deal && prospect &&
                        <ServiceOrderComponent
                            prospect={prospect}
                            soid={deal.soid}
                            title={deal.title}
                            services={deal.dealServices}
                            did={deal.id}
                        />}

                    {deal && <StageHistoryComponent dealCreatedDate={deal.dateCreated} stageState={deal.stage} />}

                    <SharedFunctions.Provider value={{ setActivities, did: id }}>
                        {!activities && <LoadingContainer text="Loading activities..." />}
                        {activities && activities.length > 0 ? <Records dataArray={activities} /> : <NoRecordComp />}
                    </SharedFunctions.Provider>
                </div>
                <div className={styles.right}>
                    <div className={`${styles.f_col_exclude}`}>
                        <div className="flex__row">
                            <input disabled={status !== undefined} checked={mcs} onChange={onCheckMcs} type="checkbox" name="mcs" id="mcs" />
                            <label htmlFor="mcs">Mark as Confirmed Sale (For deal creation month)</label>
                        </div>
                        <div className={styles.label}>
                            <span className="material-icons">attach_money</span>
                            <span>Deal value</span>
                        </div>
                        <h3>
                            {deal === null ? <MiniSpinner wh="25" /> : `RM ${deal.value ?? "0.00"}`}
                        </h3>
                    </div>
                    {status === undefined &&
                        <div className={styles.f_col} style={{padding:0}}>
                            <CloseDealComponent pid={deal?.pid} />
                        </div>}

                    <div className={styles.f_col_exclude}>
                        <div className={styles.label}>
                            <span className="material-icons">info</span>
                            <span>Status</span>
                        </div>
                        <>
                            {deal === null ? <MiniSpinner wh="25" /> : <StatusComponent status={deal.status} />}
                        </>
                    </div>

                    <div className={styles.f_col} onClick={() => { status === undefined && setToggleAP(true) }}>
                        <div className={styles.label}>
                            <span className="material-icons">person</span>
                            <span>Assigned Person</span>
                        </div>
                        <h3>
                            {deal === null ? <MiniSpinner wh="25" /> : `${deal.owner}`}
                        </h3>
                    </div>
                    {
                        status === undefined ?
                            <WallActions setAddActivity={setAddActivity} setAddNote={setAddNote} id={id} /> :
                            deal !== null &&
                            <>
                                <h3>Deal has been closed on {deal.closingDate.toDate().toDateString()}</h3>
                                <h4>You cannot do any changes on a closed deal.</h4>
                                <h4>Closing remarks</h4>
                                <p>{deal.closingRemarks}</p>
                            </>
                    }


                </div>
            </div>

            {addNote &&
                <DialogAddNote
                    onCancel={() => setAddNote(null)}
                    id={id}
                    uid={uid}
                    onAdded={(data) => { setActivities(prev => [data, ...prev]) }}
                />
            }
            {addActivity && prospect && deal &&
                <DialogAddActivity
                    defValue={{
                        prospect: prospect.name,
                        location: deal.locationOverview,
                        services: deal.dealServices,
                        path: deal.path
                    }}
                    onCancel={() => setAddActivity(null)}
                    id={id}
                    uid={uid}
                    onAdded={(data) => { setActivities(prev => [data, ...prev]) }}
                />
            }

            {toggleProspect &&
                <EditProspectDialog
                    onCancel={() => setToggleProspect(false)}
                    prospect={prospect}
                    callback={ppCallback}
                />
            }

            {toggleDeal &&
                <EditDealDialog
                    prefs={prefs}
                    onCancel={() => setToggleDeal(false)}
                    deal={deal}
                    callback={dealCallback}
                />
            }

            {toggleAP &&
                <AssignPerson
                    setDeal={setDeal}
                    onCancel={() => setToggleAP(false)}
                />
            }

        </div>
    )
}
