import React, { useState, useEffect, useRef, useContext } from 'react'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate';
import styles from './activities.module.css'
import { addSchedule, removeSchedule } from '../../../controllers/externalStaffController';
import { updateActivityWithDocumentPath } from '../../../controllers/dealController'
import { ListActCtx } from './ListCalendar';

export default function StaffDialog({ staffArray, onCancel, title, customKey, actPath, aid, data }) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const isMounted = useRef()
    const { setActList, actList } = useContext(ListActCtx)
    const currActivity = useRef(actList.filter(d => aid === d.id)[0])

    const onSave = async () => {
        const checkeds = document.querySelectorAll('#cb-staff:checked')
        const SIDS = Array.from(checkeds).map(inp => inp.dataset.id)
        const defaultStaff = currActivity.current.staffs?.[customKey]

        const staffData = {
            AID: aid,
            date: customKey,
            ...data
        }

        setIsLoading(true)
        //First time add staff
        let IDS;
        let IDSData;
        if (defaultStaff === undefined || defaultStaff === null || defaultStaff.length === 0) {
            IDS = await Promise.all(SIDS.map(sid => addSchedule(sid, {...staffData,staff:staffArray.filter(d=>d.id===sid)[0].fullName}))).catch(err => {
                console.error(err)
                return
            })

            if(IDS)
            IDSData = IDS.map(d => ({ SID: d.path.split('/')[1], path: d.path }))
        } else {

            const defaultIDS = defaultStaff.map(d => d.SID)
            let isEqual = defaultIDS.length === SIDS.length &&
                defaultIDS.every((value, index) => value === SIDS[index]);

                if(!isEqual){
                    const added = SIDS.filter( function(n) { return !this.has(n) }, new Set(defaultIDS));;
                    const removed = defaultIDS.filter( function(n) { return !this.has(n) }, new Set(SIDS));

                    if(added.length > 0){
                        IDS = await Promise.all(added.map(sid => addSchedule(sid, {...staffData,staff:staffArray.filter(d=>d.id===sid)[0].fullName}))).catch(err => {
                            console.error(err)
                            return
                        })

                        if(IDS)
                        IDSData = [...defaultStaff,...IDS.map(d => ({ SID: d.path.split('/')[1], path: d.path }))]
                    }else{
                        IDSData = defaultStaff
                    }

                    if(removed.length > 0) {
                        let removedStaff =  []
                        removed.forEach(sid => {
                            removedStaff.push(defaultStaff.filter(data => data.SID === sid)[0])
                            IDSData =  IDSData.filter(data => data.SID !== sid)
                        });

                        await Promise.all(removedStaff.map(data => removeSchedule(data.path))).catch(err => {
                            console.error(err)
                            return
                        })
                    }
                }
        }

        if(IDSData){
            let dataUp = currActivity.current.staffs
            dataUp === undefined ?
                dataUp = { staffs: { [customKey]: IDSData } } : //If Never set a custom data
                dataUp = { staffs: { ...dataUp, [customKey]: IDSData } } // Merge / Update existing
            //Upload Operation goes here
            await updateActivityWithDocumentPath(actPath, dataUp)
                .catch(err => console.error(err))

            //Update state to match latest data, to make it accessible throughout this whole table
            // console.log(dataUp)
            currActivity.current.staffs = dataUp.staffs
            setActList(prev => {
                const idx = prev.findIndex(e => e.id === currActivity.current.id)
                prev.splice(idx, 1, currActivity.current)
                const newArr = Array.from(prev)
                return newArr
            })
        
        }

        setIsLoading(false)
        onCancel()
    }

    useEffect(() => {
        isMounted.current = true
        const defaultStaffs = currActivity.current.staffs?.[customKey]
        if (defaultStaffs === undefined) return

        const cbs = Array.from(document.querySelectorAll('#cb-staff'))

        for (let i = 0; i < cbs.length; i++) {
            const cb = cbs[i];
            for (let j = 0; j < defaultStaffs.length; j++) {
                const staff = defaultStaffs[j];
                if (cb.dataset.id === staff.SID) {
                    cb.checked = true
                }
            }
        }

        return () => isMounted.current = false
    }, [customKey])

    return (
        <DialogTemplate width="800px" title={"Assign Staff | " + title} toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}

            <div>
                <h4>Select staff to be assigned</h4>
                {
                    staffArray.map(staff => (
                        <div className={styles.staffTable} key={staff.id}>
                            <div>
                                <input data-id={staff.id} type="checkbox" name="cb-staff" id="cb-staff" />
                            </div>
                            <div>
                                <p>{staff.fullName}</p>
                            </div>
                            <div>
                                <p>{staff.type}</p>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="buttons-dialog__form" >
                <button
                    disabled={isLoading}
                    type="button"
                    className="btn-white"
                    onClick={() => { setError(null); onCancel() }}
                >Close</button>
                <button disabled={isLoading} onClick={onSave} className="btn-green">
                    {isLoading ? 'Saving...' : 'Save'}
                </button>
            </div>
        </DialogTemplate>
    )
}
