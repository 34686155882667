import React, { useState, useRef } from 'react'
import Header from '../../../components/Header/Header'
import Pipeline from './Pipeline'
import Deals from './Deals'
import Services from './Services'
import StaffManagement from './StaffManagement'
import Prospects from './Prospects'


function handleSwitch(ref) {
    document.querySelector('.tab.active').classList.remove("active")
    ref.current.classList.add("active")
}

function SubHeader({ setToggle }) {

    const deals = useRef()
    const prospects = useRef()
    const pipeline = useRef()
    const services = useRef()
    const staffMg = useRef()

    return (
        <div className="subheader">
            <div className="tab active" ref={deals} onClick={(e) => {
                handleSwitch(deals)
                setToggle('deals')
            }}>
                <span className="material-icons">feed</span>
                <span>Deals</span>
            </div>

            <div className="tab " ref={prospects} onClick={(e) => {
                handleSwitch(prospects)
                setToggle('prospects')
            }}>
                <span className="material-icons">person</span>
                <span>Prospects</span>
            </div>

            <div className="tab " ref={pipeline} onClick={(e) => {
                handleSwitch(pipeline)
                setToggle('pipeline')
            }}>
                <span className="material-icons">view_agenda</span>
                <span>Pipeline</span>
            </div>

            <div className="tab " ref={services} onClick={(e) => {
                handleSwitch(services)
                setToggle('services')
            }}>
                <span className="material-icons">miscellaneous_services</span>
                <span>Services</span>
            </div>

            <div className="tab " ref={staffMg} onClick={(e) => {
                handleSwitch(staffMg)
                setToggle('staffMg')
            }}>
                <span className="material-icons">manage_accounts</span>
                <span>Staff Management</span>
            </div>

        </div>
    )
}

export default function Report() {
    const [toggle, setToggle] = useState('deals');

    return (
        <>
            <Header title="Report" />
            <SubHeader setToggle={setToggle} />

            <div className="tab-contents">
                {toggle === "deals" && <Deals />}
                {toggle === "prospects" && <Prospects />}
                {toggle === "pipeline" && <Pipeline />}
                {toggle === "services" && <Services />}
                {toggle === "staffMg" && <StaffManagement />}
            </div>
        </>
    )
}
