import React, { useState, useRef, useEffect } from 'react'
import Header from '../../../components/Header/Header'
import '../crm.css'
import Pipeline from './Pipeline'
import DealsTable from './DealsTable'
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom'
import searchClient from '../../../algolia'
import SearchComponent from '../../../components/SearchComponent/SearchComponent'
import SearchHit from './SearchHit'

const HitComp = connectHits(SearchHit)

function SubHeader({ setToggle }) {

    const pipeline = useRef()
    const list = useRef()


    useEffect(() => {

        if(list.current === null || pipeline.current === null) return

        const handleHashChange = () => {
            if (window.location.hash === "#pipeline") {
                list.current.classList.remove("active")
                pipeline.current.classList.add("active")
                setToggle("pipeline")
            } else {
                pipeline.current.classList.remove("active")
                list.current.classList.add("active")
                setToggle("list")
            }
        }

        handleHashChange();

        window.addEventListener("hashchange", handleHashChange, false);

        return () => window.removeEventListener("hashchange",handleHashChange);
        
    }, [setToggle,list,pipeline])

    return (
        <div className="subheader">
            <div className="tab active" ref={pipeline} onClick={(e) => {
                window.location.href = "#pipeline"
            }}>
                <span className="material-icons">view_agenda</span>
                <span>Pipeline</span>
            </div>

            <div className="tab " ref={list} onClick={(e) => {
                window.location.href = "#list"
            }}>
                <span className="material-icons">list</span>
                <span>List</span>
            </div>

            <SearchComponent>
                <HitComp />
            </SearchComponent>

        </div>
    )
}

export default function Deals() {
    const [toggle, setToggle] = useState('pipeline');

    return (
        <>
            <InstantSearch
                indexName={process.env.REACT_APP_ALGOLIA_INDEX_DEALS}
                searchClient={searchClient}>
                <Configure
                    hitsPerPage={5}
                    attributesToRetrieve={["name", "organisation", "phoneNumber", "value", "ticket", "status"]}
                />
                <Header title="Deals" />
                <SubHeader setToggle={setToggle} />
                <div className="tab-contents">
                    {toggle === "pipeline" ?
                        <Pipeline /> :
                        <DealsTable />
                    }
                </div>
            </InstantSearch>
        </>
    )
}
