import { db } from "../firebase";

const ref = db.collection("AppSettings")

export const getVersion = () => {
    return ref.doc("Version").get()
}

export const getCompanyInfo = () => {
    return ref.doc("Company").get()
}

export const  updateCompanyInfo = async (key,value) => {
    return ref.doc("Company")
        .update({[key]:value})
        .catch(err => {
            //first time
            return ref.doc('Company').set({[key]:value},{merge:true})
        })
}