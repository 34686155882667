import React,{useState} from 'react'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate'
import { useUsers } from '../../../contexts/UsersContext'
import styles from './wall.module.css'
import { updateDeal } from '../../../controllers/dealController'
import { useParams } from 'react-router'

const User = ({ user, setIsLoading, setError,isLoading,did,callback }) => {

    const click = async () => {
        setIsLoading(true)
        setError(null)
        await updateDeal(did,{owner:user.id}).catch(err => {setIsLoading(false);setError(err)})
        setIsLoading(false)
        callback(user.name)
    }

    return (
        <>
            <div className={styles.ap__cont}>
                <p>{user.name}</p>
                <button disabled={isLoading} onClick={click} className="btn-purple">{isLoading ? 'Changing...' : 'Change'}</button>
            </div>
        </>
    )
}

export default function AssignPerson({ onCancel, setDeal }) {

    const [error,setError] = useState(null)
    const [isLoading,setIsLoading] = useState(false)
    const { users } = useUsers()
    const {id} = useParams()

    const callback = (name) => {
        setDeal(prev => ({...prev,owner:name}))
    }

    return (
        <DialogTemplate title="Change Assigned Person" toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {users.map((user) => {
                return <User key={user.id} callback={callback} did={id} user={user} isLoading={isLoading} setIsLoading={setIsLoading} setError={setError} />
            })}

            <div className="buttons-dialog__form" >
                <button
                    disabled={isLoading}
                    style={{ marginLeft: 'auto' }}
                    type="button"
                    className="btn-white"
                    onClick={() => { onCancel() }}
                >Close</button>
            </div>
        </DialogTemplate>
    )
}
