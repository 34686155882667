import { db,timestamp ,convertTimestamp } from "../firebase";

const ref = db.collection("Deals")

export const getDeal = (id) => {
    return ref.doc(id).get()
}

export const updateSoid = (id,soid) => {
    return ref.doc(id).update({
        dateUpdated: timestamp,
        soid
    })
}

export const getActivities = (id) => {
    return ref.doc(id).collection("Activities").orderBy("dateCreated","desc").get()
}

export const setActivity = (id,data,uid) => {
    return ref.doc(id).collection("Activities").add({
        ...data,
        dateCreated: timestamp,
        dateUpdated: timestamp,
        start:convertTimestamp(data.start),
        end: data.end === null ? null : convertTimestamp(data.end),
        createdBy:uid,
        hide:false
    })
}

export const setNote = (id,data,uid) => {
    return ref.doc(id).collection("Activities").add({
        ...data,
        dateCreated: timestamp,
        dateUpdated: timestamp,
        createdBy:uid
    })
}

export const updateNote = (id,data,aid) => {
    return ref.doc(id).collection("Activities").doc(aid).update({
        dateUpdated: timestamp,
        ...data
    })
}

export const updateActivity = (id,data,aid) => {
    return ref.doc(id).collection("Activities").doc(aid).update({
        dateUpdated: timestamp,
        ...data,
        start:convertTimestamp(data.start),
        end: data.end === null ? null : convertTimestamp(data.end),
    })
}

export const updateHideActivity = (hide,id,aid) => {
    return ref.doc(id).collection("Activities").doc(aid).update({
        dateUpdated: timestamp,
        hide
    })
}

export const deleteActivity = (id,aid) => {
    return ref.doc(id).collection("Activities").doc(aid).delete()
}

export const getWallLogs = (id) => {
    return ref.doc(id).collection("Logs").orderBy("dateCreated","desc").get()
}

export const setStageLog = (id,uid,data) => {
    return ref.doc(id).collection("Logs").add({
        dateCreated: timestamp,
        createdBy:uid,
        type:"stage",
        ...data
    })
}

export const updateDeal = (did,data) => {
    return ref.doc(did).update({
        ...data,
        dateUpdated: timestamp,
    })
}

export const updateStage = (uid,did,data) => {
    return ref.doc(did).update({
        stage:data.stage,
        dateUpdated: timestamp,
        updatedBy:uid
    }
    ).then(() => {
        return setStageLog(did,uid,{from:data.from,to:data.to})
    })
}

export const transferBulkOwnership = (oid,ids) => {
    const batch = db.batch()

    ids.forEach(id => {
        batch.update(ref.doc(id),{owner:oid})
    });

    return batch.commit()
}

export const activitiesRef = db.collectionGroup("Activities").where("type","in",["Job","Meeting","Others"]).orderBy("dateCreated","desc");
export const activitiesRefRaw = db.collectionGroup("Activities").where("type","in",["Job","Meeting","Others"])
export const activitiesRefDef = db.collectionGroup("Activities")

export const updateActivityWithDocumentPath = (path,data) => {
    return db.doc(path).update({
        dateUpdated: timestamp,
        ...data
    })
}