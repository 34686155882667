import { db, timestamp } from "../firebase";

export const ref = db.collection('leaveApplication');

export const checkRole = () => {
    return ref.doc('config').get();
}

export const setManager = async (obj) => {
    try {
        return await ref.doc('config').update('managers', obj);
    } catch (err) {
        if (err.code === 'not-found') {
            return ref.doc('config').set({'managers': obj});
        }
    }
}

export const setEntitlement = async (obj) => {
    try {
        return await ref.doc('config').update('entitlement', obj);
    } catch (err) {
        if (err.code === 'not-found') {
            return ref.doc('config').set({'entitlement': obj});
        }
    }
}

export const getStats = (uid,year) => {
    return ref.doc(uid).collection('stats').doc(year).get()
}

export const setDefaultStats = (uid,year,obj) => {
    return ref.doc(uid).collection('stats').doc(year).set(obj);
}

export const createManagerRefandDocID = (year,dept) => {
    return ref.doc(`applications${year}`).collection(dept).doc()
}

export const createReqRef = (year,did,uid) => {
    return ref.doc(uid).collection(`applications${year}`).doc(did)
}

export const setManagersCollection = (managerRef,data) => {
    return managerRef.set({...data,requestDate:timestamp});
}

export const setStaffData = (doc,data) => {
    return doc.set({...data,requestDate:timestamp})
}

export const incrementStaffStats = (uid,year,isMc = false, duration = 1) => {
    const staffDocRef = ref.doc(uid).collection('stats').doc(year);

    return db.runTransaction((transaction) => {
        return transaction.get(staffDocRef).then((doc) => {
            if(!doc.exists){
                throw new Error("Document does not exist, please contact your administrator.");
            }

            const data = doc.data()
            let newData;
            if(isMc){
                newData = {mcUsed : data.mcUsed + duration}
            }else{
                newData = {leaveUsed : data.leaveUsed + duration}
            }
            return transaction.update(staffDocRef,newData)
        })
    })
}

export const decrementStaffStats = (uid,year,isMc = false,duration = 1) => {
    const staffDocRef = ref.doc(uid).collection('stats').doc(year);

    return db.runTransaction((transaction) => {
        return transaction.get(staffDocRef).then((doc) => {
            if(!doc.exists){
                throw new Error("Document does not exist, please contact your administrator.");
            }
 
            const data = doc.data()
            let newData;
            if(isMc){
                newData = {mcUsed : data.mcUsed - duration}
            }else{
                newData = {leaveUsed : data.leaveUsed - duration}
            }
            return transaction.update(staffDocRef,newData)
        })
    })
}


export const getMyList = (year,uid) => {
    return ref.doc(uid).collection(`applications${year}`).orderBy('requestDate','desc').get()
}

export const getManagerList = (year,dept) => {
    return ref.doc(`applications${year}`).collection(dept).where("cancelled","==",false).orderBy('requestDate','desc').get()
}

export const updateSelfRead = (docRef,value) => {
    return db.doc(docRef).update({'read.self':value})
}

export const updateManagerRead = (docRef,value) => {
    return db.doc(docRef).update({'read.manager':value})
}

export const cancelReq = (docRef) => {
    return db.doc(docRef).update({'cancelled':true,'status':'cancelled'})
}

export const updateStatus = (docRef,status,remarks = '') => {

    if(status === 'rejected'){
        return db.doc(docRef).update({status,remarks})
    }

    return db.doc(docRef).update({status})
}

export const getApplications = (year) => {
    return db.collectionGroup('stats')
        .where('year','==',year)
        .get()
}