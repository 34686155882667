import React, { useRef, useState } from 'react'
import styles from './wall.module.css'
import DialogClosingDeal from './DialogClosingDeal'
import RedirectComponent from './RedirectComponent'

//Styling in the global.css

function CloseDealComponent({pid}) {

    const dropdownMenu = useRef()
    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleRedirect,setToggleRedirect] = useState(false)
    const dialogTitle = useRef('')

    const clickStatus = (e) => {
        e.stopPropagation()
        if(pid === undefined || pid === null) return
        dropdownMenu.current.classList.toggle('active')
    }

    const click = async (e) => {
        e.stopPropagation()
        const close = e.target.textContent

        if(close === "Completed"){
            dialogTitle.current={title:"Deal Completion",message:"Are you sure to close this deal ?",type:"Completed"}
        }else if(close === "Lost"){
            dialogTitle.current={title:"Deal Lost",message:"Are you sure to declare this deal as a lost ?",type:"Lost"}
        }else{
            dialogTitle.current={title:"Deal Deletion",message:"Are you sure to delete this deal ?",type:"Deleted"}
        }

        dropdownMenu.current.classList.remove('active')
        setToggleDialog(true)
    }

    return (
        <>
            <div onClick={clickStatus} className={`${styles.label} closing__dropdown`} style={{padding:'10px 10px'}}>
                <span className="material-icons">archive</span>
                <span>Close Deal</span>
                <div ref={dropdownMenu} id="closingDropdownMenu" className='closing__dropdown_menu'>
                    <p onClick={click}>Completed</p>
                    <p onClick={click}>Lost</p>
                    <p onClick={click}>Delete</p>
                </div>
            </div>
            {
                toggleDialog &&
                <DialogClosingDeal
                    pid={pid}
                    setRedirect={setToggleRedirect} 
                    title={dialogTitle.current}
                    onCancel={()=>setToggleDialog(false)}
                />
            }

            {
                toggleRedirect &&
                <RedirectComponent 
                    which={dialogTitle.current.type}
                />
            }
        </>
    )
}


export default React.memo(CloseDealComponent)
