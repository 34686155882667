import Row from "./Row"
import React from "react"

const DealInformation = ({ deal }) => {
    return (
        <>
            <Row left="Date created" right={deal.dateCreated.toDate().toDateString()} />
            <Row left="Title" right={deal.dealTitle} />
            <Row left="Source" right={deal.dealSource} />
            <Row left="Service(s)" right={deal.dealServices} />
            <Row left="Estimated closing date" right={deal.ECD} />
            <Row left="Location overview" right={deal.locationOverview} />
            <Row left="Premise type" right={deal.premiseType} />
            <Row left="Premise size (sqft)" right={deal.premiseSize} />
            <Row left="Have pets ?" right={deal.pets} />
            <Row left="Number of bathroom" right={deal.bathroom} />
            <Row left="Number of bedroom" right={deal.bedroom} />
            <Row left="Furnishing type" right={deal.furnishType} />
            <Row left="Renovation / paint" right={deal.renovation} />
            <Row left="Remarks" right={deal.remarks} />
        </>
    )
}

export default React.memo(DealInformation)