import { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { Redirect, useParams } from 'react-router-dom'
import styles from './wall.module.css'
import { getDeal, getActivities } from '../../../controllers/dealController'
import { getProspect } from '../../../controllers/prospectController'
import Header from './Header'
import StageProgress from './StageProgress'
import MainContainer from './MainContainer'
import ErrorComponent from './ErrorComponent'
import { useUsers } from '../../../contexts/UsersContext'
import { fetchDealPreferences } from '../../../controllers/appPreferencesController'
import { getArchiveWithPath, getArchiveCollectionWithPath } from '../../../controllers/archiveController'

export default function Wall() {
    const { currentUser,isAuthenticated } = useAuth()
    const { id, status } = useParams()
    const [error, setError] = useState(null)
    const isMounted = useRef(false)
    const [deal, setDeal] = useState(null)
    const [prospect, setProspect] = useState(null)
    const [activities, setActivities] = useState(null)
    const { users } = useUsers()
    const servicesRef = useRef([])
    const sourcesRef = useRef([])


    const fetchPrefs = async () => {
        const result = await fetchDealPreferences()
        if (!result.exists) {
            if (isMounted.current) {
                setError({
                    message: "Deal preferences does not exist!",
                    code: "404-Deal-Pref-Not-Exist"
                })
                return {
                    services: [],
                    sources: []
                }
            }
        } else {
            const data = result.data()
            return {
                services: data.services,
                sources: data.sources
            }
        }
    }

    

    const fetchProspect = async (pid) => {
        const res = await getProspect(pid).catch(err => {
            if (isMounted.current) {
                setError(err)
            }
        });
        if (!res.exists) {
            if (isMounted.current) {
                setError({
                    message: "Prospect does not exist!",
                    code: "404-Prospect-Not-Exist"
                })
            }
        } else {
            if (isMounted.current) {
                setProspect({ ...res.data(), id: pid })
            }
        }
    }

   
    useEffect(() => {
        isMounted.current = true
        document.querySelector("title").textContent = "Wall"
        if(!isAuthenticated || users.length === 0) return
        //document.querySelector('nav').style.display = "none"

        const fetchRecord = async () => {
            let querySnapshot = null
    
            if (status !== undefined) {
                let path = `Archive/Deals/${status}/${id}/Activities`;
                if(status === "Deleted") {
                    path = `Archive/Deals/${status}/${id}/Records`;
                }
                querySnapshot = await getArchiveCollectionWithPath(path).catch(err => {
                    if (isMounted.current) {
                        setError(err)
                    }
                });
            } else {
                querySnapshot = await getActivities(id).catch(err => {
                    if (isMounted.current) {
                        setError(err)
                    }
                });
            }
    
    
            if (querySnapshot.size === 0) {
                setActivities([])
            } else {
                if (isMounted.current) {
                    setActivities(querySnapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }))
                }
            }
        }

        const fetchDeal = async () => {
            let dealRes = null;
    
            if (status !== undefined) {
                const path = `Archive/Deals/${status}/${id}`;
    
                dealRes = await getArchiveWithPath(path).catch(err => {
                    if (isMounted.current) {
                        setError(err)
                        return null
                    }
                })
    
            } else {
                dealRes = await getDeal(id).catch(err => {
                    if (isMounted.current) {
                        setError(err)
                        return null
                    }
                })
            }
            if (!dealRes.exists) {
                if (isMounted.current) {
                    setError({
                        message: "Deal does not exist!",
                        code: "404-Deal-Not-Exist"
                    })
                    return isMounted.current = false
                }
            } else {
                if (isMounted.current) {
                    document.querySelector("title").textContent = `${dealRes.data().name} - Wall`
                    setDeal({ ...dealRes.data(), id: dealRes.id, path:dealRes.ref.path })
                    return { pid: dealRes.data().pid, ownerID: dealRes.data().owner }
                }
            }
        }

        const fetchData = async () => {
            if (isMounted.current) {
                const { pid, ownerID } = await fetchDeal()


                if (currentUser.uid === ownerID) {
                    setDeal(prevState => ({
                        ...prevState,
                        owner: currentUser.displayName
                    }))
                } else {
                    console.log(users,ownerID)
                    setDeal(prevState => ({
                        ...prevState,
                        owner: users.filter((u) => { return u.id === ownerID })[0].name
                    }))
                }

                const { services, sources } = await fetchPrefs()
                servicesRef.current = services
                sourcesRef.current = sources
                await fetchProspect(pid)
                await fetchRecord()
            }
        }

        fetchData()
        return () => isMounted.current = false
    }, [id,users,status,currentUser.uid,currentUser.displayName,isAuthenticated])

    return (
        <>
            {currentUser !== null ?
                <div className={styles.parent}>
                    <Header data={{ title: deal?.name, organisation: deal?.organisation, ticket: deal?.ticket }} />
                    <StageProgress setError={setError} setDeal={setDeal} stage={deal?.stage ?? null} />
                    <MainContainer prefs={{ services: servicesRef.current, sources: sourcesRef.current }} setDeal={setDeal} setProspect={setProspect} data={{ deal, prospect, activities, users }} setActivities={setActivities} id={id} uid={currentUser.uid} />
                    {error && <ErrorComponent err={error} />}
                </div> :
                <Redirect to="/login" />
            }

            {
                error !== null && error.code === "404-Deal-Not-Exist" &&
                <Redirect to="/404-not-found" />
            }
        </>
    )
}
