import React, { useState, useContext } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useUsers } from '../../../contexts/UsersContext'
import styles from './wall.module.css'
import Indicator from './Indicator'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import { deleteActivity, updateHideActivity } from '../../../controllers/dealController'
import { SharedFunctions } from './MainContainer'
import DialogEditActivity from './DialogEditActivity'
import { useParams } from 'react-router-dom'

function ActivityComponent({ data }) {
    const { status } = useParams()
    const { currentUser } = useAuth()
    const { users } = useUsers()
    const [isLoading, setIsLoading] = useState('')
    const [error, setError] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const { setActivities, did } = useContext(SharedFunctions)
    const [hide, setHide] = useState(data.hide ?? false)

    const style = {
        borderLeft: "5px solid #FA3E3E"
    }

    const onHideActivity = async () => {
        setHide(!hide)//This takes time thats y we need !hide for below too

        setIsLoading("Hiding...")
        await updateHideActivity(!hide, did, data.id).catch(err => {
            setError(err)
            setIsLoading('')
        })
        setIsLoading('')
        setActivities(prev => prev.map(p => (p.id === data.id ? { ...p, hide: !hide } : p)))
    }

    const getCreator = () => {
        if (data.createdBy === currentUser.uid) {
            return currentUser.displayName
        } else {
            return users.filter((u) => u.id === data.createdBy)[0].name
        }
    }

    const getRange = () => {
        try {
            if (data.end === null || data.start === data.end) return data.start.toDate().toDateString()
            return `${data.start.toDate().toDateString()} - ${data.end.toDate().toDateString()}`
        } catch (error) {
            if (data.end === null || data.start === data.end) return data.start.toDateString()
            return `${data.start.toDateString()} - ${data.end.toDateString()}`
        }

    }

    let date = null;
    try {
        date = data.dateCreated.toDate().toDateString()
    } catch (error) {
        date = data.dateCreated
    }

    const onUpdated = (newData) => {
        setIsEditing(false)
        setActivities(prev => prev.map(p => (p.id === data.id ? { ...p, ...newData } : p)))
    }

    const onDelete = async () => {
        setIsLoading("Deleting...")
        await deleteActivity(did, data.id).catch(err => {
            setError(err)
            setIsLoading('')
        })
        setIsLoading('')
        setActivities(prev => prev.filter((p) => p.id !== data.id))
    }

    return (
        <div className={styles.record__item}>
            <Indicator type="EVENT" />
            <div className={styles.record__detail} style={style}>
                <div className={styles.act__cont}>
                    <div className={styles.act__header}>
                        <p>{data.type} created on <strong>{date}</strong> by <strong>{getCreator()}</strong> </p>
                        <div className={styles.act__actions}>
                            <label htmlFor="hideAct">Hide</label>
                            <input disabled={status !== undefined} onChange={onHideActivity} checked={hide} style={{ height: "18px", width: "18px", margin: "0 10px" }} type="checkbox" name="hideAct" />

                            {status === undefined &&
                                <>
                                    <span className="material-icons edit" onClick={() => setIsEditing(true)}>edit</span>
                                    <span className="material-icons delete" onClick={onDelete} >delete</span>
                                </>
                            }
                        </div>
                    </div>
                    {isLoading && <div className={styles.act__loading} ><MiniSpinner wh="25" /><p>{isLoading}</p></div>}
                    <h3 style={{ marginBottom: "10px" }}>{data.title}</h3>
                    <div className="flex__row" style={{ margin: "5px 0" }}>
                        <span className="material-icons">today</span>
                        <p style={{ fontWeight: "var(--medium)" }}>{getRange()}</p>
                    </div>
                    <div className="flex__row" style={{ margin: "5px 0" }}>
                        <span className="material-icons">schedule</span>
                        <p style={{ fontWeight: "var(--medium)" }}>{data.time}</p>
                    </div>
                    <div className="flex__row" style={{ margin: "5px 0" }}>
                        <span className="material-icons">update</span>
                        <p style={{ fontWeight: "var(--medium)" }}>{data.duration} hrs</p>
                    </div>
                    <p className={styles.notes}>{data.details}</p>
                    {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                </div>
            </div>

            {
                isEditing &&
                <DialogEditActivity
                    onUpdated={onUpdated}
                    data={data}
                    did={did}
                    onCancel={() => setIsEditing(false)}
                />
            }

        </div>
    )
}

export default React.memo(ActivityComponent)
