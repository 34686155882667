import React,{useState} from 'react'
import { Redirect } from 'react-router-dom';
import DialogTemplate from '../../../components/Dialogs/DialogTemplate';

export default function RedirectComponent({ which }) {
    const [redirect, setRedirect] = useState(false)
    let prep;

    if (which === "Completed") {
        prep = { title: "Deal Completion", message: "Successfully completed this deal and moved to Archive. You will be redirected to Deals." }
    } else if (which === "Lost") {
        prep = { title: "Deal Lost", message: "Deal has been declared as a lost and moved to Archive. You will be redirected to Deals." }
    } else {
        prep = { title: "Deal Deletion", message: "Successfully delete this deal.Its still accessible within 14 days in Archive before permanently removed. You will be redirected to Deals." }
    }

    const tickStyles =  {
        verticalAlign:'middle',
        color:'white',
        borderRadius:'50%',
        backgroundColor:'#6BD488',
        padding:'3px'
    }


    return (
        <>
            <DialogTemplate title={prep.title} toggle={true} height="fit-content">
                <h3 style={{textAlign:'center'}}> <span style={tickStyles} className="material-icons">done</span> {prep.message}</h3>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}} >
                    <button onClick={() => setRedirect(true)} type="button" className="btn-purple">OK</button>
                </div>
            </DialogTemplate>
            {
                redirect && <Redirect to="/crm/deals"/>
            }
        </>
    )
}
