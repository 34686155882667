import { db,timestamp } from "../firebase";
import { zeroPad } from "../pages/helper";

const soref = db.collection('ServiceOrder')
export const sorefCol = db.collection('ServiceOrder').orderBy("dateCreated","desc")
const setTicketSO = () => {
    const ref = db.collection("AppPreferences").doc("ServiceOrder")
    return db.runTransaction((transaction) => {
        return transaction.get(ref).then((doc) => {
            if (!doc.exists) {
                throw new Error("Document does not exist!");
            }
            var newCount = doc.data().ticketCount + 1;
            transaction.update(ref, { ticketCount: newCount });
            return newCount;
        });
    })
}

export const setSO = async (data,uid) => {

    const ticket = await setTicketSO()

    return soref.add({
        ...data,
        dateCreated: timestamp,
        createdBy:uid,
        status:"New",
        ticket: `SO#${zeroPad(ticket)}`
    })
} 

export const getSO = (soid) => {
    //if(soid === null ) throw {message:"noid",code:"No-so"}
    return soref.doc(soid).get()
}

export const getAllSO = () => {
    return sorefCol.get()
}

export const updateStatus = (id,status) => {
    return soref.doc(id).update({status})
}