import React, { useState } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import { addDirectory } from '../../controllers/documentInventoryController'

export default function DirectoryName({ onCancel, title }) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const form = e.target
        setIsLoading(true)

        await addDirectory({name:form.dirName.value}).catch(err => {
            setIsLoading(false)
            setError(err)
        })

        setIsLoading(false)
        onCancel()

    }

    const dialogStyles = {
        width:"20%",
        padding:"0",
        borderRadius:"5px",
        overflow:"hidden"
    }

    return (
        <DialogTemplate dialogStyles={dialogStyles} title={title} toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form">
                <label htmlFor="dirName">Enter directory name:</label>
                <input id="dirName" required type="text" placeholder="Directory name" />
                <div className="buttons-dialog__form">
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { setError(null); onCancel() }}>Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Creating..." : "Create"}
                    </button>
                </div>
            </form>

        </DialogTemplate>
    )
}
