import React, { useState, useEffect, useRef } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import styles from './settingsComp.module.css'
import reactDom from 'react-dom'
import db from './db'
import ReAuth from '../../components/ReAuth/ReAuth'
import Success from '../../components/Dialogs/Success'
import CropImage from './CropImage'
import { numOnlyRegex } from '../helper'

const dialogStyles = {
    width: "40%"
}

function ModalBasicInfo({ isLoading, isOpen, onClose, basicInfo, onSave }) {
    const [displayName, setDisplayName] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('')
    const [position, setPosition] = useState('')
    const [department, setDepartment] = useState('')

    useEffect(() => {
        function setInitVal() {
            setDisplayName(basicInfo.displayName)
            setBirthday(basicInfo.birthday)
            setGender(basicInfo.gender)
            setPosition(basicInfo.position)
            setDepartment(basicInfo.department)
        }
        setInitVal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        onSave(displayName, birthday, gender, department, position)
    }

    if (!isOpen) return null
    return reactDom.createPortal(
        <>
            <div className="dialog-overlay" />
            <div className="wpd" style={dialogStyles}>
                <p className={styles.tips}>*Leave unchanged to use the same value</p>
                <h2 className={styles.listItem}>Edit Basic Info</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.listItem}>
                        <h4>name</h4>
                        <input required onChange={(e) => setDisplayName(e.target.value)} placeholder="Enter your name" className={styles.inp50} type="text" value={displayName} />
                    </div>
                    <div className={styles.listItem}>
                        <h4>birthday</h4>
                        <input onChange={(e) => setBirthday(e.target.value)} value={birthday} type="date" />
                    </div>
                    <div className={styles.listItem}>
                        <h4>gender</h4>
                        <select onChange={(e) => setGender(e.target.value)} name="gender" value={gender}>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Rather not say">Rather not say</option>
                        </select>
                    </div>
                    <div className={styles.listItem}>
                        <h4>department</h4>
                        <input onChange={(e) => setDepartment(e.target.value)} placeholder="Enter your department" className={styles.inp50} type="text" value={department} />
                    </div>
                    <div className={styles.listItem}>
                        <h4>position</h4>
                        <input onChange={(e) => setPosition(e.target.value)} placeholder="Enter your position" className={styles.inp50} type="text" value={position} />
                    </div>
                    <div className={styles.actionBtn}>
                        <button type="button" disabled={isLoading} onClick={() => onClose()} className="btn-white">Cancel</button>
                        <button type="submit" disabled={isLoading} className="btn-purple">{isLoading ? "Saving..." : "Save"}</button>
                    </div>
                </form>
            </div>
        </>,
        document.getElementById("portal")
    )
}

function ModalAccInfo({ isLoading, isOpen, onClose, onSave }) {
    const which = isOpen.which
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        if (which === 1) return onSave(which, email);
        if (which === 2) return onSave(which, phoneNumber);
        if (which === 3) {
            if (password !== confPassword) return alert("Password does not match!");
            return onSave(which, password);
        }
    }

    if (!isOpen.bool) return null
    return reactDom.createPortal(
        <>
            <div className="dialog-overlay" />
            <div className="wpd" style={dialogStyles}>
                {which === 1 &&
                    <>
                        <p className={styles.tips}>*You will be logged out after changing email successfully.</p>
                        <h2 className={styles.listItem}>Change Email</h2>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.listItem}>
                                <h4>email</h4>
                                <input required onChange={(e) => setEmail(e.target.value)} placeholder="Enter your new email" className={styles.inp50} type="text" value={email} />
                            </div>
                            <div className={styles.actionBtn}>
                                <button type="button" disabled={isLoading} onClick={() => onClose()} className="btn-white">Cancel</button>
                                <button type="submit" disabled={isLoading} className="btn-purple">{isLoading ? "Updating..." : "Update"}</button>
                            </div>
                        </form>
                    </>
                }
                {which === 2 &&
                    <>
                        <h2 className={styles.listItem}>Change Phone Number</h2>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.listItem}>
                                <h4>phone number</h4>
                                <input required onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Enter your new phone number" className={styles.inp50} type="text" value={phoneNumber} />
                            </div>
                            <div className={styles.actionBtn}>
                                <button type="button" disabled={isLoading} onClick={() => onClose()} className="btn-white">Cancel</button>
                                <button type="submit" disabled={isLoading} className="btn-purple">{isLoading ? "Updating..." : "Update"}</button>
                            </div>
                        </form>
                    </>
                }
                {which === 3 &&
                    <>
                        <p className={styles.tips}>*Password needs to be longer than 6 characters. You will be logged out if the password changed successfully.</p>
                        <h2 className={styles.listItem}>Change Password</h2>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.listItem}>
                                <h4>new password</h4>
                                <input required onChange={(e) => setPassword(e.target.value)} placeholder="Enter your new password" className={styles.inp50} type="password" value={password} />
                            </div>
                            <div className={styles.listItem}>
                                <h4>confirm new password</h4>
                                <input required onChange={(e) => setConfPassword(e.target.value)} placeholder="Confirm your new password" className={styles.inp50} type="password" value={confPassword} />
                            </div>
                            <div className={styles.actionBtn}>
                                <button type="button" disabled={isLoading} onClick={() => onClose()} className="btn-white">Cancel</button>
                                <button type="submit" disabled={isLoading} className="btn-purple">{isLoading ? "Updating..." : "Update"}</button>
                            </div>
                        </form>
                    </>
                }
            </div>
        </>,
        document.getElementById("portal")
    )
}

export default function General() {

    const isMounted = useRef()
    const { currentUser, logout, reAuth } = useAuth()
    const database = new db(currentUser);
    const [isLoading, setLoading] = useState(false)
    const [isOpenBasicInfo, setOpenBasicInfo] = useState(false)
    const [isOpenAccInfo, setOpenAccInfo] = useState({ bool: false, which: 0 })
    const [basicInfo, setBasicInfo] = useState({
        displayName: '',
        birthday: "",
        gender: '',
        department: '',
        position: ''
    })
    const [passwordChanged, setPasswordChanged] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [reauth, setReauth] = useState(false)
    const [reauthCallback, setReauthCallback] = useState(null)
    const [isFetching, setFetching] = useState(true)
    const [error, setError] = useState(null)
    const [showSuccess, setShowSuccess] = useState(false)
    const [successCallback, setSuccessCallback] = useState({
        message: "",
        callback: () => { }
    })

    const [pfp, setPfp] = useState(null)
    const [openCrop, setOpenCrop] = useState(false)

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = 'Profile - Settings'

        const fetchProfile = async () => {
            if (isMounted.current) {
                setLoading(true)
                setFetching(true)
            }

            const result = await database.getProfile().catch(err => {
                return console.error(err.message)
            })

            if (result) {
                const { birthday, department, displayName, gender, position, passwordChanged, phoneNumber, email, pfpLink } = result.data();

                if (isMounted.current) {

                    const pwChangeDate = passwordChanged ? passwordChanged.toDate().toDateString() : 'NEVER'

                    sessionStorage.setItem("PFP_URL", pfpLink);
                    setPfp(pfpLink)
                    
                    setBasicInfo({
                        displayName: displayName ?? "Not set",
                        birthday: birthday ?? "Not set",
                        gender: gender ?? "Rather not say",
                        department: department ?? "Not set",
                        position: position ?? "Not set"
                    })

                    setEmail(email)
                    setPasswordChanged(pwChangeDate)
                    setPhoneNumber(phoneNumber ?? "Not set")
                }

            }

            if (isMounted.current) {
                setLoading(false);
                setFetching(false)
            }

        }

        fetchProfile()

        return () => isMounted.current = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showError = ({ message, code }) => {
        setError({
            message,
            code
        })
    }

    const onSaveBasicInfo = async (displayName, birthday, gender, department, position) => {
        setLoading(true)
        try {

            await database.updateDisplayName(displayName)
            await database.updateProfile(gender, birthday, department, position)

            setBasicInfo({
                displayName,
                birthday,
                gender,
                department,
                position
            })

            setLoading(false)
        } catch (error) {
            showError(error)
            setLoading(false)
        }
        setOpenBasicInfo(false)
    }

    const onSaveAccInfo = async (which, value) => {
        setLoading(true)
        setError(null)
        if (which === 2) {

            if(!value.match(numOnlyRegex)){
                showError({code:'Foreign-Character',message:'Phone number must contain only numbers.'})
                setLoading(false)
                return
            }

            try {
                await database.updatePhoneNumber(value)
                setPhoneNumber(value)
                setLoading(false)
            } catch (error) {
                showError(error)
                setLoading(false)
            }
        } else if (which === 3) {

            const pwCallback = async () => {
                setLoading(true)
                await database.updatePassword(value)
                await database.updatePasswordChanged();
                setReauth(false)
                setLoading(false)
                setShowSuccess(true)
            }

            try {
                await database.updatePassword(value)
                await database.updatePasswordChanged();
                setLoading(false)
                setShowSuccess(true)
            } catch (error) {
                if (error.code === "auth/requires-recent-login") {
                    setReauthCallback(pwCallback)
                    setReauth(true)
                } else {
                    showError(error)
                }
                setLoading(false)
            }

            setSuccessCallback({
                message: "Successfully changed password, you will be logged out.",
                callback: () => {
                    logout()
                }
            })

        } else if (which === 1) {

            const emailCallback = async () => {
                setLoading(true)
                await database.updateEmail(value)
                await currentUser.sendEmailVerification()
                setReauth(false)
                setLoading(false)
                setShowSuccess(true)
            }

            try {
                await database.updateEmail(value)
                await currentUser.sendEmailVerification()
                setLoading(false)
                setShowSuccess(true)
            } catch (error) {
                if (error.code === "auth/requires-recent-login") {
                    setReauthCallback(emailCallback)
                    setReauth(true)
                } else {
                    showError(error)
                }
                setLoading(false)
            }

            setSuccessCallback({
                message: "Successfully changed email, you will be logged out. Please verify your email.",
                callback: () => {
                    logout()
                }
            })
        }

        setOpenAccInfo(false)
    }

    const getPfp = async (img) => {
        setOpenCrop(false)
        sessionStorage.setItem("PFP_URL", img);
        setLoading(true)
        try {
            await database.updatePhotoUrl(img)
            setLoading(false)
        } catch (error) {
            showError(error)
            setLoading(false)
        }
        setPfp(img)
    }

    return (
        <div>
            <Success
                toggle={showSuccess}
                callback={() => {
                    successCallback.callback()
                }}
                infoText={successCallback.message} />

            <ReAuth
                isLoading={isLoading}
                isOpen={reauth}
                reAuth={reAuth}
                onClose={() => { setReauth(false) }}
                callback={() => reauthCallback} />

            {!isFetching && <ModalBasicInfo
                isLoading={isLoading}
                isOpen={isOpenBasicInfo}
                onClose={() => { setOpenBasicInfo(false) }}
                basicInfo={basicInfo}
                onSave={onSaveBasicInfo} />}

            <ModalAccInfo
                isLoading={isLoading}
                isOpen={isOpenAccInfo}
                onClose={() => { setOpenAccInfo({ bool: false, which: 0 }) }}
                onSave={onSaveAccInfo} />

            {
                openCrop && currentUser &&
                <CropImage
                    onCancel={() => setOpenCrop(false)}
                    callback={getPfp}
                    uid={currentUser.uid}
                />
            }

            <div className={styles.titleCont}>
                {isLoading && <div className={styles.miniSpinner}></div>}
                <h2 className={styles.title}>Profile</h2>
            </div>
            {error && <p className="error-bar">{`${error.message} --code: ${error.code}`}</p>}
            <h3 className={styles.subtitle}>Basic Info <span onClick={() => setOpenBasicInfo(true)} className="material-icons">edit</span></h3>
            <div className={styles.list}>
                <div className={styles.listItem}>
                    <h4>profile photo</h4>
                    <button onClick={() => setOpenCrop(true)} style={{ textTransform: 'revert', color: 'white' }} className="btn-blue">Upload</button>
                    <div className={styles.img__cont}><img src={pfp} alt="Preview Profile Alias" /></div>
                </div>
                <div className={styles.listItem}>
                    <h4>name</h4>
                    <h4>{basicInfo.displayName}</h4>
                </div>
                <div className={styles.listItem}>
                    <h4>birthday</h4>
                    <h4>{basicInfo.birthday}</h4>
                </div>
                <div className={styles.listItem}>
                    <h4>gender</h4>
                    <h4>{basicInfo.gender}</h4>
                </div>
                <div className={styles.listItem}>
                    <h4>department</h4>
                    <h4>{basicInfo.department}</h4>
                </div>
                <div className={styles.listItem}>
                    <h4>position</h4>
                    <h4>{basicInfo.position}</h4>
                </div>
            </div>
            <h3 className={styles.subtitle}>Account Info</h3>
            <div className={styles.list}>
                <div className={styles.listItem}>
                    <h4>email</h4>
                    <h4>{email} <span onClick={() => { setOpenAccInfo({ bool: true, which: 1 }) }} className="material-icons">edit</span></h4>
                </div>
                <div className={styles.listItem}>
                    <h4>phone number</h4>
                    <h4>{phoneNumber} <span onClick={() => { setOpenAccInfo({ bool: true, which: 2 }) }} className="material-icons">edit</span></h4>
                </div>
                <div className={styles.listItem}>
                    <h4>password</h4>
                    <h4>@ last changed at {passwordChanged} <span onClick={() => { setOpenAccInfo({ bool: true, which: 3 }) }} className="material-icons">edit</span></h4>
                </div>
            </div>
        </div>
    )
}
