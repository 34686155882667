import styles from './wall.module.css'

const DetailContainer = ({children, title, editable = true , onClick}) => {

    const style = {
        visibility: `${editable ? "visible" : "hidden"}`
    }

    return (
        <div className={styles.detail}>
            <div className={styles.detail__title}>
                <h3>{title}</h3>
                <span onClick={onClick} style={style} className="material-icons">edit</span>
            </div>
            <div className={styles.detail__children}>
                {children}
            </div>
        </div>
    )
}

export default DetailContainer;