import React from 'react'
import styles from './las.module.css'

export default function StaffPanel() {
    return (
        <div className={styles.panel}>
            This is a Staff panel
        </div>
    )
}
