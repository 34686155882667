import { db,timestamp } from "../firebase";

const ref = db.collection('externalStaff');

export const createStaff = (data) => {
    return ref.add({
        ...data,
        isRemoved:false,
        dateCreated: timestamp,
        dateUpdated: timestamp
    })
}

export const getAllStaff = () => {
    return ref.where('isRemoved','==',false).orderBy('fullName').get()
}

export const getAllStaffIncludeRemoved = () => {
    return ref.orderBy('fullName').get()
}

export const removeStaff = (id) => {
    return ref.doc(id).update({isRemoved:true})
}

export const updateStaff = (id,data) => {
    return ref.doc(id).update(data)
}

export const addSchedule = (sid,data) => {
    const splitted = data.date.split("-")
    const d = new Date(splitted[2],splitted[1],splitted[0]);
    return ref.doc(sid).collection('schedule').add({...data,date:d})
}

export const removeSchedule = (path) => {
    return db.doc(path).delete()
}