import React from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'

const dialogStyles = {
    height:'100%',
    width:'100%'
}

export default function ViewAttachmentDialog({ link, onClose }) {
    return (
        <DialogTemplate toggle={true} title='View Attachment' width={600} height='max-content'>
            <div className='fx fx-col' style={dialogStyles}>
                <div style={{flexGrow:'2'}}>
                    <img 
                        style={{objectFit:'contain'}}
                        width={'100%'} 
                        height={'100%'} 
                        src={link} 
                        alt="mc" />
                </div>
                <div>
                    <button style={{marginLeft:'auto',marginTop:'10px'}} onClick={onClose} className="btn-white">Close</button>
                </div>
            </div>
        </DialogTemplate>
    )
}
