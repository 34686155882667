import Row from "./Row"
import { sortAddress } from "../../helper"
import React from "react"

function ProspectInformation({data}) {

    const address =  sortAddress(data)

    return (
        <>
            <Row left="Name" right={data.name} />
            <Row left="Organisation" right={data.organisation} />
            <Row left="Email" right={data.email} />
            <Row left="Phone number" right={data.phoneNumber} />
            <Row left="Address" right={address} />
        </>
    )
}

export default React.memo(ProspectInformation)
