import React, { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router'
import Header from '../../components/Header/Header';
import DialogUploadFile from './DialogUploadFile';
import MiniSpinner from '../../components/Loading/MiniSpinner';
import { getDirectory, deleteFile } from '../../controllers/documentInventoryController';
import Info from '../../components/Dialogs/Info';
import EditFilename from './EditFilename';
import {useNav} from '../../contexts/NavContext'

function SubHeader({ isLoading }) {


    const onSearch = (e) => {
        const container = document.getElementById("tableFiles");
        let txtValue, i;
        const filter = e.target.value.toUpperCase();
        const items = container.querySelectorAll("tr")

        for (i = 0; i < items.length; i++) {
            const td = items[i].querySelector("td:nth-child(3)");
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    items[i].style.display = "";
                } else {
                    items[i].style.display = "none";
                }
            }
        }
    }

    return (
        <div className="subheader">
            <div style={{ marginLeft: "0", display: 'flex', alignItems: 'center', gap: '10px', color: 'white', fontStyle: 'italic' }}>
                {isLoading &&
                    <>
                        <MiniSpinner wh="35" />
                        <span>{isLoading}</span>
                    </>
                }
            </div>

            <div className="search-input-only">
                <span className="material-icons">search</span>
                <input onChange={onSearch} type="text" placeholder="Search" />
            </div>
        </div>
    )
}

function Table({ files, dir, setError, setFileList }) {

    const [toggleDel, setToggleDel] = useState(null)
    const [toggleRename, setToggleRename] = useState(null)

    const getDate = (date) => {
        try {
            return date.toDate().toDateString()
        } catch (error) {
            return date.toDateString()
        }
    }

    const handleRename = (currName, id) => {
        setToggleRename({ filename: currName, id, dir })
    }

    const onRename = (data) => {
        setFileList(prev => {
            const idx = prev.findIndex(d => d.id === data.id)
            const modified = prev[idx]
            modified.displayName = data.displayName
            prev.splice(idx, 1, modified)
            const newArr = Array.from(prev)
            return newArr
        })
    }

    const handleDelete = (storageRef, id) => {
        setToggleDel({ storageRef, id })
    }

    const proceed = async (setLoading) => {
        setLoading(true)
        setError(null)
        await deleteFile(toggleDel.storageRef, toggleDel.id, dir).catch(err => {
            setError(err)
            setLoading(false)
        })
        setFileList(prev => {
            const idx = prev.findIndex(d => d.id === toggleDel.id)
            prev.splice(idx, 1)
            const newArr = Array.from(prev)
            return newArr
        })
        setLoading(false)
        setToggleDel(null)
    }

    return (
        <>
            <table style={{ width: "60%", margin: "10px auto" }} id="tableFiles" className="table tableInventory" cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th>Num.</th>
                        <th>Bulk</th>
                        <th>Filename</th>
                        <th>Upload Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map((file, i) => (<tr key={file.id} id={file.id} data-ref={file.ref}>
                        <td style={{ width: "40px", minWidth: "40px" }}>{i + 1}</td>
                        <td style={{ width: "40px", minWidth: "40px" }}><input style={{ maxWidth: "40px", width: "20px" }} type="checkbox" /></td>
                        <td>{file.displayName}</td>
                        <td>{getDate(file.dateUploaded)}</td>
                        <td style={{ width: "80px", minWidth: "80px" }}>
                            <div className="di-action">
                                <a href={file.url} download={file.displayName}>
                                    <button className="btn-green">
                                        <span className="material-icons">download</span>
                                    </button>
                                </a>
                                <button onClick={() => handleRename(file.displayName, file.id)} className="btn-blue">
                                    <span className="material-icons">edit</span>
                                </button>
                                <button onClick={() => handleDelete(file.ref, file.id)} className="btn-red">
                                    <span className="material-icons">delete</span>
                                </button>
                            </div>
                        </td>
                    </tr>))}
                </tbody>
            </table>
            {
                toggleDel &&
                <Info
                    yesCallback={proceed}
                    noCallback={() => setToggleDel(null)}
                    infoText="Are you sure to delete this file ?"
                />
            }
            {
                toggleRename &&
                <EditFilename
                    onComplete={onRename}
                    defValue={toggleRename}
                    onCancel={() => setToggleRename(null)}
                />
            }
        </>
    )
}

export default function Document() {
    const { path, folder } = useParams()
    const [error, setError] = useState(false)
    const [fileList, setFileList] = useState([])
    const isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const [upload, toggleUpload] = useState(false)
    const bulkInfo = useRef()
    const [bulkDel, setBulkDel] = useState(null)
    const {setNavigation} = useNav()

    useEffect(() => {
        document.querySelector('title').textContent = folder + ' - Document Inventory'
        setNavigation('Document Inventory')
        isMounted.current = true

        const getAllDocuments = async () => {
            setIsLoading("Gathering files...")
            const files = await getDirectory(path).catch(err => {
                setIsLoading(false)
                setError(err)
            });
            setIsLoading(false)
            setFileList(files.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }
        getAllDocuments()
        return () => isMounted.current = false;
    }, [path, folder,setNavigation])

    const TipStyles = {
        display: 'flex',
        alignItems: "center",
        marginTop: "10px",
        gap: "10px"
    }

    function download_files(files) {
        function download_next(i) {
            if (i >= files.length) {
                return;
            }
            let a = files[i]
            if (a.click) {
                a.click(); // The click method is supported by most browsers.
            }
            // Download the next file with a small timeout. The timeout is necessary
            // for IE, which will otherwise only download the first file.
            setTimeout(function () {
                download_next(i + 1);
            }, 500);
        }
        // Initiate the first download.
        download_next(0);
    }

    const onBulkInit = (e) => {
        const val = e.target.value
        if (val === "def") return;

        const selections = document.querySelectorAll('#tableFiles tr>td>input:checked');
        if (selections.length === 0) {
            bulkInfo.current.textContent = "No file was selected !"
            setTimeout(() => {
                bulkInfo.current.textContent = ""
            }, 700);
            return
        }

        if (val === "dwl") {
            bulkInfo.current.textContent = "Downloading files..."
            const links = Array.from(selections).map(s => s.parentNode.parentNode.querySelector('td>div>a'))
            download_files(links)
        } else if (val === "del") {
            setBulkDel(Array.from(selections).map(s => {
                const tr = s.parentNode.parentNode
                return{
                    id:tr.id,
                    ref:tr.dataset.ref
                }
            }))
        }

        setTimeout(() => {
            bulkInfo.current.textContent = ""
        }, 700);
    }

    const bulkDeletion = async (isLoading) => {
        isLoading(true)

        await Promise.all([bulkDel.map(obj => deleteFile(obj.ref,obj.id,path))]).catch(err => {
            console.error(err)
            isLoading(false)
        })

        setFileList(prev => {
            bulkDel.forEach(obj => {
                const idx = prev.findIndex(d => d.id === obj.id);
                prev.splice(idx, 1)
            })
            const newArr = Array.from(prev)
            return newArr
        })

        isLoading(false)
        setBulkDel(null)
    }

    return (
        <>
            <Header title={folder} />
            <SubHeader isLoading={isLoading} />
            <div className="tab-contents">
                {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={TipStyles}>
                        <span style={{ color: 'var(--sky-blue)' }} className="material-icons">info</span>
                        <p>Click <strong>Upload</strong> to upload new files into the folder.</p>
                    </div>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <p style={{ fontStyle: 'italic' }} ref={bulkInfo}></p>
                        <select onChange={onBulkInit} >
                            <option value="def">Bulk Action</option>
                            <option value="dwl">Download</option>
                            <option value="del">Delete</option>
                        </select>
                        <button onClick={() => toggleUpload(true)} className="btn-purple">
                            <span className="material-icons">upload</span>
                            <span>Upload File</span>
                        </button>
                    </div>
                </div>
                {fileList.length === 0 ? <p style={{ textAlign: 'center' }}>No file yet... Upload a new one by clicking on <strong>Upload</strong> button.</p> :
                    <Table files={fileList} dir={path} setError={setError} setFileList={setFileList} />
                }
            </div>
            {
                upload &&
                <DialogUploadFile
                    setFileList={setFileList}
                    path={path}
                    onCancel={() => toggleUpload(false)}
                />
            }

            {
                bulkDel &&
                <Info
                    yesCallback={bulkDeletion}
                    noCallback={() => setBulkDel(false)}
                    infoText="Are you sure to delete these files, IT CANNOT BE UNDONE ?"
                />
            }
        </>
    )
}
