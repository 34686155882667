import React, { useState, useEffect, useMemo, useRef,useCallback } from 'react'
import Indicator from './Indicator'
import styles from './wall.module.css'
import DialogCreateSO from './DialogCreateSO'
import { updateSoid } from '../../../controllers/dealController'
import { useUsers } from '../../../contexts/UsersContext'
import { getSO } from '../../../controllers/serviceOrderController'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import { openInNewTab, getOrigin, copyToClipboard, sortAddress } from '../../helper'
import { useParams } from 'react-router-dom'

const NoSOComp = ({ onClick }) => {
    return (
        <div className={styles.no__so} style={{ borderLeft: "5px solid #6BD488" }}>
            <p>Service order has not being created yet.</p>
            <button onClick={onClick} className="btn-white">Create Service Order</button>
        </div>
    )
}

function ServiceOrderComponent({prospect, soid, title, services, did }) {

    const {status} = useParams()
    const loadingSpinner = useRef(false)
    const [so, setSO] = useState(soid)
    const [toggleSo, setToggleSo] = useState(false)
    const [isLoading, setIsLoading] = useState('')
    const [error, setError] = useState(null)
    const { users } = useUsers()
    const [soData, setSoData] = useState({
        date: '',
        creator: '',
        status: ''
    })

    const style = {
        borderLeft: "5px solid #6BD488"
    }

    const getCreator = useCallback( (creator) => {
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            if (user.id === creator) {
                return user.name
            }
        }
    },[users])

    const creatorMemo = useMemo(() => getCreator(soData.creator), [soData,getCreator])

    const onClick = () => { 
        if(status !== undefined) return null
        setToggleSo(true) 
    }

    const onUpdated = async (newSoid) => {
        setIsLoading("Updating...")
        await updateSoid(did, newSoid).catch(err => {
            setError(err)
        })
        setIsLoading('')
        setSO(newSoid)
    }

    const onCopy = async () => {
        setError(null)
        setIsLoading('Copying...')
        const result = await copyToClipboard(`${getOrigin()}/service-order/${so}`)
       
        if(result !== "ok"){
            setIsLoading('')
            setError(result)
        }else{
            loadingSpinner.current = true
            setIsLoading('Copied to clipboard  !')
            
            setTimeout(() => {
                loadingSpinner.current = false
                setIsLoading('')
            }, 5000);
    
        }
        
    }

    useEffect(() => {
        let isMounted = true
        async function fetchSO() {
            if (so !== null && so !== undefined) {
                if (isMounted) setIsLoading('Checking SO...')
                const result = await getSO(so).catch(err => {
                    if (isMounted) {
                        setIsLoading('')
                        setError(err)
                    }
                })

                if(!result.exists){
                    return setSO(null)
                }

                const data = result.data()

                if (isMounted) {
                    setIsLoading('')
                    setSoData({
                        date: data.dateCreated.toDate().toDateString(),
                        creator: data.createdBy,
                        status: data.status
                    })
                }

            } else {
                return
            }
        }
        fetchSO()
        return () => isMounted = false
    }, [so, setSO])

    return (

        <div className={styles.record__item}>
            <Indicator type="SO" />
            {so === null || so === undefined ?
                <NoSOComp onClick={onClick} /> :
                <div className={styles.record__detail} style={style}>
                    <div>
                        <div className={styles.act__header}>
                            <span>Service Order created on <strong>{soData.date}</strong> by <strong>{users && creatorMemo}</strong></span> <span className={styles.so__status}>{soData.status}</span>
                        </div>
                        {isLoading && <div className={styles.act__loading} ><MiniSpinner hidden={loadingSpinner.current} wh="25" /><p>{isLoading}</p></div>}
                        <div className={styles.act__header} style={{ marginBottom: "0" }}>
                            <div className={styles.act__actions}>
                                <div className="flex__row" style={{ marginRight: "10px" }}>
                                    <span onClick={() => openInNewTab(`${getOrigin()}/service-order/${so}`)} className="material-icons">visibility</span>
                                    <p>View</p>
                                </div>
                                <div className="flex__row" style={{ marginRight: "10px" }}>
                                    <span onClick={onCopy} className="material-icons">content_copy</span>
                                    <p>Copy link</p>
                                </div>
                            </div>
                            <button onClick={onClick} className="btn-white">Create Service Order</button>
                        </div>
                    </div>
                    {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                </div>
            }
            {
                toggleSo &&
                <DialogCreateSO
                    defValue={{ title, services,creator:creatorMemo ?? '',name:prospect.name,address:sortAddress(prospect),phoneNumber:prospect.phoneNumber }}
                    onCancel={() => setToggleSo(false)}
                    onUpdated={onUpdated}
                />
            }

        </div>
    )
}

export default React.memo(ServiceOrderComponent)
