import React, { useEffect, useContext, useState, useRef } from 'react'
import { LocationServicesChartsCtx } from '../LocationServices'
import styles from '../dashboard.module.css'
import Loading from '../Loading'
import { getMonthlyCompleted, getMonthlyLost, getMonthlyOngoing, getProspect } from '../../../../controllers/dashboardController'
import useResizeObserver from '../../../../hooks/useResizeObserver'
import { select, axisBottom, axisRight, scaleBand, scaleLinear, min, max } from 'd3'
import { randomColor } from '../../../helper'

//Monthly Inquiries by Cities chart

const Chart = React.memo(({ data }) => {
    const chartWrapperRef = useRef()
    const svgRef = useRef()
    const dimensions = useResizeObserver(chartWrapperRef)

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const counts = data.map((v) => v[1])
        const cities = data.map((v) => v[0])

        const xScale = scaleBand()
            .domain(cities)
            .range([0, dimensions.width])
            .padding(0.5)

        const yScale = scaleLinear()
            .domain([min(counts) - 10, max(counts) + 10])//hardcoded number is for offset
            .range([dimensions.height, 0])

        const xAxis = axisBottom(xScale).ticks(data.length)

        svg.select(".x-axis")
            .style("transform", `translateY(${dimensions.height}px)`)
            .call(xAxis)
            .selectAll("text")//text styles
            .style("text-anchor", "end")
            .style("font", "13px sans-serif")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-30)");

        const yAxis = axisRight(yScale);

        svg.select(".y-axis")
            .style("transform", `translateX(${dimensions.width}px)`)
            .call(yAxis)
            .selectAll("text")//text styles
            .style("font", "13px sans-serif")

        svg.selectAll(".bar")
            .data(counts)
            .join("rect")
            .attr("class", "bar")
            .style("transform", "scale(1,-1)")
            .attr("x", (value, index) => xScale(cities[index]))
            .attr("y", -dimensions.height)
            .attr("width", xScale.bandwidth())
            .transition()
            .attr("height", value => dimensions.height - yScale(value))
            .attr("fill", () => randomColor())
            .each(function (d, i) {
                select(this)
                    .on("mouseenter", () => {
                        svg.selectAll(".tooltip")
                            .data([d])
                            .join("text")
                            .attr("class", "tooltip")
                            .text(d)
                            .attr("x", xScale(cities[i]) + (xScale.bandwidth() / 2))
                            .attr("y", yScale(d) - 8)
                            .attr("text-anchor", "middle")
                            .style("font", "14px sans-serif")
                            .transition()
                            .attr("opacity", 1)
                    })
                    .on("mouseleave", () => svg.select(".tooltip").remove())
            })

    }, [data, dimensions])

    const pieStyles = { width: "95%", height: '550px', overflow: 'visible' }

    return (
        <div ref={chartWrapperRef} style={pieStyles}>
            <svg style={{ height: "100%", width: "100%", overflow: 'visible' }} ref={svgRef}>
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
        </div>
    )
})


export default function CHART_1() {

    const ctx = useContext(LocationServicesChartsCtx)
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        const { isMounted, date } = ctx
        const firstDay = date.firstDay
        const lastDay = date.lastDay

        const initFetch = async (func) => {
            const res = await func().catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })
            if (isMounted.current && res) {
                if (res.size !== 0) {
                    const total = res.docs.map(doc => doc.get('value') ?? 0).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr))
                    return {
                        arr: res.docs.map(doc => ({ pid: doc.get('pid'), value: doc.get('value') ?? 0 })),
                        amount: total,
                        total: res.size
                    }
                }
            }
            return {
                arr: [],
                amount: 0,
                total: 0
            }
        }

        const results = async () => {
            if (isMounted.current) setIsFetching(true)
            const completes = await initFetch(() => getMonthlyCompleted(firstDay, lastDay))
            const losts = await initFetch(() => getMonthlyLost(firstDay, lastDay))
            const ongoings = await initFetch(() => getMonthlyOngoing(firstDay, lastDay))

            const pids = [
                ...completes.arr.map(d => d.pid),
                ...losts.arr.map(d => d.pid),
                ...ongoings.arr.map(d => d.pid)
            ]

            const res = await Promise.all(pids.map(id => getProspect(id)))
                .catch(err => {
                    if (isMounted.current) {
                        setError(err)
                        console.error(err)
                    }
                })

            let cities = []
            if (res) {
                cities = res.map(r => r.get('city')).filter(d => d != null)
            }


            if (isMounted.current) setIsFetching(false)

            //Categorize
            const counter = {}
            cities.forEach(function (city) {
                var keyjson = JSON.stringify(city)
                const key = keyjson.replace(/"/g, '')
                counter[key] = (counter[key] || 0) + 1
            })

            const arrayPairs = Object.entries(counter)
            if (isMounted.current) {
                setData(arrayPairs)
            }
        }
        results()
    }, [ctx])

    const chartStyles = {
        height: '100%',
        width: '100%'
    }

    return (

        <div style={chartStyles} className={styles.container}>
            <p className={styles.title}>Inquiries by Cities</p>
            {error && <div style={{ width: '60%', margin: "0 auto" }} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {isFetching ?
                <Loading /> :
                <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                    <Chart data={data} type={'total'} />
                </div>}
            <p style={{ paddingTop: '0' }} className={styles.tips}>Inquiries by Cities chart is generated from <strong>monthly deal completed + monthly deal lost + monthly ongoing deals (sorted by cities)</strong>.</p>
        </div>
    )
}
