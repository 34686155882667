import React from 'react'
import styles from './las.module.css'
import Dashboard from './Dashboard'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import { useState, useEffect, useRef } from 'react'
import { useUsers } from '../../contexts/UsersContext'
import { getStats, setDefaultStats, createManagerRefandDocID, createReqRef, setManagersCollection, setStaffData, incrementStaffStats } from '../../controllers/lasController'
import { useAuth } from '../../contexts/AuthContext'
import { storage } from '../../firebase'
import { useLAS } from './LASContext'
import MyApplications from './MyApplications'
import ManagerPanel from './ManagerPanel'
import StaffPanel from './StaffPanel'
import Configure from './Configure'
import { getLeaveDuration, validateDuration } from './Bloc/lasbloc'

export default function LAS({ isSuperAdmin, setError }) {
    const [isLoading, setIsLoading] = useState(false)
    const [formError, setFormError] = useState(null)
    const { users } = useUsers()
    const { currentUser } = useAuth()
    const isMounted = useRef()
    const reportingOfficer = useRef()
    const uploadMC = useRef()
    const form = useRef()
    const { managers, setManagers, ent, setLeaves, leaves } = useLAS()
    const [type, setType] = useState('annual')
    const [dept, setDept] = useState('hr')
    const [date, setDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [officer, setOfficer] = useState('hr')
    const acceptedFileTypes = ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg']
    const imageMaxSize = 8000000
    const [img, setImg] = useState(null)
    const successInd = useRef()
    const [openConfig, setOpenConfig] = useState(false)
    const [isManager, setIsManager] = useState(false)

    const setManagersCallback = (id, dept) => {
        setManagers(prev => ({ ...prev, [dept]: id }))
    }

    const onChangeDept = (e) => {
        const val = e.target.value;
        setDept(val)
        setOfficer(val)
    }

    const onChangeType = (e) => {
        const val = e.target.value
        setType(val)

        if (val === 'medical') {
            uploadMC.current.style.display = 'block'
            uploadMC.current.required = true
        } else {
            uploadMC.current.required = false
            uploadMC.current.style.display = 'none'
        }
    }

    const onsubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setFormError(null)

        //preapare firestore documents
        const year = new Date().getFullYear().toString()
        const doc = createManagerRefandDocID(year, dept)
        const reqDoc = createReqRef(year, doc.id, currentUser.uid)

        const officersArr = Array.from(reportingOfficer.current.children)
        const manager = officersArr.filter(opt => opt.value === officer)[0].dataset.officer
        const managerID = officersArr.filter(opt => opt.value === officer)[0].dataset.id
        const startLeave = new Date(date);
        const endLeave = new Date(endDate);

        if (endLeave < startLeave) {
            setIsLoading(false)
            setFormError({ message: "Start date must be later than end date", code: 'Error-Input' });
            return
        }

        //calculate duration
        const duration = getLeaveDuration(startLeave, endLeave);

        if (!validateDuration(duration, leaves, type)) {
            setIsLoading(false)
            setFormError({ message: `Insufficent leave balance, your requested duration was : ${duration} day(s)`, code: "Error-Input" })
            return
        }

        const data = {
            status: 'new',
            read: {
                manager: false,
                self: false
            },
            leaveType: type,
            dept: dept,
            officer: manager,
            officerID: managerID,
            leaveDate: startLeave,
            leaveDateEnd: endLeave,
            requestor: currentUser.displayName,
            requestorID: currentUser.uid,
            cancelled: false,
            deptPath: doc.path,
            reqPath: reqDoc.path,
            img: null
        }

        if (img !== null && type === 'medical') {
            const fileRef = storage.ref().child(reqDoc.path)//put ref here
            await fileRef.put(img).catch(err => {
                setIsLoading(false)
                setFormError(err)
                return
            })
            const link = await fileRef.getDownloadURL().catch(err => {
                setIsLoading(false)
                setFormError(err)
                return
            })
            data.img = link
        }

        await setManagersCollection(doc, data).catch(err => {
            setIsLoading(false)
            setFormError(err)
            return
        })

        await setStaffData(reqDoc, data).catch(err => {
            setIsLoading(false)
            setFormError(err)
            return
        })

        //update user stat
        await incrementStaffStats(currentUser.uid, year, type === 'medical', duration).catch(err => {
            setIsLoading(false)
            setFormError(err)
            return
        })

        //If success
        form.current.reset();
        setImg(null)
        setIsLoading(false)
        successInd.current.style.display = 'block'
        setLeaves(prev => {
            return type === 'medical' ? { ...prev, mcUsed: prev.mcUsed + duration } : { ...prev, leaveUsed: prev.leaveUsed + duration };
        })

        setTimeout(() => {
            if (isMounted.current)
                successInd.current.style.display = 'none'
        }, 3000);

    }

    const onFileSelected = (e) => {
        const file = e.target.files[0]
        if (!acceptedFileTypes.includes(file.type)) {
            setFormError({
                message: 'Invalid File Type',
                code: 'File-Error'
            })
            return
        }
        if (file.size > imageMaxSize) {
            setFormError({
                message: 'File size needs to be under 8MB.',
                code: 'File-Error'
            })
            return
        }
        setImg(file)
    }

    useEffect(() => {
        isMounted.current = true
        reportingOfficer.current.innerHTML = ''
        if (!currentUser || users.length === 0) return

        if (isMounted.current) setIsLoading(true)

        const fetch = async () => {
            const stats = await getStats(currentUser.uid, new Date().getFullYear().toString()).catch(err => {
                if (isMounted.current) setError(err)
            })

            if (stats) {
                if (stats.exists) {
                    setLeaves({ ...stats.data(), leaveEnt: ent.leave, mcEnt: ent.mc })
                } else {
                    //year and UID only matters in HR Dashboard
                    const defLeave = {
                        year: new Date().getFullYear().toString(),
                        uid: currentUser.uid,
                        leaveUsed: 0,
                        mcUsed: 0
                    }
                    await setDefaultStats(currentUser.uid, new Date().getFullYear().toString(), defLeave).catch(err => {
                        if (isMounted.current) setError(err)
                    })

                }
            }
        }
        fetch()

        const getName = (uid) => {
            return users.filter(u => u.id === uid)[0]?.name
        }

        const officers = [
            { id: managers.it, text: `IT Department - ${getName(managers.it)}`, dept: 'it' },
            { id: managers.hr, text: `HR Department - ${getName(managers.hr)}`, dept: 'hr' },
            { id: managers.marketing, text: `Marketing Department - ${getName(managers.marketing)}`, dept: 'marketing' },
            { id: managers.corporate, text: `Corporate Department - ${getName(managers.corporate)}`, dept: 'corporate' },
            { id: managers.operation, text: `Operation Department - ${getName(managers.operation)}`, dept: 'operation' },
        ]

        for (let i = 0; i < officers.length; i++) {
            const item = officers[i]
            const opt = document.createElement('OPTION');
            opt.innerText = item.text
            opt.setAttribute('data-id', item.id)
            opt.setAttribute('data-officer', item.text)
            opt.setAttribute('value', item.dept)
            reportingOfficer.current.appendChild(opt)
        }

        setIsManager(managers.hr === currentUser.uid ||
            managers.it === currentUser.uid ||
            managers.marketing === currentUser.uid ||
            managers.corporate === currentUser.uid ||
            managers.operation === currentUser.uid)

        if (isMounted.current) setIsLoading(false)

        return () => isMounted.current = false
    }, [currentUser, users, managers, setError, ent, setLeaves])

    return (
        <>
            {
                openConfig &&
                <Configure
                    managers={managers}
                    setManagers={setManagersCallback}
                    onCancel={() => setOpenConfig(false)}
                    isCompleted={() => console.log('Configuration completed.')} />
            }
            <div>
                <Dashboard />

                <div className={styles.container}>
                    <div className={styles.leaveForm}>
                        <div className={styles.header}>
                            <p>Apply for Leave</p>
                            {isLoading && <MiniSpinner wh={30} />}
                            <span ref={successInd} style={{ display: 'none' }} className={`material-icons ${styles.success}`}>check</span>
                        </div>
                        {formError && <div className={`error-bar`}>
                            <span className="material-icons">error</span>
                            <p>{formError.message} --code: {formError.code}</p>
                        </div>}
                        <form ref={form} onSubmit={onsubmit} className='fx fx-col fx-gap-1' >
                            <label htmlFor="leaveType">Choose leave type : </label>
                            <select onChange={onChangeType} value={type} name="leaveType" id="leaveType">
                                <option value="annual">Annual Leave</option>
                                <option value="emergency">Emergency Leave</option>
                                <option value="medical">Medical Leave - Must upload MC proof</option>
                                <option value="unpaid">Unpaid Leave</option>
                            </select>

                            <input style={{ display: 'none' }} ref={uploadMC} onChange={onFileSelected} accept=".jpg,.png" type="file" name="mcproof" id="mcproof" />

                            <label htmlFor="dept">Choose department : </label>
                            <select disabled value={dept} onChange={onChangeDept} name="dept" id="dept">
                                <option value="hr">HR</option>
                                <option value="it">IT</option>
                                <option value="marketing">Marketing</option>
                                <option value="corporate">Corporate</option>
                                <option value="operation">Operation</option>
                            </select>
                            <label htmlFor="reportingOfficer">Choose reporting officer : </label>
                            <select disabled value={officer} onChange={(e) => setOfficer(e.target.value)} ref={reportingOfficer} name="reportingOfficer" id="reportingOfficer" />
                            <label htmlFor="leaveDate">Choose start date : </label>
                            <input onChange={(e) => setDate(e.target.value)} value={date} type="date" name="leaveDate" id="leaveDate" required />
                            <label htmlFor="leaveDateEnd">Choose end date : </label>
                            <input onChange={(e) => setEndDate(e.target.value)} value={endDate} type="date" name="leaveDateEnd" id="leaveDateEnd" required />
                            <button disabled={isLoading} type="submit" className='btn-green'>Submit for Approval</button>
                        </form>
                    </div>
                    <div className={styles.rightContainer} >
                        <div>
                            <MyApplications uid={currentUser.uid} />
                            {isSuperAdmin &&
                                <button
                                    onClick={() => setOpenConfig(true)}
                                    className="btn-white">
                                    <span className="material-icons">settings</span><span>Configure</span>
                                </button>}
                        </div>
                        {isManager ? <ManagerPanel uid={currentUser.uid} managers={managers} /> : <StaffPanel />}
                    </div>
                </div>
            </div>
        </>
    )
}
