import styles from './wall.module.css'

export default function ErrorComponent({err}) {
    return (
        <div className={styles.error__comp}>
            <h3>{err.code}</h3>
            <p>{err.message}</p>
        </div>
    )
}
