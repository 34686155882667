import React from 'react'
import Header from '../../components/Header/Header'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import LAS from './LAS'
import { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useUsers } from '../../contexts/UsersContext'
import { checkRole } from '../../controllers/lasController'
import styles from './las.module.css'
import Configure from './Configure'
import LASProvider, { useLAS } from './LASContext'

const SetManagerLayout = ({ isSuperAdmin, onConf }) => {
    return (
        <div className='fx fx-col fx-center fx-gap-1'>
            <p>Please finish the configuration first.</p>
            {isSuperAdmin ? <button className='btn-blue' onClick={onConf}> <span className='material-icons'>settings</span><span>Configure</span></button> :
                <p>Please contact a <strong>Super Admin</strong> to configure.</p>}
        </div>
    )
}

function LasMain() {
    const isMounted = useRef()
    const { currentUser } = useAuth()
    const { users } = useUsers()
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [managerExist, setManagerExist] = useState(false)
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [openConf, setOpenConf] = useState(false)
    const { managers, setManagers, setEnt } = useLAS()

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').innerText = 'My Dashboard - Leave Application System'
        if (!currentUser || users.length === 0) return

        if (isMounted.current) setIsLoading(true)
        const role = users.filter(u => u.id === currentUser.uid)[0].role
        if (role.includes('SuperAdmin')) { if (isMounted.current) setIsSuperAdmin(true) }

        const initFetch = async () => {
            //check role
            const managerDoc = await checkRole()
                .catch(err => {
                    if (!isMounted.current) return

                    setIsLoading(false)
                    setError(err)
                    return
                });

            if (managerDoc && managerDoc.exists) {
                const managersObj = managerDoc.get('managers')
                const entObj = managerDoc.get('entitlement')

                if (managersObj && entObj) {//if both required conf exist
                    setEnt(entObj)
                    //Check if configuration isnt completed but existed
                    if (!managersObj.marketing || !managersObj.corporate || !managersObj.operation
                        || !managersObj.it || !managersObj.hr) {
                        if (isMounted.current) {

                            if (managersObj.marketing || managersObj.corporate || managersObj.operation || managersObj.it || managersObj.hr) {
                                setManagers(managersObj)
                            }

                            setManagerExist(false)

                        }
                    } else {
                        if (isMounted.current) {
                            setManagerExist(true)
                            setManagers(managersObj)

                            //check isManager here
                            // const myID = currentUser.uid
                            // const role = []

                            // managersObj.marketing === myID && role.push("marketingManager")
                            // managersObj.corporate === myID && role.push("corporateManager")
                            // managersObj.operation === myID && role.push("operationManager")
                            // managersObj.it === myID && role.push("itManager")
                            // managersObj.hr === myID && role.push("hrManager")

                            // setMyRole(role)
                        }
                    }
                } else {
                    //No manager assigned
                    if (isMounted.current) setManagerExist(false)
                }
            } else {
                //First time
                if (isMounted.current) setManagerExist(false)
            }
        }
        initFetch()

        if (isMounted.current) setIsLoading(false)
        return () => isMounted.current = false
    }, [currentUser, users, managerExist, setEnt, setManagers])

    return (
        <>
            <Header title="Leave Application" />
            <div className="tab-contents">
                {error && <div className={`error-bar ${styles.lasmain_err}`}>
                    <span className="material-icons">error</span>
                    <p>{error.message} --code: {error.code}</p>
                </div>}

                <div className={styles.parentWrapper}>

                    {isLoading && <div className={styles.lasmain_loading}><MiniSpinner wh={30} /></div>}

                    {
                        !managerExist ? <SetManagerLayout isSuperAdmin={isSuperAdmin} onConf={() => setOpenConf(true)} /> :
                            managerExist && !error &&
                            <LAS
                                setError={setError}
                                isSuperAdmin={isSuperAdmin}
                               />
                    }
                </div>

                {
                    openConf &&
                    <Configure
                        managers={managers}
                        setManagers={(id, dept) => setManagers((prev) => ({ ...prev, [dept]: id }))}
                        isCompleted={() => setManagerExist(true)}
                        onCancel={() => setOpenConf(false)} />
                }

            </div>
        </>
    )
}

export default function LasWrapper() {
    return (
        <LASProvider>
            <LasMain />
        </LASProvider>
    )
}