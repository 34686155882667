import React from 'react'
import styles from './noPerm.module.css'

export default function NoPerm() {
    return (
        <div className={styles.noPerm}>
            <span className="material-icons">privacy_tip</span>
            <p>You do not have the permission to view this tab.</p>
        </div>
    )
}
