import React, { useContext, useState } from 'react'

const NavContext = React.createContext()

export function useNav() {
    return useContext(NavContext)
}

export default function NavProvider({ children }) {
    const [getNav, setNav] = useState(sessionStorage.getItem('PAGE_NAV') ?? 'Home')
    //console.log(sessionStorage.getItem('PAGE_NAV'))

    const setNavigation = (nav) => {
        sessionStorage.setItem('PAGE_NAV', nav)
        setNav(nav)
    }

    const value = {
        getNav,
        setNavigation
    }

    return (
        <NavContext.Provider value={value}>
            {children}
        </NavContext.Provider>
    )
}
