import'./NoDataTable.css';
import React from 'react'

export default function NoDataTable( {text}) {
    return (
        <div className="no-data-table">
            {text}
        </div>
    )
}
