import React, { useEffect, useContext, useState, useRef } from 'react'
import { DealChartsCtx } from '../Deals'
import styles from '../dashboard.module.css'
import Loading from '../Loading'
import { getFullyPaid, getCreditCard, getDepositedDeposit, getDepositedPaid, getRecurring } from '../../../../controllers/dashboardController'
import useResizeObserver from '../../../../hooks/useResizeObserver'
import { select, arc, pie } from 'd3'
import { randomColor } from '../../../helper'

//Monthly Nett Sales  Chart

const Chart = React.memo(({ data }) => {
    const chartWrapperRef = useRef()
    const svgRef = useRef()
    const dimensions = useResizeObserver(chartWrapperRef)

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const radius = Math.min(dimensions.width, dimensions.height) / 2 - 50
        svg
            .append("g")
            .attr("transform", "translate(" + dimensions.width / 2 + "," + dimensions.height / 2 + ")");

        const arcGenerator = arc()
            .innerRadius(20)
            .outerRadius(radius)

        const pieGenerator = pie();
        const instructions = pieGenerator(data.value)
        const colors = data.key.map(d => randomColor())

        svg.selectAll(".slice")
            .data(instructions)
            .join("path")
            .attr("class", "slice")
            .attr("fill", (instruction, index) => colors[index])
            .attr("transform", "translate(" + dimensions.width / 2 + "," + dimensions.height / 2 + ")")
            .each(function (d, i) {
                select(this)
                    .on("mouseenter", (event, instruction) => {
                        svg.selectAll(".tooltip")
                            .data([instruction])
                            .join("text")
                            .attr("class", "tooltip")
                            .text(`${data.key[i]}: ${'RM ' + instruction.data}`)
                            .style("transform", `translate(${event.offsetX}px, ${event.offsetY}px)`)
                            .style("font", "14px sans-serif")
                            .transition()
                            .attr("opacity", 1)
                    })
                    .on("mouseleave", () => svg.select('.tooltip').remove())
            })
            .transition()
            .attr("d", instruction => arcGenerator(instruction))

        // Add one dot in the legend for each name.
        svg.selectAll("mydots")
            .data(data.key)
            .enter()
            .append("circle")
            .attr("cx", 500)
            .attr("cy", function (d, i) { return 100 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .attr("r", 7)
            .style("fill", function (d, i) { return colors[i] })

        // Add one dot in the legend for each name.
        svg.selectAll("mylabels")
            .data(data.key)
            .enter()
            .append("text")
            .attr("x", 520)
            .attr("y", function (d, i) { return 100 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .style("fill", function (d, i) { return colors[i] })
            .text(function (d) { return d })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")

    }, [data, dimensions])

    const pieStyles = { width: "450px", height: '450px', overflow: 'visible' }

    return (
        <div ref={chartWrapperRef} style={pieStyles}>
            <svg style={{ height: "100%", width: "100%", overflow: 'visible' }} ref={svgRef}></svg>
        </div>
    )
})


export default function CHART_5() {

    const ctx = useContext(DealChartsCtx)
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(null)
    const currValue = useRef()
    const [data, setData] = useState([])

    useEffect(() => {
        const { isMounted, date } = ctx
        const firstDay = new Date(date.y, date.m, 1)
        const lastDay = new Date(date.y, date.m + 1, 0)

        const initFetch = async (func) => {
            const res = await func().catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })
            if (isMounted.current && res) {
                if (res.size !== 0) {
                    return {
                        arr: res.docs.map(doc => doc.data())
                    }
                }
            }
            return {
                arr: []
            }
        }

        const results = async () => {
            setIsFetching(true)
            const fullyPaid = await initFetch(() => getFullyPaid(firstDay, lastDay))
            const creditCard = await initFetch(() => getCreditCard(firstDay, lastDay))
            const deposited = await initFetch(() => getDepositedDeposit(firstDay, lastDay))
            const depositPaid = await initFetch(() => getDepositedPaid(firstDay, lastDay))
            const indexes = ['first', 'second', 'third', 'forth', 'fifth', 'sixth']
            const recurrRes = await Promise.all(indexes.map(index => initFetch(() => getRecurring(firstDay, lastDay, index))))

            const recurringAmount = recurrRes.map((d, index) => d.arr.map(data => data[indexes[index]]?.amount ?? 0))
                .map(a => a.reduce((p, c) => p + parseFloat(c), 0))
                .reduce((p, c) => p + c)

            const fpAmount = fullyPaid.arr.map(data => data.fullyPaid?.amount ?? 0)
                .reduce((p, c) => p + parseFloat(c), 0)

            const ccAmount = creditCard.arr.map(data => data.fullyPaid?.amount ?? 0)
                .reduce((p, c) => p + parseFloat(c), 0)

            const dpAmount = depositPaid.arr.map(data => data.fullyPaid?.amount ?? 0)
                .reduce((p, c) => p + parseFloat(c), 0)

            const dAmount = deposited.arr.map(data => data.deposit?.amount ?? 0)
                .reduce((p, c) => p + parseFloat(c), 0)

            setIsFetching(false)

            const arrayData = [fpAmount, ccAmount, dAmount + dpAmount, recurringAmount]
            const arrayKey = ['Fully Paid', 'Credit Card Payment', 'Deposit/Balance Payment', 'Recurring Payment']

            if (isMounted.current) {
                currValue.current.textContent = "RM " + arrayData.reduce((p,c)=>p+c,0)
                setData({ value: arrayData, key: arrayKey })
            }
        }
        results()
    }, [ctx])

    const chartStyles = {
        height: '100%',
        width: '70%'
    }

    return (

        <div style={chartStyles} className={styles.container}>
            <p className={styles.title}>Nett Sales</p>
            {error && <div style={{ width: '60%', margin: "0 auto" }} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {isFetching ?
                <Loading /> :
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Chart data={data} type={'total'} />
                    <div>
                        <div className={styles.subSection}>
                            <p>Nett Sales</p>
                            <p className={styles.valueText}><strong ref={currValue} >RM 0.00</strong></p>
                        </div>
                    </div>
                </div>}
            <p style={{ paddingTop: '0' }} className={styles.tips}><strong>Any payment made is recorded to generate Nett Sales chart</strong>.</p>
        </div>
    )
}
