import {useRef} from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

const CustomSearchBar = ({ currentRefinement, isSearchStalled, refine,focus,blur }) => (

    <form noValidate action="" role="search">
        <span className="material-icons">search</span>
        <input
            onFocus={focus}
            onBlur={blur}
            placeholder="Search"
            type="search"
            value={currentRefinement}
            onChange={event => refine(event.currentTarget.value)}
        />
        {/* <button onClick={() => refine('')}>Reset query</button>
        {isSearchStalled ? 'My search is stalled' : ''} */}
    </form>

);

const Searchbar = connectSearchBox(CustomSearchBar)

const SearchComponent = ({ children }) => {

    const hitBox = useRef(null)

    const focus = (e) => {
        hitBox.current.style.display = "block"
    }

    const blur = (e) => {
        hitBox.current.style.display = "none"
    }

    return (
        <div className="search-input">
            <Searchbar defaultRefinement="" focus={focus} blur={blur}/>
            <div ref={hitBox} className="hit-box">
                {children}
            </div>
        </div>
    )
}

export default SearchComponent
