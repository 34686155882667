import { useState } from "react"
import DialogTemplate from "../../../components/Dialogs/DialogTemplate"
import { setNote } from "../../../controllers/dealController"

export default function DialogAddNote( {onCancel , id, uid ,onAdded}) {
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        const form = e.target

        const formData = {
            type:"Note",
            notes: form.createNote.value
        }
        
        const res = await setNote(id,formData,uid).catch(err => {
            setLoading(false)
            return setError(err)
        })
        onAdded({...formData,dateCreated:new Date().toDateString(),createdBy:uid,id:res.id})
        setLoading(false)
        form.reset()
        onCancel()
    }

    return (
        <DialogTemplate title="Create Note" toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form" >

                <textarea required name="createNote" id="createNote" cols="30" rows="10" placeholder="What's on your mind ?"></textarea>

                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => {setError(null);onCancel()}}
                    >Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Creating..." : "Create"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
