import { db } from "../../../firebase";
import { timestamp } from "../../../firebase";

export const prospectRef = db.collection("Prospects").orderBy("name")

export const createNewProspect = (data) => {
    return db.collection("Prospects").add({
        ...data,
        dateCreated: timestamp,
        dateUpdated: timestamp,
    })
}

export const checkExistingProspect = async (phoneNumber) => {
    const querySnapshot = await db.collection("Prospects")
        .where("phoneNumber", "==", phoneNumber)
        .limit(1)
        .get()
        .catch(err => {
            throw err
        })

    return querySnapshot.size > 0 ? querySnapshot.docs[0].id : null
}

export const updateProspect = (data,pid) => {
    return db.collection("Prospects").doc(pid).update({...data, dateUpdated: timestamp})
}

export const fetchDealHistory = (pid) => {
    return db.collection("Prospects").doc(pid).collection("DealHistory").orderBy("dateCreated","desc").get()
}
