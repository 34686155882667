import React, { useEffect, useContext, useState, useRef, useCallback } from 'react'
import { DealChartsCtx } from '../Deals'
import styles from '../dashboard.module.css'
import Loading from '../Loading'
import { getMonthlyCompleted, getMonthlyLost } from '../../../../controllers/dashboardController'
import useResizeObserver from '../../../../hooks/useResizeObserver'
import { select, axisBottom, axisRight, scaleBand, scaleLinear, min, max } from 'd3'
import { randomColor } from '../../../helper'

//Monthly User (Deals) Bar Chart

const Chart = React.memo(({ data }) => {
    const chartWrapperRef = useRef()
    const svgRef = useRef()
    const dimensions = useResizeObserver(chartWrapperRef)

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const containedMoreData = data.won.length > data.lost.length ? data.won : data.lost

        const xScale = scaleBand()
            .domain(containedMoreData.map((v, i) => v[0]))
            .range([0, dimensions.width])
            .padding(0.5)

        const combined = [...data.lost.map(d => d[1].count), ...data.won.map(d => d[1].count)]
        const yScale = scaleLinear()
            .domain([min(combined) - 10, max(combined) + 10])//hardcoded number is for offset
            .range([dimensions.height, 0])

        const xAxis = axisBottom(xScale).ticks(max([...data.won, ...data.lost]))

        svg.select(".x-axis")
            .style("transform", `translateY(${dimensions.height}px)`)
            .call(xAxis)
            .selectAll("text")//text styles
            .style("text-anchor", "end")
            .style("font", "13px sans-serif")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-30)");

        const yAxis = axisRight(yScale);

        svg.select(".y-axis")
            .style("transform", `translateX(${dimensions.width}px)`)
            .call(yAxis)

        const colors = [randomColor(), randomColor()]

        svg.selectAll(".bar .won")
            .data(data.won.map((v) => v[1].count))
            .join("rect")
            .attr("class", "bar won")
            .style("transform", "scale(1,-1)")
            .attr("x", (value, index) => xScale(data.won.map(d => d[0])[index]))
            .attr("y", -dimensions.height)
            .attr("width", xScale.bandwidth() / 2)
            .transition()
            .attr("height", value => dimensions.height - yScale(value))
            .attr("fill", colors[0])

        svg.selectAll(".bar .lost")
            .data(data.lost.map((v) => v[1].count))
            .join("rect")
            .attr("class", "bar lost")
            .style("transform", "scale(1,-1)")
            .attr("x", (value, index) => xScale(data.lost.map(d => d[0])[index]) + xScale.bandwidth() / 2)
            .attr("y", -dimensions.height)
            .attr("width", xScale.bandwidth() / 2)
            .transition()
            .attr("height", value => dimensions.height - yScale(value))
            .attr("fill", colors[1])

        svg.selectAll(".bar")
            .on("mouseenter", (event, value) => {
                svg.selectAll(".tooltip")
                    .data([value])
                    .join("text")
                    .attr("class", "tooltip")
                    .text(value)
                    .attr("x", parseFloat(event.target.attributes.x.value)+xScale.bandwidth()/2/2 )
                    .attr("y", yScale(value) - 8)
                    .attr("text-anchor", "middle")
                    .style("font", "14px sans-serif")
                    .transition()
                    .attr("opacity", 1)
            })
            .on("mouseleave", () => svg.select(".tooltip").remove())

        // Add one dot in the legend for each name.
        svg.selectAll("mydots")
            .data(["Won", "Lost"])
            .enter()
            .append("circle")
            .attr("cx", 0)
            .attr("cy", function (d, i) { return 0 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .attr("r", 7)
            .style("fill", function (d, i) { return colors[i] })

        // Add one dot in the legend for each name.
        svg.selectAll("mylabels")
            .data(["Won", "Lost"])
            .enter()
            .append("text")
            .attr("x", 20)
            .attr("y", function (d, i) { return 0 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .style("fill", function (d, i) { return colors[i] })
            .text(function (d) { return d })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")

    }, [data, dimensions])

    const pieStyles = { width: "650px", height: '400px', overflow: 'visible' }

    return (
        <div ref={chartWrapperRef} style={pieStyles}>
            <svg style={{ height: "100%", width: "100%", overflow: 'visible' }} ref={svgRef}>
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
        </div>
    )
})


export default function CHART_12() {

    const ctx = useContext(DealChartsCtx)
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    const getOwnerName = useCallback(
        (id) => {
            return ctx.users.filter(u => u.id === id)[0].name
        },
        [ctx.users],
    ) 

    useEffect(() => {
        const { isMounted, date } = ctx
        const firstDay = new Date(date.y, date.m, 1)
        const lastDay = new Date(date.y, date.m + 1, 0)



        const initFetch = async (func) => {
            const res = await func().catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })
            if (isMounted.current && res) {
                if (res.size !== 0) {
                    return {
                        arr: res.docs.map(doc => ({ name: getOwnerName(doc.get('owner')), value: doc.get('value') ?? 0 }))
                    }
                }
            }
            return {
                arr: []
            }
        }

        const results = async () => {
            setIsFetching(true)
            const completed = await initFetch(() => getMonthlyCompleted(firstDay, lastDay))
            const lost = await initFetch(() => getMonthlyLost(firstDay, lastDay))
            setIsFetching(false)


            const counter1 = {}
            completed.arr.forEach(function (obj) {
                var keyjson = JSON.stringify(obj.name)
                const key = keyjson.replace(/"/g, '')
                counter1[key] = {
                    count: (counter1[key]?.count || 0) + 1,
                    value: (counter1[key]?.value || 0) + parseFloat(obj.value),
                }
            })

            const counter2 = {}
            lost.arr.forEach(function (obj) {
                var keyjson = JSON.stringify(obj.name)
                const key = keyjson.replace(/"/g, '')
                counter2[key] = {
                    count: (counter2[key]?.count || 0) + 1,
                    value: (counter2[key]?.value || 0) + parseFloat(obj.value),
                }
            })

            const arrayPairs1 = Object.entries(counter1)
            const arrayPairs2 = Object.entries(counter2)

            // const temp = {
            //     won: [["Web", { count: 20 }], ["Web2", { count: 23 }], ["Web3", { count: 22 }]],
            //     lost: [["Web", { count: 22 }], ["Web2", { count: 25 }]]
            // }

            if (isMounted.current) {
                setData({won:arrayPairs1,lost:arrayPairs2})
            }
        }
        results()
    }, [ctx,getOwnerName])

    const chartStyles = {
        height: '100%',
        width: '50%'
    }

    return (

        <div style={chartStyles} className={styles.container}>
            <p className={styles.title}>User (Deals)</p>
            {error && <div style={{ width: '60%', margin: "0 auto" }} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {isFetching ?
                <Loading /> :
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Chart data={data} />
                </div>}
            <p style={{ paddingTop: '0' }} className={styles.tips}>User (Deals) chart is generated from <strong>monthly deal completion and monthly lost deals sorted by deal's owner</strong>.</p>
        </div>
    )
}
