import React, { useEffect, useRef, useState } from 'react'
import { MONTHS } from '../../configuration'
import NoResult from './NoResult'
import styles from './report.module.css'
import { db } from '../../../firebase'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import { CSVLink } from "react-csv";
import useSortableData from '../../../hooks/useSortableData'
import Tip from './Tip'
import { useReactToPrint } from 'react-to-print';
import { sortAddress } from '../../helper'

const Table = React.forwardRef(({ result, isInclude, isFetching }, ref) => {

    const { items, requestSort } = useSortableData(result);

    const onHighlight = (e) => {
        const target = e.currentTarget
        target.classList.toggle("highlight")
    }

    const pageStyle = `
    @media print {
        @page {
            size: landscape;
            margin: 10mm !important;
        }
        th,td{
            font-size: 10pt;
            padding: 0 2mm !important;
        }
      }`;

    return (
        <table ref={ref} className={`table ${styles.table_prosp}`} cellSpacing="0" cellPadding="0">
            <style>{pageStyle}</style>
            <thead>
                <tr>
                    <th>Num.</th>
                    <th onClick={() => requestSort('name')}>Name</th>
                    <th onClick={() => requestSort('phoneNumber')}>Phone Number</th>
                    <th onClick={() => requestSort('organisation')}>Organisation</th>
                    <th onClick={() => requestSort('email')}>Email</th>
                    <th>Full Address</th>
                    <th onClick={() => requestSort('state')}>State</th>
                    <th onClick={() => requestSort('dateCreated')}>Date Created</th>
                    {isInclude && <th>Deals & Services</th>}
                </tr>
            </thead>
            <tbody>
                {items.map((data, i) => <tr onClick={onHighlight} key={data.id}>
                    <td>{i + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.phoneNumber}</td>
                    <td>{data.organisation ?? "N/A"}</td>
                    <td>{data.email ?? "N/A"}</td>
                    <td>{data.fullAddress}</td>
                    <td>{data.state}</td>
                    <td>{data.dateCreated.toDateString()}</td>

                    {isInclude && !isFetching && <td> {data.history.map(d => <div key={d.id}>
                        <p><strong>{d.status}</strong> - {d.dateCreated} - {d.title ?? "N/A"} - {d.services}</p>
                    </div>
                    )}</td>}

                </tr>)}
            </tbody>
        </table>
    )
}
)
export default function Prospects() {
    const isMounted = useRef(false)
    const [error, setError] = useState(null)
    const [years, setYears] = useState([])
    const [result, setResult] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const excelResult = useRef([])
    const year = useRef()
    const month = useRef()
    const include = useRef()

    const printComp = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComp.current,
    });

    const generate = async () => {
        const m = month.current.value
        const y = year.current.value
        const i = include.current.checked
        let ref = db.collection("Prospects");
        let resultArray = []

        let start = null, end = null
        if (m !== "all") {
            start = new Date(y, MONTHS.indexOf(m), 1)
            end = new Date(y, MONTHS.indexOf(m) + 1, 0, 8)
        } else {
            start = new Date(y, 0, 1)
            end = new Date(y, 12, 0)
        }
        ref = ref.where("dateCreated", ">=", start).where("dateCreated", "<=", end)

        setIsFetching(true)
        const res = await ref.get().catch(err => {
            setIsFetching(false)
            console.error(err)
            setError(err)
        })
        resultArray = res.docs.map(d => ({ ...d.data(), id: d.id }))
        const ids = res.docs.map(d => d.id)

        resultArray = resultArray.map((data) => ({
            ...data,
            dateCreated: data.dateCreated.toDate(),
            fullAddress: sortAddress(data)
        }))

        excelResult.current = resultArray.map((r, idx) => ({
            Num: idx + 1,
            Name: r.name,
            Phone_Number: r.phoneNumber,
            Organisation: r.organisation,
            Email: r.email,
            Full_Address: r.fullAddress,
            State: r.state,
            Date_Created: r.dateCreated.toDateString(),
        }))

        if (i) {
            const res = await Promise.all([...ids.map(id => db.collection(`Prospects/${id}/DealHistory`).orderBy("dateCreated", "desc").get())]).catch(err => {
                setIsFetching(false)
                console.error(err)
                setError(err)
            })

            res.forEach((r, idx) => {
                resultArray[idx].history = r.docs.map(d => ({ ...d.data(), id: d.id, dateCreated: d.data().dateCreated.toDate().toDateString() }))
                excelResult.current[idx].History = r.docs.map(d => `[${d.data().status} - ${d.data().dateCreated.toDate().toDateString()} - ${d.data().title} - ${d.data().services}]`)
            })
        }

        resultArray.sort((a, b) => b.dateCreated - a.dateCreated)

        setResult(resultArray)
        setIsFetching(false)
    }

    useEffect(() => {
        document.querySelector('title').textContent = 'Prospects - Report'
        isMounted.current = true
        let initYear = new Date(Date.parse(localStorage.getItem("INIT_DATE"))).getFullYear()
        const currYear = new Date().getFullYear()
        const yearArr = []
        while (initYear <= currYear) {
            yearArr.push(initYear++);
        }
        setYears(yearArr)
        return () => isMounted.current = false
    }, [])

    return (
        <div>
            <div className="option-tab">
                <h3 className="option-tab-name">Prospects</h3>
                <div className="v-seperator"></div>
                <label htmlFor="prospectYear">Year: </label>
                <select ref={year} name="prospectYear" id="prospectYear">
                    {years.map(y => <option key={y} value={y}>{y}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="prospectMonth">Month: </label>
                <select ref={month} name="prospectMonth" id="prospectMonth">
                    <option value="all">All</option>
                    {MONTHS.map(m => <option key={m} value={m}>{m}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="includeDs">Include Deals & Services </label>
                <input ref={include} type="checkbox" name="includeDs" id="includeDs" />
                <div className="v-seperator"></div>
                <button onClick={generate} className="btn-green">
                    <span style={{ marginRight: "10px" }}>Generate</span>
                    {isFetching && <MiniSpinner wh={20} />}
                </button>
                {result.length > 0 &&
                    <>
                        <div className="v-seperator"></div>
                        <CSVLink data={excelResult.current} filename={`Prospects_Report_${new Date().toString()}.csv`}>
                            <button className="btn-green">
                                <span>Export to MS Excel</span>
                            </button>
                        </CSVLink>
                        <div className="v-seperator"></div>
                        <button onClick={handlePrint} className="btn-white">
                            <span>Print report</span>
                        </button>
                    </>
                }
            </div>
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {result.length === 0 ?
                <NoResult /> :
                <div className={styles.container}>
                    <Tip />
                    <Table ref={printComp} result={result} isInclude={include.current.checked} isFetching={isFetching} />
                </div>
            }
        </div>
    )
}
