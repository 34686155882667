import React, { useRef, useState } from 'react'
import {STATUS} from '../../configuration'
import { updateDeal } from '../../../controllers/dealController'
import { useParams } from 'react-router'

//Styling in the global.css

function StatusComponent({status}) {

    const dropdownMenu = useRef()
    const [onStatus,setOnStatus] = useState(status)
    const params = useParams()
    const id = params.id
    const dealStatus = params.status

    const clickStatus = (e) => {
        if(dealStatus !== undefined) return null
        e.stopPropagation()
        dropdownMenu.current.classList.toggle('active')
    }

    const click = async (e) => {
        e.stopPropagation()
        const newStatus = e.target.textContent
        await updateDeal(id,{status:newStatus})
        dropdownMenu.current.classList.remove('active')
        setOnStatus(newStatus)
    }

    let pointerStyle = {
        backgroundColor: '#91919d',
        borderRadius: '50%',
        width: '15px',
        height: '15px',
        display:'inline-block',
        verticalAlign:'bottom'
    }
    if(onStatus === STATUS[0]){
        pointerStyle.backgroundColor = '#6bd488'
    }else if(onStatus === STATUS[1]){
        pointerStyle.backgroundColor = '#2bccff'
    }

    return (
        <div onClick={clickStatus} className='status__dropdown'>
            <h3><span style={pointerStyle}></span> {onStatus} </h3>
            <div ref={dropdownMenu} id="statusDropdownMenu" className='status__dropdown_menu'>
                {STATUS.map(s => <p onClick={click} key={s}>{s}</p>)}
            </div>
        </div>
    )
}


export default React.memo(StatusComponent)
