import React, { useState } from 'react'
import reactDom from 'react-dom'
import { useAuth } from './contexts/AuthContext'

export default function ForgotPassword({ onClose }) {
    const [status, setStatus] = useState(null)
    const { resetPassword } = useAuth()
    const [isSending, setIsSending] = useState(false)

    const dialogStyles = {
        borderRadius: '10px'

    }

    const submit = (e) => {
        e.preventDefault()
        setIsSending(true)
        resetPassword(e.target.email.value).then(() => {
            setStatus('success')
            setIsSending(false)
        }).catch(err => {
            console.error(err)
            setStatus('fail')
            setIsSending(false)
        })
    }
    return reactDom.createPortal(
        <>
            <div className="dialog-overlay"></div>
            <div className="wpd" style={dialogStyles}>
                <span onClick={onClose} style={{ color: 'red', float: 'right', cursor: 'pointer' }} className="material-icons">close</span>
                <h2>Forgot password ?</h2>
                {status && <p style={{ margin: '10px 0', color: status === 'success' ? 'green' : 'red' }}>
                    {status === 'success' ? 'Password reset email sent !' : 'Something went wrong, please try again.'}
                </p>}
                <p style={{ margin: '10px 0' }}>Enter your email to reset your password.</p>
                <form onSubmit={submit}>
                    <input style={{ width: '100%', borderRadius: '10px' }} required type="email" name="email" id="email" placeholder="Email" />
                    <button disabled={isSending} style={{ marginLeft: 'auto', marginTop: '10px' }} className="btn-green">
                        {isSending ? 'Sending...' : 'Send'}
                    </button>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
}
