import React from 'react'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate'
import styles from './activities.module.css'
import dayjs from 'dayjs'

function ViewDialog({ onCancel, data }) {

    const label = {
        fontWeight: "var(--regular)"
    }

    const marginBot = {
        marginBottom:"10px"
    }

    return (
        <DialogTemplate title="View Activity" toggle={true} height="fit-content">
            <form className="layout-dialog__form" >

                <p style={label}>Title</p>
                <p style={marginBot}>{data.title}</p>

                <p style={label}>Location</p>
                <p style={marginBot}>{data.location ?? "N/A"}</p>

                <div style={marginBot} className={styles.date__field}>
                    <div style={{minWidth:"100px"}} className="layout-dialog__form">
                        <p style={label}>Status</p>
                        <p>{data.status}</p>
                    </div>

                    <div style={{minWidth:"100px"}} className="layout-dialog__form">
                        <p style={label}>Prospect</p>
                        <p>{data.prospect}</p>
                    </div>

                    <div style={{minWidth:"100px"}} className="layout-dialog__form">
                        <p style={label}>Services</p>
                        <p>{data.services}</p>
                    </div>
                </div>

                <div style={marginBot} className={styles.date__field}>
                    <div style={{minWidth:"100px"}} className="layout-dialog__form">
                        <p style={label}>Date Start</p>
                        <p>{dayjs(data.start.toDate()).format('YYYY-MM-DD')}</p>
                    </div>

                    <div style={{minWidth:"100px"}} className="layout-dialog__form">
                        <p style={label}>Date End</p>
                        <p>{data.end ? dayjs(data.end.toDate()).format('YYYY-MM-DD') : "N/A"}</p>
                    </div>

                    <div style={{minWidth:"100px"}}  className="layout-dialog__form">
                        <p style={label}>Time</p>
                        <p>{data.time}</p>
                    </div>
                </div>

                <div style={marginBot} className={styles.date__field}>
                    <div className="layout-dialog__form" style={{minWidth:"100px"}} >
                        <p style={label}>Duration</p>
                        <p>{data.duration}</p>
                    </div>

                    <div className="layout-dialog__form" style={{minWidth:"100px"}}  >
                        <p style={label}>Activity</p>
                        <p>{data.type}</p>
                    </div>

                    <div className="layout-dialog__form" style={{minWidth:"100px"}} />
                </div>

                <p style={label}>Other Details</p>
                <p style={marginBot}>{data.details}</p>

                <div className="buttons-dialog__form" >
                    <button
                        type="button"
                        className="btn-white"
                        onClick={() => { onCancel() }}
                    >Cancel</button>
                </div>
            </form>
        </DialogTemplate>
    )
}

export default React.memo(ViewDialog)
