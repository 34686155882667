import styles from './wall.module.css'

export default function Indicator({type}) {

    const SO_STYLES = {
        backgroundColor: "#6BD488"
    }

    const EVENT_STYLES = {
        backgroundColor: "#FA3E3E"
    }

    const NOTE_STYLES = {
        backgroundColor: "#2ACCFF"
    }

    const SYSTEM_STYLES = {
        backgroundColor: "#536EEF"
    }

    return (
        <div className={styles.record__ind}>
            <div className={styles.round} style={
                type === "SO" ? SO_STYLES :
                type === "EVENT" ? EVENT_STYLES :
                type === "NOTE" ? NOTE_STYLES :
                SYSTEM_STYLES
            }>
                <span className="material-icons">{
                     type === "SO" ? "assignment" :
                     type === "EVENT" ? "event_note" :
                     type === "NOTE" ? "note" :
                     "history"
                }</span>
            </div>
            <div className={styles.bar} style={
                 type === "SO" ? SO_STYLES :
                 type === "EVENT" ? EVENT_STYLES :
                 type === "NOTE" ? NOTE_STYLES :
                 SYSTEM_STYLES
            }></div>
        </div>
    )
}
