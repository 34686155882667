import React from 'react'
import SearchComponent from '../../../components/SearchComponent/SearchComponent'
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom'
import searchClient from '../../../algolia'
import SearchHit from './SearchHit'
import {MONTHS} from '../../configuration'

export default function Option({ tab,onChangeYear,onChangeMonth }) {

    let initYear = new Date(Date.parse(localStorage.getItem("INIT_DATE"))).getFullYear()
    const currYear = new Date().getFullYear()
    const currMonth = MONTHS[new Date().getMonth()]
    let years = []
    while ( initYear <= currYear ) {
        years.push(initYear++);
    }

    const HitComp = connectHits(SearchHit)
    let index;
    let which;
    let attToRetrieve = ['dealTitle', 'name', 'organisation', 'dealServices', 'closingDate', 'value']
    if (tab === "Completed Deals") {
        which = "Completed"
        index = process.env.REACT_APP_ALGOLIA_INDEX_COMPLETED_DEALS
    } else if (tab === "Lost Deals") {
        which = "Lost"
        index = process.env.REACT_APP_ALGOLIA_INDEX_LOST_DEALS
    } else if (tab === "Deleted Deals") {
        which = "Deleted"
        index = process.env.REACT_APP_ALGOLIA_INDEX_DELETED_DEALS
    } else {
        which = "so"
        index = process.env.REACT_APP_ALGOLIA_INDEX_SERVICE_ORDER
        attToRetrieve = ['title','services','ticket','status','name','creator','dateCreated']
    }

    return (
        <div style={{marginBottom:'10px'}} className="archive-tab">
            <h3 className="tab-name">{tab}</h3>
            <InstantSearch
                indexName={index}
                searchClient={searchClient}>
                <Configure
                    hitsPerPage={5}
                    attributesToRetrieve={attToRetrieve}
                />
                <SearchComponent>
                    <HitComp tab={which} />
                </SearchComponent>
            </InstantSearch>
            {tab !== "Service Order" ? <>
                <div className="v-seperator"></div>
                <label htmlFor="archiveYear">Year: </label>
                <select defaultValue={currYear} onChange={onChangeYear} name="archiveYear" id="archiveYear">
                    {years.map(y =><option key={y} value={y}>{y}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="archiveMonth">Month: </label>
                <select defaultValue={currMonth} onChange={onChangeMonth} name="archiveMonth" id="archiveMonth">
                    {MONTHS.map(m =><option key={m} value={m}>{m}</option> )}
                </select>
            </> : ""}
        </div>
    )
}
