import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useNav } from '../../contexts/NavContext'
import { useUsers } from '../../contexts/UsersContext'
import { getVersion } from '../../controllers/appSettingsController'
import { storage } from '../../firebase'
import './navbar.css'

const disabledStyle = {
    filter: "grayscale(70%)",
    // pointerEvents:'none'
}

function NavItem({ dest, title, active, icon, disabled = false, locked = false }) {

    const { setNavigation } = useNav()
    const navLink = useRef()

    if (disabled) {
        icon = "warning"
    }

    const handleOnClick = (e) => {
        if (disabled || locked) {
            e.preventDefault()
            return
        }

        const active = document.querySelector('.nav-link.active')
        if (active != null) active.classList.remove('active')
        navLink.current.classList.add("active")
        const dropdownItemActive = document.querySelector('.nav-sub-link.active')
        if (dropdownItemActive != null) dropdownItemActive.classList.remove('active')
        setNavigation(title)
    }

    return (
        <li>
            {!locked ?
                <Link style={disabled ? disabledStyle : undefined} name={title} onClick={handleOnClick} ref={navLink} className={`nav-link ${active ? "active" : null}`}
                    to={dest}>
                    <div className="nav-link-logo">
                        <span className="material-icons">{icon}</span>
                    </div>
                    <span>{title}</span>
                    <span className="material-icons">chevron_right</span>
                </Link> :
                <Link style={locked ? disabledStyle : undefined} name={title} onClick={handleOnClick} ref={navLink} className={`nav-link ${active ? "active" : null}`}
                    to={dest}>
                    <div className="nav-link-logo">
                        <span className="material-icons">{icon}</span>
                    </div>
                    <span>{title}</span>
                    <span className="material-icons">lock</span>
                </Link>}
        </li>
    )
}

function Dropdown({ locked = false, disabled = false }) {
    const { setNavigation } = useNav()
    const dropdownItems = useRef()
    const [toggle, setToggle] = useState(false);
    const parent = useRef()
    const arrow = useRef()

    const handleOnClick = (e) => {
        if (disabled || locked) {
            e.preventDefault()
            return
        }

        const dropdownItemActive = document.querySelector('.nav-sub-link.active')
        if (dropdownItemActive != null) dropdownItemActive.classList.remove('active')
        e.target.classList.add("active")
        const active = document.querySelector('.nav-link.active')
        if (active != null) active.classList.remove('active')
        parent.current.classList.add("active")
        setNavigation('CRM')
    }

    const toggleDropdown = (e) => {
        if (disabled || locked) {
            e.preventDefault()
            return
        }
        if (toggle && dropdownItems.current.classList.contains("hide")) {
            dropdownItems.current.classList.remove("hide")
            arrow.current.style.transform = "rotate(90deg)"
            return
        }
        setToggle(!toggle)
        toggle ? arrow.current.style.transform = "unset" : arrow.current.style.transform = "rotate(90deg)"
    }

    return (
        <li>
            {!locked ? <Link name="CRM" ref={parent} to="#" onClick={toggleDropdown} className="nav-link">
                <div className="nav-link-logo">
                    <span className="material-icons">post_add</span>
                </div>
                <span>CRM</span>
                <span ref={arrow} className="material-icons rotating-arrow">chevron_right</span>
            </Link> :
                <Link style={disabledStyle} name="CRM" ref={parent} to="#" onClick={toggleDropdown} className="nav-link">
                    <div className="nav-link-logo">
                        <span className="material-icons">post_add</span>
                    </div>
                    <span>CRM</span>
                    <span ref={arrow} className="material-icons rotating-arrow">lock</span>
                </Link>
            }
            <div ref={dropdownItems} className={`dropdown-menu ${toggle ? "" : "hide"}`} >
                <Link to="/crm/deals#pipeline" onClick={handleOnClick} className="nav-sub-link">
                    Deals
                </Link>
                <Link to="/crm/activities" onClick={handleOnClick} className="nav-sub-link">
                    Activities
                </Link>
                <Link to="/crm/prospects" onClick={handleOnClick} className="nav-sub-link">
                    Prospects
                </Link>
                <Link to="/crm/dashboard" onClick={handleOnClick} className="nav-sub-link">
                    Dashboard
                </Link>
                <Link to="/crm/report" onClick={handleOnClick} className="nav-sub-link">
                    Report
                </Link>
                <Link to="/crm/archive" onClick={handleOnClick} className="nav-sub-link">
                    Archive
                </Link>
            </div>
        </li>
    )
}

const NavHeader = React.memo(({user}) => {
    const [logo, setLogo] = useState('./img/img_placeholder.png')
    const { currentUser } = useAuth()
    const [displayName,setDisplayName] = useState("")

    useEffect(() => {
        if(!currentUser) return

        setDisplayName(currentUser.displayName)
        if(currentUser.displayName === undefined || currentUser.displayName === null){
            const setNameIntoCurrentUser = async () => {
                await currentUser.updateProfile({ displayName:user.name }).catch(err => {
                    console.error(err)
                })
                setDisplayName(user.name)
            }
            setNameIntoCurrentUser()
        }

        const fetchLogo = async () => {
            const ref = storage.ref().child('/company/logos/comp_logo_full_200x200.png')
            ref.getDownloadURL()
                .then((url) => {
                    sessionStorage.setItem('LOGO_COMP_FULL', url)
                    setLogo(url)
                })
                .catch((error) => {
                    console.error('Error retrieving image.')
                });
        }
        if (sessionStorage.getItem('LOGO_COMP_FULL') !== null) {
            setLogo(sessionStorage.getItem('LOGO_COMP_FULL'))
        } else {
            fetchLogo()
        }
    }, [currentUser,user])

    return (
        <div className="nav-header">
            <div className="nav-logo">
                <img height="100%" width="100%" src={logo} alt="Company logo" />
            </div>
            <div className="nav-user">
                <span className="material-icons">person</span>
                <p>Logged in as <strong>{displayName}</strong></p>
            </div>
        </div>
    )
})

const Version = React.memo(() => {
    const ver = useRef()

    useEffect(() => {
        const fetchVer = async () => {
            const res = await getVersion().catch(err => {
                console.error("Something went wrong while fetching app version.")
            })

            if (res) {
                ver.current.textContent = 'Version ' + res.get('version');
            }
        }
        fetchVer()
    }, [])

    return (
        <div className="nav-version">
            <p ref={ver}></p>
        </div>
    )
})

export default function Navbar() {
    const nav = useRef()
    const { getNav } = useNav()
    const { currentUser } = useAuth()
    const { users } = useUsers()
    const [isLocked, setLocked] = useState({
        home: false,
        crm: false,
        docinv: false,
        tracker: false,
        rfp: false
    })
    const [user,setUser] = useState(null)

    useEffect(() => {
        if (!currentUser || users.length === 0) return

        const active = document.querySelector('.nav-link.active')
        if (active != null) active.classList.remove('active')

        const toActive = document.getElementsByName(getNav)[0]
        toActive.classList.add("active")

        const dropdownItemActive = document.querySelector('.nav-sub-link.active')
        if (dropdownItemActive != null) dropdownItemActive.classList.remove('active')

        const user = users.filter(u => u.id === currentUser.uid)[0]
        if (user.role.includes("Operation")) {
            setLocked({
                home: false,
                crm: true,
                docinv: true,
                tracker: true,
                rfp: false
            })
        }
        setUser(user)

    }, [getNav, currentUser, users])

    const handleMouseLeave = () => {
        nav.current.classList.add("collapsed")
        document.querySelector(".dropdown-menu").classList.add("hide")
        document.querySelector(".rotating-arrow").style.transform = "unset"
    }

    const handleMouseEnter = () => {
        nav.current.classList.remove("collapsed")
    }

    const navStyle = {
        display: 'flex',
        flexDirection: 'column'
    }

    return currentUser && (
        <nav ref={nav} className="nav collapsed" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <ul style={navStyle}>
                <NavHeader user={user} />
                <div>
                    <NavItem icon="home" dest="/" title="Home" active={true} locked={isLocked.home} />
                    <Dropdown locked={isLocked.crm} />
                    <NavItem icon="folder" dest="/document-inventory" title="Document Inventory" locked={isLocked.docinv} />
                    {/* <NavItem icon="description" dest="/tracker" title="Tracker" disabled={true} locked={isLocked.tracker} /> */}
                    <NavItem icon="request_page" dest="/rfp" title="RFP" locked={isLocked.rfp} />
                    {/* LAS Update */}
                    <NavItem icon="class" dest="/las" title="Leave Application" locked={isLocked.home} />
                </div>
                <Version />
            </ul>
        </nav>
    )
}
