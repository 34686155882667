import React from 'react'
import styles from './las.module.css'
import { useLAS } from './LASContext'

function Dashboard() {
    const {leaves} = useLAS()

    return (
        <div className={styles.dashboard}>
            <div className={styles.board}>
                <p>Total Leave Entitlement</p>
                <span>{leaves.leaveEnt}</span>
            </div>
            <div className={styles.board}>
                <p>Total Leave Balance</p>
                <span>{leaves.leaveEnt - leaves.leaveUsed}</span>
            </div>
            <div className={styles.board}>
                <p>Total MC Entitlement</p>
                <span>{leaves.mcEnt}</span>
            </div>
            <div className={styles.board}>
                <p>Total MC Balance</p>
                <span>{leaves.mcEnt - leaves.mcUsed}</span>
            </div>
        </div>
    )
}

export default React.memo(Dashboard)
