import React from 'react'
import reactDom from 'react-dom'
import styles from './dialogs.module.css'

const dialogStyles = {
    width: "20%",
    padding:"0"
}

function Success({ toggle, callback, infoText = '' }) {

    if (!toggle) return null
    return reactDom.createPortal(
        <>
            <div className="dialog-overlay" />
            <div className="wpd" style={dialogStyles}>
                <div className={styles.dialog__layout}>
                    <div className={styles.banner__green}>
                        <div className={styles.circle}>
                            <span className="material-icons">done</span>
                        </div>
                    </div>
                    <h2 className={styles.header__text}>Success !</h2>
                    <p className={styles.infoText}>{infoText}</p>
                    <button className={`${styles.ok__single__btn} btn-white`} onClick={()=>callback()}>OK</button>
                </div>
            </div>
        </>,
        document.getElementById("portal")
    )
}

export default Success
