import { useState } from "react"
import DialogTemplate from "../../../components/Dialogs/DialogTemplate"
import { updateNote } from "../../../controllers/dealController"

export default function DialogEditNote( {onCancel , id,data,onUpdated}) {
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [notes,setNotes] = useState(data.notes)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)

        const formData = {
            notes: notes
        }
        
        await updateNote(id,formData,data.id).catch(err => {
            setLoading(false)
            return setError(err)
        })
        setLoading(false)
        e.target.reset()
        onUpdated(formData.notes)
    }

    return (
        <DialogTemplate title="Edit Note" toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form" >

                <textarea value={notes} onChange={(e)=>setNotes(e.target.value)} required name="createNote" id="createNote" cols="30" rows="10" placeholder="What's on your mind ?"></textarea>

                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => {setError(null);onCancel()}}
                    >Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Saving..." : "Save"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
