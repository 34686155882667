export const nullifyEmptyInput = (input) => {
    return input.value === "" ? null : input.value
}

export const getOrigin = () => {
    return window.location.origin
}

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const zeroPad = (num) => String(num).padStart(7, '0');

export const zeroPadCustom = (num, pad) => String(num).padStart(pad, '0');

export const sortAddress = (data) => {
    return `${data.address ?? ""}, ${data.address2 ?? ""}, ${data.poscode ?? ""} ${data.city ?? ""}, ${data.state ?? ""}`
}

export const parseToDateInputObj = (date) => {
    if (date === null) return null
    return `${date.getFullYear()}-${zeroPadCustom(date.getMonth() + 1, 2)}-${zeroPadCustom(date.getDate(), 2)}`
}

export const copyToClipboard = (text) => {
    return navigator.clipboard.writeText(text).then(function () {
        return "ok"
    }, function (err) {
        return err
    });
}

export const isOdd = (num) => { return num % 2; }

export function printElement(id, title = "") {
    const divToPrint = document.getElementById(id);
    const newWin = window.open("");
    newWin.document.write(title);
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
}

export const searchFunction = (searchBoxID, tableID) => {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById(searchBoxID);
    filter = input.value.toUpperCase();
    table = document.getElementById(tableID);
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[1];
        if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }
}

export const randomColor = () => "#" + Math.floor(Math.random() * 16777215).toString(16);

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

export const  getCroppedImg = async (imageSrc, pixelCrop,ext, rotation = 0) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve({url:URL.createObjectURL(file),blob:file})
    }, ext)
  })
}

export const base64StringtoFile = (base64String, filename) => {
    var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}

export const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export const numOnlyRegex = /^[0-9]+$/;

export const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }