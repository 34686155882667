import React from 'react'
import dayjs from 'dayjs'
import styles from './archive.module.css'
import { openInNewTab, getOrigin } from '../../helper'

const DealHit = ({hits,tab}) => {

    const handleClick = (e,id) => {
        e.stopPropagation()
        openInNewTab(`${getOrigin()}/crm/wall/${id}/${tab}`)
    }

    return (
        <div >
        {hits.map(hit =>{ 
            return (
            <li onMouseDown={(e)=>handleClick(e,hit.objectID)} className={styles.hit} key={hit.objectID} >
                <div>
                    <h4>{hit.dealTitle ?? "No title"} - {hit.name}</h4>
                    <p>{hit.dealServices}</p>
                    <p>{hit.organisation}</p>
                    <p>{dayjs.unix(hit.closingDate._seconds).format('DD-MM-YYYY')}</p>
                    <p>{hit.value !== null ? `RM ${hit.value}` : "RM 0"}</p>
                </div>
            </li>
        )})}
    </div>
    )
}

const SOHit = ({hits}) => {
     const handleClick = (e,id) => {
        e.stopPropagation()
        openInNewTab(`${getOrigin()}/service-order/${id}`)
    }

    return (
        <div >
        {hits.map(hit =>{ 
            return (
            <li onMouseDown={(e)=>handleClick(e,hit.objectID)} className={styles.hit} key={hit.objectID} >
                <div>
                    <h4>{hit.ticket} - {hit.title ?? "No title"}</h4>
                    <p>{hit.name}</p>
                    <p>{hit.services}</p>
                    <p>{dayjs.unix(hit.dateCreated._seconds).format('DD-MM-YYYY')}</p>
                    <p>{hit.status}</p>
                </div>
            </li>
        )})}
    </div>
    )
}

export default function SearchHit({hits,tab}) {

    let noText = "No deal matched."
    if(tab === "so"){
        noText = "No service order matched."
    }

    return (
        <>{hits.length === 0 && <p style={{textAlign:"center",color:'black'}}>{noText}</p>}
            <ul>
               {tab !== "so" ? <DealHit tab={tab} hits={hits}/>: <SOHit hits={hits}/>}
            </ul>
        </>
    )
}
