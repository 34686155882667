import {useEffect, useState} from 'react'
import { useUsers } from '../../contexts/UsersContext'
import styles from './home.module.css'
import ViewUserPfp from './ViewUserPfp'

const ListItem = ({ user }) => {
    const [isView,setIsView] = useState(false)

    const roleStyle = {
        backgroundColor:'var(--green)',
        color:'white',
        padding:'5px 10px',
        borderRadius:'20px',
        alignSelf:'flex-end'
    }

    return (
        <>
        <div style={{borderBottom:'1px solid var(--clr-hover-white)',paddingBottom:'10px'}}>
            <div onClick={()=>setIsView(true)} className={styles.img__user}>
                <img style={{objectFit:'cover'}} src={user.pfpLink ?? './img/user_placeholder.png'} alt="user avatar" />
            </div>
            <div>
                <h3>{user.name}</h3>
                <p>{user.position}</p>
            </div>
            <div style={{ marginLeft: 'auto',display:'flex',flexDirection:'column',gap:'5px',textAlign:'end' }}>
                {user.role.map((role,idx) => <span style={roleStyle} key={idx}>{role}</span>)}
            </div>
        </div>
        {
            isView && 
            <ViewUserPfp
                link={user.pfpLink}
                name={user.name}
                onClose={()=>setIsView(false)}
            />
        }
        </>
    )
}

export default function StaffListWidget() {
    const { users } = useUsers()

    useEffect(() => {}, [users])

    const containerStyle ={
        maxHeight:'400px',
        padding:'10px',
        overflow:'auto'
    }

    return (
        <div className={styles.widget__staff}>
            <div className={styles.section}>
                <span className="material-icons">groups</span>
                <h3 style={{ color: 'var(--primary-purple)' }}>Staff List</h3>
            </div>
            <div style={containerStyle}>
                {
                    users.map(user => <ListItem key={user.id} user={user}/>)
                }
            </div>
        </div>
    )
}
