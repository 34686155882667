import React, { useState, useRef } from 'react'
import Header from '../../components/Header/Header'
import List from './List'
import Request from './Request'
import Settings from './Settings'
import Status from './Status'
import './rfp.css'

function handleSwitch(ref) {
    document.querySelector('.tab.active').classList.remove("active")
    ref.current.classList.add("active")
}

function SubHeader({ setToggle }) {

    const requestTab = useRef()
    const list = useRef()
    const statusTab = useRef()
    const settingTab = useRef()

    return (
        <div className="subheader">

            <div className="tab active" ref={requestTab} onClick={(e) => {
                handleSwitch(requestTab)
                setToggle('requestTab')
            }}>
                <span className="material-icons">add</span>
                <span>Request</span>
            </div>

            <div className="tab " ref={statusTab} onClick={(e) => {
                handleSwitch(statusTab)
                setToggle('statusTab')
            }}>
                <span className="material-icons">pending_actions</span>
                <span>Status</span>
            </div>

            <div className="tab" ref={list} onClick={(e) => {
                handleSwitch(list)
                setToggle('list')
            }}>
                <span className="material-icons">list</span>
                <span>List</span>
            </div>


            <div className="tab " ref={settingTab} onClick={(e) => {
                handleSwitch(settingTab)
                setToggle('settingTab')
            }}>
                <span className="material-icons">settings</span>
                <span>Settings</span>
            </div>

            <div>
            </div>
        </div>
    )
}

export default function RFP() {
    const [toggle, setToggle] = useState('requestTab');

    return (
        <>
            <Header title="Request For Purchase" />
            <SubHeader setToggle={setToggle} />
            <div className="tab-contents">
                {toggle === "requestTab" && <Request />}
                {toggle === "list" && <List />}
                {toggle === "statusTab" && <Status />}
                {toggle === "settingTab" && <Settings />}
            </div>
        </>
    )
}
