import React, { useState, useEffect, useRef } from 'react'
import DialogTemplate from '../../../../components/Dialogs/DialogTemplate';

export default function PaymentConfDialog({ onCancel, callback, which = 0, amountLimit = 9999999 }) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const isMounted = useRef(false)

    const dialogStyles = {
        width: "20%",
        padding: "0",
        borderRadius: "5px",
        overflow: "hidden"
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const form = e.target
        setError(false)
        setIsLoading(true)

        let data;
        if(which === 0){
            data = {
                date: form.payDate.valueAsDate
            }
        }else{

            if(parseFloat(form.payAmount.value) > parseFloat(amountLimit)) {
                if(isMounted.current) setIsLoading(false)
                return setError({code:"Amount-Limiter",message:"Total paying amount is larger than deal value."})
            }

            data = {
                date: form.payDate.valueAsDate,
                amount: form.payAmount.value
            }
        }
    
        const res = await callback(data)
        if(isMounted.current) setIsLoading(false)
        if (res.status === 'not-ok') {
            setError(res.error)
        }
    }

    useEffect(() => {
        isMounted.current = true
        return () => isMounted.current = false
    }, [])

    return (
        <DialogTemplate dialogStyles={dialogStyles} title="Set Payment Date" toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form" >

                {which === 1 && <input required placeholder="Paid amount" type="number" step="0.01" name="payAmount" id="payAmount" />}
                 <p style={{ textAlign: "end" }}>(Optional) If the payment date is today, leave unchanged.</p>
                <input type="date" name="payDate" id="payDate" />

                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => onCancel()}
                    >Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Saving..." : "Save"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
