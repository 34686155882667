import React from 'react'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate'
import { useUsers } from '../../../contexts/UsersContext'
import styles from './wall.module.css'

const StageHistory  = ({data}) => {
    return (
        <div className={styles.history}>
            <div>
                <p><strong>{data.createdBy.name}</strong> on <strong>{data.dateCreated.toDate().toDateString()}</strong></p>
            </div>
            <p>Stage updated from <strong>{data.from}</strong> to <strong>{data.to}</strong> </p>
        </div>
    )
}

export default function WallHistory({ dataArray,onCancel }) {

    const {users} = useUsers()

    dataArray = dataArray.map(data => ({...data,createdBy:users.filter(u => u.id === data.createdBy)[0]}))

    return (
        <DialogTemplate title="Wall History" toggle={true} height="fit-content">

            {
                dataArray.length === 0 &&
                <p>Nothing happens yet. History is empty.</p>
            }

            {dataArray.map((data,idx) => {
                if(data.type === 'stage'){
                    return <StageHistory key={idx} data={data} />
                }
                return null
                //update other logs here
            })}

            <div className="buttons-dialog__form" >
                <button
                    style={{marginLeft:'auto'}}
                    type="button"
                    className="btn-white"
                    onClick={() => { onCancel()}}
                >Close</button>
            </div>
        </DialogTemplate>
    )
}
