import React, { useEffect, useRef, useState } from 'react'
import CHART_1 from './SummaryCharts/CHART_1'
import CHART_2 from './SummaryCharts/CHART_2'
import CHART_3 from './SummaryCharts/CHART_3'
import CHART_4 from './SummaryCharts/CHART_4'
import CHART_5 from './SummaryCharts/CHART_5'
import CHART_6 from './SummaryCharts/CHART_6'
import { useUsers } from '../../../contexts/UsersContext'

export const SummaryChartsCtx = React.createContext()

export default function Summary() {
    const d = new Date()
    const year = useRef()

    const firstDay = new Date(d.getFullYear(), 0, 1)
    const lastDay = new Date(d.getFullYear(), 11 + 1, 0)

    const [date, setDate] = useState({
        firstDay,
        lastDay
    })

    const { users } = useUsers()
    const [years, setYears] = useState([])
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = "Summary - Dashboard"
        let initYear = new Date(localStorage.getItem('INIT_DATE')).getFullYear()
        const ys = []
        const d = new Date()
        while (initYear <= d.getFullYear()) {
            ys.push(initYear)
            initYear++
        }
        setYears(ys)

        return () => isMounted.current = false
    }, [])

    const stylesFlex = { display: 'flex', height: '250px', alignItems: 'center', gap: '10px', width: '100%', marginBottom: '3rem' }
    const stylesFlexBigSquare = { ...stylesFlex, height: '550px' }
    const singleChart = { ...stylesFlex, height: '700px',justifyContent:'center' }

    const onChangeDate = () => {
        const y = parseInt(year.current.value)
        const firstDay = new Date(y, 0, 1)
        const lastDay = new Date(y, 11 + 1, 0)
        setDate({firstDay,lastDay})
    }

    return (
        <>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <select ref={year} defaultValue={date.y}>
                    {years.map(y => <option key={y} value={y}>{y}</option>)}
                </select>
                <button onClick={onChangeDate} className="btn-purple">Apply</button>
            </div>
            <SummaryChartsCtx.Provider value={{ date, users, isMounted }}>
                <div style={stylesFlexBigSquare}>
                    <CHART_1 />
                    <CHART_2 />
                    <CHART_3 />
                </div>
                <div style={singleChart}>
                    <CHART_4 />
                </div>
                <div style={singleChart}>
                    <CHART_5 />
                </div>
                <div style={singleChart}>
                    <CHART_6 />
                </div>
            </SummaryChartsCtx.Provider>
        </>
    )
}
