import styles from './wall.module.css'
import Indicator from './Indicator'
import { useUsers } from '../../../contexts/UsersContext'
import { useAuth } from '../../../contexts/AuthContext'
import { SharedFunctions } from './MainContainer'
import React, { useContext, useState } from 'react'
import { deleteActivity } from '../../../controllers/dealController'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import DialogEditNote from './DialogEditNote'
import { useParams } from 'react-router-dom'

function NoteComponent({ data }) {
    const { status } = useParams()
    const { users } = useUsers()
    const { currentUser } = useAuth()
    const { setActivities, did } = useContext(SharedFunctions)
    const [isLoading, setIsLoading] = useState('')
    const [error, setError] = useState(null)
    const [isEditing, setIsEditing] = useState(false)

    const style = {
        borderLeft: "5px solid #2ACCFF"
    }

    const getCreator = () => {
        if (data.createdBy === currentUser.uid) {
            return currentUser.displayName
        } else {
            return users.filter((u) => u.id === data.createdBy)[0].name
        }
    }

    let date = null;
    try {
        date = data.dateCreated.toDate().toDateString()
    } catch (error) {
        date = data.dateCreated
    }

    const onUpdated = (newNote) => {
        setIsEditing(false)
        setActivities(prev => prev.map(p => (p.id === data.id ? { ...p, notes: newNote } : p)))
    }

    const onDelete = async () => {
        setIsLoading("Deleting...")
        await deleteActivity(did, data.id).catch(err => {
            setError(err)
            setIsLoading('')
        })
        setIsLoading('')
        setActivities(prev => prev.filter((p) => p.id !== data.id))
    }

    return (
        <div className={styles.record__item}>
            <Indicator type="NOTE" />
            <div className={styles.record__detail} style={style}>
                <div className={styles.note__cont}>
                    <div className={styles.act__header}>
                        <p>Note created on <strong>{date}</strong> by <strong>{getCreator()}</strong> </p>
                        {status === undefined &&
                            <div className={styles.act__actions}>
                                <span className="material-icons edit" onClick={() => setIsEditing(true)}>edit</span>
                                <span className="material-icons delete" onClick={onDelete} >delete</span>
                            </div>}
                    </div>
                    {isLoading && <div className={styles.act__loading} ><MiniSpinner wh="25" /><p>{isLoading}</p></div>}
                    <p className={styles.notes}>{data.notes}</p>
                    {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                </div>
            </div>

            {
                isEditing && <DialogEditNote
                    onUpdated={onUpdated}
                    data={data}
                    id={did}
                    onCancel={() => setIsEditing(false)}
                />
            }

        </div>
    )
}

export default React.memo(NoteComponent)
