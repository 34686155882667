import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import AuthProvider from './contexts/AuthContext';
import NavProvider from './contexts/NavContext'
import Home from './pages/Home/Home'
import Login from './pages/Login'
import Deals from './pages/CRM/Deals/Deals';
import DocumentInventory from './pages/DocumentInventory/DocumentInventory'
import Tracker from './pages/Tracker/Tracker'
import RFP from './pages/RFP/RFP'
import './global.css'
import NotFound from './pages/NotFound';
import Archive from './pages/CRM/Archive/Archive';
import Activities from './pages/CRM/Activities/Activities';
import Prospects from './pages/CRM/Prospects/Prospects';
import Report from './pages/CRM/Report/Report';
import Dashboard from './pages/CRM/Dashboard/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import Settings from './pages/AppSettings/Settings';
import Wall from './pages/CRM/Wall/Wall'
import UsersProvider from './contexts/UsersContext';
import ServiceOrder from './pages/CRM/ServiceOrder/ServiceOrder'
import Document from './pages/DocumentInventory/Document'
import ViewRfp from './pages/RFP/ViewRfp'
import LAS from './pages/LAS/LasMain'
import LasMyList from './pages/LAS/MyList'
import LasManagerList from './pages/LAS/ManagerList'
import HRDashboard from './pages/LAS/HRDashboard'

ReactDOM.render(
  <Router >
    <AuthProvider>
      <NavProvider>
        <UsersProvider>
          <Switch>
            <Route path="/login" component={Login} exact />
            <Route path="/crm/wall/:id/:status?" component={Wall} />
            <Route path="/service-order/:id" component={ServiceOrder} />
            <Route path="/rfp/:id/:view?" component={ViewRfp} />
            <PrivateRoute path={`/`} component={Home} exact />
            <PrivateRoute path={`/crm/deals`} component={Deals} exact />
            <PrivateRoute path={`/crm/activities/:calendar?`} component={Activities} />
            <PrivateRoute path={`/crm/prospects`} component={Prospects} exact />
            <PrivateRoute path={`/crm/report`} component={Report} exact />
            <PrivateRoute path={`/crm/dashboard`} component={Dashboard} exact />
            <PrivateRoute exact={true} path={`/document-inventory`} component={DocumentInventory} />
            <PrivateRoute path="/document-inventory/document/:path/:folder" component={Document} />
            <PrivateRoute path={`/tracker`} component={Tracker} />
            <PrivateRoute path={`/rfp`} component={RFP} exact />
            <PrivateRoute path={`/las`} component={LAS} exact />
            <PrivateRoute path={`/las/mylist`} component={LasMyList} exact />
            <PrivateRoute path={`/las/list/:dept`} component={LasManagerList} exact />
            <PrivateRoute path={`/las/list/hr/dashboard`} component={HRDashboard} exact />
            <PrivateRoute path={`/crm/archive`} component={Archive} exact />
            <PrivateRoute path={`/settings`} component={Settings} exact />
            <Route path='/404' component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </UsersProvider>
      </NavProvider>
    </AuthProvider>
  </Router >,
  document.getElementById('root')
);