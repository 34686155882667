import React, { useEffect, useRef } from 'react'
import styles from './las.module.css'
import { db } from '../../firebase'
import { Link } from 'react-router-dom'

function ManagerPanel({ uid, managers }) {
    const unreadHR = useRef()
    const unreadIT = useRef()
    const unreadMarketing = useRef()
    const unreadCorporate = useRef()
    const unreadOperation = useRef()
    const year = new Date().getFullYear()
    const ref = db.collection('leaveApplication').doc(`applications${year.toString()}`);

    // useEffect(() => {
    //     console.log('ManagerPanel.js rendered')
    // }, [managers])

    useEffect(() => {
        if (managers.hr !== uid) return

        const hrref = ref.collection('hr').where('cancelled', '==', false).where('read.manager', '==', false)
        const unsub = hrref.onSnapshot((qs) => {
            if (qs.size > 0) {
                unreadHR.current.textContent = qs.size;
                unreadHR.current.style.display = 'block'
            } else {
                unreadHR.current.style.display = 'none'
            }
        }, (error) => {
            console.error('Something went wrong while fetching hr count')
        });

        return unsub
    }, [managers, uid, ref])

    useEffect(() => {
        if (managers.it !== uid) return

        const hrref = ref.collection('it').where('cancelled', '==', false).where('read.manager', '==', false)
        const unsub = hrref.onSnapshot((qs) => {
            // console.log('it : ',qs.size)
            if (qs.size > 0) {
                unreadIT.current.textContent = qs.size;
                unreadIT.current.style.display = 'block'
            } else {
                unreadIT.current.style.display = 'none'
            }
        }, (error) => {
            console.error('Something went wrong while fetching it count')
        });

        return unsub;
    }, [managers, uid, ref])

    useEffect(() => {
        if (managers.marketing !== uid) return

        const hrref = ref.collection('marketing').where('cancelled', '==', false).where('read.manager', '==', false)
        const unsub = hrref.onSnapshot((qs) => {
            // console.log('marketing : ',qs.size)
            if (qs.size > 0) {
                unreadMarketing.current.textContent = qs.size;
                unreadMarketing.current.style.display = 'block'
            } else {
                unreadMarketing.current.style.display = 'none'
            }
        }, (error) => {
            console.error('Something went wrong while fetching marketing count')
        });

        return unsub;
    }, [managers, uid, ref])

    useEffect(() => {
        if (managers.corporate !== uid) return

        const hrref = ref.collection('corporate').where('cancelled', '==', false).where('read.manager', '==', false)
        const unsub = hrref.onSnapshot((qs) => {
            //console.log('corporate : ',qs.size)
            if (qs.size > 0) {
                unreadCorporate.current.textContent = qs.size;
                unreadCorporate.current.style.display = 'block'
            } else {
                unreadCorporate.current.style.display = 'none'
            }
        }, (error) => {
            console.error('Something went wrong while fetching corporate count')
        });

        return unsub;
    }, [managers, uid, ref])

    useEffect(() => {
        if (managers.operation !== uid) return

        const hrref = ref.collection('operation').where('cancelled', '==', false).where('read.manager', '==', false)
        const unsub = hrref.onSnapshot((qs) => {
            if (qs.size > 0) {
                unreadOperation.current.textContent = qs.size;
                unreadOperation.current.style.display = 'block'
            } else {
                unreadOperation.current.style.display = 'none'
            }
        }, (error) => {
            console.error('Something went wrong while fetching operation count')
        });

        return unsub;
    }, [managers, uid, ref])


    return (
        <div className={styles.managerPanel}>
            <div>
                <span className="material-icons">admin_panel_settings</span>
                <h4>Manager's Panel</h4>
            </div>

            <div className={styles.managerBtns}>

                {managers.hr === uid &&
                    <Link to={'las/list/hr/dashboard'}>
                        <button className="btn-green">
                            <p className="material-icons">dashboard</p>
                            <span>HR Dashboard</span>
                        </button>
                    </Link>}

                {managers.hr === uid &&
                    <Link to={'las/list/hr'}>
                        <button className="btn-blue btn-rel">
                            <span ref={unreadHR} className={styles.unreadCircle} >0</span>
                            <span className="material-icons">text_snippet</span>
                            <span>View HR Department's Request</span>
                        </button>
                    </Link>}

                {managers.it === uid &&
                    <Link to={'las/list/it'}>
                        <button className="btn-blue btn-rel">
                            <span ref={unreadIT} className={styles.unreadCircle} >0</span>
                            <span className="material-icons">text_snippet</span>
                            <span>View IT Department's Request</span>
                        </button>
                    </Link>}

                {managers.marketing === uid &&
                    <Link to={'las/list/marketing'}>
                        <button className="btn-blue btn-rel">
                            <span ref={unreadMarketing} className={styles.unreadCircle} >0</span>
                            <span className="material-icons">text_snippet</span>
                            <span>View Marketing Department's Request</span>
                        </button>
                    </Link>}

                {managers.corporate === uid &&
                    <Link to={'las/list/corporate'}>
                        <button className="btn-blue btn-rel">
                            <span ref={unreadCorporate} className={styles.unreadCircle} >0</span>
                            <span className="material-icons">text_snippet</span>
                            <span>View Corporate Department's Request</span>
                        </button>
                    </Link>}

                {managers.operation === uid &&
                    <Link to={'las/list/operation'}>
                        <button className="btn-blue btn-rel">
                            <span ref={unreadOperation} className={styles.unreadCircle} >0</span>
                            <span className="material-icons">text_snippet</span>
                            <span>View Operation Department's Request</span>
                        </button>
                    </Link>}
            </div>
        </div>
    )
}

export default React.memo(ManagerPanel)