import React, { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import styles from './so.module.css'
import { getCompanyInfo } from '../../../controllers/appSettingsController'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import { getSO, updateStatus } from '../../../controllers/serviceOrderController'
import { useReactToPrint } from 'react-to-print'

const Loading = () => {
    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20%',
        gap: '10px',
        height: '100%'
    }
    return (
        <div style={style}>
            <MiniSpinner wh='60' />
            <h1>Loading your Service Order, please wait...</h1>
            <div
                style={{
                    maxWidth: '300px',
                    maxHeight: '300px',
                    marginTop: '10%'
                }}>
                <img
                    style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '100%'
                    }}
                    src="/img/app_logo_full.png"
                    alt="app logo" />
            </div>
        </div>
    )
}

export default function ServiceOrder() {
    const { id } = useParams()
    const { currentUser } = useAuth()
    const isMounted = useRef(false)
    const [companyInfo, setCompanyInfo] = useState(null)
    const [soData, setSoData] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const printComp = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComp.current,
    });
    const [showAction,setShowAction] = useState(true)
    const [status,setStatus] = useState('')

    useEffect(() => {
        isMounted.current = true
        document.querySelector("title").textContent = "Service Order"

        if(currentUser && isMounted.current) setShowAction(false)

        const fetch = async () => {
            const compInfo = await getCompanyInfo().catch(err => {
                if (isMounted.current) setError(err)
                console.error('Something went wrong while fetching company\'s info')
                return
            })

            if (compInfo) {
                if (isMounted.current) {
                    setCompanyInfo(compInfo.data())
                }
            }

            const soRes = await getSO(id).catch(err => {
                if (isMounted.current) setError(err)
                console.error('Something went wrong while fetching SO')
                return
            })

            if (soRes && soRes.exists) {
                if (isMounted.current) {
                    const st = soRes.get('status')
                    if(st === 'accepted' || st === 'rejected'){
                        setShowAction(false)
                    }
                    setStatus(st)
                    setSoData(soRes.data())
                }
                setIsLoading(false)
            }
        }

        fetch()
        return () => isMounted.current = false
    }, [id,currentUser])


    const pageStyle = `
    @media print {
        @page {
            size: potrait;
            margin: 10mm !important;
        }
      }`;

    const onAccept = async (e) => {
        e.target.disabled = true
        await updateStatus(id,'accepted').catch(err => {
            setError(err)
            return
        })
        e.target.disabled = false
        setStatus('accepted')
        setShowAction(false)
    }

    const onReject = async (e) => {
        e.target.disabled = true
        await updateStatus(id,'rejected').catch(err => {
            setError(err)
            return
        })
        e.target.disabled = false
        setStatus('rejected')
        setShowAction(false)
    }

    return (
        <>{
            isLoading ?
                <Loading /> :
                <>
                    <div ref={printComp} className={styles.pageLayout}>
                    <style>{pageStyle}</style>
                        {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                        <section style={{ marginBottom: '20px' }}>
                            <p style={{ textAlign: 'center' }}>{soData.ticket}</p>
                        </section>

                        <section className={styles.header}>
                            <div className={styles.imgContainer}>
                                <img src="/img/comp_logo_full.png" alt="company logo" />
                            </div>

                            <div className={styles.headerMid}>
                                <span className={styles.compName}>{companyInfo?.name} </span><span className={styles.regNum}>{companyInfo?.regNumber}</span>
                                <p>{companyInfo?.note}</p>
                                <p className={styles.address}>{companyInfo?.address}</p>
                                <br />
                                <p>{companyInfo?.officeNumber}</p>
                                <p>{companyInfo?.email}</p>
                            </div>

                            <div className={styles.headerEnd}>
                                <h1 style={{ fontSize: '2.5rem' }}>SERVICE ORDER</h1>
                                <p>SONum : {soData.ticket}</p>
                                <br />
                                <p style={{ textTransform: 'capitalize' }}>Issued by : {soData.creator}</p>
                                <p>Date : {soData.dateCreated.toDate().toDateString()}</p>
                            </div>

                        </section>
                        <div style={{ margin: '20px 0' }} className="h-seperator" />
                        <section>

                            <div className={styles.contentHead}>
                                <div>
                                    <p style={{ fontWeight: 'bold' }}>Customer:</p>
                                    <p className={styles.custName} >{soData.name}</p>
                                    <p className={styles.custAddress}>{soData.address}</p>
                                    <p style={{ padding: '10px 0' }}>{soData.phoneNumber}</p>
                                </div>
                                <div className={styles.status}>
                                    <p>Status</p>
                                    <p className={styles.soStatus}>{status}</p>
                                </div>
                            </div>

                            <div className={styles.contentBody}>
                                <p className={styles.title} >Title : {soData.title}</p>
                                <p style={{ fontWeight: 'bold' }}>Service(s):</p>

                                <p className={styles.services}>{soData.services}</p>

                                <p className={styles.content}>{soData.details}</p>
                            </div>

                        </section>

                        <section className={styles.sectionFooter}>
                            <p>{soData.footer}</p>
                        </section>

                    </div>
                    <div className={styles.actions}>
                        <button style={{marginBottom:'20px'}} onClick={handlePrint} className="btn-blue">Print</button>
                        {showAction &&
                        <div>
                            <button style={{marginBottom:'20px'}}  onClick={onAccept} className="btn-green">Accept</button>
                            <button style={{marginBottom:'20px'}}  onClick={onReject} className="btn-red">Reject</button>
                        </div>}
                    </div>
                </>
        }
        </>
    )
}
