import React from 'react'
import MiniSpinner from '../../../components/Loading/MiniSpinner'

export default function Loading() {

    const styles = {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContents:'center',
        gap:'10px',
        margin:'auto 0'
    }

    return (
        <div style={styles}>
            <MiniSpinner />
            <p>Loading chart, this may take a while...</p> 
        </div>
    )
}
