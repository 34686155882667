import React, { useRef, useState } from 'react'
import styles from './wall.module.css'
import { useAuth } from '../../../contexts/AuthContext'
import Loading from '../../../components/Loading/Loading'
import { Link } from 'react-router-dom'
import MiniSpinner from '../../../components/Loading/MiniSpinner'

export default function Header({ data }) {

    const {title,ticket,organisation} = data

    const { logout } = useAuth()
    const profile = useRef()
    const [isLoading, setLoading] = useState(false)

    const handleMouseLeave = (e) => {
        e.current.classList.remove("open");
    }

    const handleHover = (e) => {
        if (!e.current.classList.contains("open"))
            e.current.classList.add("open");
    }

    const handleLogout = async () => {
        setLoading(true)
        await logout().catch(err => {
            setLoading(false)
            console.error(err)
        })
    }

    return (
        <>
            <div className={styles.header}>

                {
                    title === null && <MiniSpinner wh="25" />
                }

                <div>
                    <h1>{title}</h1>
                    <h4>{organisation}</h4>
                </div>

                <h3
                    style={{ marginLeft: "auto", marginRight: "20px" }}
                >{ticket}</h3>
                <div
                    className="dd-profile"
                    ref={profile}
                    onMouseOver={() => handleHover(profile)}
                    onMouseLeave={() => handleMouseLeave(profile)}>
                    <span className="material-icons-outlined">person</span>
                    <div className="ddi-profile">
                        <ul>
                            <Link to="/settings">
                                <li> <span className="material-icons">settings</span> Settings</li>
                            </Link>
                            <li onClick={handleLogout} > <span className="material-icons">logout</span> Log Out</li>
                        </ul>
                    </div>
                </div>
            </div>
            {isLoading && <Loading open={isLoading} />}
        </>
    )
}
