import React, { useEffect, useRef, useState } from "react";
import { getVersion } from '../../controllers/appSettingsController'
import Header from '../../components/Header/Header'
import SearchWidget from "./SearchWidget";
import StaffListWidget from "./StaffListWidget";
import ShortcutsWidget from "./ShortcutsWidget";
import styles from './home.module.css'
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { storage } from '../../firebase'
import { useUsers } from "../../contexts/UsersContext";

export default function Home() {
  const isMounted = useRef()
  const { currentUser } = useAuth()
  const { users } = useUsers()
  const history = useHistory()
  const [logo, setLogo] = useState('./img/img_placeholder.png')

  //boolean for operation UAC
  const [isOperation, setIsOperation] = useState(false)

  useEffect(() => {
    document.querySelector("title").textContent = "Home - Business Management System"

    if (!currentUser || users.length === 0) return

    isMounted.current = true
    if (localStorage.getItem("INIT_DATE") === null || localStorage.getItem("INIT_DATE") === undefined) {
      const fetchVersion = async () => {
        const res = await getVersion().catch(err => console.error(err))
        localStorage.setItem("INIT_DATE", res.get("initDate").toDate());
      }
      fetchVersion();
    }
    const fetchLogo = async () => {
      const storageRef = storage.ref().child('company/logos/comp_logo_200x200.png')
      storageRef.getDownloadURL()
        .then((url) => {
          sessionStorage.setItem('LOGO_COMP', url)
          if (isMounted.current) {
            setLogo(url)
          }
        })
        .catch((error) => {
          console.error('Error retrieving image.')
        });
    }

    if (sessionStorage.getItem('LOGO_COMP') === null) {
      fetchLogo()
    } else {
      if (isMounted.current) {

        const user = users.filter(u => currentUser.uid === u.id)[0]

        if (user.role.includes("Operation")) {
          setIsOperation(true)
        }

        setLogo(sessionStorage.getItem('LOGO_COMP'))
      }
    }

    return () => isMounted.current = false
  }, [currentUser, users])

  return (
    <>
      <Header title="Home" />
      <div style={{ scrollBehavior: 'smooth', margin: 0, padding: '20px' }} className="tab-contents">
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div className={styles.section}>
            <span className="material-icons">whatshot</span>
            <h1>Welcome Back, <strong >{currentUser.displayName}</strong> !</h1>
          </div>
          <SearchWidget isOperation={isOperation} />
        </div>

        <div className={styles.section__second}>
          <div className={styles.img__comp}>
            <img style={{ objectFit: 'contain' }} src={logo} alt="Company Logo" />
          </div>
          <div style={{ margin: '0 20px' }}>
            <h1 style={{ padding: '10px 0' }}>Business Management System</h1>
            <h2 style={{ fontWeight: '300' }} >Your business experience</h2>
            <h3 style={{ fontWeight: '300' }}>CRM, Calendar, RFP and etc.</h3>
          </div>

          <div className={styles.div__avatar} style={{ marginLeft: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <button disabled className="btn-orange">Send us feedback / suggestion</button>
              <span onClick={() => history.push('/settings')} className="material-icons">settings</span>
            </div>
            <div className={styles.img__avatar}>
              <img style={{ objectFit: 'cover' }} src={currentUser.photoURL ?? './img/user_placeholder.png'} alt="avatar" />
            </div>
          </div>

        </div>

        <div className={styles.section}>
          <span className="material-icons">shortcut</span>
          <h2> Shortcuts</h2>
        </div>

        <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-start', marginTop: '20px' }}>
          <ShortcutsWidget isOperation={isOperation} />
          <StaffListWidget />
        </div>
      </div>
    </>
  )
}
