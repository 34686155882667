import React, { useEffect, useRef, useState } from 'react'
import { MONTHS } from '../../configuration'
import CHART_1 from './LocationServicesCharts/CHART_1'
import CHART_2 from './LocationServicesCharts/CHART_2'
import CHART_3 from './LocationServicesCharts/CHART_3'
import CHART_4 from './LocationServicesCharts/CHART_4'
import { useUsers } from '../../../contexts/UsersContext'

export const LocationServicesChartsCtx = React.createContext()

export default function Summary() {
    const d = new Date()
    const year = useRef()
    const month = useRef()

    const firstDay = new Date(d.getFullYear(), d.getMonth(), 1)
    const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0)

    const [date, setDate] = useState({
        firstDay,
        lastDay
    })

    const { users } = useUsers()
    const [years, setYears] = useState([])
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = "Location & Services - Dashboard"
        let initYear = new Date(localStorage.getItem('INIT_DATE')).getFullYear()
        const ys = []
        const d = new Date()
        while (initYear <= d.getFullYear()) {
            ys.push(initYear)
            initYear++
        }
        setYears(ys)

        return () => isMounted.current = false
    }, [])

    const stylesFlex = { display: 'flex', height: '250px', alignItems: 'center', gap: '10px', width: '100%', marginBottom: '3rem' }
    // const stylesFlexBigSquare = { ...stylesFlex, height: '550px' }
    const singleChart = { ...stylesFlex, height: '700px', justifyContent: 'center' }

    const onChangeDate = () => {
        const m = MONTHS.indexOf(month.current.value)
        const y = parseInt(year.current.value)
        const firstDay = new Date(y, m, 1)
        const lastDay = new Date(y, m + 1, 0)
        setDate({ firstDay, lastDay })
    }

    return (
        <>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <select ref={month} defaultValue={MONTHS[d.getMonth()]}>
                    {MONTHS.map(m => <option key={m} value={m}>{m}</option>)}
                </select>
                <select ref={year} defaultValue={d.getFullYear()}>
                    {years.map(y => <option key={y} value={y}>{y}</option>)}
                </select>
                <button onClick={onChangeDate} className="btn-purple">Apply</button>
            </div>
            <LocationServicesChartsCtx.Provider value={{ date, users, isMounted }}>
                <div style={singleChart}>
                    <CHART_1 />
                </div>
                <div style={singleChart}>
                    <CHART_2 />
                </div>
                <div style={singleChart}>
                    <CHART_3 />
                </div>
                <div style={{...singleChart,height:'750px'}}>
                    <CHART_4 />
                </div>
            </LocationServicesChartsCtx.Provider>
        </>
    )
}
