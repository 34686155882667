import React, { useEffect, useRef, useState } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import styles from './las.module.css'
import { useAuth } from '../../contexts/AuthContext'
import { decrementStaffStats, updateManagerRead, updateSelfRead, updateStatus } from '../../controllers/lasController'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import { getLeaveDuration } from './Bloc/lasbloc'

export default function ViewDetailsDialogManager({ data, onClose, updater, setIsRead }) {
    const isMounted = useRef()
    const { currentUser } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState(data.status)
    const [openRemarks, setOpenRemarks] = useState(false)
    const remarks = useRef()
    const [rm,setRm] = useState(data.remarks ?? 'N/A')
      const duration = getLeaveDuration(data.leaveDate.toDate(),data.leaveDateEnd ? data.leaveDateEnd.toDate() : data.leaveDate.toDate())

    const statusStyles = {
        backgroundColor: status === 'approved' ? 'var(--green)' :
            status === 'rejected' ? 'red' :
                status === 'cancelled' ? 'orange' :
                    'var(--sky-blue)',
        padding: '10px 5px',
        width: 'min-content',
        borderRadius: '10px',
        color: 'white',
        letterSpacing: '1px',
        textTransform: 'uppercase'
    }

    const onAction = async (action) => {
        setIsLoading(true)
        let remarksText = ''

        if (action === 'rejected') {
            remarksText = remarks.current.value
        }

        //Update self read
        await updateSelfRead(data.reqPath, false).catch(err => console.error(err))

        //Update status both path
        //each path only matter in its designated list only
        await updateStatus(data.deptPath, action, remarksText).catch(err => console.error(err))
        await updateStatus(data.reqPath, action, remarksText).catch(err => console.error(err))

        //TODO: Increase leave balance on reject
        if (action === 'rejected') {
            await decrementStaffStats(data.requestorID, new Date().getFullYear().toString(), data.leaveType === 'medical',duration).catch(err => console.error(err))
            setRm(remarksText)
        }

        setIsLoading(false)
        setStatus(action)//local
        updater(data.id, action,remarksText)
    }

    useEffect(() => {
        isMounted.current = true
        if (!currentUser) return

        if (!data.read.manager) {
            const updateRead = async () => {
                await updateManagerRead(data.deptPath, true).catch(err => {
                    console.error(err)
                })
            }
            updateRead()
            setIsRead(data.id)
        }

        return () => isMounted.current = false
    }, [currentUser, data, setIsRead])

    return (
        <DialogTemplate title='Details' toggle={true} width={600} height='max-content'>
            <div>

                <div className={styles.details}>
                    <div>
                        <p>Status :</p>
                        <p style={statusStyles}>{status}</p>
                    </div>

                    {
                        status === 'rejected' &&
                        <div>
                            <p>Remarks :</p>
                            <p>{rm}</p>
                        </div>
                    }

                    <div>
                        <p>Requestor :</p>
                        <p>{data.requestor}</p>
                    </div>

                    <div>
                        <p>Request Date :</p>
                        <p>{data.requestDate.toDate().toDateString()}</p>
                    </div>

                    <div>
                        <p>Leave Date :</p>
                        <p>{data.leaveDate.toDate().toDateString() + " - " + (data.leaveDateEnd ? 
                            data.leaveDateEnd.toDate().toDateString() : data.leaveDate.toDate().toDateString())}</p>
                    </div>

                    <div>
                        <p>Duration :</p>
                        <p>{duration} day(s)</p>
                    </div>


                    <div>
                        <p>Leave Type :</p>
                        <p>{data.leaveType}</p>
                    </div>

                    <div>
                        <p>Department :</p>
                        <p>{data.dept}</p>
                    </div>

                    <div>
                        <p>Officer :</p>
                        <p>{data.officer}</p>
                    </div>

                    <div>
                        <p>Attachment : </p>
                        {
                            data.img ?
                                <img width={'100%'} style={{ objectFit: 'contain', margin: '10px 0px' }} src={data.img} alt="mc" /> :
                                <p>Attachment unavailable.</p>
                        }
                    </div>

                    <div >
                        <p>Actions :</p>
                        <p className={styles.tips}>Warning, these actions cannot be undone.</p>
                        <div className={styles.actions}>
                            {isLoading && <MiniSpinner wh={30} />}
                            <button onClick={() => onAction('approved')} disabled={status !== 'new' || isLoading} className="btn-green">Approve</button>
                            <button onClick={() => setOpenRemarks(!openRemarks)} disabled={status !== 'new' || isLoading} className="btn-red">Reject</button>
                        </div>

                        {openRemarks && <div>
                            <textarea ref={remarks} placeholder='Remarks for rejecting the request...' style={{ width: '100%', margin: '10px 0px' }} cols="30" rows="10"></textarea>
                            <button disabled={status !== 'new' || isLoading} onClick={() => onAction('rejected')} style={{ margin: '0px auto' }} className="btn-orange">Confirm</button>
                        </div>}
                    </div>
                </div>

                <div>
                    <button style={{ marginLeft: 'auto' }} onClick={onClose} className="btn-white">Close</button>
                </div>
            </div>
        </DialogTemplate>
    )
}
