import React from 'react'
import reactDom from 'react-dom'

export default function DialogTemplate( {children , width = null ,toggle ,dialogStyles = null, title="Dialog" ,height = "50vh"}) {

    if(dialogStyles === null ){
        dialogStyles = {
            width:"30%",
            padding:"0",
            borderRadius:"5px",
            overflow:"hidden"
        }
    }

    if(width){
        dialogStyles.width = width
    }

    const defStyles = {
        padding:"2rem",
    }

    const headerStyles = {
        backgroundColor:"var(--primary-purple)",
        color:"white",
        fontWeight:"var(--medium)",
        fontSize:"1.2rem",
        padding:"1.2rem 2rem",
        display:'flex',
        justifyContent:'space-between',
        userSelect:'none'
    }

    // const onClose = ()  => {
    //     dialogSetState(false)
    // }

    if(!toggle ) return null
    return reactDom.createPortal (
        <>
            <div className="dialog-overlay"></div>
            <div className="wpd"  style={dialogStyles}>
                <div style={{...defStyles,...headerStyles}}>
                    <p>{title}</p>
                    {/* <span onClick={onClose} style={{cursor:'pointer'}} className="material-icons">close</span> */}
                </div>
                <div style={{...defStyles,overflow:"auto",height:`${height}`,maxHeight:"85vh",scrollBehavior: "smooth"}}>
                {children}
                </div>
            </div>
        </>,
        document.getElementById("portal")
    )
}
