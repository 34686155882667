import React, { useState, useEffect, useRef } from 'react'
import DetailContainer from '../DetailContainer'
import styles from '../wall.module.css'
import PaymentConfDialog from './PaymentConfDialog'
import { updatePayment, setPayment } from '../../../../controllers/paymentController'
import { timestamp, convertTimestamp } from '../../../../firebase'
import { useParams } from 'react-router-dom'

export default function RecurringPayment({ value, paymentData, paymentMethod, setPaymentData }) {

    const { id ,status } = useParams()
    const [isOpen, setIsOpen] = useState(false)
    const month = useRef('')
    const [checkedFirst, setCheckedFirst] = useState(paymentData?.first?.amount !== 0 && paymentData?.first?.amount !== undefined)
    const [checkedSecond, setCheckedSecond] = useState(paymentData?.second?.amount !== 0 && paymentData?.second?.amount !== undefined)
    const [checkedThird, setCheckedThird] = useState(paymentData?.third?.amount !== 0 && paymentData?.third?.amount !== undefined)
    const [checkedForth, setCheckedForth] = useState(paymentData?.forth?.amount !== 0 && paymentData?.forth?.amount !== undefined)
    const [checkedFifth, setCheckedFifth] = useState(paymentData?.fifth?.amount !== 0 && paymentData?.fifth?.amount !== undefined)
    const [checkedSixth, setCheckedSixth] = useState(paymentData?.sixth?.amount !== 0 && paymentData?.sixth?.amount !== undefined)


    const onChecked = async (e, setChecked, which) => {
        setChecked(e.target.checked)

        if (e.target.checked) {
            month.current = which
            setIsOpen(true)
        } else {
            const data = {
                method: paymentMethod,
                [which]: {
                    amount: 0,
                    date: null
                }
            }
            await updatePayment(id, data).catch(err => console.error(err))
            setPaymentData(prev => ({ ...prev, ...data }))
        }
    }


    const onSubmitCallback = async (formData) => {
        const data = {
            method: paymentMethod,
            [month.current]: {
                amount: formData.amount,
                date: formData.date === null ? timestamp : convertTimestamp(formData.date)
            }
        }

        await updatePayment(id, data).catch(({ code, message }) => {
            if (code === 'not-found') {
                return setPayment(id, data)
            } else {
                return {
                    error: {
                        code,
                        message
                    },
                    status: 'not-ok'
                }
            }
        })

        if (formData.date === null) data[month.current].date = convertTimestamp(new Date())//avoid rendering date with undefined value
        setPaymentData(prev => ({ ...prev, ...data }))
        setIsOpen(false)
        return {
            status: "ok"
        }
    }

    const onCancelDialog = () => {
        switch (month.current) {
            case 'first':
                setCheckedFirst(false)
                break;
            case 'second':
                setCheckedSecond(false)
                break;
            case 'third':
                setCheckedThird(false)
                break;
            case 'forth':
                setCheckedForth(false)
                break;
            case 'fifth':
                setCheckedFifth(false)
                break;
            case 'sixth':
                setCheckedSixth(false)
                break;
            default:
                console.error("error occured")
                break;
        }
        setIsOpen(false)
    }

    const calcBalance = () => {
        const fir = paymentData?.first?.amount || 0
        const sec = paymentData?.second?.amount || 0
        const thi = paymentData?.third?.amount || 0
        const fort = paymentData?.forth?.amount || 0
        const fif = paymentData?.fifth?.amount || 0
        const six = paymentData?.sixth?.amount || 0
        return parseFloat(value) - parseFloat(fir) - parseFloat(sec) - parseFloat(thi) - parseFloat(fort) - parseFloat(fif) - parseFloat(six)
    }

    useEffect(() => {
        setCheckedFirst(paymentData?.first?.amount !== 0 && paymentData?.first?.amount !== undefined)
        setCheckedSecond(paymentData?.second?.amount !== 0 && paymentData?.second?.amount !== undefined)
        setCheckedThird(paymentData?.third?.amount !== 0 && paymentData?.third?.amount !== undefined)
        setCheckedForth(paymentData?.forth?.amount !== 0 && paymentData?.forth?.amount !== undefined)
        setCheckedFifth(paymentData?.fifth?.amount !== 0 && paymentData?.fifth?.amount !== undefined)
        setCheckedSixth(paymentData?.sixth?.amount !== 0 && paymentData?.sixth?.amount !== undefined)
    }, [paymentData])

    return (
        <>
            <DetailContainer title="Payment" editable={false}>
                <div className={styles.row}><p >Payment method can be change inside deal information section.</p></div>
                <div className={styles.row}><h4>{paymentMethod}</h4></div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={status !== undefined} checked={checkedFirst} onChange={(e) => onChecked(e, setCheckedFirst, "first")} type="checkbox" name="firstMth" id="firstMth" />
                        <label htmlFor="firstMth">1st month</label>
                    </div>
                    <p>RM {paymentData?.first?.amount || '0'}</p>
                    <p>{paymentData?.first?.date?.toDate().toDateString()}</p>
                </div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={status !== undefined} checked={checkedSecond} onChange={(e) => onChecked(e, setCheckedSecond, "second")} type="checkbox" name="secMth" id="secMth" />
                        <label htmlFor="secMth">2nd month</label>
                    </div>
                    <p>RM {paymentData?.second?.amount || '0'}</p>
                    <p>{paymentData?.second?.date?.toDate().toDateString()}</p>
                </div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={status !== undefined} checked={checkedThird} onChange={(e) => onChecked(e, setCheckedThird, "third")} type="checkbox" name="thirdMth" id="thirdMth" />
                        <label htmlFor="thirdMth">3rd month</label>
                    </div>
                    <p>RM {paymentData?.third?.amount || '0'}</p>
                    <p>{paymentData?.third?.date?.toDate().toDateString()}</p>
                </div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={status !== undefined} checked={checkedForth} onChange={(e) => onChecked(e, setCheckedForth, "forth")} type="checkbox" name="forthMth" id="forthMth" />
                        <label htmlFor="forthMth">4th month</label>
                    </div>
                    <p>RM {paymentData?.forth?.amount || '0'}</p>
                    <p>{paymentData?.forth?.date?.toDate().toDateString()}</p>
                </div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={status !== undefined} checked={checkedFifth} onChange={(e) => onChecked(e, setCheckedFifth, "fifth")} type="checkbox" name="fifthMth" id="fifthMth" />
                        <label htmlFor="fifthMth">5th month</label>
                    </div>
                    <p>RM {paymentData?.fifth?.amount || '0'}</p>
                    <p>{paymentData?.fifth?.date?.toDate().toDateString()}</p>
                </div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={status !== undefined} checked={checkedSixth} onChange={(e) => onChecked(e, setCheckedSixth, "sixth")} type="checkbox" name="sixthMth" id="sixthMth" />
                        <label htmlFor="sixthMth">6th month</label>
                    </div>
                    <p>RM {paymentData?.sixth?.amount || '0'}</p>
                    <p>{paymentData?.sixth?.date?.toDate().toDateString()}</p>
                </div>
                <p>Remaining value : RM {calcBalance()}</p>
            </DetailContainer>


            {
                isOpen &&
                <PaymentConfDialog
                    amountLimit={calcBalance()}
                    which={1}
                    callback={onSubmitCallback}
                    onCancel={onCancelDialog}
                />
            }
        </>
    )
}
