import React, { useEffect, useState, useRef } from 'react'
import { getOrigin, openInNewTab } from '../helper'
import { useUsers } from '../../contexts/UsersContext'
import { useAuth } from '../../contexts/AuthContext'
import styles from './rfp.module.css'
import { getRfpStatusList, updateRfp } from '../../controllers/rfpController'
import MiniSpinner from '../../components/Loading/MiniSpinner'

const Table = ({ list }) => {
    const { users } = useUsers()

    const getOwner = (id) => {
        return users.filter(u => u.id === id)[0].name
    }

    const onView = (id, e, reads) => {
        const row = e.currentTarget
        row.className = styles.read
        openInNewTab(`${getOrigin()}/rfp/${id}/1`)
        if (!reads) {
            updateRfp(id, { [`reads.self`]: true })
        }
    }

    return (
        <table className="table" cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    <th>Requester</th>
                    <th>Subject</th>
                    <th>Submission Date</th>
                    <th>Status</th>
                    <th>Applicant</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.map(l => (
                        <tr onClick={(e) => onView(l.id, e, l.reads.self)} className={l.reads.self ? styles.read : undefined} key={l.id}>
                            <td>{l.requester}</td>
                            <td>{l.subject}</td>
                            <td>{l.dateCreated.toDate().toDateString()}</td>
                            <td>{l.status}</td>
                            <td>{getOwner(l.createdBy)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

const EmptyTable = () => {
    return (
        <p style={{textAlign:'center'}}>You haven't make any request yet. Switch to <strong>Request</strong> tab to make one.</p>
    )
}

const LoadingComp = () => {
    return (
        <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
            <MiniSpinner />
            <p>Fetching list...</p>
        </div>
    )
}

export default function Status() {
    const isMounted = useRef(false)
    const [list, setList] = useState([])
    const { currentUser } = useAuth()
    const [error,setError] = useState(null)
    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {
        isMounted.current = true

        const init = async () => {
            setIsLoading(true)
            const result = await getRfpStatusList(currentUser.uid).catch(err => {
                if(isMounted.current){
                setIsLoading(false)
                setError(err)}
                return console.error(err)
            })

            if(isMounted.current && result){
                setIsLoading(false)
                setList(result.docs.map(doc => ({ ...doc.data(), id: doc.id})))
            }
        }
        init()
        return () => isMounted.current = false
    }, [currentUser.uid])

    return (
        <div>
            {error && <div style={{width:'60%',margin:"0 auto"}} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {
                isLoading &&
                <LoadingComp/>
            }
            {
                !isLoading && list.length > 0 ?
                    <Table list={list} /> :
                    error === null ? <EmptyTable /> :
                    null
            }
        </div>
    )
}
