import { db, timestamp } from "../../../firebase";

export const dealRef = db.collection("Deals").orderBy("dateCreated","desc")

export const createNewDeal = (deal) => {
    return db.collection("Deals").add({
        ...deal,
        dateCreated: timestamp,
        dateUpdated: timestamp,
        soid:null
    });
}

export const fetchDealPreferences = () => {
    return db.collection("AppPreferences").doc("DealDialog").get()
}

export const updateTicketCount = () => {
    const ref = db.collection("AppPreferences").doc("DealDialog")
    return db.runTransaction((transaction) => {
        return transaction.get(ref).then((doc) => {
            if (!doc.exists) {
                throw new Error("Document does not exist!");
            }
            var newCount = doc.data().ticketCount + 1;
            transaction.update(ref, { ticketCount: newCount });
            return newCount;
        });
    })
}

export const updatePropect = (did, services, title, pid) => {
    return db.collection("Prospects").doc(pid).collection("DealHistory").doc(did).set({
        dateCreated: timestamp,
        services,
        title,
        status: "Ongoing"
    })
}

export const createNewProspect = (name, phoneNumber, email, organisation) => {
    return db.collection("Prospects").add({
        name,
        phoneNumber,
        email,
        organisation,
        dateCreated: timestamp,
        dateUpdated: timestamp,
        address: null,
        address2: null,
        city: null,
        dateOfBirth: null,
        poscode: null,
        state: null
    })
}

export const checkExistingProspect = async (phoneNumber) => {
    const querySnapshot = await db.collection("Prospects")
        .where("phoneNumber", "==", phoneNumber)
        .limit(1)
        .get()
        .catch(err => {
            throw new Error(err.message);
        })

    return querySnapshot.size > 0 ? querySnapshot.docs[0].id : null
}