import React from 'react'
import { useState, useEffect, useRef } from 'react'
import styles from './las.module.css'
import Header from '../../components/Header/Header'
import { useAuth } from '../../contexts/AuthContext'
import { getMyList } from '../../controllers/lasController'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import ViewAttachmentDialog from './ViewAttachmentDialog'
import ViewDetailsDialog from './ViewDetailsDialog'

const Row = ({ data, num, onViewDetails, onView }) => {

    return (
        <tr className={!data.read.self ? undefined : styles.unreadStyles}>
            <td>{num}</td>
            <td>{data.requestDate.toDate().toDateString()}</td>
            <td>{data.leaveDate.toDate().toDateString()}</td>
            <td>{data.leaveType}</td>
            <td>{data.status}</td>
            <td>{data.img ? <button onClick={() => onView(data.img)} className="btn-blue">View</button> : " N/A"}</td>
            <td><button onClick={() => onViewDetails(data)} className="btn-white">Details</button></td>
        </tr>
    )
}

export default function MyList() {
    const [error, setError] = useState()
    const { currentUser } = useAuth()
    const [year, setYear] = useState(new Date().getFullYear().toString())
    const yearRef = useRef()
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const [openAttachment, setOpenAttachment] = useState('')
    const [openDetails, setOpenDetails] = useState(null)

    const onViewAttc = (imgLink) => {
        setOpenAttachment(imgLink)
    }

    const onViewDetails = (data) => {
        setOpenDetails(data)
    }

    const setIsCancelled = (id) => {
        setList(prev => {
            const idx = prev.findIndex(d => d.id === id)
            const modified = prev[idx]
            modified.cancelled = true
            modified.status = 'cancelled'
            prev.splice(idx, 1, modified)
            const newArr = Array.from(prev)
            return newArr
        })
    }

    const setIsRead = (id) => {
        setList(prev => {
            const idx = prev.findIndex(d => d.id === id)
            const modified = prev[idx]
            modified.read.self = true
            prev.splice(idx, 1, modified)
            const newArr = Array.from(prev)
            return newArr
        })
    }

    useEffect(() => {
        document.querySelector('title').innerText = 'My Leave Requests - Leave Application System'
        if (!currentUser) return

        let initYear = new Date(Date.parse(localStorage.getItem("INIT_DATE"))).getFullYear()
        const currYear = new Date().getFullYear()
        yearRef.current.innerHTML = ''
        while (initYear <= currYear) {
            const opt = document.createElement('option')
            const y = initYear++
            opt.value = y
            opt.textContent = y
            yearRef.current.prepend(opt)
        }

        yearRef.current.value = currYear

        const fetch = async () => {
            setIsLoading(true)
            setLoadingText('Acquiring data...')
            const res = await getMyList(year, currentUser.uid).catch(err => {
                setError(err)
                setIsLoading(false)
                setLoadingText('')
                return
            })

            if (res) {
                setList(res.docs.map(d => ({ ...d.data(), id: d.id })))
            }
            setIsLoading(false)
            setLoadingText('')
        }
        fetch()

    }, [currentUser, year])

    return (
        <>
            {
                openAttachment &&
                <ViewAttachmentDialog
                    link={openAttachment}
                    onClose={() => setOpenAttachment('')}
                />
            }

            {
                openDetails &&
                <ViewDetailsDialog
                    setIsRead={setIsRead}
                    setIsCancelled={setIsCancelled}
                    data={openDetails}
                    onClose={() => setOpenDetails(null)}
                />
            }

            <Header title="My Leave Request" />
            <div className="tab-contents">
                {error && <div className={`error-bar ${styles.lasmain_err}`}>
                    <span className="material-icons">error</span>
                    <p>{error.message} --code: {error.code}</p>
                </div>}

                <div>
                    <div className='fx fx-jc-sb'>
                        <div>
                            <p>{`You have made ${list.length} request(s) this year.`}</p>
                        </div>
                        <div>
                            <span>Select list year : </span>
                            <select ref={yearRef} value={year} name="slYear" id="slYear" onChange={e => setYear(e.target.value)} />
                        </div>
                    </div>

                    {isLoading &&
                        <div className='flex__row' style={{ gap: '10px' }}>
                            <MiniSpinner wh={30} />
                            <span>{loadingText}</span>
                        </div>}

                    <div>
                        <table cellPadding={0} cellSpacing={0} className={`table ${styles.mylistTable}`}>
                            <thead>
                                <tr>
                                    <th>Num</th>
                                    <th>Request Date</th>
                                    <th>Leave Date</th>
                                    <th>Leave Type</th>
                                    <th>Status</th>
                                    <th>Attachment</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((item, idx) => <Row key={item.id} data={item} num={idx + 1} onViewDetails={onViewDetails} onView={onViewAttc} />)}
                            </tbody>
                        </table>
                        {list.length === 0 && <div className={styles.mtTableDiv} >List is empty.</div>}
                    </div>
                </div>

            </div>
        </>
    )
}
