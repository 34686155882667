import {useRef, useState} from 'react'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate'
import { STATES } from '../../configuration'
import { nullifyEmptyInput } from '../../helper'

export default function ViewProspectDialog({ prospect, isLoading, onCancel, callback }) {
    const errorBar = useRef(null)
    const [error, setError] = useState(null)
    const [name,setName] = useState(prospect.name)
    const [phoneNum,setPhoneNum] = useState(prospect.phoneNumber)
    const [org,setOrg] = useState(prospect.organisation ?? "")
    const [email,setEmail] = useState(prospect.email ?? "")
    const [dob,setDob] = useState(prospect.dateOfBirth ?? "")
    const [address,setAddress] = useState(prospect.address ?? "")
    const [address2,setAddress2] = useState(prospect.address2 ?? "")
    const [poscode,setPoscode] = useState(prospect.poscode ?? "")
    const [city,setCity] = useState(prospect.city ?? "")
    const [state,setState] = useState(prospect.state ?? "")

    const handleSubmit = async (e) => {
        e.preventDefault()
        const form = e.target
        const formData = {
            name:form.prosName.value,
            phoneNumber:form.prosPhoneNum.value,
            organisation:nullifyEmptyInput(form.prosOrg),
            email:nullifyEmptyInput(form.prosEmail),
            dateOfBirth:nullifyEmptyInput(form.prosDOB),
            address:nullifyEmptyInput(form.prosAddress),
            address2:nullifyEmptyInput(form.prosAddress2),
            poscode:nullifyEmptyInput(form.prosPoscode),
            city:nullifyEmptyInput(form.prosCity),
            state:nullifyEmptyInput(form.prosState)
        }
        setError(null)
        const { status, code, message } = await callback(formData,prospect.id)
        if (status !== "ok") {
            setError({
                code,
                message
            })
            //need delay after setting errors
            setTimeout(() => {
                errorBar.current.scrollIntoView()
            }, 0);
        }
    }

    return (
        <DialogTemplate title="View Prospect" toggle={true} height="fit-content">
            {error && <div ref={errorBar} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <p style={{textAlign:"end"}}><span className="red-asterisk">*</span> Required field</p>
            <p style={{textAlign:"end"}}>Any changed value will be updated, remain unchanged to use default value</p>
            <form onSubmit={handleSubmit} className="layout-dialog__form">

                <label htmlFor="prosName"><span className="red-asterisk">*</span> Name</label>
                <input value={name} onChange={(e) => setName(e.target.value)} type="text" id="prosName" name="prosName" placeholder="Prospect name" required />

                <label htmlFor="prosPhoneNum"><span className="red-asterisk">*</span> Phone Number</label>
                <input value={phoneNum} onChange={(e) => setPhoneNum(e.target.value)} type="number" name="prosPhoneNum" id="prosPhoneNum" placeholder="Phone number" />

                <label htmlFor="prosOrg">Organisation</label>
                <input value={org} onChange={(e) => setOrg(e.target.value)} type="text" id="prosOrg" name="prosOrg" placeholder="Organisation" />

                <label htmlFor="prosEmail">Email</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="prosEmail" name="prosEmail" placeholder="Email" />

                <label htmlFor="prosDOB">Date of Birth</label>
                <input value={dob} onChange={(e) => setDob(e.target.value)} type="date" id="prosDOB" name="prosDOB" />

                <label htmlFor="prosAddress">Address</label>
                <input value={address} onChange={(e) => setAddress(e.target.value)} type="text" name="prosAddress" id="prosAddress" placeholder="Address" />
                <input value={address2} onChange={(e) => setAddress2(e.target.value)} type="text" name="prosAddress2" id="prosAddress2" placeholder="Address 2" />

                <label htmlFor="prosPoscode">Poscode</label>
                <input value={poscode} onChange={(e) => setPoscode(e.target.value)} type="number" name="prosPoscode" id="prosPoscode" placeholder="Poscode" />

                <label htmlFor="prosCity">City</label>
                <input value={city} onChange={(e) => setCity(e.target.value)} type="text" name="prosCity" id="prosCity" placeholder="City" />

                <label htmlFor="prosState">State</label>
                <select value={state} onChange={(e) => setState(e.target.value)} name="prosState" id="prosState">
                    {STATES.map(s => <option key={s} value={s}>{s}</option>)}
                </select>

                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={()=>{setError(null); onCancel()}}>Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Updating..." : "Update"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
