import React,{useEffect, useState} from 'react'
import './settings.css'
import Header from '../../components/Header/Header'
import General from './General'
import Users from './Users'
import Deal from './Deal'
import Company from './Company'
import StaffAgentManagement from'./StaffAgentManagement'
import { useAuth } from '../../contexts/AuthContext'
import { useUsers } from '../../contexts/UsersContext'

export default function Settings() {
    //use index to switch tabs
    const [tab,setTab] = useState(0)
    const {currentUser} = useAuth()
    const {users} = useUsers()
    const [isOperation,setIsOperation] = useState(false)
    const [isAdmin,setIsAdmin] = useState(false)

    useEffect(() => {
        if(!currentUser || users.length === 0) return

        const user = users.filter(u => u.id === currentUser.uid)[0];

        if(user.role.includes("Operation")){
            setIsOperation(true)
        }else if(user.role.includes("Admin") && !user.role.includes("SuperAdmin")){
            setIsAdmin(true)
        }

    }, [currentUser,users])

    const handleClick = (idx) => {
        setTab(idx);
    }

    return (
        <>
        <Header title="Settings" />
        <div className="tab-contents">
            <div className="settings">
                <div className="settings-tabs">
                    <ul>
                        <li className={tab === 0 ? "active" : undefined} onClick={() => handleClick(0)}>Profile</li>
                        <li className={tab === 1 ? "active" : undefined} onClick={() => handleClick(1)}>Users</li>
                        <li className={tab === 2 ? "active" : undefined} onClick={() => handleClick(2)}>Deal</li>
                        <li className={tab === 3 ? "active" : undefined} onClick={() => handleClick(3)}>Company</li>
                        <li className={tab === 4 ? "active" : undefined} onClick={() => handleClick(4)}>Staff & Agent Management</li>
                    </ul>
                </div>
                <div className="settings-container">
                    {tab === 0 && <General/>}
                    {tab === 1 && <Users isAdmin={isAdmin} isOperation={isOperation}/>}
                    {tab === 2 && <Deal isAdmin={isAdmin} isOperation={isOperation}/>}
                    {tab === 3 && <Company isAdmin={isAdmin} isOperation={isOperation}/>}
                    {tab === 4 && <StaffAgentManagement isAdmin={isAdmin} isOperation={isOperation}/>}
                </div>
            </div>
        </div>
        </>
    )
}
