import { useState, useEffect, useCallback } from "react";

function useFetchInfinite(query) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [list, setList] = useState([]);
    const [lastDoc,setLastDoc] = useState(null)

    const sendQuery = useCallback(async () => {
        if(query === "" ) return
        try {
            setLoading(true);
            setError(false);
            const res = await query.get()
            if (res.size === 0) {
                setLastDoc(null)
                setLoading(false)
            } else {
                const data = res.docs.map(doc => ({path:doc.ref.path,id:doc.id,...doc.data()}))
                setLastDoc(res.docs[res.size - 1])
                setList((prev) => [...prev, ...data]);
                setLoading(false);
            }
           
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }, [query]);

    useEffect(() => {
        sendQuery(query);
    }, [query, sendQuery]);

    return { loading, error, list , lastDoc ,setList };
}

export default useFetchInfinite;