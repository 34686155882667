import { useState, useCallback, useRef } from "react"
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from "../helper"
import { storage, fb as firebase } from '../../firebase'
import { setPfpLink } from "../../controllers/usersController"

export default function CropImage({ onCancel, callback, uid }) {
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [loadImg, setLoadImg] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    const [zoom, setZoom] = useState(1)
    const [pixelCrop, setPixelCrop] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const acceptedFileTypes = ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg']
    const imageMaxSize = 8000000
    const progress = useRef()

    const onUploadPfp = useCallback((image, ext) => {
        const progressEle = progress.current
        const uploadTask = storage.ref().child(`users/${uid}/avatar/pfp.${ext}`).put(image.blob);
        // Listen for state changes, errors, and completion of the upload.
        return uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                progressEle.textContent = `Uploading... ${parseFloat(progressPercent).toFixed(2)} + %`
                // switch (snapshot.state) {
                //     case firebase.storage.TaskState.PAUSED: // or 'paused'
                //         console.log('Upload is paused');
                //         break;
                //     case firebase.storage.TaskState.RUNNING: // or 'running'
                //         console.log('Upload is running');
                //         break;
                // }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                setError({ message: error.message, code: error.code })
                // switch (error.code) {
                //     case 'storage/unauthorized':
                //         // User doesn't have permission to access the object
                //         break;
                //     case 'storage/canceled':
                //         // User canceled the upload
                //         break;

                //     // ...

                //     case 'storage/unknown':
                //         // Unknown error occurred, inspect error.serverResponse
                //         break;
                // }
            },
            async () => {

                //Upload completed successfully, now we can get the download URL
                const url = await uploadTask.snapshot.ref.getDownloadURL();
                await setPfpLink(uid,url).catch(err => setError(err))
                setLoading(false)
                callback(url)
            }
        );
    }, [uid,callback]
    )
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        //console.log(croppedArea, croppedAreaPixels)
        setPixelCrop(croppedAreaPixels)
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (imgSrc !== null && pixelCrop !== null) {
            setLoading(true)
            try {
                const croppedImage = await getCroppedImg(
                    imgSrc.img,
                    pixelCrop,
                    imgSrc.ext
                )
                onUploadPfp(croppedImage, imgSrc.ext.split('/')[1])
            } catch (e) {
                setLoading(false)
                console.error(e)
            }

        }
    }

    const onFileSelected = (e) => {
        setError(null)
        const image = e.target.files[0]
        if (!acceptedFileTypes.includes(image.type)) {
            setError({
                message: 'Invalid File Type',
                code: 'File-Error'
            })
            return
        }

        if (image.size > imageMaxSize) {
            setError({
                message: 'File size needs to be under 8MB.',
                code: 'File-Error'
            })
            return
        }

        setLoadImg(true)
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            setImgSrc({ img: reader.result, ext: image.type })
            setLoadImg(false)
        }, false)

        reader.readAsDataURL(image)
    }

    return (
        <DialogTemplate title="Crop Image" toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}

            <div>
                {imgSrc &&
                    <div style={{ position: 'relative', width: '100%', height: '500px' }}>
                        <Cropper
                            image={imgSrc.img}
                            crop={crop}
                            aspect={1 / 1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            zoom={zoom}
                            onZoomChange={setZoom}
                            cropShape={"round"}
                        />
                    </div>
                }

                {loadImg &&
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', margin: '10px' }}>
                        <MiniSpinner wh={25} />
                        <span>Loading image...</span>
                    </div>
                }

            </div>

            <form onSubmit={handleSubmit} className="layout-dialog__form" >
                <input onChange={onFileSelected} accept=".jpg,.png" type="file" name="pfp" id="pfp" required />
                <span ref={progress}>0%</span>
                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { setError(null); onCancel() }}
                    >Cancel</button>
                    <button disabled={isLoading || error} type="submit" className="btn-purple">
                        {isLoading ? "Saving..." : "Save"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
