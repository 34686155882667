import React from 'react'
import styles from './home.module.css'
import { useHistory } from 'react-router-dom'
import { useNav } from '../../contexts/NavContext'

const Shortcuts = ({ title, icon, url, cname, nav,locked = false }) => {

    const {setNavigation} = useNav()
    const history = useHistory()

    const onRedirect = (e) => {
        if(locked){
            e.preventDefault();
            return
        }
        setNavigation(nav)
        history.push(url)
    }

    return (
        <div onClick={onRedirect} className={`${styles.shortcut} ${styles[cname]}`}>
            <h1>{title}</h1>
            <span className="material-icons">{locked ? 'lock' : icon}</span>
        </div>
    )
}

export default function ShortcutsWidget({isOperation}) {
    return (
        <div className={styles.div__shortcuts}>
            <Shortcuts
                title="Calendar"
                icon="today"
                cname="shortcut__cal"
                url="/crm/activities/calendar"
                nav="CRM"
            />
            <Shortcuts
                title="Deals"
                icon="feed"
                cname="shortcut__deal"
                url="/crm/deals#pipeline"
                nav="CRM"
                locked={isOperation}
            />
            <Shortcuts
                title="Report"
                icon="analytics"
                cname="shortcut__rep"
                url="/crm/report"
                nav="CRM"
                locked={isOperation}
            />
            <Shortcuts
                title="RFP"
                icon="request_page"
                cname="shortcut__rfp"
                url="/rfp"
                nav="RFP"
            />
            <Shortcuts
                title="Dashboard"
                icon="assessment"
                cname="shortcut__dashboard"
                url="/crm/dashboard"
                nav="CRM"
                locked={isOperation}
            />
        </div>
    )
}
