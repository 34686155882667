import React, { useEffect, useContext, useState, useRef,useCallback } from 'react'
import { DealChartsCtx } from '../Deals'
import styles from '../dashboard.module.css'
import Loading from '../Loading'
import { getMonthlyLost, getMonthlyPrefs, setMonthlyPrefs } from '../../../../controllers/dashboardController'
import useResizeObserver from '../../../../hooks/useResizeObserver'
import { select, arc, pie } from 'd3'
import { randomColor } from '../../../helper'

//Monthly Lost Deals Chart

const Chart = React.memo(({ data }) => {
    const chartWrapperRef = useRef()
    const svgRef = useRef()
    const dimensions = useResizeObserver(chartWrapperRef)

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const arcGenerator = arc()
            .innerRadius(70)
            .outerRadius(88)

        const pieGenerator = pie();
        const values = data.map(arr => arr[1].count)
        const keys = data.map(arr => arr[0])
        const amounts = data.map(arr => arr[1].value.toFixed(2))
        const instructions = pieGenerator(values)
        const colors = data.map(d => randomColor())
        svg.selectAll(".slice")
            .data(instructions)
            .join("path")
            .attr("class", "slice")
            .attr("fill", (instruction, index) => colors[index])
            .style("transform", `translate(40%, 55%)`)
            .on("mouseenter", (event, instruction) => {
                svg.selectAll(".tooltip")
                    .data([instruction])
                    .join("text")
                    .attr("class", "tooltip")
                    .text(`${keys[instruction.index]}: ${instruction.data} | RM ${amounts[instruction.index]}`)
                    .style("transform", `translate(${event.offsetX}px, ${event.offsetY}px)`)
                    .transition()
                    .attr("opacity", 1)
            })
            .on("mouseleave", () => svg.select('.tooltip').remove())
            .transition()
            .attr("d", instruction => arcGenerator(instruction))

    }, [data, dimensions])

    const pieStyles = { width: '40%', height: '115%', overflow: 'visible' }

    return (
        <div ref={chartWrapperRef} style={pieStyles}>
            <svg style={{ height: "100%", width: "100%", overflow:'visible' }} ref={svgRef}></svg>
        </div>
    )
})


const Balloon = ({ currTarget, setTarget, onClose }) => {
    const {date} = useContext(DealChartsCtx)
    const submit = async (e) => {
        e.preventDefault()
        const form = e.target
        const value = form.inpTarget.value
        const intVal = parseInt(value)
        await setMonthlyPrefs(date.m,date.y,'threshold',intVal)
        .catch(err => {
            console.error(err.code)
        })
        setTarget(intVal)
        onClose()
    }

    return (
        <div className={styles.balloon}>
            <form onSubmit={submit} >
                <input name="inpTarget" required type="text" style={{marginBottom:'10px'}} placeholder='Set threshold' defaultValue={currTarget} />
                <button style={{marginLeft:'auto'}} type="submit" className="btn-blue">Apply</button>
            </form>
        </div>
    )
}

export default function CHART_2() {

    
    const ctx = useContext(DealChartsCtx)
    const [target, setTarget] = useState(0)
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(null)
    const currProg = useRef()
    const progPercent = useRef()
    const progress = useRef()
    const currTotal = useRef()
    const currValue = useRef()
    const [data, setData] = useState([])
    const [isOpen, setIsOpen] = useState(false)


    const getOwnerName = useCallback(
        (id) => {
            return ctx.users.filter(u => u.id === id)[0].name
        },
        [ctx.users],
    ) 

    useEffect(() => {
        
        const { isMounted, date } = ctx
        const firstDay = new Date(ctx.date.y, ctx.date.m, 1)
        const lastDay = new Date(ctx.date.y, ctx.date.m + 1, 0)
        const initFetch = async () => {
            setIsFetching(true)

            const prefs = await getMonthlyPrefs(date.m, date.y).catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })

            if (prefs && prefs.exists) {
                setTarget(prefs.get('threshold') ?? 0)
            }

            const res = await getMonthlyLost(firstDay, lastDay).catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })

            setIsFetching(false)

            if (isMounted.current && res) {
                if (res.size !== 0) {
                    currTotal.current.textContent = res.size
                    const total = res.docs.map(doc => doc.get('value') ?? 0).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr))
                    currValue.current.textContent = 'RM ' + total.toFixed(2)
                    currProg.current.textContent = total

                    if (total / target !== Infinity) {
                        const percent = (total / target * 100).toFixed(2)
                        progPercent.current.textContent = `${percent}%`
                        if (percent > 100) {
                            progress.current.style.width = '100%'
                        } else {
                            progress.current.style.width = `${percent}%`
                        }
                    }
                    const arr = res.docs.map(doc => ({name:getOwnerName(doc.get('owner')),value:doc.get('value') ?? 0}))
                    const counter = {}
                    arr.forEach(function ({name,value}) {
                        var keyjson = JSON.stringify(name)
                        const key = keyjson.replace(/"/g, '')
                        counter[key] = {
                            count : (counter[key]?.count || 0) + 1,
                            value : (counter[key]?.value || 0) + parseFloat(value)
                        }
                    })

                    const arrayPairs = Object.entries(counter)
                    setData(arrayPairs)
                }
            }
        }
        initFetch()
    }, [ctx,target,getOwnerName])

    const chartStyles = {
        height: '100%',
        width: '50%'
    }

    return (

        <div style={chartStyles} className={styles.container}>
            <p className={styles.title}>Lost Deals</p>
            {error && <div style={{ width: '60%', margin: "0 auto" }} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {isFetching ?
                <Loading /> :
                <div style={{ display: 'flex' }}>
                    <Chart data={data} />
                    <div className={`${styles.progress}`} style={{ width: '40%' }}>
                        <div className={styles.subSection}>
                            <p>Monthly Threshold</p>
                            <p className={styles.valueText}><strong >RM {target}</strong></p>
                        </div>
                        <div style={{ position: 'relative' }} className={styles.subSection}>
                            <button className={!isOpen?"btn-green":"btn-white"} onClick={() => setIsOpen(!isOpen)}>{!isOpen?'Change':'Close'}</button>
                            {isOpen && <Balloon
                                onClose={()=>setIsOpen(false)} 
                                currTarget={target} 
                                setTarget={setTarget} />}
                        </div>
                        <div className={`${styles.subSection}`}>
                            <div>
                                <span><strong ref={currProg} >0</strong>/<span >{target}</span> </span>
                                <span> | </span>
                                <span> <strong ref={progPercent}>0%</strong></span>
                            </div>
                            <div className={styles.track}>
                                <div style={{backgroundColor:'red'}} ref={progress} />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div className={styles.subSection}>
                            <p>Lost Deals</p>
                            <p className={styles.valueText}><strong ref={currTotal} >0</strong></p>
                        </div>
                        <div className={styles.subSection}>
                            <p>Value</p>
                            <p className={styles.valueText}><strong ref={currValue} >RM 0.00</strong></p>
                        </div>
                    </div>
                </div>}
            <p className={styles.tips}>Lost Deals values are calculated based on <strong>monthly lost deals</strong>.</p>
        </div>
    )
}
