import { db,timestamp } from "../firebase";

const ref = db.collection("Prospects")

export const getProspect = (id) => {
    return ref.doc(id).get()
}

export const updateProspect = (data,pid) => {
    return ref.doc(pid).update({...data, dateUpdated: timestamp})
}

export const checkExistingProspect = async (phoneNumber) => {
    const querySnapshot = await db.collection("Prospects")
        .where("phoneNumber", "==", phoneNumber)
        .limit(1)
        .get()
        .catch(err => {
            throw err
        })

    return querySnapshot.size > 0 ? querySnapshot.docs[0].id : null
}

export const updateProspectDeal = (pid,did,data)=>{
    return ref.doc(pid).collection("DealHistory").doc(did).update(data)
}