import React, { useState, useEffect } from 'react'
import DetailContainer from '../DetailContainer'
import styles from '../wall.module.css'
import PaymentConfDialog from './PaymentConfDialog'
import { updatePayment, setPayment, resetPayment } from '../../../../controllers/paymentController'
import { timestamp, convertTimestamp } from '../../../../firebase'
import { useParams } from 'react-router-dom'

function FullPayment({ value, paymentData, paymentMethod, setPaymentData }) {

    const { id, status } = useParams()
    const [isOpen, setIsOpen] = useState(false)
    const [checked, setChecked] = useState(paymentData?.fullyPaid?.amount !== 0 && paymentData?.fullyPaid?.amount !== undefined)
    const onChecked = async (e) => {
        setChecked(!checked)

        if (e.target.checked) {
            setIsOpen(true)
        } else {
            const data = {
                method: paymentMethod,
                fullyPaid: {
                    amount: 0,
                    date: undefined
                }
            }
            await resetPayment(id).catch(err => console.log(err))
            setPaymentData(data)
        }
    }

    const onSubmitCallback = async (formData) => {
        const data = {
            method: paymentMethod,
            fullyPaid: {
                amount: value,
                date: formData.date === null ? timestamp : convertTimestamp(formData.date)
            }
        }

        await updatePayment(id, data).catch(({ code, message }) => {
            if (code === 'not-found') {
                return setPayment(id, data)
            } else {
                return {
                    error: {
                        code,
                        message
                    },
                    status: 'not-ok'
                }
            }
        })

        if (formData.date === null) data.fullyPaid.date = convertTimestamp(new Date())//avoid rendering date with undefined value
        setPaymentData(data)
        setIsOpen(false)
        return {
            status: "ok"
        }
    }

    useEffect(() => { setChecked(paymentData?.fullyPaid?.amount !== 0 && paymentData?.fullyPaid?.amount !== undefined) }, [paymentData])

    return (
        <>
            <DetailContainer title="Payment" editable={false}>
                <div className={styles.row}><p >Payment method can be change inside deal information section.</p></div>
                <div className={styles.row}><h4>{paymentMethod}</h4></div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={status !== undefined} checked={checked} onChange={onChecked} type="checkbox" name="fpPaid" id="fpPaid" />
                        <label htmlFor="fpPaid">Fully paid</label>
                    </div>
                    <p>RM {paymentData?.fullyPaid?.amount || 0}</p>
                    <p>{paymentData?.fullyPaid?.date?.toDate()?.toDateString()}</p>
                </div>
                <p>Remaining value : RM {paymentData?.fullyPaid?.date === undefined ? value : '0'}</p>
            </DetailContainer>
            {
                isOpen &&
                <PaymentConfDialog
                    callback={onSubmitCallback}
                    onCancel={() => { setChecked(false); setIsOpen(false) }}
                />
            }
        </>
    )
}

export default FullPayment
