import styles from './wall.module.css'
import Indicator from './Indicator'
import React, { useState, useEffect } from 'react'
import { getWallLogs } from '../../../controllers/dealController'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import { useParams } from 'react-router-dom'
import WallHistory from './WallHistory'

function StageHistoryComponent({ dealCreatedDate, stageState }) {
    const {id} = useParams()
    const [stage, setStage] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setIsloading] = useState(true)
    const [toggleHistory,setToggleHistory] = useState(false)
    const [logs,setLogs] = useState([])

    const style = {
        borderLeft: "5px solid #536EEF"
    }

    useEffect(() => {
        let isMounted = true
        setIsloading(true)
        async function fetchLogs() {
            const result = await getWallLogs(id).catch(err => {
                if (isMounted) {
                    setIsloading(false)
                    setError(err)
                }
            })
            if(isMounted) setLogs(result.docs.map(doc => doc.data()))
            const stageLogs = result.docs.filter(doc => doc.data().type === "stage")
            const dataArray = stageLogs.map(doc => doc.data())
            if (result.size === 0) {
                if (isMounted) {
                    setIsloading(false)
                    setStage({
                        from: "Lead In",
                        to: "Lead In",
                        dateCreated: dealCreatedDate
                    })
                }
            } else {
                if (isMounted) {
                    setIsloading(false)
                    setStage(dataArray[0])}
            }
        }

        fetchLogs()
        return () => isMounted = false
    }, [stageState,dealCreatedDate,id])

    return (
        <>
            <div className={styles.record__item}>
                <Indicator type="SYSTEM" />
                <div className={styles.record__detail_sh} style={style}>
                    <div className={styles.sh__cont}>
                        {
                            isLoading || !stage ? <MiniSpinner wh="25" /> : 
                            <p>Updated from <strong>{stage.from}</strong> to <strong>{stage.to}</strong> on <strong>{stage.dateCreated.toDate().toDateString()}</strong></p>
                        }
                        <button className="btn-white" onClick={() => setToggleHistory(true)}>Wall History</button>
                    </div>
                </div>
            </div>
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}

            {
                toggleHistory &&
                <WallHistory
                    dataArray={logs}
                    onCancel={()=>setToggleHistory(false)}
                />
            }
        </>
    )
}

export default React.memo(StageHistoryComponent)
