import { db,arrayMethod } from "../firebase"

const ref = db.collection("AppPreferences");

export const fetchDealPreferences = () => {
    return db.collection("AppPreferences")
    .doc("DealDialog").get()
}

export const fetchRFPPrefs = () => {
    return db.collection("AppPreferences").doc("RFP").get()
}

export const addArrayRfp = (key,value) => {
    return ref.doc('RFP').update({
        [key]: arrayMethod.union(value)
    });
}

export const removeArrayRfp = (key,value) => {
    return ref.doc('RFP').update({
        [key]: arrayMethod.remove(value)
    });
}

export const addArrayDealDialog = (key,value) => {
    return ref.doc('DealDialog').update({
        [key]: arrayMethod.union(value)
    });
}

export const removeArrayDealDialog = (key,value) => {
    return ref.doc('DealDialog').update({
        [key]: arrayMethod.remove(value)
    });
}