import React from 'react'
import { useState, useEffect, useRef } from 'react'
import styles from './las.module.css'
import Header from '../../components/Header/Header'
import { useAuth } from '../../contexts/AuthContext'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import { checkRole, getApplications, getManagerList } from '../../controllers/lasController'
import { useUsers } from '../../contexts/UsersContext'
import { CSVLink } from "react-csv";

const TableBody = ({ year, ent, getList }) => {
    const [list, setList] = useState([])
    const { users } = useUsers()

    useEffect(() => {

        if (users.length === 0) return

        const getNames = (uid) => {
            return users.filter(u => u.id === uid)[0].name
        }

        const fetch = async () => {
            const res = await getApplications(year).catch(err => console.error(err))
            if (res) {
                const arr = res.docs.map(doc => ({ ...doc.data(), staff: getNames(doc.get('uid')) }));
                setList(arr)
                getList(arr)
            }
        }
        fetch()
    }, [year, users, getList]);

    return (
        <tbody>
            {list.length === 0 && <tr>
                <td></td>
                <td></td>
                <td>Table is empty.</td>
                <td></td>
                <td></td>
            </tr>}
            {list.map(doc => (
                <tr key={doc.uid}>
                    <td>{doc.staff}</td>
                    <td>{doc.leaveUsed}</td>
                    <td>{ent.leave - doc.leaveUsed}</td>
                    <td>{doc.mcUsed}</td>
                    <td>{ent.mc - doc.mcUsed}</td>
                </tr>
            ))}
        </tbody>
    )
}

const TotalLeaveCard = ({ dept, year }) => {
    const [total, setTotal] = useState(0)

    useEffect(() => {

        const fetch = async () => {
            const res = await getManagerList(year, dept.toLowerCase());
            if (res) {
                setTotal(res.size)
            }
        }
        fetch()
    }, [dept, year]);


    return (
        <div className={styles.totalLeaveCard}>
            <p>{dept}</p>
            <p>{total}</p>
        </div>
    )
}

export default function HRDashboard() {
    const [ent, setEnt] = useState(null)
    const [isManager, setIsManager] = useState(false)
    const [error, setError] = useState()
    const { currentUser } = useAuth()
    const [year, setYear] = useState(new Date().getFullYear().toString())
    const yearRef = useRef()
    const [isLoading, setIsLoading] = useState(false)
    const [loadingText, setLoadingText] = useState('')
    const excelResult = useRef([])

    const getList = (list) => {
        excelResult.current = list.map((doc, idx) => ({
            Num: idx + 1,
            Staff: doc.staff,
            Leave_Used: doc.leaveUsed,
            Leave_Balance: ent.leave - doc.leaveUsed,
            MC_Used: doc.mcUsed,
            MC_Balance: ent.mc - doc.mcUsed,
        }))
    }

    useEffect(() => {
        document.querySelector('title').innerText = 'HR Dashboard - Leave Application System'

        if (!currentUser) return

        const sort = () => {
            let asc = false;
            const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

            const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
                v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
            )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

            document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
                const table = th.closest('table');
                const tbody = table.querySelector('tbody');
                Array.from(tbody.querySelectorAll('tr'))
                    .sort(comparer(Array.from(th.parentNode.children).indexOf(th), asc = !asc))
                    .forEach(tr => tbody.appendChild(tr));
            })))
        }

        setError(null)

        const fetch = async () => {
            setIsLoading(true)
            setLoadingText('Acquiring data...')
            //Do something here
            setIsLoading(false)
            setLoadingText('')
        }

        //TODO: Check if this user is a valid manager
        const check = async () => {
            setIsLoading(true)
            setLoadingText('Checking user information...')

            const config = await checkRole().catch(err => {
                setError(err)
                setIsLoading(false)
                setLoadingText('')
                return
            })

            if (config) {
                const roles = config.get('managers');
                setEnt(config.get('entitlement'))
                if (roles.hr === currentUser.uid) {
                    setIsManager(true)

                    let initYear = new Date(Date.parse(localStorage.getItem("INIT_DATE"))).getFullYear()
                    const currYear = new Date().getFullYear()
                    yearRef.current.innerHTML = ''
                    while (initYear <= currYear) {
                        const opt = document.createElement('option')
                        const y = initYear++
                        opt.value = y
                        opt.textContent = y
                        yearRef.current.prepend(opt)
                    }

                    yearRef.current.value = currYear

                    fetch()
                    sort()
                } else {
                    setIsManager(false)
                    setError({ message: 'You do not have the permission to view this page.', code: 'PERM' })
                    setIsLoading(false)
                    setLoadingText('')
                    return
                }
            }

        }

        check()


    }, [currentUser, year])

    return (
        <>
            <Header title={"HR Dashboard"} />
            <div className="tab-contents">
                {error && <div className={`error-bar ${styles.lasmain_err}`}>
                    <span className="material-icons">error</span>
                    <p>{error.message} --code: {error.code}</p>
                </div>}

                {!isManager ? <div className={styles.noPerm}>
                    <span className='material-icons'>admin_panel_settings</span>
                    <p>You do not have permission to view this page. Please contact your administrator.</p>
                </div> :

                    <div>
                        <div className='fx fx-jc-sb'>
                            <div style={{ marginLeft: 'auto' }}>
                                <span >Showing stats for : </span>
                                <select ref={yearRef} value={year} name="slYear" id="slYear" onChange={e => setYear(e.target.value)} />
                            </div>
                        </div>

                        {isLoading &&
                            <div className='flex__row' style={{ gap: '10px' }}>
                                <MiniSpinner wh={30} />
                                <span>{loadingText}</span>
                            </div>}

                        <div>
                            <div className='fx fx-center fx-gap-1'>
                                <span className="material-icons">space_dashboard</span>
                                <h3> Total Leave in a Year</h3>
                            </div>

                            <div className={styles.totalLeaveContainer}>
                                <TotalLeaveCard
                                    year={year}
                                    dept={'HR'} />
                                <TotalLeaveCard
                                    year={year}
                                    dept={'IT'} />
                                <TotalLeaveCard
                                    year={year}
                                    dept={'Marketing'} />
                                <TotalLeaveCard
                                    year={year}
                                    dept={'Corporate'} />
                                <TotalLeaveCard
                                    year={year}
                                    dept={'Operation'} />
                            </div>

                            <div className='h-seperator' style={{ margin: '30px 0', opacity: '0.2' }}></div>

                            <div className='fx fx-center fx-gap-1'>
                                <span className="material-icons">analytics</span>
                                <h3> Staff Total Leaves</h3>
                            </div>

                            <div>
                                <div className='flex__row' style={{ gap: '10px', justifyContent: 'flex-end' }}>
                                    <CSVLink data={excelResult.current} filename={`Leave_Stats_${new Date().toString()}.csv`}>
                                        <button className="btn-green">Download CSV</button>
                                    </CSVLink>
                                    <p>Click on the table headers to sort.</p>
                                    <p style={{ marginLeft: 'auto' }}>Leave Entitlement : <strong>{ent.leave}</strong></p>
                                    <p>MC Entitlement : <strong>{ent.mc}</strong></p>
                                </div>

                                <table className="table staff-leave" cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th>Staff</th>
                                            <th>Leave Used</th>
                                            <th>Leave Balance</th>
                                            <th>MC Used</th>
                                            <th>MC Balance</th>
                                        </tr>
                                    </thead>
                                    <TableBody ent={ent} year={year} getList={getList} />
                                </table>
                            </div>

                        </div>
                    </div>}

            </div>
        </>
    )
}
