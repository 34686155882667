import { useEffect, useRef, useState } from 'react'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import styles from './prospect.module.css'
import { fetchDealHistory } from './database'
import { openInNewTab } from '../../helper'

export default function ViewProspectDialog({ prospect, onCancel }) {
    const errorBar = useRef(null)
    const [error, setError] = useState(null)
    const [isFetching, setisFetching] = useState(true)
    const [deals, setDeals] = useState([])
    const isMounted = useRef(false)
    const origin = window.location.origin

    const fetchDeals = async () => {
        const result = await fetchDealHistory(prospect.pid).catch(err => {
            if (isMounted.current) {
                setisFetching(false)
                setError(err)
                setTimeout(() => {
                    errorBar.current.scrollIntoView()
                }, 0);
            }
        })

        if (isMounted.current) {
            setDeals(result.docs.map(d => { return { ...d.data(), id: d.id } }))
            setisFetching(false)
        }
    }

    const handleRedirect = (did, status) => {
        if (status === "Completed" || status === "Lost" || status === "Deleted") {
            openInNewTab(`${origin}/crm/wall/${did}/${status}`)
        } else {
            openInNewTab(`${origin}/crm/wall/${did}`)
        }
    }

    useEffect(() => {
        isMounted.current = true
        fetchDeals()
        return () => isMounted.current = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <DialogTemplate title="View Prospect History" toggle={true} height="fit-content">
            {error && <div ref={errorBar} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <h3>{`Showing ${prospect.name}'s deal history`}</h3>
            {isFetching &&
                <div className={styles.indicator}>
                    <MiniSpinner wh={25} />
                    <p>Fetching deals...</p>
                </div>
            }

            {
                deals.length > 0 ?
                    <div>
                        <p style={{ padding: "1rem 0" }}>{`Total of ${deals.length} deal(s) found.`}</p>

                        <ul className={styles.history__list} >
                            {deals.map(deal =>
                                <li key={deal.id} onClick={() => handleRedirect(deal.id, deal.status)}>
                                    <h4>{deal.title}</h4>
                                    <p>{deal.services}</p>
                                    <div>
                                        <span>{deal.status}</span>
                                        <span>{deal.dateCreated.toDate().toDateString()}</span>
                                    </div>
                                </li>
                            )}
                        </ul>

                    </div> :
                    <p style={{ padding: "1rem 0" }}>No history found in this prospect.</p>
            }

            <div >
                <button style={{ marginLeft: "auto" }} className="btn-white" onClick={onCancel}>Close</button>
            </div>

        </DialogTemplate>
    )
}
