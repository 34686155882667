import React, { useState, useEffect, useCallback, useRef } from 'react'
import GridCalendar from './GridCalendar'
import ListCalendar from './ListCalendar'
import { MONTHS } from '../../configuration'
import MiniSpinner from '../../../components/Loading/MiniSpinner'

function CalHeader({ isRendering, setToggle, setDate, date }) {

    const month = date.m
    const year = date.y

    const [value, setValue] = useState(date)
    const monthRef = useRef(month)
    const yearRef = useRef(year)
    const clickTimeout = useRef();

    const handleOnChange = (e) => {
        setToggle(e.target.value)
    }

    const display = useCallback(
        (node) => {
            if (node !== null) {
                node.textContent = `${MONTHS[value.m]} ${value.y}`
            }
        },
        [value],
    )

    const onChangeMonth = useCallback(
        (direction) => {
            clearTimeout(clickTimeout.current)

            if (direction === "prev") {
                monthRef.current--
                if (monthRef.current < 0) {
                    monthRef.current = 11
                    --yearRef.current
                }
            } else {
                monthRef.current++
                if (monthRef.current === 12) {
                    ++yearRef.current
                    monthRef.current = 0
                }
            }
            setValue({ m: monthRef.current, y: yearRef.current })
            clickTimeout.current = setTimeout(() => {
                setDate({ m: monthRef.current, y: yearRef.current })
            }, 500);
        }
        ,
        [setDate]
    )
    const onChangeYear = useCallback(
        (direction) => {
            clearTimeout(clickTimeout.current)
            if (direction === "prev") {
                yearRef.current--
            } else {
                yearRef.current++
            }
            setValue(prev => ({ ...prev, y: yearRef.current }))
            clickTimeout.current = setTimeout(() => {
                setDate(prev => ({ ...prev, y: yearRef.current }))
            }, 500);
        },
        [setDate],
    )
    return (
        <div className="cal-header">
            <div>
                <span onClick={() => onChangeYear("prev")} className="material-icons cal-btn">keyboard_double_arrow_left</span>
                <span onClick={() => onChangeMonth("prev")} className="material-icons cal-btn">chevron_left</span>
                <span ref={display} className="cal-month"></span>
                <span onClick={() => onChangeMonth("next")} className="material-icons cal-btn">chevron_right</span>
                <span onClick={() => onChangeYear("next")} className="material-icons cal-btn">keyboard_double_arrow_right</span>
                <div style={{ marginLeft: "20px" }}>{isRendering && <MiniSpinner wh="30" />}</div>
            </div>
            <select style={{ width: "100px" }} name="calType" id="calType" onChange={handleOnChange}>
                <option value="grid">Grid</option>
                <option value="list">List</option>
            </select>
        </div>
    )
}

export default function Calendar({isOperation}) {
    const [toggle, setToggle] = useState("grid")
    const d = new Date()
    const [date, setDate] = useState({ m: d.getMonth(), y: d.getFullYear() })
    const [isRendering, setIsRendering] = useState(false)

    useEffect(() => {
        document.querySelector("title").textContent = "Calendar - Activities"
    }, [])

    return (
        <>
            <CalHeader isRendering={isRendering} setToggle={setToggle} setDate={setDate} date={date} />
            {toggle === "grid" ?
                <GridCalendar isOperation={isOperation} date={date} setIsRendering={setIsRendering} /> :
                <ListCalendar isOperation={isOperation} setIsRendering={setIsRendering} date={date} />
            }
        </>
    )
}
