import { db,timestamp,storage } from "../firebase";

export const getDirectories = () => {
    return db.collection("DocumentInventory").where("archived","==",false).get()
}

export const getDirectory = (dir) => {
    return db.collection("DocumentInventory").doc(dir).collection("Files").orderBy("dateUploaded","desc").get()
}

export const addDirectory = (data) => {
    return db.collection("DocumentInventory").add({
            ...data,
            dateCreated:timestamp,
            archived:false
        })
}

export const editDirectory = (id,data) => {
    return db.collection("DocumentInventory").doc(id).update(data)
}

export const deleteDirectory = (id) => {
    return db.collection("DocumentInventory").doc(id).delete()
}

export const deleteFile = (storagePath,fileID,dir) => {
    return Promise.all([
        db.collection("DocumentInventory").doc(dir).collection("Files").doc(fileID).delete(),
        storage.ref(storagePath).delete()
    ])
}

export const updateFile = (fileID,dir,data) => {
    return db.collection("DocumentInventory")
        .doc(dir).collection("Files")
        .doc(fileID).update(data)
}