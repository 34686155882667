import React, { useEffect, useRef, useState } from 'react'
import { fetchDealPreferences,addArrayDealDialog,removeArrayDealDialog } from '../../controllers/appPreferencesController'
import styles from './settingsComp.module.css'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import NoPerm from '../../components/ErrorDialog/NoPerm'

const DealSource = React.memo(({ data, setData }) => {

    const key = "sources"

    const submit = async (e) => {
        e.preventDefault()
        const item = e.target.item.value;
        await addArrayDealDialog(key,item).catch(err => console.error(err))
        const sources = [item,...data]
        setData(prev => ({...prev,sources}))
        e.target.reset()
    }

    const removeItem = async (item) => {
        await removeArrayDealDialog(key,item).catch(err => console.error(err))
        const sources = data.filter(d => d !== item)
        setData(prev => ({...prev,sources}))
    }

    return (
        <div>
            <h4> Deal Source </h4>
            <form onSubmit={submit} style={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '10px 0' }}>
                <input id="item" style={{ width: '400px', padding: '10px' }} required type="text" placeholder="Add source" />
                <button type="submit" className="btn-green">Add</button>
            </form>

            <div style={{maxHeight:'70vh',overflow:'scroll'}}>
                {
                    data.map((d,idx) => <div key={idx} className={styles.item__style}>
                        <p>{d}</p> <span onClick={() => removeItem(d)} className="material-icons" style={{ color: 'red' }}>close</span>
                    </div>)
                }
            </div>

        </div>
    )
})

const Services = React.memo(({ data, setData }) => {
    const key = "services"

    const submit = async (e) => {
        e.preventDefault()
        const item = e.target.item.value;
        await addArrayDealDialog(key,item).catch(err => console.error(err))
        const services = [item,...data]
        setData(prev => ({...prev,services}))
        e.target.reset()
    }

    const removeItem = async (item) => {
        await removeArrayDealDialog(key,item).catch(err => console.error(err))
        const services = data.filter(d => d !== item)
        setData(prev => ({...prev,services}))
    }

    return (
        <div>
            <h4> Deal Services </h4>
            <form onSubmit={submit} style={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '10px 0' }}>
                <input id="item" style={{ width: '400px', padding: '10px' }} required type="text" placeholder="Add service" />
                <button type="submit" className="btn-green">Add</button>
            </form>

            <div style={{maxHeight:'70vh',overflow:'scroll'}}>
                {
                    data.map((d,i) => <div key={i} className={styles.item__style}>
                        <p>{d}</p> <span onClick={() => removeItem(d)} className="material-icons" style={{ color: 'red' }}>close</span>
                    </div>)
                }
            </div>

        </div>
    )
})

export default function Deal({isOperation}) {
    const [tab, setTab] = useState(1)
    const isMounted = useRef()
    const [data, setData] = useState({
        sources: [],
        services: []
    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = 'Deal - Settings'

        if(isOperation) return

        const initFetch = async () => {
            if (isMounted.current) setIsLoading(true)
            const res = await fetchDealPreferences().catch(err => {
                if (isMounted.current) setError(err)
            })

            if (res) {
                if (isMounted.current) {
                    const { services, sources } = res.data()
                    setIsLoading(false)
                    setData({ services, sources })
                }
            }
        }
        initFetch()
        return () => isMounted.current = false
    }, [isOperation])

    const styleActive = {
        backgroundColor: 'var(--primary-purple)',
        color: 'white',
        transition: '120ms'
    }
    return (
        <>
            {isLoading && <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                <MiniSpinner wh={30} />
                <span>Loading...</span>
            </div>}
            {error && <p className="error-bar">{`${error.message} --code: ${error.code}`}</p>}
           {!isOperation ? <div style={{ display: 'flex', alignItems: 'flex-start' }}>

                <div className={styles.list__section}>

                    <ul>
                        <li style={tab === 1 ? styleActive : undefined} onClick={() => setTab(1)}>Deal Sources</li>
                        <li style={tab === 2 ? styleActive : undefined} onClick={() => setTab(2)}>Services</li>
                    </ul>

                </div>
                <div className={styles.cont__section}>
                    {
                        !isLoading && data ? tab === 1 ? <DealSource setData={setData} data={data.sources} /> : <Services setData={setData} data={data.services} /> : null
                    }
                </div>
            </div> :
            <NoPerm/>
            }
        </>
    )
}
