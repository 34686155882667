import { useEffect, useRef, useState, forwardRef } from 'react'
import { getCompanyInfo, updateCompanyInfo } from '../../controllers/appSettingsController'
import { nullifyEmptyInput } from '../helper'
import styles from './settingsComp.module.css'
import NoPerm from '../../components/ErrorDialog/NoPerm'

const InputComp = forwardRef(({ label, defValue, setData, field }, ref) => {
    const [enable, setEnable] = useState(false)
    const saved = useRef(false)
    const noti = useRef()

    const onEdit = (e) => {

        if (!enable) {//React delay should be 'enable'
            setEnable(!enable)
            e.target.textContent = 'close'
            e.target.classList.toggle(styles.active)
        } else {
            const value = nullifyEmptyInput(ref.current)
            if (!saved.current && (value === null || value !== defValue)) {
                noti.current.textContent = 'Input changed and not saved!'

                setTimeout(() => {
                    noti.current.textContent = ''
                }, 1000);
            } else {
                e.target.classList.toggle(styles.active)
                e.target.textContent = 'edit'
                setEnable(!enable)
                if (saved.current) {
                    saved.current = false
                }
            }
        }
    }

    const onUpdate = async () => {
        const value = nullifyEmptyInput(ref.current)
        if (value !== null)
            await updateCompanyInfo(field, value).catch(err => {
                console.error(err)
                noti.current.textContent = err.code
                return
            })
        noti.current.textContent = 'Saved !'
        saved.current = true
        setData(prev => ({ ...prev, [field]: value }))

        setTimeout(() => {
            noti.current.textContent = ''
        }, 1000);
    }

    return (
        <div>
            <p>{label}:</p>
            <div className={styles.inputContainer}>
                <input disabled={!enable} ref={ref} type="text" placeholder={label} />
                <span onClick={onEdit} className={`material-icons`}>edit</span>
                <button onClick={onUpdate} style={{ visibility: enable ? 'visible' : 'hidden' }} className="btn-green">Save</button>
                <span style={{ visibility: enable ? 'visible' : 'hidden' }} ref={noti}></span>
            </div>
        </div>
    )
})

export default function Company({ isAdmin, isOperation }) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({
        address: null,
        email: null,
        name: null,
        note: null,
        officeNumber: null,
        regNumber: null
    })
    const isMounted = useRef(false)
    const inpOrganisationName = useRef()
    const inpEmail = useRef()
    const inpNote = useRef()
    const inpOfficeNumber = useRef()
    const inpRegNumber = useRef()
    const inpAddress = useRef()

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = 'Company - Settings'

        if (isAdmin || isOperation) return

        const fetch = async () => {
            if (isMounted.current) setIsLoading(true)
            const res = await getCompanyInfo().catch(err => {
                if (isMounted.current) {
                    setError(err)
                    setIsLoading(false)
                }
                return
            })

            if (res) {
                if (res.exists) {
                    if (isMounted.current) {
                        setData(res.data())
                        inpOrganisationName.current.value = res.get('name')
                        inpNote.current.value = res.get('note')
                        inpRegNumber.current.value = res.get('regNumber')
                        inpOfficeNumber.current.value = res.get('officeNumber')
                        inpEmail.current.value = res.get('email')
                        inpAddress.current.value = res.get('address')
                    }
                }
            }
            setIsLoading(false)
        }
        fetch()
        return () => isMounted.current = false
    }, [isAdmin, isOperation])

    return (
        <div>

          {!isAdmin && !isOperation ?  <>
                <div className={styles.titleCont}>
                    {isLoading && <div className={styles.miniSpinner}></div>}
                    <h2 className={styles.title}>Company Information</h2>
                </div>
                {error && <p className="error-bar">{`${error.message} --code: ${error.code}`}</p>}
                <h3 className={styles.subtitle}>Basic Info</h3>
                <p style={{ marginBottom: '10px', fontStyle: 'italic' }}>This information will be used inside forms and letters.</p>
                <div>
                    <InputComp
                        ref={inpOrganisationName}
                        label="Organisation name"
                        defValue={data.name}
                        setData={setData}
                        field="name"
                    />
                    <InputComp
                        ref={inpNote}
                        label="Note (eg: Previously known as / also known as ,etc.)"
                        defValue={data.note}
                        setData={setData}
                        field="note"
                    />
                    <InputComp
                        ref={inpRegNumber}
                        label="Registration number"
                        defValue={data.regNumber}
                        setData={setData}
                        field="regNumber"
                    />
                    <InputComp
                        ref={inpOfficeNumber}
                        label="Office phone number"
                        defValue={data.officeNumber}
                        setData={setData}
                        field="officeNumber"
                    />
                    <InputComp
                        ref={inpEmail}
                        label="Email"
                        defValue={data.email}
                        setData={setData}
                        field="email"
                    />
                    <InputComp
                        ref={inpAddress}
                        label="Full address"
                        defValue={data.address}
                        setData={setData}
                        field="address"
                    />

                </div>
            </> : 
            <NoPerm/>}
        </div>
    )
}
