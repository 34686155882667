import React, { useState, useRef } from "react"
import DialogTemplate from "../../../components/Dialogs/DialogTemplate"
import { nullifyEmptyInput } from "../../helper"
import { PAYMENT_METHOD } from "../../configuration"
import Info from "../../../components/Dialogs/Info"
import { updateDeal } from '../../../controllers/dealController'
import { resetPayment } from "../../../controllers/paymentController"
import { updateProspectDeal } from "../../../controllers/prospectController"


function EditDealDialog({ prefs, deal, callback, onCancel }) {

    const PAYMENT_INFO = "You are about to change the payment method/value for this deal, changing payment method/value will result in resetting payment information. Any payment made for this deal will be lost."
    const { services, sources } = prefs
    const [isLoading,setIsLoading] = useState(false)
    const [infoToggle, setInfoToggle] = useState(false)
    const form = useRef(null)
    const errorBar = useRef(null)
    const [error, setError] = useState(null)
    const [title, setTitle] = useState(deal.dealTitle ?? '')
    const [source, setSource] = useState(sources[sources.indexOf(deal.dealSource)])
    const [service, setService] = useState(services[services.indexOf(deal.dealServices)])
    const [pm, setPm] = useState(PAYMENT_METHOD[PAYMENT_METHOD.indexOf(deal.paymentMethod)])
    const [locOverview, setLocOverview] = useState(deal.locationOverview ?? '')
    const [premiseType, setPremiseType] = useState(deal.premiseType)
    const [premiseSize, setPremiseSize] = useState(deal.premiseSize ?? '')
    const [bathroom, setBathroom] = useState(deal.bathroom ?? '')
    const [bedroom, setBedroom] = useState(deal.bedroom ?? '')
    const [pets, setPets] = useState(deal.pets)
    const [renovation, setRenovation] = useState(deal.renovation)
    const [dispose, setDispose] = useState(deal.dispose)
    const [furnishType, setFurnishType] = useState(deal.furnishType)
    const [remarks, setRemarks] = useState(deal.remarks ?? '')
    const [value, setValue] = useState(parseFloat(deal.value) ?? 0)

    const submitChanges = async () => {
        const data = form.current
        const formData = {
            dealTitle: nullifyEmptyInput(data.dealTitle),
            dealSource: data.dealSource.value,
            dealServices: data.dealServices.value,
            paymentMethod: data.paymentMethod.value,
            locationOverview: nullifyEmptyInput(data.locationOverview),
            premiseType: data.premiseType.value,
            premiseSize: nullifyEmptyInput(data.premiseSize),
            bathroom: nullifyEmptyInput(data.bathroom),
            bedroom: nullifyEmptyInput(data.bedroom),
            pets: data.pets.value,
            renovation: data.renovation.value,
            dispose: data.dispose.value,
            furnishType: data.furnishType.value,
            remarks: nullifyEmptyInput(data.remarks),
            value: nullifyEmptyInput(data.dealValue)
        }

        const prospectData = {
            title: nullifyEmptyInput(data.dealTitle),
            services: data.dealServices.value,
        }

        await updateProspectDeal(deal.pid,deal.id,prospectData).catch(err => handleError(err))
        await updateDeal(deal.id, formData).catch(err => handleError(err))
        return formData

    }

    const infoYes = async () => {
        await resetPayment(deal.id).catch(err => handleError(err))
        const data = await submitChanges()
        setInfoToggle(false)
        setIsLoading(false)
        callback(data)
    }

    const submit = async (e) => {
        e.preventDefault()
        const data = form.current
        setError(null)
        setIsLoading(true)
        if (deal.paymentMethod !== data.paymentMethod.value || deal.value !== nullifyEmptyInput(data.dealValue)) {
            setInfoToggle(true)
            return
        } else {
            const data = await submitChanges()
            setIsLoading(false)
            callback(data)
        }

    }

    const handleError = (err) => {
        setIsLoading(false)
        setError(err)
        setTimeout(() => {
            errorBar.current.scrollIntoView()
        }, 0);
    }

    const handleCancel = () => {
        setError(null)
        onCancel()
    }

    return <> <DialogTemplate toggle={true} title="Edit Deal" height="85vh">
        {error && <div ref={errorBar} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
        <p style={{ textAlign: "end" }}>Prospect related information can be edited in the Prospect section</p>
        <p style={{ textAlign: "end" }}><span className="red-asterisk">*</span> Required fields</p>
        <form ref={form} onSubmit={submit} className="layout-dialog__form">

            <label htmlFor="dealValue">Deal Value (RM)</label>
            <input onChange={e => setValue(e.target.value)} value={value} type="number" step="0.01" name="dealValue" id="dealValue" placeholder="Deal value" />

            <label htmlFor="dealTitle">Deal Title</label>
            <input onChange={e => setTitle(e.target.value)} value={title} type="text" name="dealTitle" id="dealTitle" placeholder="Deal title" />

            <label htmlFor="dealSource">Deal Source</label>
            <select onChange={e => setSource(e.target.value)} value={source} name="dealSource" id="dealSource">
                {sources ? sources.map(s => <option key={s} value={s}>{s}</option>) : <option value="unset">Loading</option>}
            </select>

            <label htmlFor="dealServices">Services</label>
            <select onChange={e => setService(e.target.value)} value={service} name="dealServices" id="dealServices">
                {services ? services.map(s => <option key={s} value={s}>{s}</option>) : <option value="unset">Loading</option>}
            </select>

            <label htmlFor="paymentMethod"><span className="red-asterisk">*</span> Payment Method</label>
            <select onChange={e => setPm(e.target.value)} value={pm} name="paymentMethod" id="paymentMethod" required>
                {PAYMENT_METHOD.map(pm => <option key={pm} value={pm}>{pm}</option>)}
            </select>

            <label htmlFor="locationOverview">Location Overview</label>
            <input onChange={e => setLocOverview(e.target.value)} value={locOverview} type="text" name="locationOverview" id="locationOverview" placeholder="Location overview" />

            <label htmlFor="premiseType"><span className="red-asterisk">*</span> Premise Type</label>
            <input onChange={e => setPremiseType(e.target.value)} value={premiseType} type="text" name="premiseType" id="premiseType" placeholder="Premise type" required />

            <label htmlFor="premiseSize">Premise Size (Sqft)</label>
            <input onChange={e => setPremiseSize(e.target.value)} value={premiseSize} type="text" name="premiseSize" id="premiseSize" placeholder="Premise size" />

            <label htmlFor="bathroom">No. of Bathroom</label>
            <input onChange={e => setBathroom(e.target.value)} value={bathroom} type="number" name="bathroom" id="bathroom" placeholder="No. of bathroom" />

            <label htmlFor="bedroom">No. of Bedroom</label>
            <input onChange={e => setBedroom(e.target.value)} value={bedroom} type="number" name="bedroom" id="bedroom" placeholder="No. of bedroom" />

            <label htmlFor="pets">Have Pets ?</label>
            <select onChange={e => setPets(e.target.value)} value={pets} name="pets" id="pets">
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>

            <label htmlFor="renovation">Is There Any Renovation/Paint ?</label>
            <select onChange={e => setRenovation(e.target.value)} value={renovation} name="renovation" id="renovation">
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>

            <label htmlFor="dispose">Dispose Furniture ?</label>
            <select onChange={e => setDispose(e.target.value)} value={dispose} name="dispose" id="dispose">
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>

            <label htmlFor="furnishType">Furnishing Type</label>
            <select onChange={e => setFurnishType(e.target.value)} value={furnishType} name="furnishType" id="furnishType">
                <option value="Empty">Empty</option>
                <option value="Partially Furnished">Partially Furnished</option>
                <option value="Fully Furnished">Fully Furnished</option>
            </select>

            <label htmlFor="remarks">Remarks</label>
            <textarea onChange={e => setRemarks(e.target.value)} value={remarks} name="remarks" id="remarks" cols="30" rows="10" placeholder="Remarks"></textarea>

            <div className="buttons-dialog__form">
                <button disabled={isLoading} type="button" className="btn-white"
                    onClick={handleCancel}>Cancel</button>
                <button disabled={isLoading} type="submit" className="btn-purple">
                    {isLoading ? "Saving..." : "Save"}
                </button>
            </div>
        </form>
    </DialogTemplate>

        {
            infoToggle &&
            <Info
                yesCallback={() => infoYes()}
                noCallback={() => { setIsLoading(false); setInfoToggle(false) }}
                infoText={PAYMENT_INFO}
            />
        }

    </>
}

export default EditDealDialog
