import React from 'react'
import Header from '../../components/Header/Header'
import './tracker.css'

function ListItem() {
    return (
        <div className="doc-inv-list-item">
            <span>Sop Directory</span>
            <div className="di-action">
                <button className="btn-blue">
                    <span className="material-icons">edit</span>
                </button>
            </div>
        </div>
    )
}

function SubHeader() {

    return (
        <div className="subheader">
            <button className="btn btn-green">
                <span className="material-icons">add</span>
                <span>Add New Directory</span>
            </button>

            <button className="btn btn-dark" style={{marginLeft:"1rem"}}>
                <span className="material-icons">archive</span>
                <span>View Archive</span>
            </button>

            <div className="search-input">
                <span className="material-icons">search</span>
                <input type="text" placeholder="Search" />
            </div>
        </div>
    )
}

export default function Tracker() {

    return (
        <div>
            <Header title="Tracker" />
            <SubHeader />
            <div className="tab-contents">
                <div className="doc-inv-container">
                    <div className="doc-inv-indicator">
                        <p>Trackers</p>
                        <p>Actions</p>
                    </div>
                    <div className="doc-inv-list">
                        <ListItem/>
                        <ListItem/>
                        <ListItem/>
                        <ListItem/>
                        <ListItem/>
                        <ListItem/>
                        <ListItem/>
                        <ListItem/>
                    </div>
                </div>
            </div>
        </div>
    )
}
