import React from 'react'
import styles from './wall.module.css'
import MiniSpinner from '../../../components/Loading/MiniSpinner'

export default function LoadingContainer({text}) {
    return (
        <div className={styles.loading__cont}>
            <MiniSpinner wh="30" />
            <p>{text}</p>
        </div>
    )
}
