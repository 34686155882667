import React from 'react'
import { useState, useEffect, useRef } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import UsersListDialog from '../../components/Dialogs/UsersListDialog'
import { useUsers } from '../../contexts/UsersContext'
import styles from './las.module.css'
import { setManager as updateManager, setEntitlement } from '../../controllers/lasController'
import { useLAS } from './LASContext'

export default function Configure({ onCancel, managers, setManagers, isCompleted }) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const marketingManager = useRef()
    const corporateManager = useRef()
    const operationManager = useRef()
    const itManager = useRef()
    const hrManager = useRef()
    const [toggleList, setToggleList] = useState('')
    const selected = useRef(null)
    const { users } = useUsers()
    const { ent, setEnt } = useLAS()

    const onChange = (dept) => {
        const title = `Set a ${dept} manager`
        setToggleList(title)
        selected.current = { dept }
    }

    const onSetCallback = async (id) => {

        setError(null)
        setIsLoading(true)

        await updateManager({ ...managers, [selected.current.dept]: id }).catch(err => {
            setError(err)
            setIsLoading(false)
            return
        })
        setManagers(id, selected.current.dept)

        setIsLoading(false)
        selected.current = null
    }

    const onFinish = async () => {
        setError(null)

        setIsLoading(true)
        await setEntitlement(ent).catch(err => {
            setIsLoading(false)
            setError(err)
            return
        })
        setIsLoading(false)

        if (!managers.marketing || !managers.corporate || !managers.operation
            || !managers.it || !managers.hr) {
            setError({ message: 'You missed something ! Please check again.', code: 'Unfinished' })
        } else {
            onCancel()
            isCompleted()
        }
    }

    useEffect(() => {

        if (users.length === 0) return

        const getName = (uid) => {
            return users.filter(u => u.id === uid)[0].name
        }

        marketingManager.current.innerText = managers.marketing ? getName(managers.marketing) : 'Not set'
        corporateManager.current.innerText = managers.corporate ? getName(managers.corporate) : 'Not set'
        operationManager.current.innerText = managers.operation ? getName(managers.operation) : 'Not set'
        itManager.current.innerText = managers.it ? getName(managers.it) : 'Not set'
        hrManager.current.innerText = managers.hr ? getName(managers.hr) : 'Not set'

    }, [managers, users])

    return (
        <DialogTemplate title='Configure' toggle={true} width={500} height='max-content'>
            <div>

                {toggleList && <UsersListDialog
                    callback={onSetCallback}
                    onCancel={() => setToggleList('')}
                    title={toggleList} />}

                {error && <div className={`error-bar`}>
                    <span className="material-icons">error</span>
                    <p>{error.message} --code: {error.code}</p>
                </div>}

                <div className={styles.confManager}>

                    <div style={{ justifyContent: 'flex-start', gap: '5px' }}>
                        <span className="material-icons">manage_accounts</span>
                        <h3>Set Managers</h3>
                    </div>

                    <div>
                        <p>Marketing Department</p>
                        <span ref={marketingManager}>Not set</span>
                        <button className="btn-orange" onClick={() => onChange('marketing')}>Change</button>
                    </div>
                    <div>
                        <p>Corporate Department</p>
                        <span ref={corporateManager}>Not set</span>
                        <button className="btn-orange" onClick={() => onChange('corporate')}>Change</button>
                    </div>
                    <div>
                        <p>Operation Department</p>
                        <span ref={operationManager}>Not set</span>
                        <button className="btn-orange" onClick={() => onChange('operation')}>Change</button>
                    </div>
                    <div>
                        <p>IT Department</p>
                        <span ref={itManager}>Not set</span>
                        <button className="btn-orange" onClick={() => onChange('it')}>Change</button>
                    </div>
                    <div>
                        <p>HR Department</p>
                        <span ref={hrManager}>Not set</span>
                        <button className="btn-orange" onClick={() => onChange('hr')}>Change</button>
                    </div>
                </div>

                <div className={styles.confManager}>

                    <div style={{ justifyContent: 'flex-start', gap: '5px' }}>
                        <span className="material-icons">manage_accounts</span>
                        <h3>Set Entitlement</h3>
                    </div>

                    <div>
                        <p>Leave</p>
                        <input type="number" onChange={(e) => setEnt(prev => ({ ...prev, leave: parseInt(e.target.value === '' ? '0' : e.target.value) }))} value={ent.leave.toString()} />
                    </div>

                    <div>
                        <p>MC</p>
                        <input type="number" onChange={(e) => setEnt(prev => ({ ...prev, mc: parseInt(e.target.value === '' ? '0' : e.target.value) }))} value={ent.mc.toString()} />
                    </div>

                </div>

                <div className='flex__row'>
                    <span style={{ color: 'red' }} className="material-icons">warning</span>
                    <p>Warning! Make sure to hit "Done" to save the configuration made.</p>
                </div>

                <div className="buttons-dialog__form" >
                    <button style={{ marginLeft: "auto", marginTop: '10px' }} disabled={isLoading} className="btn-green" onClick={onFinish}>Done</button>
                    <button style={{ marginTop: '10px' }} disabled={isLoading} className="btn-red" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </DialogTemplate>
    )
}
