import React, { useEffect, useRef, useState, useCallback } from 'react'
import ListLeft from './ListLeft'
import useFetchInfinite from '../../../hooks/useFetchInfinite'
import { activitiesRef } from '../../../controllers/dealController'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import styles from './activities.module.css'
import dayjs from 'dayjs'
import { openInNewTab, getOrigin } from '../../helper'
import ViewDialog from './ViewDialog'
import NoPerm from '../../../components/ErrorDialog/NoPerm'

const NoActivity = ({ filter }) => {

    let text;
    switch (filter) {
        case "Overdue":
            text = "No overdued activity yet."
            break;
        case "Upcoming":
            text = "No today and new upcoming activity yet."
            break;
        case "Cancelled":
            text = "No cancelled activity yet."
            break;
        case "Done":
            text = "No completed activity yet."
            break;

        default:
            text = "No new activity yet."
            break;
    }

    return (
        <div>
            <h3 style={{ textAlign: "center" }} >{text}</h3>
        </div>
    )
}

const Card = React.forwardRef((props, ref) => {

    const data = props.data
    const setToggleDialog = props.setToggleDialog

    const status = data.path.includes("Lost") ? "Lost" :
        data.path.includes("Deleted") ? "Deleted" :
            data.path.includes("Completed") ? "Completed" : "Ongoing"

    data.status = status;

    const btnStyle = {
        borderRadius: "43px",
        justifyContent: "space-between"
    }

    const formatDate = (date) => {
        try {
            return dayjs(date.toDate()).format('DD/MM/YYYY')
        } catch (err) {
            return "N/A"
        }

    }

    const goToWall = () => {
        if (status === "Ongoing") {
            const did = data.path.split("Deals/")[1].split("/Activities")[0]
            openInNewTab(`${getOrigin()}/crm/wall/${did}`)
        } else {
            const did = data.path.split("/" + status + "/")[1].split("/Activities")[0]
            openInNewTab(`${getOrigin()}/crm/wall/${did}/${status}`)
        }
    }

    return (
        <div ref={ref} className="activity-card">
            <div className="event-info">
                <p>{data.dateCreated.toDate().toDateString()}</p>
                <p><span className="material-icons">calendar_today</span>{formatDate(data.start)} - {formatDate(data.end)}</p>
                <p><span className="material-icons">schedule</span>{data.time}</p>
                <p><span className="material-icons">timer</span>{data.duration} Hours</p>
            </div>
            <div className="activity-info">
                <div>
                    <h3>{data.title}</h3>
                    <p>{data.type} - {status}</p>
                    <p><span className="material-icons">person</span>{data.prospect ?? "N/A"}</p>
                    <div>
                        <p><span className="material-icons">place</span>{data.location ?? "N/A"}</p>
                        <p><span className="material-icons">cleaning_services</span>{data.services ?? "N/A"}</p>
                    </div>
                </div>
                <div>
                    <button onClick={goToWall} className="btn btn-purple" style={btnStyle}><span>Wall</span><span className="material-icons">arrow_forward</span></button>
                    <button onClick={() => setToggleDialog(data)} className="btn btn-blue" style={btnStyle}><span>View more</span><span className="material-icons">pageview</span></button>
                </div>
            </div>
        </div>
    )
})

const CardMemo = React.memo(Card);

export default function List({ isOperation }) {
    const isMounted = useRef(false)
    const [query, setQuery] = useState("");
    const { loading, error, list, lastDoc, setList } = useFetchInfinite(query);
    const observer = useRef();
    const FETCH_LIMIT = 10;
    const [toggleDialog, setToggleDialog] = useState(null)
    const [queryRef, setQueryRef] = useState(activitiesRef)
    const [filter, setFilter] = useState("All")

    const lastElementRef = useCallback(  // (*)
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && lastDoc) {
                    setQuery(queryRef.startAfter(lastDoc).limit(FETCH_LIMIT));
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, lastDoc, queryRef]
    );

    useEffect(() => {
        isMounted.current = true
        document.querySelector("title").textContent = "List - Activities"
        if (isOperation) return

        setList([])
        setQuery(queryRef.limit(FETCH_LIMIT));
        return () => isMounted.current = false
    }, [queryRef, setList,isOperation])

    return (
        <div className="activities-list">
            {!isOperation ? <>
                <ListLeft setFilter={setFilter} setQueryRef={setQueryRef} />
                <div className="right-side-container">
                    {list.map((item, i) =>
                        i === list.length - 1 ?
                            <CardMemo ref={lastElementRef} key={i} data={item} setToggleDialog={setToggleDialog} /> :
                            <CardMemo key={i} data={item} setToggleDialog={setToggleDialog} />
                    )
                    }
                    {
                        !loading && !error && list.length === 0 &&
                        <NoActivity filter={filter} />
                    }
                    {loading &&
                        <div className={styles.load__more}>
                            <MiniSpinner wh="40" />
                            <h3>Loading...</h3>
                        </div>
                    }
                    {error &&
                        <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>
                    }
                </div>
            </> :
            <NoPerm/>
        }
            {
                toggleDialog &&
                <ViewDialog
                    data={toggleDialog}
                    onCancel={() => setToggleDialog(null)}
                />
            }
        </div>
    )
}
