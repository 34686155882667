import { db } from "../firebase";

const completedDealRef = db.collection("Archive").doc("Deals").collection("Completed").orderBy("closingDate", "desc")
const lostDealRef = db.collection("Archive").doc("Deals").collection("Lost").orderBy("closingDate", "desc")

export const getMonthlyCompleted = (firstDay, lastDay) => {
    return completedDealRef.where('closingDate', '>=', firstDay).where('closingDate', '<=', lastDay).get()
}

export const getMonthlyLost = (firstDay, lastDay) => {
    return lostDealRef.where('closingDate', '>=', firstDay).where('closingDate', '<=', lastDay).get()
}

export const getMonthlyOngoing = (firstDay, lastDay) => {
    return db.collection("Deals").where('dateCreated', '>=', firstDay).where('dateCreated', '<=', lastDay).get()
}

export const getProspect = (pid) => {
    return db.collection("Prospects").doc(pid).get()
}

export const getMonthlyOngoingConfirmed = (firstDay, lastDay) => {
    return db.collection("Deals")
        .where('dateCreated', '>=', firstDay)
        .where('dateCreated', '<=', lastDay)
        .where('isConfirmedSale','==',true)
        .get()
}

export const getMonthlyPrefs = (month, year) => {
    return db.collection('Dashboard')
        .doc(`${month}_${year}`).get()
}

export const setMonthlyPrefs = (month, year, key, value) => {
    return db.collection('Dashboard')
        .doc(`${month}_${year}`).update({ [key]: value })
        .catch(err => {
            if (err.code === 'not-found')
                return db.collection('Dashboard')
                    .doc(`${month}_${year}`).set({ [key]: value }, { merge: true })
        })
}

const paymentRef = db.collection("Payment")
//nett sales
export const getFullyPaid = (firstday,lastday) => {
    return paymentRef
        .where("method","==","Full Payment")
        .where("fullyPaid.date",">=",firstday)
        .where("fullyPaid.date","<=", lastday)
        .get()
}

export const getCreditCard = (firstday,lastday) => {
    return paymentRef
        .where("method","==","Credit Card Payment")
        .where("fullyPaid.date",">=",firstday)
        .where("fullyPaid.date","<=", lastday)
        .get()
}

export const getDepositedDeposit = (firstday,lastday) => {
    return paymentRef
        .where("method","==","Deposit/Balance Payment")
        .where("deposit.date",">=",firstday)
        .where("deposit.date","<=", lastday)
        .get()
}

export const getDepositedPaid = (firstday,lastday) => {
    return paymentRef
        .where("method","==","Deposit/Balance Payment")
        .where("fullyPaid.date",">=",firstday)
        .where("fullyPaid.date","<=", lastday)
        .get()
}

export const getRecurring = (firstday,lastday,index) => {
    return paymentRef
        .where("method","==","Recurring Payment")
        .where(`${index}.date`,">=",firstday)
        .where(`${index}.date`,"<=", lastday)
        .get()
}
