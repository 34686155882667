export const getLeaveDuration = (start, end) => {
    let duration = 1;
    if (start.toDateString() === end.toDateString()) return duration;

    //Configure breakdays here
    let breakDays = 0;

    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        //0 means Sunday in JS
        if(new Date(d).getDay() === 0 ){
            breakDays++;
        }
    }

    let diff = end.getTime() - start.getTime();
    let daydiff = diff / (1000 * 60 * 60 * 24) + 1;

    return daydiff - breakDays;
}

export const validateDuration = (duration, leaves, type) => {

    if (type !== "medical") {
        const leaveBal = leaves.leaveEnt - leaves.leaveUsed
        return duration <= leaveBal
    }

    const mcBal = leaves.mcEnt - leaves.mcUsed;
    return duration <= mcBal

}