import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { getRfp, updateRfp } from '../../controllers/rfpController'
import styles from './rfp.module.css'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import { useUsers } from '../../contexts/UsersContext'
import { useAuth } from '../../contexts/AuthContext'
import { useReactToPrint } from 'react-to-print'

const Form = React.forwardRef(({ data, users }, ref) => {

    const getOwner = () => {
        return users.filter(u => u.id === data.createdBy)[0]?.name
    }

    const pageStyle = `
    @media print {
        @page {
            size: potrait;
            margin: 10mm !important;
        }
        th,td{
            font-size: 10pt;
            padding: 0 2mm !important;
        }
      }`;

    return (
        <div ref={ref}>
            <style>{pageStyle}</style>
            <div className={styles.form__header}>
                <img src="" alt="" />
                <div>
                    <h2>Request for Purchase</h2>
                    <p>{data.ticket}</p>
                </div>
            </div>
            <div style={{ margin: '10px 0' }} className="h-seperator"></div>
            <div className={styles.form__body}>
                <div>
                    <p><strong>Requester : </strong>{data.requester}</p>
                    <p><strong>Issued by : </strong>{getOwner()}</p>
                    <p><strong>Submission date : </strong>{data.dateCreated.toDate().toDateString()}</p>
                    <p><strong>Department / Division : </strong>{data.department}</p>
                    <p><strong>Category : </strong>{data.category}</p>
                    <p><strong>Purpose : </strong>{data.purpose}</p>
                </div>
                <div className="status">
                    {data.status}
                </div>
            </div>
            <table cellSpacing="0" cellPadding="0" className="table">
                <thead>
                    <tr>
                        <th style={{ minWidth: "0" }}>Num</th>
                        <th>Item(s)</th>
                        <th>Quantity(s)</th>
                        <th>Price (RM)</th>
                        <th>Subtotal (RM)</th>
                    </tr>
                </thead>
                <tbody>
                    {data.items.map((item, i) => (
                        <tr key={i}>
                            <td style={{ width: 'max-content' }}>{i + 1}</td>
                            <td>{item.item}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                            <td>{item.total}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h3 style={{ textAlign: 'end', marginTop: '10px' }}>Total Price : RM {data.totalPrice}</h3>
        </div>
    )
})

export default function ViewRfp() {
    const { currentUser } = useAuth()
    const { users } = useUsers()
    const { id } = useParams()
    const isMounted = useRef(false)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState(null)
    const role = useRef(0)
    const { view } = useParams()
    const printComp = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComp.current,
    });

    const onForward = async () => {
        await updateRfp(id, { forwards: true, status: "Forwarded" }).catch(err => {
            return setError(err)
        })
        setData(prev => ({ ...prev, status: "Forwarded", forwards: true }))
    }

    const onApprove = async () => {
        await updateRfp(id, { status: "Approved" }).catch(err => {
            return setError(err)
        })
        setData(prev => ({ ...prev, status: "Approved" }))
    }

    const onReject = async () => {
        await updateRfp(id, { status: "Rejected" }).catch(err => {
            return setError(err)
        })
        setData(prev => ({ ...prev, status: "Rejected" }))
    }
    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = 'View RFP'
        const init = async () => {
            setIsLoading(true)

            if (view !== '1') {
                const { claims } = await currentUser.getIdTokenResult()
                if (claims.RfpManager) {
                    role.current = 1
                } else if (claims.AccountManager) {
                    role.current = 2
                }
            }else{
                role.current = 0
            }

            const result = await getRfp(id).catch(err => {
                if (isMounted.current) {
                    setError(err)
                    setIsLoading(false)
                }
            })

            if (isMounted.current && result) {
                document.querySelector('title').textContent = result.get('ticket') + ' - View RFP'
                setIsLoading(false)
                setData({ ...result.data(), id: result.id })
            }
        }
        init()
        return () => isMounted.current = false
    }, [id, currentUser ,view])

    const actionCondition = (status, forwards, role) => {
        if (role === 1 && forwards === null && status !== "Approved" && status !== "Rejected") {
            return true
        } else if (role === 2 && status !== "Approved" && status !== "Rejected") {
            return true
        }
        return false
    }

    return (
        <div className={styles.form__parent}>
            <div className={styles.form}>
                {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                {isLoading && <div className={styles.form_loading}>
                    <MiniSpinner wh="40" />
                    <p>Loading RFP from...</p>
                </div>}

                {
                    data &&
                    <Form data={data} users={users} ref={printComp} />
                }

                { role.current !== 0 && <div className={styles.action}>
                    <button onClick={handlePrint} className="btn-blue">
                        <span className="material-icons">print</span>
                        <span>Print</span>
                    </button>
                    {
                        data?.status !== "Approved" &&
                        data?.status !== "Rejected" &&
                        role.current === 1 &&
                        data?.forwards === null &&
                        <button onClick={onForward} className="btn-orange">
                            <span className="material-icons">forward</span>
                            <span>Forward</span>
                        </button>}
                    {
                        data && actionCondition(data.status, data.forwards, role.current) &&
                        <>
                            <button onClick={onApprove} className="btn-green">
                                <span className="material-icons">admin_panel_settings</span>
                                <span>Approve</span>
                            </button>
                            <button onClick={onReject} className="btn-red">
                                <span className="material-icons">admin_panel_settings</span>
                                <span>Reject</span>
                            </button>
                        </>
                    }
                </div>}

            </div>
        </div>
    )
}
