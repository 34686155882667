import React from 'react'
import dayjs from 'dayjs'
import styles from './activities.module.css'
import { openInNewTab, getOrigin } from '../../helper'

export default function SearchHit({ hits }) {
    const handleClick = (e, id, path) => {
        e.stopPropagation()

        const status = path.includes("Completed") ? "Completed" :
            path.includes("Lost") ? "Lost" :
                path.includes("Deleted") ? "Deleted" :
                    "Ongoing"

        if (status === "Ongoing") {
            const did = path.split("Deals/")[1].split("/Activities")[0]
            openInNewTab(`${getOrigin()}/crm/wall/${did}`)
        } else {
            const did = path.split("/" + status + "/")[1].split("/Activities")[0]
            openInNewTab(`${getOrigin()}/crm/wall/${did}/${status}`)
        }
    }

    const getStatus = (path) => {
        return path.includes("Completed") ? "Completed" :
            path.includes("Lost") ? "Lost" :
            path.includes("Deleted") ? "Deleted" :
            "Ongoing"
    }
    return (
        <div >
            <ul>
                {hits.length === 0 && <p style={{ textAlign: "center", color: 'black' }}>No activity matched.</p>}
                {hits.map(hit => {
                    return (
                        <li onMouseDown={(e) => handleClick(e, hit.objectID, hit.path)} className={styles.hit} key={hit.objectID} >
                            <div>
                                <h4>{hit.title ?? "No title"}</h4>
                                <p><strong>{hit.type} - {getStatus(hit.path)}</strong></p>
                                <p>Start : {dayjs.unix(hit.start._seconds).format('DD-MM-YYYY')}</p>
                                <p>End : {hit.end && dayjs.unix(hit.end._seconds).format('DD-MM-YYYY')}</p>
                                <p>Time : {hit.time}</p>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
