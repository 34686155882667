import React from 'react'
import { useRef,useEffect } from 'react'
import reactDom from 'react-dom'
import { useUsers } from '../../contexts/UsersContext'
import styles from './userListDialog.module.css'

//This dialog will be rendered in 'modal' node

export default function UsersListDialog({ width = null, dialogStyles = null, title = "User List", height = "70vh", onCancel, callback }) {

    const { users } = useUsers()
    const list = useRef()

    if (dialogStyles === null) {
        dialogStyles = {
            width: "30%",
            padding: "0",
            borderRadius: "5px",
            overflow: "hidden"
        }
    }

    if (width) {
        dialogStyles.width = width
    }

    const defStyles = {
        padding: "2rem",
    }

    const headerStyles = {
        backgroundColor: "var(--primary-purple)",
        color: "white",
        fontWeight: "var(--medium)",
        fontSize: "1.2rem",
        padding: "1.2rem 2rem",
        display: 'flex',
        justifyContent: 'space-between',
        userSelect: 'none'
    }

    const onSearch = (e) => {
        const searchText = e.target.value.toLowerCase()
        const all = Array.from(list.current.querySelectorAll(`div > span`));
        all.forEach(span => span.parentElement.style.display = '')

        if(searchText === '') return 
    
        const filtered = all.filter(span => !span.innerText.toLowerCase().includes(`${searchText}`))
        filtered.forEach(span => span.parentElement.style.display = 'none')
    }

    useEffect(() => {
        if (users.length === 0) return
    }, [users])

    return reactDom.createPortal(
        <>
            <div className="dialog-overlay"></div>
            <div className="wpd" style={dialogStyles}>
                <div style={{ ...defStyles, ...headerStyles }}>
                    <p>{title}</p>
                </div>
                <div style={{ ...defStyles, overflow: "auto", height: `${height}`, maxHeight: "85vh", scrollBehavior: "smooth" }}>

                    <div className={styles.searchBox}>
                        <span className="material-icons">search</span>
                        <input placeholder='Search names...' onChange={onSearch} type="search" name="searchUser" id="searchUser" />
                    </div>

                    <div ref={list} className={styles.list}>
                        {users.map(u => (<div key={u.id} className={styles.userItem}>
                            <img src={u.pfpLink ?? './img/user_placeholder.png'} alt="pfp" />
                            <span data-name={u.name} >{u.name}</span>
                            <button className="btn-green" onClick={()=>{callback(u.id);onCancel()}}>Select</button>
                        </div>))}
                    </div>

                    <div style={{position:'fixed',bottom:'10px',right:'20px'}}>
                        <button style={{marginLeft:'auto',marginTop:'20px'}} className="btn-red" onClick={onCancel}>Cancel</button>
                    </div>

                </div>
            </div>
        </>,
        document.getElementById("modal")
    )
}
