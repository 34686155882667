import React, { useState, useRef } from 'react'
import Header from '../../../components/Header/Header'
import Deals from './Deals'
import LocationServices from './LocationServices'
import Summary from './Summary'
import styles from './dashboard.module.css'

function handleSwitch(ref) {
    document.querySelector('.tab.active').classList.remove("active")
    ref.current.classList.add("active")
}

function SubHeader({ setToggle }) {

    const deals = useRef()
    const locationService = useRef()
    const summary = useRef()

    return (
        <div className="subheader">
            <div className="tab active" ref={deals} onClick={(e) => {
                handleSwitch(deals)
                setToggle('deals')
            }}>
                <span className="material-icons">feed</span>
                <span>Deals</span>
            </div>

            <div className="tab " ref={locationService} onClick={(e) => {
                handleSwitch(locationService)
                setToggle('locationService')
            }}>
                <span className="material-icons">location_city</span>
                <span>Location & Services</span>
            </div>

            <div className="tab " ref={summary} onClick={(e) => {
                handleSwitch(summary)
                setToggle('summary')
            }}>
                <span className="material-icons">summarize</span>
                <span>Summary</span>
            </div>

            <div>
            </div>
        </div>
    )
}

export default function Dashboard() {
    const [toggle, setToggle] = useState('deals');

    return (
        <>
            <Header title="Dashboard" />
            <SubHeader setToggle={setToggle} />
            <div style={{ backgroundColor: 'white' }} className="tab-contents">
                <div className={styles.parentWrapper}>
                    {
                        toggle === "deals" ? <Deals /> :
                            toggle === "locationService" ? <LocationServices /> :
                                <Summary />
                    }
                </div>
            </div>
        </>
    )
}
