import React, { useRef, useState } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import { useUsers } from '../../contexts/UsersContext'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import { setRFPManager, removeRFPManager } from '../../controllers/usersController'
import { functions } from '../../firebase'

export default function SetManagerDialog({ onCancel, which, callback }) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const { users,setUsers } = useUsers()
    const currentManager = useRef()

    const title = which === "RfpManager" ? "Set Manager (2nd Level)" : "Set Account PIC"

    const onSet = async (id, email, name) => {
        setIsLoading(true)
        const role = which;
        const addRole = functions.httpsCallable('addRole');
        await addRole({ email, role }).catch(err => {
            setError(err)
            setIsLoading(false)
            return
        })

        await setRFPManager(id, role).catch(err => {
            setError(err)
            setIsLoading(false)
            return
        })

        if (currentManager.current) {
            const currManagerID = currentManager.current.dataset.id
            const currManagerEmail = currentManager.current.dataset.email

            await addRole({ email:currManagerEmail, role:"unset" }).catch(err => {
                setError(err)
                setIsLoading(false)
                return
            })

            await removeRFPManager(currManagerID, role).catch(err => {
                setError(err)
                setIsLoading(false)
                return
            })

            const modifiedUser = users.filter(u => u.id === currManagerID)[0]
            modifiedUser.role = modifiedUser.role.filter(r => r !== role)

            setUsers(prev => {
                const idx = prev.findIndex(d => d.id === modifiedUser.id)
                prev.splice(idx,1,modifiedUser)
                const newArr = Array.from(prev)
                return newArr
            })
        }

        const modifiedUser = users.filter(u => u.id === id)[0]
        modifiedUser.role.push(role)

        setUsers(prev => {
            const idx = prev.findIndex(d => d.id === modifiedUser.id)
            prev.splice(idx,1,modifiedUser)
            const newArr = Array.from(prev)
            return newArr
        })

        setIsLoading(false)
        callback(name, which)
        onCancel()
    }

    const dialogStyles = {
        width: "17%",
        padding: "0",
        borderRadius: "5px",
        overflow: "hidden"
    }

    const itemStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom:'10px'
    }

    return (
        <DialogTemplate dialogStyles={dialogStyles} title={title} toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}

            {
                isLoading &&
                <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center", gap: '10px', marginBottom: '10px' }}><MiniSpinner wh="25" /> Assigning...</div>
            }

            {users.map(u => <div style={itemStyle} key={u.id}>
                <p data-id={u.id} data-email={u.email} ref={u.role.includes(which) ? currentManager : undefined} >{u.name}</p>
                <button disabled={u.role.includes(which) || isLoading} onClick={() => onSet(u.id, u.email, u.name)} data-id={u.id} className="btn-orange">{isLoading ? "Loading..." : "Set"}</button>
            </div>)}

            <div className="buttons-dialog__form" >
                <button style={{ marginLeft: "auto" }} disabled={isLoading} className="btn-white" onClick={onCancel}>Cancel</button>
            </div>

        </DialogTemplate>
    )
}
