import React from 'react'
import { STAGES } from '../../configuration'
import styles from './wall.module.css'
import { updateDeal, setStageLog } from '../../../controllers/dealController'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'

const Stage = ({ setError, callback, currStage, stage, state }) => {

    const { id, status } = useParams()
    const { currentUser } = useAuth()

    let classNameBar = undefined
    let classNameRound = undefined

    if (state) {
        classNameBar = styles.bar__active
        classNameRound = styles.round__active
    } else {
        classNameBar = styles.bar__inactive
        classNameRound = styles.round__inactive
    }

    const handleClick = async () => {
        if(status !== undefined) return null
        if (stage === currStage) return null
        setError(null)
        await updateDeal(id, { stage }).catch(err => setError(err))
        await setStageLog(id, currentUser.uid, { from: currStage, to: stage }).catch(err => setError(err))
        callback(stage)
    }

    return (
        <>
            <div className={classNameBar}></div>
            <div className={classNameRound} onClick={handleClick}>
                <div><span className="material-icons">done</span></div>
                <p>{stage}</p>
            </div>
        </>
    )
}

function StageProgress({ setError, setDeal, stage }) {

    const { id, status } = useParams()
    const { currentUser } = useAuth()

    const callback = (newStage) => {
        setDeal(prev => ({ ...prev, stage: newStage }))
    }

    const resetStage = async () => {
        if(status !== undefined) return null
        if (stage === STAGES[0]) return null
        setError(null)
        await updateDeal(id, { stage:STAGES[0] }).catch(err => setError(err))
        await setStageLog(id, currentUser.uid, { from: stage, to: STAGES[0] }).catch(err => setError(err))
        callback(STAGES[0])
    }

    return (
        <div className={styles.stageProgress}>
            <div className={styles.round__active} onClick={resetStage}>
                <div><span className="material-icons">done</span></div>
                <p>{STAGES[0]}</p>
            </div>
            {STAGES.map((s, idx) => {
                const currIdx = STAGES.indexOf(stage) + 1
                if (s === STAGES[0]) return null
                if (stage !== STAGES[0] && s !== STAGES[currIdx] && idx <= currIdx) {
                    return <Stage key={s} callback={callback} setError={setError} currStage={stage} stage={s} state={true} />
                } else {
                    return <Stage key={s} callback={callback} setError={setError} currStage={stage} stage={s} state={false} />
                }
            })}
        </div>
    )
}

export default React.memo(StageProgress)
