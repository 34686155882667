import { useState } from "react"
import { useParams } from "react-router-dom"
import DialogTemplate from "../../../components/Dialogs/DialogTemplate"
import { updateDeal } from "../../../controllers/dealController"
import { timestamp } from "../../../firebase"
import { setArchiveWithPath } from "../../../controllers/archiveController"
import { updateProspectDeal } from "../../../controllers/prospectController"

export default function DialogClosingDeal({ onCancel, title, setRedirect,pid }) {
    const {id} = useParams()
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        const path = `Archive/Deals/${title.type}/${id}`;
        const form = e.target
        const updateData = {
            status:title.type,
        }
        const setData = {
            closingRemarks: form.closingRemarks.value,
            closingDate: form.closingDate.valueAsDate ?? timestamp
        }

        await updateProspectDeal(pid,id,updateData).catch(err => {setLoading(false);setError(err)})
        await updateDeal(id,updateData).catch(err => {setLoading(false);setError(err)})
        await setArchiveWithPath(path,setData).catch(err => {setLoading(false);setError(err)})
        setRedirect(true)
        setLoading(false)
    }

    return (
        <DialogTemplate title={title.title} toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form" >
                <p>{title.message}</p>
                <p>(Optional) Leave unchanged if the date is today.</p>
                <input type="date" name="closingDate" id="closingDate" />
                <textarea required name="closingRemarks" id="closingRemarks" cols="30" rows="10" placeholder="Describe your action"></textarea>
                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { onCancel() }}
                    >Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
