import { db, arrayMethod } from "../firebase"
const ref = db.collection("Users");

export const getAllUser = () => {
    return db.collection("Users")
        .where("deleted","!=",true)
        .get()
}

export const getRFPUsers = () => {
    return db.collection("Users").where("role", "array-contains-any", ['RfpManager', 'AccountManager']).get()
}

export const setRFPManager = (id, value) => {
    return ref.doc(id).update({
        role: arrayMethod.union(value)
    });
}

export const removeRFPManager = (id, value) => {
    return ref.doc(id).update({
        role: arrayMethod.remove(value)
    });
}

export const setPfpLink = (uid, link) => {
    return ref.doc(uid).update({ pfpLink: link });
}

export const updateRole = (id,role) => {
    return ref.doc(id).update({role});
}
