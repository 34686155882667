import React from "react";
import ReactDOM from "react-dom";
import Logo from "../../logo.svg";
import styles from "./loading.module.css";

export default function Loading({ open, onClose }) {
  if (!open) return null;

  return ReactDOM.createPortal(
    <>
      <div
        className={styles.loading__overlay}
        onClick={() => {
          onClose();
        }}
      />
      <div className={styles.modal__loading}>
        <div className="init-spinner-wrapper">
          <div className="init-spinner"></div>
          <img src={Logo} alt="Logo" width="60px" height="60px" />
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
}
