import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import ForgotPassword from "../ForgotPassword";

function ErrorComp({ error }) {
  return (
    <div className="error-msg">
      <span className="material-icons">close</span>
      <p id="errorMsg">{error}</p>
    </div>
  );
}

export default function Login() {
  const topClosure = useRef();
  const botClosure = useRef();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  const { login } = useAuth();
  const history = useHistory();
  const [fp, setFp] = useState(false)

  useEffect(() => {
    // document.querySelector('nav').style.display = "none"
    document.querySelector('title').textContent = 'Login to BMS'
    setTimeout(() => {
      topClosure.current.classList.add("collapse");
      botClosure.current.classList.add("collapse");
    }, 200);

  }, []);

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login(email, pass);
      setLoading(false);
      history.push("/");
    } catch (error) {
      console.log("Error occured");
      setLoading(false);
      setError(error.message)
    }

  };

  const imgContainer = {
    width: '250px',
    height: '200px'
  }

  const appLogoContainer = {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '10px'
  }

  return (
    <>
      <div className="login-parent">
        <div style={appLogoContainer}>
          <img style={{ objectFit: 'contain' }} src="./img/app_logo_full.png" alt="app logo full" />
        </div>
        <div className="top-closure closure" ref={topClosure}></div>
        <div className="content">
          <div style={imgContainer}>
            <img style={{ objectFit: 'contain' }} height="100%" width="100%" src="./img/comp_logo_full.png" alt="company logo" id="companyLogo" />
          </div>
          <form className="login-form" onSubmit={onSubmitLogin}>
            {error && <ErrorComp error={error} />}
            <div>
              <span className="material-icons inp">person</span>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                required
                placeholder="Email"
              />
            </div>
            <div>
              <span className="material-icons inp">lock</span>
              <input
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                }}
                type="password"
                required
                placeholder="Password"
              />
            </div>
            <div onClick={() => setFp(true)} className="fp">
              <span className="material-icons">info</span>
              <span>Forgot Password ?</span>
            </div>
            <button className="btn btn-purple">
              {loading ? " Logging in..." : "Login"}
            </button>
          </form>
        </div>
        <div className="bottom-closure closure" ref={botClosure}></div>
      </div>

      {
        fp && 
        <ForgotPassword 
          onClose={()=>setFp(false)}
        />
      }
    </>
  );
}
