import React, { useRef, useEffect, useState } from 'react'
import DetailContainer from './DetailContainer'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import styles from './wall.module.css'
import { getPayment } from '../../../controllers/paymentController'
import FullPayment from './PaymentComponents/FullPayment'
import CreditCardPayment from './PaymentComponents/CreditCardPayment'
import BalancePayment from './PaymentComponents/BalancePayment'
import RecurringPayment from './PaymentComponents/RecurringPayment'

function PaymentContainer({ data }) {

    const { value, did, paymentMethod } = data
    const isMounted = useRef(false)
    const [paymentData, setPaymentData] = useState(null)

    useEffect(() => {
        isMounted.current = true
        async function fetchPM() {
            const result = await getPayment(did).catch(err => console.log(err))
            if (!result.exists) return setPaymentData(null)
            if (isMounted.current) setPaymentData(result.data())
        }
        fetchPM()
        return () => isMounted.current = false
    }, [paymentMethod,value, did])

    switch (paymentMethod) {
        case "Full Payment":
            return <FullPayment value={value} paymentData={paymentData} setPaymentData={setPaymentData} paymentMethod={paymentMethod}/>
        case "Deposit/Balance Payment":
            return <BalancePayment value={value} paymentData={paymentData} setPaymentData={setPaymentData} paymentMethod={paymentMethod}/>
        case "Recurring Payment":
            return <RecurringPayment value={value} paymentData={paymentData} setPaymentData={setPaymentData} paymentMethod={paymentMethod}/>
        case "Credit Card Payment":
            return <CreditCardPayment value={value} paymentData={paymentData} setPaymentData={setPaymentData} paymentMethod={paymentMethod}/>
        default:
            return (
                <DetailContainer title="Payment" editable={false}>
                    {
                        !paymentData &&
                        <div className={styles.loading__cont}>
                            <MiniSpinner wh="30" />
                            <p>Loading payment information..</p>
                        </div>
                    }
                </DetailContainer>
            )
    }

}

export default React.memo(PaymentContainer)
