import React, { useState } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import { updateStaff } from '../../controllers/externalStaffController'
import { nullifyEmptyInput } from '../helper'

export default function EditStaffDialog({ onCancel, callback, data }) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const form = e.target;

        const newdata = {
            fullName: form.fullName.value,
            phoneNumber: form.phoneNumber.value,
            type: form.type.value,
            accNumber: nullifyEmptyInput(form.accNumber),
            bank: nullifyEmptyInput(form.bank)
        }

        setIsLoading(true)
        await updateStaff(data.id,newdata).catch(err => {
            setError(err)
            return
        })

        setIsLoading(false)
        callback(data.id,newdata)

    }

    return (
        <DialogTemplate title="Add New Staff / Agent" toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <p style={{ textAlign: 'end' }} ><span className="red-asterisk">*</span> Required fields</p>
            <form onSubmit={handleSubmit} className="layout-dialog__form" >

                <label htmlFor="fullName">Full name <span className="red-asterisk">*</span> </label>
                <input defaultValue={data.fullName} type="text" name="fullName" id="fullName" required placeholder="Full name" />

                <label htmlFor="phoneNumber">Phone number <span className="red-asterisk">*</span></label>
                <input defaultValue={data.phoneNumber} type="number" name="phoneNumber" id="phoneNumber" required placeholder="Phone number" />

                <label htmlFor="type">Type</label>
                <select defaultValue={data.type} name="type" id="type">
                    <option value="Staff Permanent">Staff Permanent</option>
                    <option value="Staff Part-Time">Staff Part-Time</option>
                    <option value="Subcon">Subcon</option>
                    <option value="Agent">Agent</option>
                </select>

                <label htmlFor="accNumber">Account number</label>
                <input defaultValue={data.accNumber} type="number" name="accNumber" id="accNumber" placeholder="Account number" />

                <label htmlFor="bank">Bank</label>
                <input defaultValue={data.bank} type="text" name="bank" id="bank" placeholder="Bank" />

                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { setError(null); onCancel() }}
                    >Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Saving..." : "Save"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
