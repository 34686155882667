import React, { useEffect, useRef, useState } from 'react'
import { MONTHS } from '../../configuration'
import { useUsers } from '../../../contexts/UsersContext'
import NoResult from './NoResult'
import styles from './report.module.css'
import { db } from '../../../firebase'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import { CSVLink } from "react-csv";
import useSortableData from '../../../hooks/useSortableData'
import Tip from './Tip'
import { useReactToPrint } from 'react-to-print';

const Table = React.forwardRef(({ result }, ref) => {

    const { items, requestSort } = useSortableData(result);

    const onHighlight = (e) => {
        const target = e.currentTarget
        target.classList.toggle("highlight")
    }

    // const getClassNamesFor = (name) => {
    //     if (!sortConfig) {
    //         return;
    //     }
    //     return sortConfig.key === name ? sortConfig.direction : undefined;
    // }

    const pageStyle = `
    @media print {
        @page {
            size: landscape;
            margin: 10mm !important;
        }
        th,td{
            font-size: 10pt;
            padding: 0 2mm !important;
        }
      }`;

    return (
        <table ref={ref} id="table_report_deals" className={`table ${styles.table}`} cellSpacing="0" cellPadding="0">
            <style>{pageStyle}</style>
            <thead>
                <tr>
                    <th >Num.</th>
                    <th onClick={() => requestSort('status')}>Status</th>
                    <th onClick={() => requestSort('stage')}>Stage</th>
                    <th onClick={() => requestSort('name')}>Prospect</th>
                    <th onClick={() => requestSort('phoneNumber')}>Phone Number</th>
                    <th onClick={() => requestSort('owner')}>Owner</th>
                    <th onClick={() => requestSort('value')}>Value (RM)</th>
                    <th onClick={() => requestSort('paymentStatus')}>Payment Status</th>
                    <th onClick={() => requestSort('paymentMethod')}>Payment Method</th>
                    <th onClick={() => requestSort('dateCreated')}>Date Created</th>
                    <th onClick={() => requestSort('dealSource')}>Source</th>
                    <th onClick={() => requestSort('dealServices')}>Service</th>
                    <th onClick={() => requestSort('premiseType')}>Premise Type</th>
                    <th >Location Overview</th>
                </tr>
            </thead>
            <tbody>

                {
                    items.map((res, i) => <tr onClick={onHighlight} key={res.id}>
                        <td>{i + 1}</td>
                        <td>{res.status}</td>
                        <td>{res.stage}</td>
                        <td>{res.name}</td>
                        <td>{res.phoneNumber}</td>
                        <td>{res.owner}</td>
                        <td>{res.value ?? 0}</td>
                        <td>{res.paymentStatus}</td>
                        <td>{res.paymentMethod}</td>
                        <td>{res.dateCreated.toDateString()}</td>
                        <td>{res.dealSource}</td>
                        <td>{res.dealServices}</td>
                        <td>{res.premiseType}</td>
                        <td>{res.locationOverview ?? "N/A"}</td>
                    </tr>)
                }

            </tbody>
        </table>
    )
})

export default function Deals() {
    const { users } = useUsers()
    const isMounted = useRef(false)
    const [error, setError] = useState(null)
    const [years, setYears] = useState([])
    const [result, setResult] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const salesRep = useRef()
    const year = useRef()
    const month = useRef()
    const status = useRef()
    const excelResult = useRef([])

    const printComp = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComp.current,
    });

    const getOwner = (oid) => {
        return users.filter(u => u.id === oid)[0].name
    }

    const generate = async () => {

        const st = status.current.value
        const sr = salesRep.current.value
        const m = month.current.value
        const y = year.current.value
        let ref = null;
        let refCompleted = null;
        let refLost = null;

        if (st !== "all") {
            if (st === "Completed") {
                ref = db.collection("Archive/Deals/Completed")
            } else if (st === "Lost") {
                ref = db.collection("Archive/Deals/Lost")
            } else {
                ref = db.collection("Deals")
            }
        } else {
            refCompleted = db.collection("Archive/Deals/Completed")
            refLost = db.collection("Archive/Deals/Lost")
            ref = db.collection("Deals")
        }

        if (sr !== "all") {
            if (refCompleted) {
                refCompleted = refCompleted.where("owner", "==", sr)
            }
            if (refLost) {
                refLost = refLost.where("owner", "==", sr)
            }
            ref = ref.where("owner", "==", sr)
        }

        if (m !== "all") {
            const start = new Date(y, MONTHS.indexOf(m), 1)
            const end = new Date(y, MONTHS.indexOf(m) + 1, 0, 8)
            if (refCompleted) {
                refCompleted = refCompleted.where("dateCreated", ">=", start).where("dateCreated", "<=", end)
            }
            if (refLost) {
                refLost = refLost.where("dateCreated", ">=", start).where("dateCreated", "<=", end)
            }
            ref = ref.where("dateCreated", ">=", start).where("dateCreated", "<=", end)
        } else {
            const start = new Date(y, 0, 1)
            const end = new Date(y, 12, 0)
            if (refCompleted) {
                refCompleted = refCompleted.where("dateCreated", ">=", start).where("dateCreated", "<=", end)
            }
            if (refLost) {
                refLost = refLost.where("dateCreated", ">=", start).where("dateCreated", "<=", end)
            }
            ref = ref.where("dateCreated", ">=", start).where("dateCreated", "<=", end)
        }

        //Generating
        setIsFetching(true)
        let resCompleted = null;
        let resLost = null;
        let res = null
        let resultArray = []
        if (refCompleted) {
            resCompleted = await refCompleted.orderBy("dateCreated", "desc").get().catch(err => {
                setIsFetching(false)
                console.error(err)
                setError(err)
            })

            resultArray = resCompleted.docs.map(d => ({ ...d.data(), id: d.id }))
        }

        if (refLost) {
            resLost = await refLost.orderBy("dateCreated", "desc").get().catch(err => {
                setIsFetching(false)
                console.error(err)
                setError(err)
            })

            resultArray = [...resultArray, ...resLost.docs.map(d => ({ ...d.data(), id: d.id }))]
        }

        res = await ref.orderBy("dateCreated", "desc").get().catch(err => {
            setIsFetching(false)
            console.error(err)
            setError(err)
        })

        resultArray = [...resultArray, ...res.docs.map(d => ({ ...d.data(), id: d.id }))]

        resultArray.sort((a, b) => b.dateCreated.toDate() - a.dateCreated.toDate())

        resultArray = resultArray.map((data, i) => ({
            ...data,
            owner: getOwner(data.owner),
            dateCreated: data.dateCreated.toDate(),
            status: data.status !== "Completed" && data.status !== "Lost" ? "Ongoing" : data.status,
            num: i + 1
        }))

        excelResult.current = resultArray.map((r, idx) => ({
            Num: idx + 1,
            Status: r.status,
            Stage:r.stage,
            Prospect: r.name,
            Phone_Number: r.phoneNumber,
            Owner: r.owner,
            Value_RM: r.value,
            Payment_Status: r.paymentStatus,
            Payment_Method: r.paymentMethod,
            Date_Created: r.dateCreated.toDateString(),
            Source: r.dealSource,
            Service: r.dealServices,
            PremiseType: r.premiseType,
            Location_Overview: r.locationOverview
        }))

        setIsFetching(false)
        setResult(resultArray)
    }


    useEffect(() => {
        document.querySelector('title').textContent = 'Deals - Report'
        isMounted.current = true
        let initYear = new Date(Date.parse(localStorage.getItem("INIT_DATE"))).getFullYear()
        const currYear = new Date().getFullYear()
        const yearArr = []
        while (initYear <= currYear) {
            yearArr.push(initYear++);
        }
        setYears(yearArr)
        return () => isMounted.current = false
    }, [])

    return (
        <div>
            <div className="option-tab">
                <h3 className="option-tab-name">Deals</h3>
                <div className="v-seperator"></div>
                <label htmlFor="SalesRep">Sales Rep.: </label>
                <select ref={salesRep} name="SalesRep" id="SalesRep">
                    <option value="all">All</option>
                    {users.map(u => <option key={u.id} value={u.id}>{u.name}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="dealYear">Year: </label>
                <select ref={year} name="dealYear" id="dealYear">
                    {years.map(y => <option key={y} value={y}>{y}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="dealMonth">Month: </label>
                <select ref={month} name="dealMonth" id="dealMonth">
                    <option value="all">All</option>
                    {MONTHS.map(m => <option key={m} value={m}>{m}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="dealStatus">Deal status: </label>
                <select ref={status} name="dealStatus" id="dealStatus">
                    <option value="all">All</option>
                    <option value="Completed">Completed</option>
                    <option value="Lost">Lost</option>
                    <option value="Ongoing">Ongoing</option>
                    <option value="Unpaid">Unpaid</option>
                    <option value="Fully paid">Fully paid</option>
                </select>
                <div className="v-seperator"></div>
                <button onClick={generate} className="btn-green">
                    <span style={{ marginRight: "10px" }}>Generate</span>
                    {isFetching && <MiniSpinner wh={20} />}
                </button>
                {result.length > 0 &&
                    <>
                        <div className="v-seperator"></div>
                        <CSVLink data={excelResult.current} filename={`Deals_Report_${new Date().toString()}.csv`}>
                            <button className="btn-green">
                                <span>Export to MS Excel</span>
                            </button>
                        </CSVLink>
                        <div className="v-seperator"></div>
                        <button onClick={handlePrint} className="btn-white">
                            <span>Print report</span>
                        </button>
                    </>
                }
            </div>
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {result.length === 0 ?
                <NoResult /> :
                <div className={styles.container}>
                    <Tip />
                    <Table ref={printComp} result={result} />
                </div>
            }
        </div>
    )
}
