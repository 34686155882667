import React, { useEffect, useRef, useState, useCallback } from 'react'
import styles from './activities.module.css'
import { activitiesRefDef } from '../../../controllers/dealController'
import { MONTHS } from '../../configuration'
import { openInNewTab, getOrigin } from '../../helper'

const Grid = ( { data, isOperation } ) =>
{
    const [isHover, setIsHover] = useState( false )

    const onHover = ( e ) =>
    {
        e.stopPropagation()
        if ( e.currentTarget.scrollHeight - e.currentTarget.clientHeight > 6 ) setIsHover( true ) //offset 6 idk y
    }

    const onMouseLeave = ( e ) =>
    {
        e.stopPropagation()
        setIsHover( false )
    }

    const className = ( today ) =>
    {
        if ( isHover ) {
            if ( today ) return `${styles.gridBox} ${styles.active_grid} ${styles.today_grid} ${styles.isOverflowing}`
            return `${styles.gridBox} ${styles.active_grid} ${styles.isOverflowing}`

        } else {
            if ( today ) return ` ${styles.gridBox} ${styles.active_grid} ${styles.today_grid}`
            return `${styles.gridBox} ${styles.active_grid}`
        }
    }

    const grid = useCallback(
        ( node ) =>
        {
            if ( node !== null ) {
                const body = node.querySelector( 'div:first-child' )
                const moreInd = node.querySelector( 'div:last-child>span:first-child' )
                if ( body.scrollHeight - body.clientHeight > 6 ) {
                    const bodyHeight = body.clientHeight
                    let height = 0;
                    let remaining = 0
                    const items = body.querySelectorAll( `.${styles.item}` )
                    for ( let i = 0; i < items.length; i++ ) {
                        const item = items[i]
                        height += item.clientHeight + 8//8 is for margin
                        if ( height > bodyHeight ) {
                            remaining = items.length - i - 1
                            break;
                        }
                    }

                    moreInd.textContent = `+${remaining} more`
                } else {
                    moreInd.textContent = ""
                }
            }
        },
        [],
    )

    const onClickItem = ( event, path, status ) =>
    {

        if ( isOperation ) {
            event.preventDefault()
            return
        }

        if ( status === "Ongoing" ) {
            const did = path.split( "Deals/" )[1].split( "/Activities" )[0]
            openInNewTab( `${getOrigin()}/crm/wall/${did}` )
        } else {
            const did = path.split( "/" + status + "/" )[1].split( "/Activities" )[0]
            openInNewTab( `${getOrigin()}/crm/wall/${did}/${status}` )
        }
    }

    return (
        <div ref={ grid } className={ className( data.today ) } breakday={ data.breakDay ?? undefined } >
            <div onMouseLeave={ onMouseLeave } onMouseEnter={ onHover } className={ `${styles.body}` }>
                { data.activities.map( ( a ) =>
                {

                    const bgClr = a.status === "Completed" ? "var(--green)" :
                        a.status === "Lost" ? "var(--orange)" :
                            "var(--sky-blue)"

                    return <p onClick={ ( e ) => onClickItem( e, a.path, a.status ) } key={ a.id } className={ styles.item } style={ { backgroundColor: bgClr } }>
                        { a.title }
                    </p>
                } ) }
            </div>
            <div className={ `${styles.foot}` }>
                <span className="cal-more" style={ { color: 'black', opacity: isHover ? 0 : 1 } }></span>
                <span>{ data.day }</span>
            </div>
        </div>
    )
}

export default function GridCalendar( { date, setIsRendering, isOperation } )
{
    const isMounted = useRef( false )
    const [grid, setGrid] = useState( {
        prevMonth: [],
        currMonth: [],
        nextMonth: []
    } )



    useEffect( () =>
    {
        const month = date.m;
        const year = date.y
        isMounted.current = true

        const firstDay = new Date( year, month, 1 )
        const lastDay = new Date( year, month + 1, 0, 8 )//Firestore automatically assigned hours = 8
        let lastDayPrevMonth = new Date( year, month, 0 ).getDate()

        let prev = firstDay.getDay() - 1
        const next = 7 - lastDay.getDay()
        let prevArr = [], currArr = [], nextArr = []

        //if the first day of the month is Sunday
        //this month will have 6 prev days cuz the calendar starts with MON
        if ( prev === -1 ) {
            prev = 6;
        }

        for ( let i = 1; i < prev + 1; i++ ) {
            prevArr.splice( 0, 0, { day: lastDayPrevMonth-- } )
        }
        for ( let i = 1; i < lastDay.getDate() + 1; i++ ) {
            if ( new Date().getDate() === i && new Date().getFullYear() === year && new Date().getMonth() === month ) {
                currArr.push( { day: i, today: true, activities: [] } )
            } else {
                currArr.push( { day: i, activities: [] } )
            }
        }

        for ( let i = 1; next !== 7 && i < next + 1; i++ ) {
            nextArr.push( { day: i } )
        }

        // if (isMounted.current) setGrid({ prevMonth: prevArr, currMonth: currArr, nextMonth: nextArr })
        const fetchData = async () =>
        {
            setIsRendering( true )
            const startRes = await activitiesRefDef.where( "start", ">=", firstDay )
                .where( "start", "<=", lastDay ).where( 'hide', '==', false ).get().catch( err => console.error( err ) )
            const startArr = [...startRes.docs.map( d => ( { ...d.data(), id: d.id } ) )]

            const endRes = await activitiesRefDef.where( "end", ">=", firstDay )
                .where( "end", "<=", lastDay ).where( 'hide', '==', false ).get().catch( err => console.error( err ) )
            const endArr = [...endRes.docs.map( d => ( { ...d.data(), id: d.id } ) )]

            //put a limit because this query does not have "year" limiter
            const inBetweenRes = await activitiesRefDef.where( "range", "array-contains", MONTHS[month] ).where( 'hide', '==', false ).limit( 100 ).orderBy( "dateCreated", "desc" ).get().catch( err => console.error( err ) )
            const inBetweenArr = [...inBetweenRes.docs.map( d => ( { ...d.data(), id: d.id } ) )]

            const all = [...startArr, ...endArr, ...inBetweenArr]
            let unique = []
            all.forEach( a => !unique.map( u => u.id ).includes( a.id )
                && a.status !== "Lost"
                && a.status !== "Deleted" ? unique.push( a ) : null )

            unique.forEach( item =>
            {
                if ( item.end === null ) {
                    const idx = item.start.toDate().getDate();
                    //date start with 1 but index start with 0
                    currArr[idx - 1].activities.push( item )

                } else if ( item.range && item.range.length !== 0 &&
                    item.range.includes( MONTHS[month] ) &&
                    ( year >= item.start.toDate().getFullYear() && year <= item.end.toDate().getFullYear() ) &&
                    ( month >= item.start.toDate().getMonth() && month <= item.end.toDate().getMonth() ) ) {
                    for ( let i = 0; i < lastDay.getDate(); i++ ) {
                        currArr[i].activities.push( item )
                    }
                } else if ( item.end !== null && item.start != null ) {
                    const start = item.start.toDate()
                    const end = item.end.toDate()
                    for ( let d = start; d <= end; d.setDate( d.getDate() + 1 ) ) {
                        if ( d.getMonth() === month && d.getFullYear() === year ) {
                            currArr[new Date( d ).getDate() - 1].activities.push( item )
                        }
                    }
                }
            } )

            if ( isMounted.current ) {

                //Set break day to different color
                //Chnage the number accordingly
                currArr.forEach( ( obj ) =>
                {
                    if ( new Date( year, month, obj.day ).getDay() === 5 ) {
                        obj.breakDay = 'true';//need to be String to be able to use in css
                    }
                } )

                setIsRendering( false )
                setGrid( { prevMonth: prevArr, currMonth: currArr, nextMonth: nextArr } )
            }

        }
        fetchData()


        return () => isMounted.current = false
    }, [date, setIsRendering] )

    return (
        <div>
            <div className={ `${styles.grid_header} ${styles.grid}` }>
                <h3>MON</h3>
                <h3>TUE</h3>
                <h3>WED</h3>
                <h3>THU</h3>
                <h3>FRI</h3>
                <h3>SAT</h3>
                <h3>SUN</h3>
            </div>
            <div className={ styles.grid }>

                {
                    grid.prevMonth.map( grid => <div key={ grid.day } className={ `${styles.gridBox} ${styles.inactive_grid}` }>{ grid.day }</div> )
                }

                {
                    grid.currMonth.map( ( grid, idx ) => <Grid isOperation={ isOperation } key={ idx } data={ grid } /> )
                }

                {
                    grid.nextMonth.map( grid => <div key={ grid.day } className={ `${styles.gridBox} ${styles.inactive_grid}` }>{ grid.day }</div> )
                }

            </div>
        </div>
    )
}
