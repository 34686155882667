import React, { useState, useEffect } from 'react'
import DetailContainer from '../DetailContainer'
import styles from '../wall.module.css'
import PaymentConfDialog from './PaymentConfDialog'
import { updatePayment, setPayment } from '../../../../controllers/paymentController'
import { timestamp, convertTimestamp } from '../../../../firebase'
import { useParams } from 'react-router-dom'

export default function BalancePayment({ value, paymentData, paymentMethod, setPaymentData }) {

    const { id,status } = useParams()
    const [isOpenPaid, setIsOpenPaid] = useState(false)
    const [isOpenDepo, setIsOpenDepo] = useState(false)
    const [checkedDepo, setCheckedDepo] = useState(paymentData?.deposit?.amount !== 0 && paymentData?.deposit?.amount !== undefined)
    const [checkedPaid, setCheckedPaid] = useState(paymentData?.fullyPaid?.amount !== 0 && paymentData?.fullyPaid?.amount !== undefined)

    const onCheckedDepo = async (e) => {
        setCheckedDepo(!checkedDepo)

        if (e.target.checked) {
            setIsOpenDepo(true)
        } else {
            const data = {
                method: paymentMethod,
                deposit: {
                    amount: 0,
                    date: null
                }
            }
            await updatePayment(id, data).catch(err => console.error(err))
            setPaymentData(prev => ({ ...prev, ...data }))
        }
    }

    const onCheckedPaid = async (e) => {
        setCheckedPaid(!checkedPaid)

        if (e.target.checked) {
            setIsOpenPaid(true)
        } else {
            const data = {
                method: paymentMethod,
                fullyPaid: {
                    amount: 0,
                    date: null
                }
            }
            await updatePayment(id, data).catch(err => console.error(err))
            setPaymentData(prev => ({ ...prev, ...data }))
        }
    }

    const onSubmitCallbackPaid = async (formData) => {
        const data = {
            method: paymentMethod,
            fullyPaid: {
                amount: calcBalance(),
                date: formData.date === null ? timestamp : convertTimestamp(formData.date)
            }
        }

        await updatePayment(id, data).catch(({ code, message }) => {
            if (code === 'not-found') {
                return setPayment(id, data)
            } else {
                return {
                    error: {
                        code,
                        message
                    },
                    status: 'not-ok'
                }
            }
        })

        if (formData.date === null) data.fullyPaid.date = convertTimestamp(new Date())//avoid rendering date with undefined value
        setPaymentData(prev => ({ ...prev, ...data }))
        setIsOpenPaid(false)
        return {
            status: "ok"
        }
    }

    const onSubmitCallbackDepo = async (formData) => {
        const data = {
            method: paymentMethod,
            deposit: {
                amount: formData.amount,
                date: formData.date === null ? timestamp : convertTimestamp(formData.date)
            }
        }

        await updatePayment(id, data).catch(({ code, message }) => {
            if (code === 'not-found') {
                return setPayment(id, data)
            } else {
                return {
                    error: {
                        code,
                        message
                    },
                    status: 'not-ok'
                }
            }
        })

        if (formData.date === null) data.deposit.date = convertTimestamp(new Date())//avoid rendering date with undefined value
        setPaymentData(prev => ({ ...prev, ...data }))
        setIsOpenDepo(false)
        return {
            status: "ok"
        }
    }

    const calcBalance = () => {
        const depo = paymentData?.deposit?.amount || 0
        const fullyPaid = paymentData?.fullyPaid?.amount || 0
        return parseFloat(value) - parseFloat(depo) - parseFloat(fullyPaid)
    }

    useEffect(() => {
        setCheckedPaid(paymentData?.fullyPaid?.amount !== 0 && paymentData?.fullyPaid?.amount !== undefined)
        setCheckedDepo(paymentData?.deposit?.amount !== 0 && paymentData?.deposit?.amount !== undefined)
    }, [paymentData])

    return (
        <>
            <DetailContainer title="Payment" editable={false}>
                <div className={styles.row}><p >Payment method can be change inside deal information section.</p></div>
                <div className={styles.row}><h4>{paymentMethod}</h4></div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={checkedPaid || status !== undefined} checked={checkedDepo} onChange={onCheckedDepo} type="checkbox" name="deposit" id="deposit" />
                        <label htmlFor="deposit">Deposited</label>
                    </div>
                    <p>RM {paymentData?.deposit?.amount || '0'}</p>
                    <p>{paymentData?.deposit?.date?.toDate().toDateString()}</p>
                </div>
                <div className={styles.row}>
                    <div className="flex__row">
                        <input disabled={status !== undefined} checked={checkedPaid} onChange={onCheckedPaid} type="checkbox" name="depoPaid" id="depoPaid" />
                        <label htmlFor="depoPaid">Fully paid</label>
                    </div>
                    <p>RM {paymentData?.fullyPaid?.amount || '0'}</p>
                    <p>{paymentData?.fullyPaid?.date?.toDate().toDateString()}</p>
                </div>
                <p>Remaining value : RM {calcBalance()}</p>
            </DetailContainer>

            {
                isOpenPaid &&
                <PaymentConfDialog
                    callback={onSubmitCallbackPaid}
                    onCancel={() => { setCheckedPaid(false); setIsOpenPaid(false) }}
                />
            }

            {
                isOpenDepo &&
                <PaymentConfDialog
                    amountLimit={value}
                    which={1}
                    callback={onSubmitCallbackDepo}
                    onCancel={() => { setCheckedDepo(false); setIsOpenDepo(false) }}
                />
            }
        </>
    )
}
