import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header/Header'
import './docInv.css'
import { db, storage } from '../../firebase'
import { openInNewTab, getOrigin } from '../helper'
import DirectoryName from './DirectoryName'
import EditDirName from './EditDirName'
import Info from '../../components/Dialogs/Info'
import {getDirectory} from '../../controllers/documentInventoryController'

function ListItem({ name, dir, setRename, firestore }) {

    const [info, toggleInfo] = useState(false)
    const infoText = `Are you sure to delete this Directory and its entire files ?\nThis action cannot be undone !`

    const handleEdit = (e) => {
        e.stopPropagation()
        setRename({ id: dir, currName: name })
    }

    const handleDelete = (e) => {
        e.stopPropagation()
        toggleInfo(true)
    }

    const yesCallback = async (setIsLoading) => {
        setIsLoading(true);

        const storageRef = storage.ref();
        const listRef = storageRef.child('DocumentInventory/' + dir);

        const res = await listRef.listAll().catch((error) => {
            console.error(error)
        });

        const itemsRef = res.items.map(item => storageRef.child(item.fullPath))

        Promise.all(itemsRef.map(ref => ref.delete()))
            .then(()=>{
                return firestore.delete()
            })
            .then(() => {
                return getDirectory(dir)
            })
            .then((res) => {
                return Promise.all(res.docs.map(doc => doc.ref.delete()))
            })
            .then(() => {
                setIsLoading(false)
                toggleInfo(false)
            })
            .catch(err => {
                setIsLoading(false)
                console.error(err)
            })
    }

    const noCallback = () => {
        toggleInfo(false)
    }

    return (
        <>
            <div onClick={() => openInNewTab(`${getOrigin()}/document-inventory/document/${dir}/${name}`)} className="doc-inv-list-item">
                <span className="folderName">{name}</span>
                <div className="di-action">
                    <button onClick={handleEdit} className="btn-blue">
                        <span className="material-icons">edit</span>
                    </button>
                    <button onClick={handleDelete} className="btn-red">
                        <span className="material-icons">delete</span>
                    </button>
                </div>
            </div>

            {info &&
                <Info
                    yesCallback={yesCallback}
                    noCallback={noCallback}
                    infoText={infoText}
                />
            }
        </>
    )
}

function SubHeader({ setToggleAddDirectory }) {


    const onSearch = (e) => {
        const container = document.getElementById("docInvCont");
        let txtValue, i;
        const filter = e.target.value.toUpperCase();
        const items = container.querySelectorAll(".doc-inv-list-item")

        for (i = 0; i < items.length; i++) {
            const span = items[i].querySelector(".folderName");
            if (span) {
                txtValue = span.textContent || span.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    items[i].style.display = "";
                } else {
                    items[i].style.display = "none";
                }
            }
        }
    }

    return (
        <div className="subheader">
            <button onClick={() => setToggleAddDirectory(true)} className="btn btn-green">
                <span className="material-icons">add</span>
                <span>Add New Directory</span>
            </button>

            <div className="search-input-only">
                <span className="material-icons">search</span>
                <input onChange={onSearch} type="text" placeholder="Search" />
            </div>
        </div>
    )
}

export default function DocumentInventory() {
    const isMounted = useRef(false)
    const [folderList, setFolderList] = useState([])
    const [error, setError] = useState(null)
    const [rename, setRename] = useState(null)
    const [toggleAddDirectory, setToggleAddDirectory] = useState(false)

    useEffect(() => {
        document.querySelector('title').textContent = 'Document Inventory - Business Management System'
        isMounted.current = true
        let unsub;
        const fetchFolders = async () => {
            unsub = db.collection("DocumentInventory").where("archived", "==", false).onSnapshot(snapshot => {
                setFolderList(snapshot.docs.map(r => ({ ...r.data(), prefix: r.id,ref:r.ref })))
            }, (err) => setError(err))
        }
        fetchFolders()
        return () => {
            isMounted.current = false;
            return unsub
        }
    }, [])

    return (
        <>
            <Header title="Document Inventory" />
            <SubHeader setToggleAddDirectory={setToggleAddDirectory} />
            <div className="tab-contents">
                <div className="doc-inv-container">
                    {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                    <div className="doc-inv-indicator">
                        <div style={{ display: 'flex', alignItems: "center", gap: "10px" }}><p>Directories</p></div>
                        <p>Actions</p>
                    </div>
                    {folderList.length === 0 && <p style={{ textAlign: 'center' }}>No directory yet... Create a new one by clicking on <strong>Add New Directory</strong> button.</p>}
                    <div id="docInvCont" className="doc-inv-list">
                        {folderList.map((f, i) => <ListItem key={i} name={f.name} dir={f.prefix} setRename={setRename} firestore={f.ref} />)}
                    </div>
                </div>
            </div>
            {
                toggleAddDirectory &&
                <DirectoryName
                    title="Add New Directory"
                    onCancel={() => setToggleAddDirectory(false)}
                />
            }

            {
                rename &&
                <EditDirName
                    defValue={rename}
                    onCancel={() => setRename(null)}
                />
            }
        </>
    )
}
