import React,{ useState, useRef, useEffect } from "react"
import DialogTemplate from "../../../components/Dialogs/DialogTemplate"
import styles from "./styles.module.css"
import { nullifyEmptyInput } from "../../helper"
import { fetchDealPreferences } from './database'
import { PAYMENT_METHOD, STATUS } from "../../configuration"
import { connectHits, connectSearchBox } from "react-instantsearch-dom"

const Hits = ({ hits, onClickCallback }) => {

    const nameStyle = {
        width:"200px",
        maxWidth:"200px"
    }
    const pNumStyle = {
        marginLeft:"auto"
    }
    return (<ul className={styles.suggest__list}>
        {hits.map(hit => (
            <li onClick={() => onClickCallback(hit)} className={styles.suggest__li} key={hit.objectID}>
                <p style={nameStyle}>{hit.name}</p>
                <span >{hit.phoneNumber}</span>
                <span style={pNumStyle}>{hit.organisation}</span>
            </li>
        ))}
    </ul>
    )
};

const SearchBoxPhoneNumber = ({ currentRefinement, refine,onChangeCallback }) => {
    const onBlur = () => {
        setTimeout(() => {
            document.getElementById("suggestionBoxDealDialog")
                .style.display = "none"
        }, 100);
    }

    const onFocus = () => {
        document.getElementById("suggestionBoxDealDialog")
            .style.display = "block"
    }

    const onChange = (event) => {
        refine(event.currentTarget.value)
        if(event.currentTarget.value === "") onChangeCallback()
    }

    return (<input
        autoComplete="none"
        onBlur={onBlur}
        onFocus={onFocus}
        type="number"
        name="phoneNumber"
        id="phoneNumber"
        placeholder="Phone number"
        onChange={onChange}
        value={currentRefinement}
        required />)
}

function SuggestionBox({onClickCallback}) {

    const CustomHits = connectHits(Hits);

    return (
        <div id="suggestionBoxDealDialog" style={{display:"none"}}>
            <CustomHits onClickCallback={onClickCallback} />
        </div>
    )
}

function DealDialog({ toggle, callback, isLoading, onCancel }) {

    const form = useRef(null)
    const errorBar = useRef(null)
    const [error, setError] = useState(null)
    const [sources, setSources] = useState([])
    const [services, setServices] = useState([])
    let pid = null;
    const SearchPhoneNumber = connectSearchBox(SearchBoxPhoneNumber)

    const pidCallback = (data) => {
        pid = data.objectID
        document.getElementById("phoneNumber").value = data.phoneNumber
        document.getElementById("custName").value = data.name
        document.getElementById("email").value = data.email
        document.getElementById("organisation").value = data.organisation
    }

    const onChangeSearchCallback = () => {
        pid = null
    }

    const submit = async (e) => {
        e.preventDefault()
        const data = form.current
        const formData = {
            name: data.custName.value,
            phoneNumber: data.phoneNumber.value,
            email: nullifyEmptyInput(data.email),
            organisation: nullifyEmptyInput(data.organisation),
            dealTitle: nullifyEmptyInput(data.dealTitle),
            dealSource: data.dealSource.value,
            dealServices: data.dealServices.value,
            paymentMethod: data.paymentMethod.value,
            locationOverview: nullifyEmptyInput(data.locationOverview),
            premiseType: data.premiseType.value,
            premiseSize: nullifyEmptyInput(data.premiseSize),
            bathroom: nullifyEmptyInput(data.bathroom),
            bedroom: nullifyEmptyInput(data.bedroom),
            pets: data.pets.value,
            renovation: data.renovation.value,
            dispose: data.dispose.value,
            furnishType: data.furnishType.value,
            remarks: nullifyEmptyInput(data.remarks),
            status: STATUS[0],
            value: nullifyEmptyInput(data.dealValue)
        }

        setError(null)
        const { status, code, message } = await callback(formData, pid)
        if (status !== "ok") {
            setError({
                code,
                message
            })
            //need delay after setting errors
            setTimeout(() => {
                errorBar.current.scrollIntoView()
            }, 0);
        }
    }

    const handleCancel = () => {
        setError(null)
        onCancel()
    }

    useEffect(() => {
        async function fetchPreferences(reject = false) {
            if (reject) return null
            const result = await fetchDealPreferences()
            const data = result.data()
            setServices(data.services)
            setSources(data.sources)
        }
        fetchPreferences()
        return fetchPreferences(true)
    }, [])

    return <DialogTemplate toggle={toggle} title="Create New Deal" height="85vh">
        {error && <div ref={errorBar} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
        <p style={{textAlign:"end"}}><span className="red-asterisk">*</span> Required fields</p>
        <p style={{textAlign:"end"}}> Enter phone number for prospect suggestion</p>
        <form ref={form} onSubmit={submit} className={styles.cnd__form}>
            <label htmlFor="custName"><span className="red-asterisk">*</span> Customer Name</label>
            <input type="text" name="custName" id="custName" placeholder="Customer name" required />

            <label htmlFor="phoneNumber"><span className="red-asterisk">*</span> Phone Number</label>
            <SearchPhoneNumber defaultRefinement="" onChangeCallback={onChangeSearchCallback}/>
            <SuggestionBox onClickCallback={pidCallback}/>

            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" placeholder="Email" />

            <label htmlFor="organisation">Organisation</label>
            <input type="text" name="organisation" id="organisation" placeholder="Organisation"/>

            <label htmlFor="dealValue">Deal Value (RM)</label>
            <input type="number" step="0.01" name="dealValue" id="dealValue" placeholder="Deal value" />

            <label htmlFor="dealTitle">Deal Title</label>
            <input type="text" name="dealTitle" id="dealTitle" placeholder="Deal title" />

            <label htmlFor="dealSource">Deal Source</label>
            <select name="dealSource" id="dealSource">
                {sources ? sources.map(s => <option key={s} value={s}>{s}</option>) : <option value="unset">Loading</option>}
            </select>

            <label htmlFor="dealServices">Services</label>
            <select name="dealServices" id="dealServices">
                {services ? services.map(s => <option key={s} value={s}>{s}</option>) : <option value="unset">Loading</option>}
            </select>

            <label htmlFor="paymentMethod"><span className="red-asterisk">*</span> Payment Method</label>
            <select name="paymentMethod" id="paymentMethod" required>
                {PAYMENT_METHOD.map(pm => <option key={pm} value={pm}>{pm}</option>)}
            </select>

            <label htmlFor="locationOverview">Location Overview</label>
            <input type="text" name="locationOverview" id="locationOverview" placeholder="Location overview" />

            <label htmlFor="premiseType"><span className="red-asterisk">*</span> Premise Type</label>
            <input type="text" name="premiseType" id="premiseType" placeholder="Premise type" required />

            <label htmlFor="premiseSize">Premise Size (Sqft)</label>
            <input type="number" name="premiseSize" id="premiseSize" placeholder="Premise size" />

            <label htmlFor="bathroom">No. of Bathroom</label>
            <input type="number" name="bathroom" id="bathroom" placeholder="No. of bathroom" />

            <label htmlFor="bedroom">No. of Bedroom</label>
            <input type="number" name="bedroom" id="bedroom" placeholder="No. of bedroom" />

            <label htmlFor="pets">Have Pets ?</label>
            <select name="pets" id="pets">
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>

            <label htmlFor="renovation">Is There Any Renovation/Paint ?</label>
            <select name="renovation" id="renovation">
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>

            <label htmlFor="dispose">Dispose Furniture ?</label>
            <select name="dispose" id="dispose">
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>

            <label htmlFor="furnishType">Furnishing Type</label>
            <select name="furnishType" id="furnishType">
                <option value="Empty">Empty</option>
                <option value="Partially Furnished">Partially Furnished</option>
                <option value="Fully Furnished">Fully Furnished</option>
            </select>

            <label htmlFor="remarks">Remarks</label>
            <textarea name="remarks" id="remarks" cols="30" rows="10" placeholder="Remarks"></textarea>

            <div className={styles.buttons}>
                <button disabled={isLoading} type="button" className="btn-white"
                    onClick={handleCancel}>Cancel</button>
                <button disabled={isLoading} type="submit" className="btn-purple">
                    {isLoading ? "Creating..." : "Create"}
                </button>
            </div>
        </form>
    </DialogTemplate>
}

export default DealDialog
