import { db } from "../firebase";

const ref = db.collection("Archive")

export const completedDealRef = ref.doc("Deals").collection("Completed").orderBy("closingDate","desc")
export const lostDealRef = ref.doc("Deals").collection("Lost").orderBy("closingDate","desc")
export const deletedDealRef = ref.doc("Deals").collection("Deleted").orderBy("closingDate","desc")

export const setArchiveWithPath = (docPath,data) => {
    return db.doc(docPath).set(data)
}

export const getArchiveWithPath = (docPath) => {
    return db.doc(docPath).get()
}

export const getArchiveCollectionWithPath = (collectionPath) => {
    return db.collection(collectionPath).get()
}

