import styles from './wall.module.css'

const Row = ({ left, right }) => {
    return (
        <>
            <div className={styles.row}>
                <p style={{minWidth:'200px'}}>{left}</p>
                <h4 style={{textAlign:"end"}}>{right ?? "-"}</h4>
            </div>
        </>
    )
}

export default Row