import React, { useState, useRef } from 'react'
import Header from '../../../components/Header/Header'
import CompletedTable from './CompletedTable'
import LostTable from './LostTable'
import SOTable from './SOTable'
import DeletedTable from './DeletedTable'

function handleSwitch(ref) {
    document.querySelector('.tab.active').classList.remove("active")
    ref.current.classList.add("active")
}

function SubHeader({ setToggle }) {

    const completedTab = useRef()
    const lostTab = useRef()
    const deletedTab = useRef()
    const soTab = useRef()

    return (
        <div className="subheader">
            <div className="tab active" ref={completedTab} onClick={(e) => {
                handleSwitch(completedTab)
                setToggle('completedTab')
            }}>
                <span className="material-icons">check_circle</span>
                <span>Completed Deals</span>
            </div>

            <div className="tab " ref={lostTab} onClick={(e) => {
                handleSwitch(lostTab)
                setToggle('lostTab')
            }}>
                <span className="material-icons">block</span>
                <span>Lost Deals</span>
            </div>

            <div className="tab " ref={deletedTab} onClick={(e) => {
                handleSwitch(deletedTab)
                setToggle('deletedTab')
            }}>
                <span className="material-icons">delete</span>
                <span>Deleted Deals</span>
            </div>

            <div className="tab " ref={soTab} onClick={(e) => {
                handleSwitch(soTab)
                setToggle('soTab')
            }}>
                <span className="material-icons">receipt</span>
                <span>Service Order</span>
            </div>
        </div>
    )
}

export default function Archive() {
    const [toggle, setToggle] = useState('completedTab');


    return (
        <>
            <Header title="Archive" />
            <SubHeader setToggle={setToggle} />

            <div className="tab-contents">
                {toggle === "completedTab" && <> <CompletedTable /></>}
                {toggle === "lostTab" && <>  <LostTable /></>}
                {toggle === "deletedTab" && <>  <DeletedTable /></>}
                {toggle === "soTab" && <>  <SOTable /></>}
            </div>
        </>
    )
}
