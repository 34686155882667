import React, { useState, useRef, useEffect, createRef } from 'react'
import { fetchRFPPrefs } from '../../controllers/appPreferencesController'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import { useAuth } from '../../contexts/AuthContext'
import { setRfp } from '../../controllers/rfpController'
import Success from '../../components/Dialogs/Success'

function Item({ itemCount }) {

    const [totalRef, setTotalRef] = useState([])
    const [qtyRef, setQtyRef] = useState([])
    const [priceRef, setPriceRef] = useState([])
    const items = []

    useEffect(() => {
        // add or remove refs
        setTotalRef(totalRef => (
            Array(itemCount).fill().map((_, i) => totalRef[i] || createRef())
        ));

        setQtyRef(qtyRef => (
            Array(itemCount).fill().map((_, i) => qtyRef[i] || createRef())
        ));

        setPriceRef(priceRef => (
            Array(itemCount).fill().map((_, i) => priceRef[i] || createRef())
        ));
    }, [itemCount])

    const setTwoNumberDecimal = (event) => {
        if (isNaN(event.target.value) || event.target.value === '') {
            event.target.value = 0
        }
        event.target.value = parseFloat(event.target.value).toFixed(2);
    }

    const setZero = (event) => {
        if (isNaN(event.target.value) || event.target.value === '') {
            event.target.value = 0
        }
    }

    const removeRow = (e) => {
        e.target.parentNode.parentNode.remove();
        const totalInputs = document.querySelectorAll('td>input.totalInput');
        document.getElementById('totalPriceRFP').textContent = Array.from(totalInputs).map(inp => parseFloat(inp.value)).reduce((a, b) => a + b, 0).toFixed(2)
    }

    const calculateTotal = (i) => {
        if (isNaN(qtyRef[i].current.value) || qtyRef[i].current.value === '') {
            qtyRef[i].current.value = 0
        }
        qtyRef[i].current.value = parseInt(qtyRef[i].current.value)
        totalRef[i].current.value = priceRef[i].current.value * qtyRef[i].current.value
        totalRef[i].current.value = parseFloat(totalRef[i].current.value).toFixed(2)

        const totalInputs = document.querySelectorAll('td>input.totalInput');
        document.getElementById('totalPriceRFP').textContent = Array.from(totalInputs).map(inp => parseFloat(inp.value)).reduce((a, b) => a + b, 0).toFixed(2)
    }


    for (let i = 0; i < itemCount; i++) {

        items.push(
            <tr key={i} style={{ height: '40px' }}>
                <td><input type="text" required /></td>
                <td><input ref={priceRef[i]} onChange={() => calculateTotal(i)} defaultValue={0} type="number" step="0.25" onBlur={setTwoNumberDecimal} required /></td>
                <td><input step={1} ref={qtyRef[i]} onChange={() => calculateTotal(i)} defaultValue={0} type="number" required onBlur={setZero} /></td>
                <td><input className="totalInput" ref={totalRef[i]} disabled defaultValue={0} type="number" step="0.25" required /></td>
                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit' }}><span
                    onClick={removeRow}
                    className="material-icons"
                    style={{ color: 'white', backgroundColor: 'red', borderRadius: '50%' }}>
                    remove
                </span>
                </td>
            </tr>
        )
    }

    return (
        <>
            {items}
        </>
    )
}

export default function Request() {

    const { currentUser } = useAuth()
    const tableBody = useRef()
    const totalPrice = useRef()
    const [addItem, setAddItem] = useState(0)
    const [departments, setDepartments] = useState([])
    const [category, setCategory] = useState([])
    const isMounted = useRef(false)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [success, setSuccess] = useState(false)
    const form = useRef()

    const addNewItemRow = () => {
        setAddItem(addItem + 1);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading("Submitting... Please wait")
        const form = e.target

        const keys = ["item", "price", "quantity", "total"]
        let items = []
        const rows = Array.from(tableBody.current.querySelectorAll('tr'))

        if (rows.length > 0) {
            items = rows.map(row => Array.from(row.querySelectorAll('td'))
                .filter(td => td.querySelector('input') !== null)
                .map(td => td.querySelector('input').value).reduce((a, v, i) => ({ ...a, [keys[i]]: v }), {}))
        }

        const data = {
            requester: form.reqName.value,
            department: form.dept.value,
            category: form.category.value,
            subject: form.subject.value,
            purpose: form.purpose.value,
            totalPrice: parseFloat(totalPrice.current.textContent).toFixed(2),
            items,
            reads: {
                self: false,
                manager: false,
                account: false
            },
            forwards: null
        }

        await setRfp(data, currentUser.uid).catch(err => {
            setError(err)
            setIsLoading(null)
        })

        setIsLoading(null)
        setSuccess(true)

    }

    const successCallback = () => {
        setSuccess(false)
        totalPrice.current.textContent = "0.00"
        form.current.reset()
    }

    useEffect(() => {
        document.querySelector('title').textContent = "Request - RFP"
        isMounted.current = true
        const fetchInit = async () => {
            setIsLoading("Loading departments and categories...")
            const res = await fetchRFPPrefs().catch(err => {
                if (isMounted.current) {
                    setIsLoading(null)
                    setError(err)
                }
            })

            if (isMounted.current) {
                setDepartments(res.get('departments'))
                setCategory(res.get('categories'))
                setIsLoading(null)
            }
        }
        fetchInit()
        return () => isMounted.current = false
    }, [])

    return (
        <>
            <div className="rfp-container">
                {isLoading && <div style={{ display: 'flex', alignItems: 'center', gap: '10px', paddingBottom: '10px' }}> <MiniSpinner wh="25" /> <p style={{ fontStyle: 'italic' }}>{isLoading}</p> </div>}
                {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                <form ref={form} className="rfp-form" onSubmit={handleSubmit}>
                    <h3>Make a new request</h3>
                    <label htmlFor="reqName">Requester Name</label>
                    <input type="text" name="reqName" id="reqName" required placeholder="Enter requestor name.." />
                    <label htmlFor="dept">Department/Division</label>
                    <select name="dept" id="dept" required>
                        {departments.map((dep, i) => <option value={dep} key={i}>{dep}</option>)}
                    </select>
                    <label htmlFor="category">Purchase Category</label>
                    <select name="category" id="category" required>
                        {category.map((cat, i) => <option value={cat} key={i}>{cat}</option>)}
                    </select>
                    <label htmlFor="subject">Subject</label>
                    <input type="text" name="subject" id="subject" required placeholder="Enter subject.." />
                    <label htmlFor="purpose">Purpose</label>
                    <textarea name="purpose" id="purpose" cols="30" rows="10" placeholder="State your purpose.." required></textarea>

                    <table className="table rfp" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Price (RM)</th>
                                <th>Quantity</th>
                                <th>Total (RM)</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody ref={tableBody}>
                            {addItem >= 0 && <Item itemCount={addItem} />}
                        </tbody>
                    </table>

                    <div className="h-seperator"></div>

                    <button disabled={isLoading} className="btn-green" type="button" onClick={addNewItemRow}>
                        <span className="material-icons">add</span>
                        <span>{isLoading ? "Wait..." : "Add Item"}</span>
                    </button>

                    <p style={{ fontWeight: 'bold' }}>TOTAL PRICE: RM <span ref={totalPrice} id="totalPriceRFP">0.00</span></p>
                    <button disabled={isLoading} className="btn-purple" type="submit">
                        <span>{isLoading ? "Wait..." : "Submit"}</span>
                    </button>

                </form>
            </div>
            {
                success &&
                <Success
                    toggle={true}
                    callback={successCallback}
                    infoText="Your request has been submitted successfully !"
                />
            }
        </>
    )
}
