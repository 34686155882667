import React, { useContext, useState } from 'react'

const LASContext = React.createContext()

export function useLAS() {
    return useContext(LASContext)
}

export default function LASProvider({children}) {
    const [ent, setEnt] = useState({
        leave: 14,
        mc: 14
    })
    
    const [managers, setManagers] = useState({
        marketing: null,
        corporate: null,
        operation: null,
        it: null,
        hr: null
    })

    const [leaves, setLeaves] = useState({
        leaveEnt: 0,
        leaveUsed: 0,
        mcEnt: 0,
        mcUsed: 0
    })

    const value = {
        ent,
        setEnt,
        managers,
        setManagers,
        leaves,
        setLeaves
    }

    return (
        <LASContext.Provider value={value}>
            {children}
        </LASContext.Provider>
    )
}
