import React, { useEffect, useRef, useState, useCallback, useContext } from 'react'
import { activitiesRefDef, updateActivityWithDocumentPath } from '../../../controllers/dealController'
import { getAllStaff, removeSchedule } from '../../../controllers/externalStaffController'
import { MONTHS } from '../../configuration'
import { openInNewTab, getOrigin, isOdd } from '../../helper'
import styles from './activities.module.css'
import StaffDialog from './StaffDialog'

export const ListActCtx = React.createContext()

const Staffs = ({ staffArray, title, customKey, actPath, aid, data, disable }) => {

    const { actList, isOperation } = useContext(ListActCtx)
    const currActivity = useRef(actList.filter(d => aid === d.id)[0])
    const dataStaff = currActivity.current.staffs?.[customKey]
    const [open, setOpen] = useState(false)
    const [staffAssigned, setStaffAssigned] = useState([])


    useEffect(() => {
        let arr = []
        if (dataStaff && dataStaff.length > 0) {
            dataStaff.forEach(data => {
                arr.push(staffArray.filter(d => d.id === data.SID)[0].fullName)
            })
        }
        setStaffAssigned(arr)
    }, [staffArray, dataStaff])

    return (
        <div>

            {
                open &&
                <StaffDialog
                    title={title}
                    staffArray={staffArray}
                    onCancel={() => setOpen(false)}
                    customKey={customKey}
                    actPath={actPath}
                    aid={aid}
                    data={data}
                />
            }


            <div style={{ paddingLeft: '20px', marginBottom: '10px' }}>
                <ul style={{ listStyleType: 'disc' }}>
                    {
                        staffAssigned.length > 0 ?
                            (
                                staffAssigned.map((name, idx) => <li key={idx}>{name}</li>)
                            ) :
                            (<li>No staff assigned</li>)
                    }
                </ul>
            </div>
            <div>
                <button disabled={isOperation || disable} onClick={() => setOpen(true)} style={{ padding: '5px 5px' }} className="btn-green">
                    <span style={{ fontSize: '1.2rem' }} className="material-icons">add</span>
                </button>
            </div>
        </div>
    )
}

const Row = ({ act, data, date, i, staffArray }) => {

    const { setActList, actList, isOperation } = useContext(ListActCtx)
    const day = data.day;
    const path = act.path + "/Activities/" + act.id
    const customKey = `${day}-${date.m}-${date.y}`
    const currActivity = useRef(actList.filter(d => act.id === d.id)[0])

    const statusStyles = (status) => {
        if (status === "Completed") {
            return {
                color: "var(--green)",
                fontWeight: "var(--medium)"
            }
        } else if (status === "Lost") {
            return {
                color: "var(--orange)",
                fontWeight: "var(--medium)"
            }
        } else {
            return {
                color: "var(--sky-blue)",
                fontWeight: "var(--medium)"
            }
        }
    }

    const todayRef = useCallback(
        (node) => {
            if (node !== null) {
                const firstNode = document.getElementsByName('today')[0]
                firstNode.scrollIntoView()
            }
        },
        [],
    )

    const trClass = (today, i) => {
        if (today) return `${styles.today}`
        if (isOdd(i)) return `${styles.oddStyles}`
    }

    let currTime = act.cus_times?.[customKey] ?? act.time
    let currDuration = act.cus_durations?.[customKey] ?? act.duration
    let currRemark = act.cus_remarks?.[customKey] ?? ''

    const onChangeTime = async (e) => {
        const newVal = e.target.value;
        if (newVal === currTime) return
        if (newVal === '') { e.target.value = currTime; return }

        let data = currActivity.current.cus_times
        let dataStaff = currActivity.current.staffs
        data === undefined && dataStaff === undefined ?
            data = {
                cus_times: { [customKey]: newVal },
                staffs: { [customKey]: [] }
            } : // Never set a custom data
            data = {
                cus_times: { ...data, [customKey]: newVal },
                staffs: { ...dataStaff, [customKey]: [] }
            } // Merge / Update existing

        await updateActivityWithDocumentPath(path, data)
            .catch(err => console.error(err))

        if (currActivity.current?.staffs?.[customKey])
            await Promise.all(currActivity.current.staffs[customKey].map(d => removeSchedule(d.path)))

        //Update state to match latest data, to make it accessible throughout this whole table
        currActivity.current.cus_times = data.cus_times
        currActivity.current.staffs = data.staffs
        setActList(prev => {
            const idx = prev.findIndex(e => e.id === currActivity.current.id)
            prev.splice(idx, 1, currActivity.current)
            const newArr = Array.from(prev)
            return newArr
        })

        currTime = newVal
    }

    const onChangeDuration = async (e) => {
        const newVal = e.target.value;
        if (newVal === currDuration) return
        if (newVal === '') { e.target.value = currDuration; return }

        let data = currActivity.current.cus_durations
        let dataStaff = currActivity.current.staffs
        data === undefined && dataStaff === undefined ?
            data = {
                cus_durations: { [customKey]: newVal },
                staffs: { [customKey]: [] }
            } : // Never set a custom data
            data = {
                cus_durations: { ...data, [customKey]: newVal },
                staffs: { ...dataStaff, [customKey]: [] }
            } // Merge / Update existing

        await updateActivityWithDocumentPath(path, data)
            .catch(err => console.error(err))

        if (currActivity.current?.staffs?.[customKey])
            await Promise.all(currActivity.current.staffs[customKey].map(d => removeSchedule(d.path)))

        //Update state to match latest data, to make it accessible throughout this whole table
        currActivity.current.cus_durations = data.cus_durations
        currActivity.current.staffs = data.staffs
        setActList(prev => {
            const idx = prev.findIndex(e => e.id === currActivity.current.id)
            prev.splice(idx, 1, currActivity.current)
            const newArr = Array.from(prev)
            return newArr
        })

        currDuration = newVal
    }

    const onChangeRemarks = async (e) => {
        const newVal = e.target.value;
        if (newVal === currRemark) return

        let data = currActivity.current.cus_remarks
        let dataStaff = currActivity.current.staffs
        data === undefined && dataStaff === undefined ?
            data = {
                cus_remarks: { [customKey]: newVal },
                staffs: { [customKey]: [] }
            } : // Never set a custom data
            data = {
                cus_remarks: { ...data, [customKey]: newVal },
                staffs: { ...dataStaff, [customKey]: [] }
            } // Merge / Update existing

        await updateActivityWithDocumentPath(path, data)
            .catch(err => console.error(err))

        if (currActivity.current?.staffs?.[customKey])
            await Promise.all(currActivity.current.staffs[customKey].map(d => removeSchedule(d.path)))

        //Update state to match latest data, to make it accessible throughout this whole table
        currActivity.current.cus_remarks = data.cus_remarks
        currActivity.current.staffs = data.staffs
        setActList(prev => {
            const idx = prev.findIndex(e => e.id === currActivity.current.id)
            prev.splice(idx, 1, currActivity.current)
            const newArr = Array.from(prev)
            return newArr
        })

        currRemark = newVal
    }

    const popupStyles = {
        fontSize: '13px',
        color: 'blue'
    }

    const goToWall = (e) => {

        if (isOperation) {
            e.preventDefault()
            return
        }

        if (act.status !== 'Ongoing') {
            const did = act.path.split(`Archive/Deals/${act.status}/`)[1].split('/Activities')[0]
            return openInNewTab(`${getOrigin()}/crm/wall/${did}/${act.status}`)
        }
        const did = act.path.split('Deals/')[1]
        return openInNewTab(`${getOrigin()}/crm/wall/${did}`)
    }

    const formattedDate = `${day} ${MONTHS[date.m]} ${date.y}`
    return (
        <tr name={data.today ? "today" : undefined} ref={data.today ? todayRef : undefined} className={trClass(data.today, i)}>
            <td style={{ maxWidth: "250px", minWidth: "100px" }}>
                {formattedDate} - <span style={statusStyles(act.status)} >{act.status}</span>
            </td>
            <td onClick={goToWall} style={{ fontWeight: "var(--medium)", maxWidth: "250px", minWidth: "250px", width: "250px" }}>
                {act.title}
                <span style={popupStyles} className="material-icons">open_in_new</span>
            </td>
            <td style={{ maxWidth: "110px", minWidth: "110px", width: "110px" }}>
                <input disabled={isOperation || act.status !== "Ongoing"} onBlur={onChangeTime} style={{ width: "100%" }} type="time" name="actTime" id="actTime" defaultValue={currTime} />
            </td>
            <td style={{ maxWidth: "100px", minWidth: "100px", width: "100px" }}>
                <input style={{ maxWidth: "70px", minWidth: "70px", width: "70px" }} disabled={isOperation || act.status !== "Ongoing"} onBlur={onChangeDuration} type="number" name="actDuration" id="actDuration" defaultValue={currDuration} />
            </td>
            <td>{act.prospect}</td>
            <td>{act.services}</td>
            <td>
                <textarea style={{ width: "100%" }} disabled={isOperation || act.status !== "Ongoing"} onBlur={onChangeRemarks} type="text" name="acRemark" id="actRemark" defaultValue={currRemark}></textarea>
            </td>
            <td>
                <Staffs
                    title={formattedDate + " - " + act.title}
                    staffArray={staffArray}
                    customKey={customKey}
                    actPath={path}
                    aid={act.id}
                    disable={act.status !== "Ongoing"}
                    data={{
                        remarks: currRemark,
                        duration: currDuration,
                        time: currTime,
                        title: act.title,
                        services: act.services,
                        prospect: act.prospect
                    }} />
            </td>
        </tr>
    )
}

function Table({ dataArray, date, staffArray }) {

    return (
        <table className="table" cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Time</th>
                    <th>Duration (Hrs)</th>
                    <th>Prospect</th>
                    <th>Services</th>
                    <th>Remarks</th>
                    <th>Assign Staff</th>
                </tr>
            </thead>
            <tbody>

                {dataArray.map((data, i) => data.activities.map(act => (
                    <Row key={act.id} data={data} act={act} date={date} i={i} staffArray={staffArray} />
                )))}


            </tbody>
        </table>
    )
}


export default function ListCalendar({ date, setIsRendering, isOperation }) {
    const isMounted = useRef(false)
    const [dataArray, setDataArray] = useState([])
    const [staffArray, setStaffArray] = useState([])
    const [actList, setActList] = useState([])

    useEffect(() => {
        isMounted.current = true
        setIsRendering(true)

        const year = date.y;
        const month = date.m;

        const firstDay = new Date(year, month, 1)
        const lastDay = new Date(year, month + 1, 0, 8)//Firestore automatically assigned hours = 8
        let currArr = []

        for (let i = 1; i < lastDay.getDate() + 1; i++) {
            if (new Date().getDate() === i && new Date().getFullYear() === year && new Date().getMonth() === month) {
                currArr.push({ day: i, today: true, activities: [] })
            } else {
                currArr.push({ day: i, activities: [] })
            }
        }

        const fetchStaff = async () => {
            if (isMounted.current) setIsRendering(true)
            const res = await getAllStaff().catch(err => console.error(err))

            if (res) {
                setStaffArray(res.docs.map(d => ({ id: d.id, ...d.data() })))
            }
        }

        const fetchData = async () => {
            setIsRendering(true)
            const startRes = await activitiesRefDef.where("start", ">=", firstDay)
                .where("start", "<=", lastDay).get().catch(err => console.error(err))
            const startArr = [...startRes.docs.map(d => ({ ...d.data(), id: d.id }))]

            const endRes = await activitiesRefDef.where("end", ">=", firstDay)
                .where("end", "<=", lastDay).get().catch(err => console.error(err))
            const endArr = [...endRes.docs.map(d => ({ ...d.data(), id: d.id }))]

            //put a limit because this query does not have "year" limiter
            const inBetweenRes = await activitiesRefDef.where("range", "array-contains", MONTHS[month]).limit(100).orderBy("dateCreated", "desc").get().catch(err => console.error(err))
            const inBetweenArr = [...inBetweenRes.docs.map(d => ({ ...d.data(), id: d.id }))]

            const all = [...startArr, ...endArr, ...inBetweenArr]
            let unique = []
            all.forEach(a => !unique.map(u => u.id).includes(a.id)
                && a.status !== "Lost"
                && a.status !== "Deleted"
                ? unique.push(a) : null)

            unique.forEach(item => {
                if (item.end === null) {
                    const idx = item.start.toDate().getDate();
                    //date start with 1 but index start with 0
                    currArr[idx - 1].activities.push(item)

                } else if (item.range && item.range.length !== 0 &&
                    item.range.includes(MONTHS[month]) &&
                    (year >= item.start.toDate().getFullYear() && year <= item.end.toDate().getFullYear()) &&
                    (month >= item.start.toDate().getMonth() && month <= item.end.toDate().getMonth())) {
                    for (let i = 0; i < lastDay.getDate(); i++) {
                        currArr[i].activities.push(item)
                    }
                } else if (item.end !== null && item.start != null) {
                    const start = item.start.toDate()
                    const end = item.end.toDate()
                    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
                        if (d.getMonth() === month && d.getFullYear() === year) {
                            currArr[new Date(d).getDate() - 1].activities.push(item)
                        }
                    }
                }
            })

            if (isMounted.current) {
                setActList(unique.map(({ id, cus_times, cus_durations, cus_remarks, staffs }) => ({ id, cus_times, cus_durations, cus_remarks, staffs })))
                setIsRendering(false)
                setDataArray(currArr)
            }
        }

        fetchStaff()
        fetchData()

        return () => isMounted.current = false
    }, [date, setIsRendering])

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <p style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "1rem" }}>
                    <span className="material-icons" style={{ color: "red" }}>info</span>Changing<strong>the time or the duration or the remarks</strong>of the job will remove all staff assigned.</p>
                <p>Hold down CTRL + F key to enter search mode.</p>
            </div>
            <ListActCtx.Provider value={{ setActList, actList, isOperation }}>
                <Table dataArray={dataArray} date={date} staffArray={staffArray} />
            </ListActCtx.Provider>
        </div>
    )
}
