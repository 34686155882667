import React, { useEffect, useRef, useState } from 'react'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import SetManagerDialog from './SetManagerDialog'
import { fetchRFPPrefs, removeArrayRfp, addArrayRfp } from '../../controllers/appPreferencesController'
import { getRFPUsers } from '../../controllers/usersController'
import styles from './rfp.module.css'
import { useAuth } from '../../contexts/AuthContext'
import { useUsers } from '../../contexts/UsersContext'

const sectionStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}

const DepartmentSection = ({ departments, setDepartments, setLoading, setError }) => {

    const newDept = useRef()

    const addItem = async () => {
        setError(null)
        const val = newDept.current.value
        if (val === '' || val === null) return
        setLoading("Adding new department...")

        await addArrayRfp("departments", val).catch(err => {
            setLoading(null)
            setError(err)
        })

        setLoading(null)
        newDept.current.value = ''
        setDepartments(prev => ([val, ...prev]))

    }

    const onRemove = async (which) => {
        setError(null)
        setLoading('Removing department...')
        await removeArrayRfp("departments", which).catch(err => {
            setLoading(null)
            setError(err)
        })

        setLoading(null)
        setDepartments(prev => {
            const newDept = prev.filter(p => p !== which)
            return newDept
        })
    }

    return (
        <div className={styles.settingSection}>
            <div style={sectionStyles}>
                <h4>Manage Departments / Division</h4>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <input ref={newDept} type="text" placeholder="Enter new department / division" />
                    <button onClick={addItem} className="btn-green"><span style={{ fontSize: "16px" }} className="material-icons">add</span></button>
                </div>
            </div>
            <table className="table" style={{ marginBottom: '10px' }}>
                <thead>
                    <tr>
                        <th>Departments / Division</th>
                        <th style={{ minWidth: '0px', width: "50px" }}>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {departments.map((dep, i) =>
                        <tr key={i}>
                            <td>{dep}</td>
                            <td style={{ minWidth: 'max-content' }}>
                                <button onClick={() => onRemove(dep)} style={{ padding: '5px' }} className="btn-red">
                                    <span className="material-icons" style={{ color: 'white', fontSize: '16px' }}>remove</span>
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

const CategorySection = ({ category, setCategory, setLoading, setError }) => {

    const newCat = useRef()

    const addItem = async () => {
        setError(null)
        const val = newCat.current.value
        if (val === '' || val === null) return
        setLoading("Adding new category...")

        await addArrayRfp("categories", val).catch(err => {
            setLoading(null)
            setError(err)
        })

        setLoading(null)
        newCat.current.value = ''
        setCategory(prev => ([val, ...prev]))

    }

    const onRemove = async (which) => {
        setError(null)
        setLoading('Removing category...')
        await removeArrayRfp("categories", which).catch(err => {
            setLoading(null)
            setError(err)
        })

        setLoading(null)
        setCategory(prev => {
            const newCat = prev.filter(p => p !== which)
            return newCat
        })
    }

    return (
        <div className={styles.settingSection}>
            <div style={sectionStyles}>
                <h4>Categories</h4>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <input ref={newCat} type="text" placeholder="New category" />
                    <button onClick={addItem} className="btn-green"><span style={{ fontSize: "16px" }} className="material-icons">add</span></button>
                </div>
            </div>
            <table className="table" style={{ marginBottom: '10px' }}>
                <thead>
                    <tr>
                        <th>Categories</th>
                        <th style={{ minWidth: '0px', width: "50px" }}>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {category.map((cat, i) =>
                        <tr key={i}>
                            <td>{cat}</td>
                            <td style={{ minWidth: 'max-content' }}>
                                <button onClick={() => onRemove(cat)} style={{ padding: '5px' }} className="btn-red">
                                    <span className="material-icons" style={{ color: 'white', fontSize: '16px' }}>remove</span>
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

const AccessDenied = () => {
    return (
        <div className={styles.access}>
            <span className="material-icons">admin_panel_settings</span>
            <div>
                <h3>Insufficient Permission</h3>
                <p>You need to be an <strong>Admin</strong> or <strong>Super Admin</strong> to view the Settings.</p>
            </div>
        </div>
    )
}

export default function Settings() {
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(null)
    const isMounted = useRef()
    const [departments, setDepartments] = useState([])
    const [category, setCategory] = useState([])
    const [manager, setManager] = useState(null)
    const accountManager = useRef()
    const rfpManager = useRef()
    const {currentUser} = useAuth()
    const {users} = useUsers()
    const [isOperation,setIsOperation] = useState(true)

    useEffect(() => {
        isMounted.current = true

        if(!currentUser || users.length === 0) return

        const user = users.filter(u => currentUser.uid === u.id)[0]

        if (user.role.includes("Operation")) {
          setIsOperation(true)
          return
        }else{
            setIsOperation(false)
        }


        const fetchInit = async () => {
            setLoading("Fetching settings... Please wait")

            const rfpUsers = await getRFPUsers().catch(err => {
                if (isMounted.current) {
                    setLoading(null)
                    setError(err)
                }
            })

            if (rfpUsers && rfpUsers.size > 0) {
                const managers = rfpUsers.docs.map(user => ({ id: user.id, ...user.data() }))
                rfpManager.current.textContent = managers.filter(d => d.role.includes("RfpManager"))[0]?.displayName ?? 'Not set'
                rfpManager.current.dataset.id = managers.filter(d => d.role.includes("RfpManager"))[0]?.id
                accountManager.current.textContent = managers.filter(d => d.role.includes("AccountManager"))[0]?.displayName ?? 'Not set'
                accountManager.current.dataset.id = managers.filter(d => d.role.includes("AccountManager"))[0]?.id
            }

            const res = await fetchRFPPrefs().catch(err => {
                if (isMounted.current) {
                    setLoading(null)
                    setError(err)
                }
            })

            if (isMounted.current) {
                setDepartments(res.get('departments'))
                setCategory(res.get('categories'))
                setLoading(null)
            }
        }

        fetchInit()
        return () => isMounted.current = false
    }, [users,currentUser])

    const setManagerCallback = (newManager, which) => {
        which === "RfpManager" ? rfpManager.current.textContent = newManager :
            accountManager.current.textContent = newManager
    }

    return (
        isOperation ? <AccessDenied/> : 
        <>
            <div className="rfp-container">
                {isLoading && <div style={{ display: 'flex', alignItems: 'center', gap: '10px', paddingBottom: '10px' }}> <MiniSpinner wh="25" /> <p style={{ fontStyle: 'italic' }}>{isLoading}</p> </div>}
                {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
                <h3 style={{ marginBottom: '10px' }}>Settings</h3>
                <DepartmentSection setError={setError} setLoading={setLoading} departments={departments} setDepartments={setDepartments} />
                <CategorySection setError={setError} setLoading={setLoading} category={category} setCategory={setCategory} />

                <div className={styles.settingSection}>
                    <h4>Set RFP Manager</h4>
                    <p style={{fontStyle:'italic'}}><span className="red-asterisk">*</span> It is recommended to re-login for the apps to apply changes.</p>
                    <div style={{ ...sectionStyles, marginBottom: '10px' }}>
                        <p ref={rfpManager}>Not set</p>
                        <button onClick={() => setManager('RfpManager')} className="btn-orange">
                            <span className="material-icons">person</span>
                            <span>Set Manager (2nd Level)</span>
                        </button>
                    </div>
                    <div style={sectionStyles}>
                        <p ref={accountManager}>Not set</p>
                        <button onClick={() => setManager('AccountManager')} className="btn-orange">
                            <span className="material-icons">person</span>
                            <span>Set Account PIC</span>
                        </button>
                    </div>
                </div>
            </div>

            {
                manager &&
                <SetManagerDialog
                    which={manager}
                    callback={setManagerCallback}
                    onCancel={() => setManager(null)}
                />
            }
        </>
    )
}
