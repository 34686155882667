import { db, timestamp } from "../firebase";
import { zeroPad } from "../pages/helper";

const rfpRef = db.collection('Rfp')

const setTicketRFP = () => {
    const ref = db.collection("AppPreferences").doc("RFP")
    return db.runTransaction((transaction) => {
        return transaction.get(ref).then((doc) => {
            if (!doc.exists) {
                throw new Error("Document does not exist!");
            }
            var newCount = doc.data().ticketCount + 1;
            transaction.update(ref, { ticketCount: newCount });
            return newCount;
        });
    })
}

export const setRfp = async (data, uid) => {

    const ticket = await setTicketRFP().catch(err => {
        console.error(err)
        return err
    })

    return rfpRef.add({
        ...data,
        dateCreated: timestamp,
        createdBy: uid,
        status: "New",
        ticket: `RFP#${zeroPad(ticket)}`
    })
}

export const getRfpListManager = (forwards) => {
    return rfpRef.where('forwards', '==', forwards)
        .orderBy('dateCreated', 'desc').get()
}

export const getRfpStatusList = (uid) => {
    return rfpRef.where("createdBy", "==", uid)
        .orderBy('dateCreated', 'desc').get()
}

export const getRfp = (id) => {
    return rfpRef.doc(id).get()
}

export const updateRfp = (id, data) => {
    return rfpRef.doc(id).update(data)
}