import React, { useEffect, useRef, useState } from 'react'
import { MONTHS } from '../../configuration'
import NoResult from './NoResult'
import styles from './report.module.css'
import { db } from '../../../firebase'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import { CSVLink } from "react-csv";
import useSortableData from '../../../hooks/useSortableData'
import Tip from './Tip'
import { useReactToPrint } from 'react-to-print';
import { getAllStaffIncludeRemoved } from '../../../controllers/externalStaffController'

const Table = React.forwardRef(({ result, isFetching }, ref) => {

    const { items, requestSort } = useSortableData(result);

    const onHighlight = (e) => {
        const target = e.currentTarget
        target.classList.toggle("highlight")
    }

    const pageStyle = `
    @media print {
        @page {
            size: landscape;
            margin: 10mm !important;
        }
        th,td{
            font-size: 10pt;
            padding: 0 2mm !important;
        }
      }`;

    return (
        <table ref={ref} className={`table ${styles.table_prosp}`} cellSpacing="0" cellPadding="0">
            <style>{pageStyle}</style>
            <thead>
                <tr>
                    <th>Num.</th>
                    <th onClick={() => requestSort('staff')}>Staff</th>
                    <th onClick={() => requestSort('date')}>Date</th>
                    <th onClick={() => requestSort('duration')}>Duration</th>
                    <th onClick={() => requestSort('time')}>Time</th>
                    <th onClick={() => requestSort('prospect')}>Prospect</th>
                    <th onClick={() => requestSort('services')}>Services</th>
                    <th onClick={() => requestSort('title')}>Title</th>
                    <th onClick={() => requestSort('remarks')}>Remarks</th>
                </tr>
            </thead>
            <tbody>
                {items.map((data, i) => <tr onClick={onHighlight} key={data.id}>
                    <td>{i + 1}</td>
                    <td>{data.staff}</td>
                    <td>{data.date.toDateString()}</td>
                    <td>{data.duration ?? "N/A"}</td>
                    <td>{data.time ?? "N/A"}</td>
                    <td>{data.prospect}</td>
                    <td>{data.services}</td>
                    <td>{data.title}</td>
                    <td>{data.remarks}</td>
                </tr>)}
            </tbody>
        </table>
    )
}
)
export default function StaffManagement() {
    const isMounted = useRef(false)
    const [error, setError] = useState(null)
    const [years, setYears] = useState([])
    const [result, setResult] = useState([])
    const [staffs, setStaffs] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const excelResult = useRef([])
    const year = useRef()
    const month = useRef()
    const staffRef = useRef()

    const printComp = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComp.current,
    });

    const generate = async () => {
        const m = month.current.value
        const y = year.current.value
        const staff = staffRef.current.value
        let ref = db.collection("externalStaff");

        if (staff === "all") {
            ref = db.collectionGroup("schedule");
        } else {
            ref = ref.doc(staff).collection("schedule")
        }

        let resultArray = []

        let start = null, end = null
        if (m !== "all") {
            start = new Date(y, MONTHS.indexOf(m), 1)
            end = new Date(y, MONTHS.indexOf(m) + 1, 0, 8)
        } else {
            start = new Date(y, 0, 1)
            end = new Date(y, 12, 0)
        }
        ref = ref.where("date", ">=", start).where("date", "<=", end)

        setIsFetching(true)
        const res = await ref.get().catch(err => {
            setIsFetching(false)
            console.error(err)
            setError(err)
        })

        if (res)
            resultArray = res.docs.map(d => ({
                ...d.data(),
                id: d.id,
                date: d.date === undefined ?
                    new Date() :
                    d.date.toDate()
                    // new Date(d.date.split('-')[2], d.date.split('-')[1], d.date.split('-')[0])
            }))

        excelResult.current = resultArray.map((r, idx) => ({
            Num: idx + 1,
            Staff: r.staff,
            Date: r.date.toDateString(),
            Duration: r.duration,
            Time: r.time,
            Prospect: r.prospect,
            Services: r.services,
            Title: r.title,
            Remarks:r.remarks
        }))

        resultArray.sort((a, b) => b.date - a.date)

        setResult(resultArray)
        setIsFetching(false)
    }

    useEffect(() => {
        document.querySelector('title').textContent = 'Staff Management - Report'
        isMounted.current = true
        let initYear = new Date(Date.parse(localStorage.getItem("INIT_DATE"))).getFullYear()
        const currYear = new Date().getFullYear()
        const yearArr = []
        while (initYear <= currYear) {
            yearArr.push(initYear++);
        }
        setYears(yearArr)

        const fetchStaff = async () => {

            if (isMounted.current) setIsFetching(true)
            const res = await getAllStaffIncludeRemoved().catch(err => {
                if (isMounted.current) {
                    setIsFetching(false)
                    console.error(err)
                    setError(err)
                }

                return
            })

            if (res) {
                const staffsData = res.docs.map(data => ({ id: data.id, name: data.get("fullName") }))
                if (isMounted.current) setStaffs(staffsData);
            }

            if (isMounted.current) setIsFetching(false)
        }
        fetchStaff()
        return () => isMounted.current = false
    }, [])

    return (
        <div>
            <div className="option-tab">
                <h3 className="option-tab-name">Staffs Management</h3>
                <div className="v-seperator"></div>
                <label htmlFor="prospectYear">Year: </label>
                <select ref={year} name="prospectYear" id="prospectYear">
                    {years.map(y => <option key={y} value={y}>{y}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="prospectMonth">Month: </label>
                <select ref={month} name="prospectMonth" id="prospectMonth">
                    <option value="all">All</option>
                    {MONTHS.map(m => <option key={m} value={m}>{m}</option>)}
                </select>
                <div className="v-seperator"></div>
                <label htmlFor="staffM">Staff: </label>
                <select ref={staffRef} name="staffM" id="staffM">
                    <option value="all">All</option>
                    {staffs.map(m => <option key={m.id} value={m.id}>{m.name}</option>)}
                </select>
                <div className="v-seperator"></div>
                <button onClick={generate} className="btn-green">
                    <span style={{ marginRight: "10px" }}>Generate</span>
                    {isFetching && <MiniSpinner wh={20} />}
                </button>
                {result.length > 0 &&
                    <>
                        <div className="v-seperator"></div>
                        <CSVLink data={excelResult.current} filename={`Staffs_Report_${new Date().toString()}.csv`}>
                            <button className="btn-green">
                                <span>Export to MS Excel</span>
                            </button>
                        </CSVLink>
                        <div className="v-seperator"></div>
                        <button onClick={handlePrint} className="btn-white">
                            <span>Print report</span>
                        </button>
                    </>
                }
            </div>
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {result.length === 0 ?
                <NoResult /> :
                <div className={styles.container}>
                    <Tip />
                    <Table ref={printComp} result={result} isFetching={isFetching} />
                </div>
            }
        </div>
    )
}
