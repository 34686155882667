import { Redirect, Route } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import Navbar from '../components/Navbar/Navbar'

export default function PrivateRoute({ component: Component, ...rest }) {
  const { isAuthenticated } = useAuth()
  return (
    <Route
      {...rest}
      render={(props) => isAuthenticated ?
        <>
          {/* Currently this works because coincidently all authenticated routes have navbar */}
          <Navbar />
          <div className="main-wrapper">
            <Component {...props} />
          </div>
        </> :
        <Redirect to="/login" />} />

  )
}
