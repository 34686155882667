import React, { useEffect, useRef, useState } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import { updateRole } from '../../controllers/usersController'

export default function DialogChangeRole({ data, onCancel, setUsers }) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const superAdminCB = useRef()
    const adminCB = useRef()
    const operationCB = useRef()

    const dialogStyles = {
        width: "20%",
        padding: "0",
        borderRadius: "5px",
        overflow: "hidden"
    }

    useEffect(() => {
       const defaultRole = data.role
    
       if(defaultRole.includes('SuperAdmin') || defaultRole.includes('Admin')){
            if(defaultRole.includes('SuperAdmin') ){
                superAdminCB.current.click()
            }
            if(defaultRole.includes('Admin') ){
                adminCB.current.click()
            }
       }else{
            operationCB.current.click()
       }

    }, [data.role])

    const onChangeCb = (e) => {
        const id = e.target.id
        const ele = e.target
        if (id === 'up_superadmin' || id === 'up_admin') {
            if (ele.checked) {
                document.querySelector('#up_operation').checked = false
                document.querySelector('#up_operation').disabled = true
            } else {
                if (!document.querySelector('#up_superadmin').checked && !document.querySelector('#up_admin').checked) {
                    document.querySelector('#up_operation').disabled = false
                }
            }
        } else {
            if (ele.checked) {
                document.querySelector('#up_superadmin').checked = false
                document.querySelector('#up_superadmin').disabled = true
                document.querySelector('#up_admin').checked = false
                document.querySelector('#up_admin').disabled = true
            } else {
                document.querySelector('#up_superadmin').disabled = false
                document.querySelector('#up_admin').disabled = false
            }
        }
    }

    const onSave = async () => {
        const id = data.id
        const role = []
        let isAnyChecked = false
        const defaultRole = data.role

        if(superAdminCB.current.checked){
            isAnyChecked = true
            role.push("SuperAdmin")  
        }
        if(adminCB.current.checked){
            isAnyChecked = true
            role.push("Admin")  
        }
        if(operationCB.current.checked){
            isAnyChecked = true
            role.push("Operation")  
        }

        if(!isAnyChecked){
            setError({
                message:'A user must have a role.',
                code:'Invalid-Input'
            })
            return
        }

        const extraRole = defaultRole.filter(d => d !== "SuperAdmin" && d !== "Admin" && d !== "Operation")
        const finalRole = [...extraRole,...role]
        
        setIsLoading(true)
        await updateRole(id,finalRole).catch(err => {
            setIsLoading(false)
            setError(err)
            return
        })

        setUsers(prev => {
            const modified = prev.filter(d => d.id === id)[0]
            const modifiedIdx = prev.findIndex(d => d.id === id)
            modified.role = finalRole
            prev.splice(modifiedIdx,1,modified)
            const newArr = Array.from(prev)
            return newArr
        })
        setIsLoading(false)
        onCancel()
    }

    return (
        <DialogTemplate title="Change Role" dialogStyles={dialogStyles} toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <p style={{ padding: '10px 0' }}>You are about to change <strong>{data.name}'s</strong> role</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column', gap: '1rem' }}>
                <div>
                    <label htmlFor="up_superadmin" style={{ marginRight: '10px' }}>Super Admin</label>
                    <input ref={superAdminCB} onChange={onChangeCb} type="checkbox" name="up_superadmin" id="up_superadmin" />
                </div>
                <div>
                    <label htmlFor="up_admin" style={{ marginRight: '10px' }}>Admin</label>
                    <input ref={adminCB} onChange={onChangeCb} type="checkbox" name="up_admin" id="up_admin" />
                </div>
                <div>
                    <label htmlFor="up_operation" style={{ marginRight: '10px' }}>Operation</label>
                    <input ref={operationCB} onChange={onChangeCb} type="checkbox" name="up_operation" id="up_operation" />
                </div>
            </div>

            <div className="buttons-dialog__form" >
                <button
                    style={{ marginLeft: 'auto' }}
                    disabled={isLoading}
                    type="button"
                    className="btn-white"
                    onClick={() => { setError(null); onCancel(); }}
                >Cancel</button>
                <button disabled={isLoading} onClick={onSave} className="btn-purple">
                    {isLoading ? "Saving..." : "Save"}
                </button>
            </div>
        </DialogTemplate>
    )
}
