import { db, timestamp } from "../../firebase";

export default class UpdateProfile {
    constructor(currentUser) {
        this.currentUser = currentUser
        this.ref = db.collection("Users").doc(currentUser.uid)
    }

    updateDisplayName = (displayName) => {
        return Promise.all([this.ref.update({ displayName }), this.currentUser.updateProfile({ displayName })])
    }

    updatePhotoUrl = (link) => {
        return this.currentUser.updateProfile({ photoURL:link });
    }

    updateEmail = (email) => {
        return Promise.all([this.ref.update({ email }), this.currentUser.updateEmail(email)])
    }

    updatePassword = (password) => {
        return this.currentUser.updatePassword(password);
    }

    updatePasswordChanged = () => {
        return this.ref.update({ passwordChanged: timestamp });
    }

    updateProfile = (gender, birthday, department, position) => {
        return this.ref.update({ gender, birthday, department, position })
    }

    updatePhoneNumber = (phoneNumber) => {
        return this.ref.update({ phoneNumber })
    }

    getProfile = () => {
        return this.ref.get()
    }
}


