import React, { useEffect, useRef } from 'react'
import styles from './las.module.css'
import { ref } from '../../controllers/lasController';
import { Link } from 'react-router-dom';

export default function MyApplications({ uid }) {
    const unreadRef = useRef()

    useEffect(() => {

        const y = new Date().getFullYear().toString()
        const unsub = ref.doc(uid).collection(`applications${y}`).where('read.self', '==', false)
            .onSnapshot((querySnapshot) => {
                if (querySnapshot.size > 0) {
                    unreadRef.current.textContent = querySnapshot.size;
                    unreadRef.current.style.display = 'block'
                } else {
                    unreadRef.current.style.display = 'none'
                }
            });

        return unsub
    }, [uid])

    return (
        <div>
            <Link to={'las/mylist'}>
                <button className="btn-blue btn-rel">
                    <span ref={unreadRef} className={styles.unreadCircle} >0</span>
                    <span className="material-icons">text_snippet</span>
                    <span>View My Leave Application</span>
                </button>
            </Link>
        </div>
    )
}
