import { useEffect, useState } from 'react'
import { db } from '../../../firebase'
import { STAGES } from '../../configuration'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { updateStage } from '../../../controllers/dealController'
import { useAuth } from '../../../contexts/AuthContext'
import { getOrigin } from '../../helper'

function Stage({ stage, size }) {
    return (
        <div className="stage">
            <h3 className="stage-name">{stage}</h3>
            <span className="stage-counter">{size}</span>
        </div>
    )
}

function Card({ data, index, id }) {

    const statusStyles = {
        backgroundColor: data.status === "Focused" ? "var(--sky-blue)" :
            data.status === "Inactive" ? "gray" : "var(--green)"
    }

    // const onClickItem = () => {
    //     openInNewTab(`${getOrigin()}/crm/wall/${data.id}`)
    // }

    const link = `${getOrigin()}/crm/wall/${data.id}`;

    return (
        <Draggable draggableId={id.toString()} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="pipeline-card"
                // onClick={onClickItem}
                >
                    <a href={link}>
                        <div style={statusStyles} className="card-status"></div>
                        <p className="card-inq">{data.ticket}</p>
                        <p className="card-name">{data.name}</p>
                        <p style={{ fontWeight: 'var(--regular)' }} >{data.organisation}</p>
                        <p className="card-value">{data.value ? "RM " + data.value : null}</p>
                    </a>
                </div>
            )}
        </Draggable>
    )
}

function Section({ children, section }) {
    return (
        <div data-section={section} className="pipeline-section">
            {children}
        </div>
    )
}



function DragContainer({ stage, data }) {


    return (
        <Droppable droppableId={stage} >
            {(provided, snapshot) => (
                <div
                    style={{ opacity: snapshot.isDraggingOver ? ".5" : "1" }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    data-section={stage}
                    className="pipeline-section card">
                    {data.map((d, index) => <Card key={d.id} id={d.id} index={index} data={d} />)}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}

function Container({ uid, deals }) {


    const [lists, setLists] = useState({ data1: [], data2: [], data3: [], data4: [], data5: [], data6: [] })

    useEffect(() => {

        const data1 = deals.filter(d => d.stage === "Lead In")
        const data2 = deals.filter(d => d.stage === "Define Problem / Propose Solution")
        const data3 = deals.filter(d => d.stage === "Issue Quotation")
        const data4 = deals.filter(d => d.stage === "Schedule Appointment")
        const data5 = deals.filter(d => d.stage === "Issue Invoice")
        const data6 = deals.filter(d => d.stage === "Follow Up Balance")

        setLists({ data1, data2, data3, data4, data5, data6 })
    }, [deals])

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result.source = sourceClone;
        result.dest = destClone;

        return result;
    };

    const getList = (id) => {
        switch (id) {
            case "Define Problem / Propose Solution":
                return { name: "data2", list: lists.data2 }
            case "Issue Quotation":
                return { name: "data3", list: lists.data3 }
            case "Schedule Appointment":
                return { name: "data4", list: lists.data4 }
            case "Issue Invoice":
                return { name: "data5", list: lists.data5 }
            case "Follow Up Balance":
                return { name: "data6", list: lists.data6 }
            default:
                return { name: "data1", list: lists.data1 }
        }
    }

    const onDragEnd = async (result) => {
        const { source, destination, draggableId } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {

            const { name, list } = getList(source.droppableId)
            const newArr = reorder(
                list,
                source.index,
                destination.index
            );

            setLists(prev => ({ ...prev, [name]: newArr }))
        } else {

            const lsource = getList(source.droppableId)
            const ldest = getList(destination.droppableId)
            const result = move(
                lsource.list,
                ldest.list,
                source,
                destination
            );

            updateStage(uid, draggableId, { stage: destination.droppableId, from: source.droppableId, to: destination.droppableId }).catch(err => console.error(err))

            setLists(prev => ({ ...prev, [lsource.name]: result.source, [ldest.name]: result.dest }))
        }
    }



    return (
        <>
            <div className="pipeline-container">
                {STAGES.map((stage, idx) =>
                    <Section key={stage}>
                        <Stage stage={stage} size={lists[`data${idx + 1}`].length} />
                    </Section>)}
            </div>
            <div className="pipeline-container card">
                <DragDropContext onDragEnd={onDragEnd}>
                    <DragContainer stage="Lead In" data={lists.data1} />
                    <DragContainer stage="Define Problem / Propose Solution" data={lists.data2} />
                    <DragContainer stage="Issue Quotation" data={lists.data3} />
                    <DragContainer stage="Schedule Appointment" data={lists.data4} />
                    <DragContainer stage="Issue Invoice" data={lists.data5} />
                    <DragContainer stage="Follow Up Balance" data={lists.data6} />
                </DragDropContext>
            </div>
        </>
    )
}

export default function Pipeline() {

    const { currentUser } = useAuth()
    const [deals, setDeals] = useState([])

    useEffect(() => {
        document.querySelector('title').textContent = "Pipeline - Deals"
        let unsub;
        async function fetchDeals() {
            const ref = db.collection("Deals");
            unsub = ref.onSnapshot(snapshot => {
                snapshot.docChanges().forEach(change => {
                    if (change.type === "added") {
                        setDeals(prev => ([...prev, { ...change.doc.data(), id: change.doc.id }]))
                    } else if (change.type === "modified") {

                        const modified = { ...change.doc.data(), id: change.doc.id }

                        if (modified.updatedBy !== currentUser.uid) {//update only on the non updater screen
                            setDeals(prev => {
                                const idx = prev.findIndex(d => d.id === modified.id)
                                prev.splice(idx, 1, modified)
                                const newArr = Array.from(prev)
                                return newArr
                            })
                        }

                    } else if (change.type === "removed") {
                        const removed = { ...change.doc.data(), id: change.doc.id }
                        setDeals(prev => {
                            const idx = prev.findIndex(d => d.id === removed.id)
                            prev.splice(idx, 1)
                            const newArr = Array.from(prev)
                            return newArr
                        })
                    }
                }, (error) => {
                    console.error(error)
                })
            })
        }
        fetchDeals()

        return unsub
    }, [currentUser.uid])

    return (
        <div>
            <Container uid={currentUser.uid} deals={deals} />
        </div>
    )
}
