import { useState } from 'react'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate'
import styles from './wall.module.css'
import { nullifyEmptyInput } from '../../helper'
import { setActivity } from '../../../controllers/dealController'
import { MONTHS } from '../../configuration'

export default function DialogAddActivity({ defValue, onCancel, id, uid, onAdded }) {
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const form = e.target;
        setError(null)
        setLoading(true)
        if (form.actEnd.value < form.actStart.value && nullifyEmptyInput(form.actEnd) !== null) {
            setLoading(false)
            return setError({
                code: "Duration Error",
                message: "Date End must be later than Date Start"
            })
        }

        const range = []
        if (form.actEnd.valueAsDate !== null && form.actEnd.valueAsDate !== form.actStart.valueAsDate) {
            let inBetween = form.actEnd.valueAsDate.getMonth() - form.actStart.valueAsDate.getMonth()

            if (inBetween < 0) {
                inBetween = 12 - (form.actEnd.valueAsDate.getMonth() - form.actStart.valueAsDate.getMonth())
            }

            if (inBetween >= 2) {
                const start = form.actStart.valueAsDate
                const end = form.actEnd.valueAsDate
                start.setMonth(start.getMonth() + 1)

                while (start < end) {
                    range.push(MONTHS[start.getMonth()])
                    start.setMonth(start.getMonth() + 1);
                }
            }
        }

        const formData = {
            title: form.actTitle.value,
            start: form.actStart.valueAsDate,
            end: form.actEnd.valueAsDate,
            time: form.actTime.value,
            duration: form.actDuration.value,
            type: form.actType.value,
            details: nullifyEmptyInput(form.actDetails),
            prospect: defValue.prospect,
            location: defValue.location,
            services: defValue.services,
            path: defValue.path,
            status: "Ongoing",
            range
        }

        const res = await setActivity(id, formData, uid).catch(err => {
            setLoading(false)
            return setError(err)
        })

        onAdded({ ...formData, dateCreated: new Date().toDateString(), createdBy: uid, id: res.id, hide: false })//just update the state with new item
        form.reset()
        setLoading(false)
        onCancel()
    }

    return (
        <DialogTemplate title="Create Activity" toggle={true} height="fit-content" width="40%">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form" >

                <label htmlFor="actTitle">Title</label>
                <input type="text" required placeholder="Enter activity title" id="actTitle" name="actTitle" />

                <div className={styles.date__field}>
                    <div className="layout-dialog__form">
                        <label htmlFor="actStart">Date Start</label>
                        <input type="date" name="actStart" id="actStart" required />
                    </div>

                    <div className="layout-dialog__form">
                        <label htmlFor="actEnd">Date End</label>
                        <input type="date" name="actEnd" id="actEnd" />
                    </div>

                    <div className="layout-dialog__form">
                        <label htmlFor="actTime">Time</label>
                        <input type="time" name="actTime" id="actTime" required />
                    </div>
                </div>

                <div className={styles.date__field} style={{ justifyContent: 'unset' }}>
                    <div className="layout-dialog__form" style={{ width: "31.7%" }}>
                        <label htmlFor="actDuration">Duration (hr)</label>
                        <input type="number" placeholder="Set duration" id="actDuration" name="actDuration" required />
                    </div>

                    <div className="layout-dialog__form" style={{ width: "31.7%", marginLeft: "6%" }} >
                        <label htmlFor="actType">Activity</label>
                        <select name="actType" id="actType">
                            <option value="Job">Job</option>
                            <option value="Meeting">Meeting</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                </div>

                <label htmlFor="actDetails">Other Details</label>
                <textarea name="actDetails" id="actDetails" cols="30" rows="10" placeholder="Other details..."></textarea>

                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { setError(null); onCancel() }}
                    >Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Creating..." : "Create"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
