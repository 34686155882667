import React, { useState } from 'react'
import reactDom from 'react-dom'
import styles from './dialogs.module.css'
import MiniSpinner from '../Loading/MiniSpinner'

const dialogStyles = {
    width: "20%",
    padding: "0",
    height:'max-content'
}

function Info({ yesCallback, noCallback, infoText = '' }) {

    const [isLoading, setIsLoading] = useState(false)

    return reactDom.createPortal(
        <>
            <div className="dialog-overlay" />
            <div className="wpd" style={dialogStyles}>
                <div className={styles.dialog__layout}>
                    <div className={styles.banner__orange}>
                        <div className={styles.circle}>
                           {isLoading ?<MiniSpinner /> : <span className="material-icons">info</span>}
                        </div>
                    </div>
                    <h2 className={styles.header__text}>Caution !</h2>
                    <p className={styles.infoText}>{infoText}</p>
                    <div className={styles.buttonLay}>
                        <button disabled={isLoading} className="btn-orange" onClick={() => yesCallback(setIsLoading)}>YES</button>
                        <button disabled={isLoading} className="btn-white" onClick={() => noCallback()}>NO</button>
                    </div>
                </div>
            </div>
        </>,
        document.getElementById("modal")
    )
}

export default Info
