import React, { useState } from 'react'
import reactDom from 'react-dom';
import styles from './reauth.module.css'

const dialogStyles = {
    width: "30%"
}

function ReAuth({isLoading, isOpen, onClose , callback ,reAuth }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [isProcessing,setIsProcessing] = useState(false)
    const [error,setError] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setIsProcessing(true)
            await reAuth(email,password)
            setIsProcessing(false)
            callback();
        } catch (error) {
            setIsProcessing(false)
            setError({
                code:error.code,
                message:error.message
            })
        }
      
    }

    if(!isOpen) return null

    return reactDom.createPortal(
        <>
            <div className="dialog-overlay" />
            <div className="wpd" style={dialogStyles}>
                <h2>Re-Authentication</h2>
                <p style={{marginBottom:"10px"}}>Re-authentication is required for this action</p>
                <form onSubmit={handleSubmit} className={styles.form___ra}>
                    {error && <p className="error-bar">{`${error.message}\n--Code : ${error.code}`}</p>}
                    <label htmlFor="email">Email</label>
                    <input placeholder="Email" onChange={(e) => {setEmail(e.target.value)}} value={email} type="email" name="email" id="email" />
                    <label htmlFor="pass">Password</label>
                    <input placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} type="password" name="pass" id="pass" />
                    <div className={styles.actionBtn}>
                        <button type="button" disabled={isLoading || isProcessing} onClick={() => onClose()} className="btn-white">Cancel</button>
                        <button type="submit" disabled={isLoading || isProcessing} className="btn-purple">{isLoading || isProcessing ? "Confirming..." : "Confirm"}</button>
                    </div>
                </form>
            </div>
        </>,
        document.getElementById("portal")
    )
}

export default ReAuth
