import React, { useEffect, useState, useRef } from 'react'
import Header from '../../../components/Header/Header'
import List from './List'
import Calendar from './Calendar'
import SearchComponent from '../../../components/SearchComponent/SearchComponent'
import { InstantSearch, Configure, connectHits } from 'react-instantsearch-dom'
import searchClient from '../../../algolia'
import SearchHit from './SearchHit'
import { useParams,useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useUsers } from '../../../contexts/UsersContext'

function SubHeader({ isOperation, setToggle, toggle }) {

    const history = useHistory()
    const HitComps = connectHits(SearchHit)

    const list = useRef()
    const cal = useRef()

    return (
        <div className="subheader">
            <div className={`tab ${toggle === "list" && "active"}`} ref={list} 
            onClick={(e) => {
                if(isOperation){
                    e.preventDefault()
                    return
                }
                cal.current.classList.remove("active")
                list.current.classList.add("active")
                history.push('/crm/activities')
            }}>
                <span className="material-icons">{isOperation? 'lock':'list'}</span>
                <span>List</span>
            </div>

            <div className={`tab ${toggle === "cal" && "active"}`} ref={cal} onClick={(e) => {
                list.current.classList.remove("active")
                cal.current.classList.add("active")
                history.push('/crm/activities/calendar')
            }}>
                <span className="material-icons">event_note</span>
                <span>Calendar</span>
            </div>

            {
                !isOperation && toggle === "list" ?
                    <InstantSearch
                        indexName={process.env.REACT_APP_ALGOLIA_INDEX_ACTIVITY}
                        searchClient={searchClient}>
                        <Configure
                            hitsPerPage={5}
                            attributesToRetrieve={["title", "start", "end", "type", "time", "path"]}
                        />
                        <SearchComponent>
                            <HitComps />
                        </SearchComponent>
                    </InstantSearch> :
                    <div className="payment-info">
                        <div><span style={{backgroundColor:'#ff7f7f'}}></span><span>Break day</span></div>
                        <div><span style={{ backgroundColor: "var(--sky-blue)" }}></span><span>Ongoing</span> </div>
                        <div><span style={{ backgroundColor: "var(--green)" }}></span><span>Fully paid or Completed</span> </div>
                        <div><span style={{ backgroundColor: "var(--orange)" }}></span><span>Partially paid</span> </div>
                    </div>
            }

        </div>
    )
}

export default function Activities() {
    const [toggle, setToggle] = useState('list');
    const {calendar} = useParams()
    const {currentUser} = useAuth()
    const {users} = useUsers()
    const [isOperation,setIsOperation] = useState(false)

    useEffect(() => {
        
        if(!currentUser || users.length === 0) return

        const user = users.filter(u => u.id === currentUser.uid)[0]
        if(user.role.includes("Operation")){
            setIsOperation(true)
        }

        if(calendar !== undefined){
            setToggle('cal')
        }else{
            setToggle('list')
        }
    }, [calendar,currentUser,users])

    return (
        <>
            <Header title="Activities" />
            <SubHeader isOperation={isOperation} setToggle={setToggle} toggle={toggle} />
                <div style={{scrollBehavior:'smooth'}} className="tab-contents">
                    {toggle === "list" ?
                        <List isOperation={isOperation} /> :
                        <Calendar isOperation={isOperation} />
                    }
                </div>
        </>
    )
}
