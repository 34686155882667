import React,{useRef,useEffect,useState} from 'react'
import NoDataTable from '../../../components/NoDataTable/NoDataTable'
import Option from './Option'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import dayjs from 'dayjs'
import { openInNewTab,getOrigin } from '../../helper'
import PaginationButtons from '../../../components/Buttons/PaginationButtons'
import { sorefCol } from '../../../controllers/serviceOrderController'

function Table({dataArray}) {
    const click = (id) => {
        openInNewTab(`${getOrigin()}/service-order/${id}`)
    }

    return (
        <table className="table" cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    <th>Ticket</th>
                    <th>Title</th>
                    <th>Services</th>
                    <th>Prospect</th>
                    <th>Status</th>
                    <th>Date Created</th>
                </tr>
            </thead>
            <tbody>
            {dataArray.map(
                    data => (
                        <tr key={data.id} onClick={()=>click(data.id)}>
                            <td>{data.ticket}</td>
                            <td>{data.title ?? "N/A"}</td>
                            <td>{data.services}</td>
                            <td>{data.name}</td>
                            <td>{data.status ?? "0"}</td>
                            <td>{dayjs.unix(data.dateCreated.seconds).format('DD-MM-YYYY')}</td>
                        </tr>
                    )
                )}
            </tbody>
        </table>
    )
}


const TableMemo = React.memo(Table)

export default function SOTable() {
    const isMounted = useRef(false)
    const [isFetching, setIsFetching] = useState(true)
    const FETCH_LIMIT = 15
    const [dataArray,setDataArray] = useState([])
    const lastDoc = useRef(null)
    const firstDoc = useRef(null)
    const ref = sorefCol;

    const fetchData = async (query) => {
        setIsFetching(true)
        const result = await query.get().catch(err => console.error(err))
        if (result.size === 0) {//No more records
            if (isMounted.current) {
                setIsFetching(false)
            }
            return
        }
        lastDoc.current = result.docs[result.size - 1]
        firstDoc.current = result.docs[0]
        const data = result.docs.map(doc => { return { id: doc.id, ...doc.data() } })
        if (isMounted.current) {
            setDataArray(data)
            setIsFetching(false)
        }
    }

    useEffect(() => {
        isMounted.current = true
        document.querySelector("title").textContent = "Service Order - Archive"
        fetchData(ref.limit(FETCH_LIMIT))
        return () => isMounted.current - false
    }, [ref])

    return (
         <div>
            <Option tab="Service Order"/>
            {
                isFetching &&
                <div className="loading__table">
                    <MiniSpinner />
                    <h3>Service Order is loading...</h3>
                </div>
            }

            {!isFetching && dataArray.length > 0 &&
                <>
                    <TableMemo dataArray={dataArray}/>
                    <PaginationButtons
                        onNext={() => fetchData(ref.startAfter(lastDoc.current).limit(FETCH_LIMIT))}
                        onPrev={() => fetchData(ref.endBefore(firstDoc.current).limitToLast(FETCH_LIMIT))} />
                </>
            }

            {
                !isFetching && dataArray.length === 0 &&
                <NoDataTable text="No service order yet." />
            }
        </div>
    )
}
