export const STAGES = [
    'Lead In',
    'Define Problem / Propose Solution',
    'Issue Quotation',
    'Schedule Appointment',
    'Issue Invoice',
    'Follow Up Balance'
]

export const PAYMENT_METHOD = [
    'Full Payment',
    'Deposit/Balance Payment',
    'Recurring Payment',
    'Credit Card Payment'
]

export const STATUS = [
    'Ongoing',
    'Focused',
    'Inactive'
]

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export const STATES = [
    'Johor',
    'Kedah',
    'Kelantan',
    'Kuala Lumpur',
    'Malacca',
    'Negeri Sembilan',
    'Pahang',
    'Penang',
    'Perak',
    'Perlis',
    'Sabah',
    'Sarawak',
    'Selangor',
    'Terengganu'
]

// Navigations = [
//     'CRM',
//     'Home',
//     'Document Inventory',
//     'Tracker',
//     'RFP',
// ]