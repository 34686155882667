import React from 'react'

export default function Tip() {

    const styles = {
        display:'flex',
        alignItems:"center",
        marginTop:"10px",
        gap:"10px"
    }
    

    return (
        <div style={styles}>
            <span style={{color:'var(--sky-blue)'}} className="material-icons">info</span>
            <p>Click a row to highlight it.</p>
            <p>Table is sorted by <strong>Date Created</strong> initially, click on headers to sort accordingly.</p>
        </div>
    )
}
