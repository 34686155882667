import React, { useEffect, useRef, useState } from 'react'
import CHART_1 from './DealsCharts/CHART_1'
import CHART_2 from './DealsCharts/CHART_2'
import CHART_3 from './DealsCharts/CHART_3'
import CHART_4 from './DealsCharts/CHART_4'
import CHART_5 from './DealsCharts/CHART_5'
import CHART_7 from './DealsCharts/CHART_7'
import CHART_8 from './DealsCharts/CHART_8'
import CHART_9 from './DealsCharts/CHART_9'
import CHART_10 from './DealsCharts/CHART_10'
import CHART_11 from './DealsCharts/CHART_11'
import CHART_12 from './DealsCharts/CHART_12'
import { useUsers } from '../../../contexts/UsersContext'
import { MONTHS } from '../../configuration'

export const DealChartsCtx = React.createContext()

export default function Deals() {
    const d = new Date()
    const month = useRef()
    const year = useRef()
    const [date, setDate] = useState({
        m: d.getMonth(),
        y: d.getFullYear()
    })
    const { users } = useUsers()
    const [years,setYears] = useState([])
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = "Deals - Dashboard"
        let initYear =  new Date( localStorage.getItem('INIT_DATE') ).getFullYear()
        const ys = []
        const d = new Date()
        while (initYear <= d.getFullYear()) {
            ys.push(initYear)
            initYear++
        }
        setYears(ys)

        return () => isMounted.current = false
    }, [])

    const stylesFlex = {display:'flex',height:'250px',alignItems:'center',gap:'10px',width:'100%',marginBottom:'3rem'}
    const stylesFlexBig = {...stylesFlex,height:'450px'}
    const stylesFlexBigSquare = {...stylesFlex,height:'550px'}

    const onChangeDate = () => {
        const m = MONTHS.indexOf(month.current.value)
        const y = parseInt(year.current.value)
        setDate({m,y})
    }

    return (
        <>
            <div style={{display:'flex',gap:'10px',alignItems:'center',justifyContent:'flex-end',marginBottom:'10px'}}>
                <select ref={month} defaultValue={MONTHS[date.m]}>
                    {MONTHS.map(m => <option key={m} value={m}>{m}</option>)}
                </select>
                <select ref={year} defaultValue={date.y}>
                    {years.map(y=><option key={y} value={y}>{y}</option>)}
                </select>
                <button onClick={onChangeDate} className="btn-purple">Apply</button>
            </div>
            <DealChartsCtx.Provider value={{ date, users, isMounted }}>
                <div style={stylesFlex}>
                    <CHART_1 />
                    <CHART_2 />
                </div>
                <div style={stylesFlexBig}>
                    <CHART_3 />
                </div>
                <div style={stylesFlexBig}>
                    <CHART_4 />
                </div>
                <div style={{...stylesFlexBigSquare,justifyContent:'center'}}>
                    <CHART_5 />
                </div>
                <div style={stylesFlexBigSquare}>
                    <CHART_7 />
                    <CHART_8 />
                </div>
                <div style={stylesFlexBigSquare}>
                    <CHART_9 />
                    <CHART_10 />
                </div>
                <div style={stylesFlexBigSquare}>
                    <CHART_11 />
                    <CHART_12 />
                </div>
            </DealChartsCtx.Provider>
        </>
    )
}
