import React, { useEffect, useRef, useState } from 'react'
import { getAllStaff, removeStaff } from '../../controllers/externalStaffController'
import AddStaffDialog from './AddStaffDialog'
import EditStaffDialog from './EditStaffDialog'
import styles from './settingsComp.module.css'
import { searchFunction } from '../helper'
import NoPerm from '../../components/ErrorDialog/NoPerm'

const Table = ({ data, setData }) => {
    const [editData, setEditData] = useState(null)

    const actionStyles = {
        display: 'flex',
        gap: '10px'
    }

    const onRemove = async (rdata) => {
        await removeStaff(rdata.id).catch(err => console.error(err))
        setData(data.filter(d => d.id !== rdata.id))
    }

    const onEdit = (d) => {
        setEditData(d)
    }

    const callback = (id, newData) => {
        setEditData(null)
        const modified = { id, ...newData }
        setData(prev => {
            const idx = prev.findIndex(d => d.id === id)
            prev.splice(idx, 1, modified)
            const newArr = Array.from(prev)
            return newArr
        })
    }

    return (
        <>
            {
                editData &&
                <EditStaffDialog
                    onCancel={() => setEditData(null)}
                    data={editData}
                    callback={callback}
                />
            }
            <table id="sa-table" cellSpacing="0" cellPadding="0" className="table">
                <thead>
                    <tr>
                        <th>Num</th>
                        <th>Full name</th>
                        <th>Phone number</th>
                        <th>Type</th>
                        <th>Account number</th>
                        <th>Bank</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length > 0 &&
                        data.map((d, idx) => <tr key={d.id}>
                            <td>{idx + 1}</td>
                            <td>{d.fullName}</td>
                            <td>{d.phoneNumber}</td>
                            <td>{d.type}</td>
                            <td>{d.accNumber ?? 'N/A'}</td>
                            <td>{d.bank ?? 'N/A'}</td>
                            <td style={actionStyles}>
                                <button onClick={() => onEdit(d)} className="btn-blue">Edit</button>
                                <button onClick={() => onRemove(d)} className="btn-red">Remove</button>
                            </td>
                        </tr>)
                    }
                </tbody>
            </table>
        </>
    )
}

export default function StaffAgentManagement({ isOperation }) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const isMounted = useRef()

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = 'Staff & Agent Management - Settings'

        if (isOperation) return

        const fetch = async () => {
            if (isMounted.current) setIsLoading(true)
            const res = await getAllStaff().catch(err => {
                if (isMounted.current) {
                    setIsLoading(false)
                    console.error(err)
                    setError(err)
                }
                return
            })

            if (res) {
                if (isMounted.current) setData(res.docs.map(d => ({ id: d.id, ...d.data() })))
            }

            if (isMounted.current) setIsLoading(false)
        }
        fetch()

        return () => isMounted.current = false
    }, [isOperation])

    const callback = (id, data) => {
        setOpen(false)
        setData(prev => ([{ id, ...data }, ...prev]))
    }

    return (
        <div>

            {
                open &&
                <AddStaffDialog
                    onCancel={() => setOpen(false)}
                    callback={callback}
                />
            }

            {!isOperation ? <> <div className={styles.titleCont}>
                {isLoading && <div className={styles.miniSpinner}></div>}
                <h2 className={styles.title}>Staff & Agent Management</h2>
            </div>
                {error && <p className="error-bar">{`${error.message} --code: ${error.code}`}</p>}
                <h3 className={styles.subtitle}>Manage</h3>
                <div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <div className={styles.search}>
                            <span className="material-icons">search</span>
                            <input id="sa-search" onInput={() => searchFunction("sa-search", "sa-table")} type="text" placeholder="Search names" />
                        </div>
                        <button className="btn-green" onClick={() => setOpen(true)}>
                            <span className="material-icons">add</span>
                            <span>Add</span>
                        </button>
                    </div>
                </div>
                <div>
                    <Table data={data} setData={setData} />
                </div>
            </> :
            <NoPerm/>
            }
        </div>
    )
}
