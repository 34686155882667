import { useState } from "react"
import DialogTemplate from "../../../components/Dialogs/DialogTemplate"
import { updateActivity } from "../../../controllers/dealController"
import styles from './wall.module.css'
import { parseToDateInputObj } from '../../helper'
import { MONTHS } from "../../configuration"

export default function DialogEditActivity({ onCancel, did, data, onUpdated }) {
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [title, setTitle] = useState(data.title)
    const [time, setTime] = useState(data.time)
    const [duration, setDuration] = useState(data.duration)
    const [type, setType] = useState(data.type)
    const [details, setDetails] = useState(data.details ?? '')

    let startDate = null, endDate = null

    try {
        startDate = data.start.toDate()
    } catch (error) {
        startDate = data.start
    }

    try {
        endDate = data.end.toDate()
    } catch (error) {
        endDate = data.end
    }

    const [start, setStart] = useState(parseToDateInputObj(startDate))
    const [end, setEnd] = useState(parseToDateInputObj(endDate) ?? '')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)

        if(end < start && end !== "" ){
            setLoading(false)
            return setError({
                code:"Duration Error",
                message:"Date End must be later than Date Start"
            })
        }

        const range = []
        if (end !== "" && end !== null && new Date(end) !== new Date(start)) {
            const stDate = new Date(start)
            const endDate = new Date(end)
            let inBetween = endDate.getMonth() - stDate.getMonth()

            if( inBetween < 0){
                inBetween = 12 - (endDate.getMonth() - stDate.getMonth())
            }

            if (inBetween >= 2) {
                stDate.setMonth(stDate.getMonth() + 1)

                while (stDate < endDate) {
                    range.push(MONTHS[stDate.getMonth()])
                    stDate.setMonth(stDate.getMonth() + 1);
                }

            }
        }

        const formData = {
            details: details === "" ? null : details,
            duration,
            end: end === "" ? null : new Date(end),
            start:new Date(start),
            time,
            title,
            type,
            range
        }

        await updateActivity(did,formData,data.id).catch(err => {
            setLoading(false)
            return setError(err)
        })
        setLoading(false)
        e.target.reset()
        onUpdated(formData)
    }

    return (
        <DialogTemplate title="Edit Activity" toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form" >

                <label htmlFor="actTitle">Title</label>
                <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" required placeholder="Enter activity title" id="actTitle" name="actTitle" />

                <div className={styles.date__field}>
                    <div className="layout-dialog__form">
                        <label htmlFor="actStart">Date Start</label>
                        <input value={start} onChange={(e) => setStart(e.target.value)} type="date" name="actStart" id="actStart" required />
                    </div>

                    <div className="layout-dialog__form">
                        <label htmlFor="actEnd">Date End</label>
                        <input value={end} onChange={(e) => setEnd(e.target.value)} type="date" name="actEnd" id="actEnd" />
                    </div>

                    <div className="layout-dialog__form">
                        <label htmlFor="actTime">Time</label>
                        <input value={time} onChange={(e) => setTime(e.target.value)} type="time" name="actTime" id="actTime" required />
                    </div>
                </div>

                <div className={styles.date__field} style={{ justifyContent: 'unset' }}>
                    <div className="layout-dialog__form" style={{ width: "31.7%" }}>
                        <label htmlFor="actDuration">Duration (hr)</label>
                        <input value={duration} onChange={(e) => setDuration(e.target.value)} type="number" placeholder="Set duration" id="actDuration" name="actDuration" required />
                    </div>

                    <div className="layout-dialog__form" style={{ width: "31.7%", marginLeft: "6%" }} >
                        <label htmlFor="actType">Activity</label>
                        <select value={type} onChange={(e) => setType(e.target.value)} name="actType" id="actType">
                            <option value="Job">Job</option>
                            <option value="Meeting">Meeting</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                </div>

                <label htmlFor="actDetails">Other Details</label>
                <textarea value={details} onChange={(e) => setDetails(e.target.value)} name="actDetails" id="actDetails" cols="30" rows="10" placeholder="Other details..."></textarea>

                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { setError(null); onCancel() }}
                    >Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Saving..." : "Save"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
