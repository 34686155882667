import React,{useState} from 'react'
import styles from './home.module.css'
import { useHistory } from 'react-router-dom'
import { useNav } from '../../contexts/NavContext'

export default function SearchWidget({isOperation}) {
    const {setNavigation} = useNav()
    const [search,setSearch] = useState([])
    const history = useHistory()

    const searchable = [
        ['rfp','/rfp','RFP'],
        ['calendar','/crm/activities/calendar','CRM'],
        ['deals','/crm/deals','CRM'],
        ['pipeline','/crm/deals','CRM'],
        ['report','/crm/report','CRM'],
        ['dashboard','/crm/dashboard','CRM'],
        ['document inventory','/document-inventory','Document Inventory'],
        ['activities','/crm/activities','CRM'],
        ['prospects','/crm/prospects','CRM'],
        ['archive','/crm/archive','CRM']
    ]

    const navigate = (url,nav) => {
        setNavigation(nav)
        history.push(url)
    }

    const onChangeText = (e) => {
        const text = e.target.value
        if(text === ''){
            setSearch([])
        }else{
            const re = new RegExp(text,"g");
            setSearch(searchable.filter(s => s[0].match(re)))
        }
    }

    return (
        <div className={styles.search}>
            <span className="material-icons">{isOperation? 'lock':'search'}</span>
            <input disabled={isOperation} onChange={onChangeText} type="text" placeholder="Search pages,modules.." />
            <div hidden={search.length === 0} className={styles.hit}>
                {search.map((s,idx) => <p key={idx} onClick={()=>navigate(s[1],s[2])}>{s[0]}</p>)}
            </div>
        </div>
    )
}
