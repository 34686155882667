import React, { useEffect, useState } from 'react'
import NoDataTable from '../../../components/NoDataTable/NoDataTable'
import DealDialog from './DealDialog'
import { createNewDeal, updateTicketCount, createNewProspect, updatePropect, checkExistingProspect, dealRef } from './database'
import { zeroPad, getOrigin } from '../../helper'
import { STAGES } from '../../configuration'
import tableStyle from './tableStyles.module.css'
import { InstantSearch, Configure } from "react-instantsearch-dom"
import searchClient from '../../../algolia'
import { useAuth } from '../../../contexts/AuthContext'
import { getAllUser } from '../../global.db'
import TransferOwnershipDialog from './TransferOwnershipDialog'

let firstDoc = null, lastDoc = null, users = []

function Buttons({ addDealCallback, toCallback, refreshCallback }) {

    return (
        <div className="button-section">
            <button className="btn btn-purple" onClick={() => refreshCallback()}>
                <span className="material-icons">refresh</span>
                <span>Refresh</span>
            </button>

            <button className="btn btn-green" onClick={() => addDealCallback()}>
                <span className="material-icons">add</span>
                <span>Add Deal</span>
            </button>

            <button className="btn btn-orange" onClick={() => toCallback()}>
                <span className="material-icons">supervisor_account</span>
                <span>Transfer Ownership</span>
            </button>
        </div>
    )
}

function Pagination({ onNext, onPrev }) {
    return (
        <div className="button-section">
            <button className="btn-purple" onClick={onPrev}><span className="material-icons">chevron_left</span></button>
            <button className="btn-purple" onClick={onNext}><span className="material-icons">chevron_right</span></button>
        </div>
    )
}

function TableItem({ data }) {

    // const handleClick = (e) => {
    //     openInNewTab(`${getOrigin()}/crm/wall/${data.id}`)
    // }

    const link = `${getOrigin()}/crm/wall/${data.id}`;

    const onChecked = (e) => {
        e.stopPropagation()
        if (e.target.checked) {
            e.target.dataset.id = data.id
            e.target.dataset.prospect = data.name
        } else {
            e.target.dataset.id = undefined
        }
    }

    return (

        <tr key={data.id}>
            <td onClick={(e) => e.stopPropagation()} style={{ width: "80px", minWidth: "80px" }} ><input onClick={onChecked} style={{ width: "20px", height: "20px" }} type="checkbox" /></td>
            <td><a href={link}>{data.ticket}</a></td>
            <td><a href={link}>{data.dateCreated?.toDate().toDateString()}</a></td>
            <td><a href={link}>{data.owner}</a></td>
            <td><a href={link}>{data.name}</a></td>
            <td><a href={link}>{data.dealSource}</a></td>
            <td><a href={link}>{data.stage}</a></td>
            <td><a href={link}>{data.value ?? 0}</a></td>
        </tr>

    )
}

function Table({ allDeals }) {

    return (
        <table className="table" name="tableDeal" cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    <th style={{ minWidth: "80px" }}>Bulk</th>
                    <th>Ticket</th>
                    <th>Date</th>
                    <th>Owner</th>
                    <th>Prospect</th>
                    <th>Source</th>
                    <th>Stage</th>
                    <th>Value (RM)</th>
                </tr>
            </thead>
            <tbody className={tableStyle.table__body}>{allDeals.map(doc => { return <TableItem key={doc.id} data={doc} /> })}</tbody>
        </table>
    )
}

export default function DealsTable() {
    const [isLoading, setLoading] = useState(false)
    const [addDealModalToggle, setAddDealModalToggle] = useState(false);
    const [toModal, setToModal] = useState(false);
    const [allDeals, setAllDeals] = useState([])
    const { currentUser } = useAuth()
    const FETCH_LIMIT = 16;

    const createDeal = async (data, pid) => {
        setLoading(true)

        const checkRes = await checkExistingProspect(data.phoneNumber)//null or ID

        if (pid === null && checkRes === null) {
            const { id } = await createNewProspect(
                data.name,
                data.phoneNumber,
                data.email,
                data.organisation
            ).catch((err) => {
                setLoading(false)
                return {
                    status: "not-ok",
                    message: err.message,
                    code: err.code
                }
            })
            pid = id
        } else if (checkRes !== null && pid !== checkRes) {
            setLoading(false)
            return {
                status: "not-ok",
                message: "Conflicting Prospect, Clearing phone number and re-inserting might help.",
                code: "Confict-Prospect-ID"
            }
        } else if (checkRes === null && pid !== null) {
            setLoading(false)
            return {
                status: "not-ok",
                message: "Prospect ID error, Clearing phone number and re-inserting might help.",
                code: "Ghost-Prospect-ID"
            }
        }

        return updateTicketCount()
            .then((ticket) => {
                ticket = `INQ#${zeroPad(ticket)}`
                return createNewDeal({
                    ...data,
                    ticket,
                    stage: STAGES[0],
                    pid,
                    owner: currentUser.uid
                })
            })
            .then((result) => {
                return updatePropect(result.id,
                    data.dealServices,
                    data.dealTitle,
                    pid)
            })
            .then(() => {
                return fetchData(dealRef.limit(FETCH_LIMIT))
            })
            .then(() => {
                setLoading(false)
                setAddDealModalToggle(false)
                return {
                    status: "ok",
                    message: "",
                    code: ""
                }
            })
            .catch(err => {
                setLoading(false)
                return {
                    status: "not-ok",
                    message: err.message,
                    code: err.code
                }
            })
    }

    const fetchData = async (query) => {

        const result = await query.get()

        if (result.size === 0) {//No more records

            return
        }
        lastDoc = result.docs[result.size - 1]
        firstDoc = result.docs[0]
        const data = result.docs.map(doc => { return { id: doc.id, ...doc.data() } })
        data.forEach(d => { users.forEach(u => { if (d.owner === u.id) d.owner = u.displayName }) })
        setAllDeals(data)
    }

    useEffect(() => {
        document.querySelector("title").textContent = "List - Deals"
        const fetchUsers = async () => {
            const usersSnapshots = await getAllUser()
            users = usersSnapshots.docs.map(doc => { return { id: doc.id, displayName: doc.data().displayName } })
        }
        fetchUsers()
        fetchData(dealRef.limit(FETCH_LIMIT))
    }, [])

    return (
        <div>
            <Buttons
                addDealCallback={() => setAddDealModalToggle(true)}
                refreshCallback={() => fetchData(dealRef.limit(FETCH_LIMIT))}
                toCallback={() => setToModal(true)} />

            {allDeals.length > 0
                ? <>
                    <Table allDeals={allDeals} />
                    <Pagination
                        onNext={() => fetchData(dealRef.startAfter(lastDoc).limit(FETCH_LIMIT))}
                        onPrev={() => fetchData(dealRef.endBefore(firstDoc).limitToLast(FETCH_LIMIT))} />
                </>
                : <NoDataTable text="Deal's list is empty, Add new one by clicking Add Deal button !" />}


            <InstantSearch
                indexName={process.env.REACT_APP_ALGOLIA_INDEX_PROSPECTS}
                searchClient={searchClient}
            >
                <Configure
                    attributesToRetrieve={['name', 'phoneNumber', 'organisation', 'email']}
                    hitsPerPage={5}
                />
                <DealDialog
                    toggle={addDealModalToggle}
                    callback={createDeal}
                    onCancel={() => setAddDealModalToggle(false)}
                    isLoading={isLoading}
                />
            </InstantSearch>

            {
                toModal &&
                <TransferOwnershipDialog
                    onCancel={() => setToModal(false)}
                />
            }
        </div>
    )
}
