import React, { useContext, useState, useEffect } from 'react'
import {auth,EmailProvider} from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export default function AuthProvider({children}) {
    const [currentUser,setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function login(email,password){
        return auth.signInWithEmailAndPassword(email,password)
    }

    function logout(){
        sessionStorage.clear()
        return auth.signOut()
    }

    function resetPassword(email){
        return auth.sendPasswordResetEmail(email)
    }

    function reAuth(email,password){
        const credential = EmailProvider.credential(email,password)
        return auth.currentUser.reauthenticateWithCredential(credential)
    }

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsub
    }, [])

    const value = {
        currentUser,
        login,
        logout,
        reAuth,
        resetPassword,
        isAuthenticated:currentUser != null
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
