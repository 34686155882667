import React, { useRef, useState } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate';
import { storage, db, timestamp } from '../../firebase'

export default function DialogUploadFile({ onCancel, path, setFileList }) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const storagePath = 'DocumentInventory/' + path
    const uploader = useRef()
    const percent = useRef()

    const uploadTask = (file, size, i) => {
        const date = new Date()

        uploader.current.value = (i) / size * 100;
        percent.current.textContent = parseInt(uploader.current.value) + "%"

        const fileExt = file.name.split('.').pop()
        const filename = `${date.getTime()}_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}.${fileExt}`

        return new Promise(function (resolve, reject) {
            let storageRef = storage.ref(storagePath + "/" + filename);
            //Upload file
            let task = storageRef.put(file);
            //Update progress bar
            task.on('state_changed',
                function progress() {
                },
                function error(err) {
                    reject(err);
                },
                async function complete() {
                    const downloadURL = await task.snapshot.ref.getDownloadURL()
                    resolve({
                        ref: storageRef.fullPath,
                        filename,
                        displayName: file.name,
                        url: downloadURL
                    })
                }
            );
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const uploadBtn = e.target.uploadBtn;
        const size = uploadBtn.files.length;
        uploader.current.value = 0;
        const files = Array.from(uploadBtn.files)
        const filesData = await Promise.all(files.map((file, i) => uploadTask(file, size, i)))
            .catch(err => {
                setError(err)
                setIsLoading(false)
            })

        const dbRef = db.collection(`DocumentInventory/${path}/Files`)
        const result = await Promise.all(filesData.map(data => dbRef.add({ ...data, dateUploaded: timestamp })))
            .catch(err => {
                setError(err)
                setIsLoading(false)
            })

        const ids = result.map(r => r.id)

        uploader.current.value = (100 - uploader.current.value) + uploader.current.value;
        percent.current.textContent = parseInt(uploader.current.value) + "%"

        setFileList(prev => ([...filesData.map((data, idx) => ({ ...data, dateUploaded: new Date(), id: ids[idx] })), ...prev]))
        setIsLoading(false)
        onCancel()

    }
    return (
        <DialogTemplate title={"Upload Files"} toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form">
                <label htmlFor="uploadBtn">Upload new file(s) : </label>
                <input required type="file" name="uploadBtn" id="uploadBtn" multiple />
                <p style={{ textAlign: 'end' }} ref={percent}>0%</p>
                <progress ref={uploader} style={{ width: '100%' }} value="0" max="100" id="uploader" name="uploader">0%</progress>
                <div className="buttons-dialog__form">
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { setError(null); onCancel() }}>Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Uploading..." : "Upload"}
                    </button>
                </div>
            </form>

        </DialogTemplate>
    )
}
