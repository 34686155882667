import React from 'react'
import styles from './styles.module.css'
import { openInNewTab, getOrigin } from '../../helper'

export default function SearchHit({ hits }) {
    const handleClick = (e, did) => {
        e.stopPropagation()
        openInNewTab(`${getOrigin()}/crm/wall/${did}`)
    }

    const statusStyles = (status) => {
        return {backgroundColor:status === "Focused" ? "var(--sky-blue)" :
         status === "Inactive" ? "gray" : "var(--green)"}
    }

    return (
        <>
            <ul>
                <div >
                    {hits.map(hit => {
                        hit.id = hit.objectID
                        return (
                            <li onMouseDown={(e) => handleClick(e, hit.objectID)} key={hit.objectID} >
                                <div className={styles.hit}>
                                    <div style={statusStyles(hit.status)} className="card-status"></div>
                                    <p className="card-inq">{hit.ticket}</p>
                                    <h4>{hit.name}</h4>
                                    <p>{hit.phoneNumber}</p>
                                    <p>{hit.organisation}</p>
                                    <p>{hit.value ? "RM "+hit.value : null}</p>
                                </div>
                            </li>
                        )
                    })}
                </div>
            </ul>
        </>
    )
}
