import React, { useContext, useState, useEffect, useRef } from 'react'
import { getAllUser } from '../controllers/usersController'
import { useAuth } from './AuthContext'

const UsersContext = React.createContext()

export function useUsers() {
    return useContext(UsersContext)
}

export default function UsersProvider({ children }) {
    const [users, setUsers] = useState([])
    const isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const [currentUserData,setCurrentUserData] = useState(null)
    const {currentUser} = useAuth()

    useEffect(() => {
        isMounted.current = true
        if(!currentUser) return
        
        const fetchUsers = async () => {
            setIsLoading(true)
            const qs = await getAllUser().catch(err => {
                throw err;
            });

            if (isMounted.current) {
                const userArray = qs.docs.map(doc => {
                    return {
                        name: doc.data().displayName,
                        id: doc.id,
                        email: doc.data().email,
                        role: doc.data().role,
                        pfpLink: doc.data().pfpLink,
                        position: doc.data().position ?? 'Not Set',
                    }
                })

                setCurrentUserData(userArray.filter(d => d.id === currentUser.uid)[0])
                setUsers(userArray)
                setIsLoading(false)
            }
        }
        fetchUsers()
        return () => isMounted.current = false
    }, [currentUser])

    return (
        <UsersContext.Provider value={{users,isLoading,setUsers,currentUserData}}>
            {children}
        </UsersContext.Provider>
    )
}
