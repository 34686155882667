import { useRef, useState } from 'react'
import { setSO } from '../../../controllers/serviceOrderController'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate'
import { nullifyEmptyInput } from '../../helper'
import { useAuth } from '../../../contexts/AuthContext'

export default function DialogCreateSO({ onCancel, defValue , onUpdated}) {
    const {currentUser} = useAuth()
    const errorBar = useRef(null)
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [title, setTitle] = useState(defValue.title ?? '')
    const [footer, setFooter] = useState('Our crew will only deliver task based on the agreed Service Order,If you require additional task, kindly inform us.')

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        const form = e.target
        
        const formData = {
            title,
            services:form.soServices.value,
            details:nullifyEmptyInput(form.soDetails),
            footer:nullifyEmptyInput(form.soFooter),
            isCov: form.covid.value === "true" ? true : false,
            name: defValue.name,
            address: defValue.address,
            phoneNumber: defValue.phoneNumber,
            creator:defValue.creator
        }

        const res = await setSO(formData,currentUser.uid).catch(err => {
            setLoading(false)
            setError(err)
            console.error(err)
            return 
        })
        
        setLoading(false)
        onUpdated(res.id)
        onCancel()

    }

    return (
        <DialogTemplate title="Create Service Order" toggle={true} height="fit-content">
            {error && <div ref={errorBar} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <p style={{ textAlign: "end" }}><span className="red-asterisk">**</span> Services is determined based on deal information.</p>
            <form onSubmit={handleSubmit} className="layout-dialog__form">

                <label htmlFor="soTitle">Title</label>
                <input type="text" name="soTitle" id="soTitle" required placeholder="Set title" value={title} onChange={(e) => setTitle(e.target.value)} />

                <label htmlFor="soServices"> <span className="red-asterisk">**</span> Services</label>
                <input readOnly defaultValue={defValue.services || ''} type="text" name="soServices" id="soServices" required placeholder="Set services" />

                <label htmlFor="covPrec">Covid-19 Precaution (Premise / Customer Status)</label>
                <div style={{display:"flex",gap:"5px",alignItems:"center"}}>
                    <input value={false || undefined} type="radio" name="covid" id="covid_no" defaultChecked />
                    <label htmlFor="covid_no">(Negative) is not related to any positive case</label>
                </div>
                <div style={{display:"flex",gap:"5px",alignItems:"center"}}>
                    <input value={true || undefined} type="radio" name="covid" id="covid_yes" />
                    <label htmlFor="covid_yes">(Positive) is related to any positive case</label>
                </div>

                <label htmlFor="soDetails">Details</label>
                <textarea name="soDetails" id="soDetails" placeholder="Enter details..." cols="30" rows="10"></textarea>

                <label htmlFor="soFooter">Footer</label>
                <textarea name="soFooter" id="soFooter" cols="30" rows="5" value={footer} onChange={(e)=>setFooter(e.target.value)} placeholder="Set footer"></textarea>

                <div className="buttons-dialog__form" >
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { setError(null); onCancel() }}
                    >Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Creating..." : "Create"}
                    </button>
                </div>
            </form>
        </DialogTemplate>
    )
}
