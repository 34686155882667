import React, { useRef, useState } from 'react'
import './header.css'
import { useAuth } from '../../contexts/AuthContext'
import Loading from '../Loading/Loading'
import { Link } from 'react-router-dom'

export default function Header({ title }) {

    const { logout } = useAuth()
    const profile = useRef()
    const noti = useRef()
    const [isLoading, setLoading] = useState(false)

    const handleMouseLeave = (e) => {
        e.current.classList.remove("open");
    }

    const handleHover = (e) => {
        if (!e.current.classList.contains("open"))
            e.current.classList.add("open");
    }

    const handleLogout = async () => {
        setLoading(true)
        await logout().catch(err => {
            setLoading(false)
            console.error(err)
        })
    }

    return (
        <>
            <div className="header">
                <h1>{title}</h1>
                <div className="dd-noti">
                    <span className="material-icons-outlined">notifications</span>
                </div>
                <div className="dd-profile" ref={profile} onMouseOver={() => handleHover(profile)} onMouseLeave={() => handleMouseLeave(profile)}>
                    <span className="material-icons-outlined">person</span>
                    <div className="ddi-profile">
                        <ul>
                            <Link to="/settings">
                                <li> <span className="material-icons">settings</span> Settings</li>
                            </Link>
                            <li onClick={handleLogout} > <span className="material-icons">logout</span> Log Out</li>
                        </ul>
                    </div>
                </div>
            </div>
            {isLoading && <Loading open={isLoading} />}
        </>
    )
}
