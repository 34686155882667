import { useState, useEffect, useRef } from 'react'
import Header from '../../../components/Header/Header'
import NoDataTable from '../../../components/NoDataTable/NoDataTable'
import { prospectRef, createNewProspect, checkExistingProspect, updateProspect } from './database'
import SearchComponent from '../../../components/SearchComponent/SearchComponent'
import { InstantSearch, Configure, connectHits } from 'react-instantsearch-dom'
import searchClient from '../../../algolia'
import tableStyle from './tableStyles.module.css'
import styles from './prospect.module.css'
import CreateProspectDialog from './CreateProspectDialog'
import ViewProspectDialog from './ViewProspectDialog'
import ViewHistoryDialog from './ViewHistoryDialog'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import SearchHit from './SearchHit'

let firstDoc = null, lastDoc = null

function Pagination({ onNext, onPrev }) {
    return (
        <div className="button-section">
            <button className="btn-purple" onClick={onPrev}><span className="material-icons">chevron_left</span></button>
            <button className="btn-purple" onClick={onNext}><span className="material-icons">chevron_right</span></button>
        </div>
    )
}

function TableItem({ data, viewSingleProspect, viewHistory }) {

    const sortAddress = () => {
        return `${data.address ?? ""}, ${data.address2 ?? ""}, ${data.poscode ?? ""} ${data.city ?? ""}, ${data.state ?? ""}`
    }

    return (
        <tr key={data.id}>
            <td>{data.name}</td>
            <td>{data.phoneNumber}</td>
            <td>{data.organisation}</td>
            <td>{sortAddress()}</td>
            <td >
                <button onClick={() => viewSingleProspect(data)} className="btn-orange">View</button>
                <button onClick={() => viewHistory({ pid: data.id, name: data.name })} className="btn-purple">Deal History</button>
            </td>
        </tr>
    )
}

function Table({ prospects, viewSingleProspect, viewHistory }) {
    return (
        <table className="table" cellSpacing="0" cellPadding="0">
            <thead className={tableStyle.table__head}>
                <tr>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Organisation</th>
                    <th>Address</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody className={tableStyle.table__body}>
                {prospects.map(doc => { return <TableItem key={doc.id} data={doc} viewSingleProspect={viewSingleProspect} viewHistory={viewHistory} /> })}
            </tbody>
        </table>
    )
}

function SubHeader({viewSearch,historySearch}) {

    const HitsComp = connectHits(SearchHit)

    return (
        <div className="subheader">
            <SearchComponent>
                <HitsComp viewCallback={viewSearch} historyCallback={historySearch}/>
            </SearchComponent>
        </div>
    )
}

export default function Prospects() {

    const isMounted = useRef(false)
    const [prospects, setProspects] = useState([])
    const FETCH_LIMIT = 15
    const [isFetching, setIsFetching] = useState(true)
    const [isLoading, setLoading] = useState(false)
    const [toggleDialog, setToggleDialog] = useState(false)
    const [singleProspect, setSingleProspect] = useState(null)
    const [viewHistoryData, setViewHistoryData] = useState(null)

    const fetchData = async (query) => {
        setIsFetching(true)
        const result = await query.get()
        if (result.size === 0) {//No more records
            if (isMounted.current) {
                setIsFetching(false)
            }
            return
        }
        lastDoc = result.docs[result.size - 1]
        firstDoc = result.docs[0]
        const data = result.docs.map(doc => { return { id: doc.id, ...doc.data() } })
        if (isMounted.current) {
            setProspects(data)
            setIsFetching(false)
        }
    }

    const createProspectCallback = async (formData) => {
        setLoading(true)
        const res = await checkExistingProspect(formData.phoneNumber)

        if (res !== null) {
            setLoading(false)
            return {
                status: "not-ok",
                message: "Prospect with this phone number is already exist",
                code: "Prospect-Existed"
            }
        }

        await createNewProspect(formData).catch(err => {
            setLoading(false)
            return {
                status: "not-ok",
                message: err.message,
                code: err.code
            }
        })

        setLoading(false)
        setToggleDialog(false)
        return {
            status: "ok",
            code: "",
            message: ""
        }
    }

    const viewSingleProspect = (data) => {
        setSingleProspect(data)
    }

    const updateProspectCallback = async (formData, pid) => {
        setLoading(true)
        await updateProspect(formData, pid).catch(err => {
            setLoading(false)
            return {
                status: "not-ok",
                message: err.message,
                code: err.code
            }
        })
        setLoading(false)
        setSingleProspect(null)
        return {
            status: "ok",
            code: "",
            message: ""
        }
    }

    const viewHistory = (data) => {
        setViewHistoryData(data)
    }

    useEffect(() => {
        isMounted.current = true
        fetchData(prospectRef.limit(FETCH_LIMIT))
        return () => isMounted.current = false
    }, [])

    return (
        <>
            <InstantSearch
                indexName={process.env.REACT_APP_ALGOLIA_INDEX_PROSPECTS}
                searchClient={searchClient}>
                <Configure
                    hitsPerPage={5}
                />
                <Header title="Prospects" />
                <SubHeader viewSearch={viewSingleProspect} historySearch={viewHistory}/>

                <div className="tab-contents">

                    <div className="button-section">
                        <button className="btn btn-purple" onClick={() => fetchData(prospectRef.limit(FETCH_LIMIT))}>
                            <span className="material-icons">refresh</span>
                            <span>Refresh</span>
                        </button>

                        <button className="btn btn-green" onClick={() => setToggleDialog(true)}>
                            <span className="material-icons">add</span>
                            <span>Add Prospect</span>
                        </button>
                    </div>

                    {
                        isFetching &&
                        <div className={styles.loading__table}>
                            <MiniSpinner />
                            <h3>Prospect is loading...</h3>
                        </div>
                    }

                    {!isFetching && prospects.length > 0 &&
                        <>
                            <Table prospects={prospects} viewSingleProspect={viewSingleProspect} viewHistory={viewHistory} />
                            <Pagination
                                onNext={() => fetchData(prospectRef.startAfter(lastDoc).limit(FETCH_LIMIT))}
                                onPrev={() => fetchData(prospectRef.endBefore(firstDoc).limitToLast(FETCH_LIMIT))} />
                        </>
                    }

                    {
                        !isFetching && prospects.length === 0 &&
                        <NoDataTable text="Deal's list is empty, Add new one by clicking Add Deal button !" />
                    }

                    <CreateProspectDialog
                        toggle={toggleDialog}
                        isLoading={isLoading}
                        onCancel={() => setToggleDialog(false)}
                        callback={createProspectCallback}
                    />

                    {
                        singleProspect &&
                        <ViewProspectDialog
                            prospect={singleProspect}
                            onCancel={() => setSingleProspect(null)}
                            isLoading={isLoading}
                            callback={updateProspectCallback}
                        />
                    }

                    {
                        viewHistoryData &&
                        <ViewHistoryDialog
                            prospect={viewHistoryData}
                            onCancel={() => setViewHistoryData(null)}
                        />
                    }
                </div>
            </InstantSearch>
        </>
    )
}
