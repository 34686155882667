import React, { useEffect, useRef, useState } from 'react'
import DialogTemplate from '../../../components/Dialogs/DialogTemplate'
import { useUsers } from '../../../contexts/UsersContext'
import MiniSpinner from '../../../components/Loading/MiniSpinner'
import styles from './styles.module.css'
import { transferBulkOwnership } from '../../../controllers/dealController'

export default function TransferOwnershipDialog({ onCancel }) {
    const isMounted = useRef(false)
    const [error, setError] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const { users, isLoading } = useUsers()
    const [selected, setSelected] = useState({ count: 0, list: [] })
    const [selectedRows,setSelectedRows] = useState([])

    const onTransfer = async (e) => {
        const selectedIDs = selected.list.map(li => li.did)
        const oid = e.target.dataset.id
        const oname =  e.target.dataset.name
        if(selectedIDs.length === 0 ) return setError({code:"No-Deal-Selected",message:"Select deals to start transferring ownership."})
        setSubmitting(true)
        await transferBulkOwnership(oid,selectedIDs).catch(err => {setSubmitting(false); setError(err)})

        document.getElementById("doneTransfer").textContent = selected.count + " deals successfully transfered."
        selectedRows.forEach(td => td.textContent = oname)

        setTimeout(() => {
            document.getElementById("doneTransfer").textContent = ""
            setSubmitting(false)
            onCancel()
        }, 1000);
        
    }

    useEffect(() => {
        isMounted.current = true
        const checkedEles = document.querySelectorAll("table[name^=tableDeal] input:checked")
        setSelectedRows([...checkedEles].map(node => node.parentElement.parentElement.querySelector('td:nth-child(4)')))
        setSelected({
            count: checkedEles.length,
            list: [...checkedEles].map(ele => ({ prospect: ele.dataset.prospect, did: ele.dataset.id }))
        })
        return () => isMounted.current = false
    }, [])

    return (
        <DialogTemplate title="Transfer Ownership" toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}

            {
                isLoading &&
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}><MiniSpinner /> Loading users...</div>
            }

            <div style={{marginBottom:"5px"}}>
                <p>{selected.count} deal(s) selected.</p>
                <p style={{color:"var(--green)"}} id="doneTransfer"></p>
            </div>

            {users.map(u => <div className={styles.user_list} key={u.id}>
                <p>{u.name}</p>
                <button disabled={submitting} onClick={onTransfer} data-id={u.id} data-name={u.name} className="btn-orange">{submitting ? "Loading..." : "Transfer"}</button>
            </div>)}

            <div className="buttons-dialog__form" >
                {/* <button onClick={()=>console.log(selectedRows)}>Test</button> */}
                <button style={{ marginLeft: "auto" }} className="btn-white" onClick={onCancel}>Cancel</button>
            </div>

        </DialogTemplate>
    )
}
