import React, { useEffect, useContext, useState, useRef } from 'react'
import { DealChartsCtx } from '../Deals'
import styles from '../dashboard.module.css'
import Loading from '../Loading'
import { getMonthlyCompleted, getMonthlyLost } from '../../../../controllers/dashboardController'
import useResizeObserver from '../../../../hooks/useResizeObserver'
import { select, arc, pie } from 'd3'
import { randomColor } from '../../../helper'

//Monthly Closed Deals  Chart

const Chart = React.memo(({ data }) => {
    const chartWrapperRef = useRef()
    const svgRef = useRef()
    const dimensions = useResizeObserver(chartWrapperRef)

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const arcGenerator = arc()
            .innerRadius(100)
            .outerRadius(150)

        const pieGenerator = pie();
        const values = data.map(arr => arr.total)
        const keys = ['Won','Lost']
        const instructions = pieGenerator(values)
        const colors = data.map(d => randomColor())
        svg.selectAll(".slice")
            .data(instructions)
            .join("path")
            .attr("class", "slice")
            .attr("fill", (instruction, index) => colors[index])
            .style("transform", `translate(30%, 50%)`)
            .on("mouseenter", (event, instruction) => {
                svg.selectAll(".tooltip")
                    .data([instruction])
                    .join("text")
                    .attr("class", "tooltip")
                    .text(`${keys[instruction.index]}: ${instruction.data} | RM ${data[instruction.index].amount}`)
                    .style("transform", `translate(${event.offsetX}px, ${event.offsetY}px)`)
                    .transition()
                    .attr("opacity", 1)
            })
            .on("mouseleave", () => svg.select('.tooltip').remove())
            .transition()
            .attr("d", instruction => arcGenerator(instruction))

        // Add one dot in the legend for each name.
        svg.selectAll("mydots")
            .data(keys)
            .enter()
            .append("circle")
            .attr("cx", 300)
            .attr("cy", function (d, i) { return 100 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .attr("r", 7)
            .style("fill", function (d, i) { return colors[i] })

        // Add one dot in the legend for each name.
        svg.selectAll("mylabels")
            .data(keys)
            .enter()
            .append("text")
            .attr("x", 320)
            .attr("y", function (d, i) { return 100 + i * 25 }) // 100 is where the first dot appears. 25 is the distance between dots
            .style("fill", function (d,i) { return colors[i]  })
            .text(function (d) { return d })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")

    }, [data, dimensions])

    const pieStyles = { width: "450px", height: '450px', overflow: 'visible' }

    return (
        <div ref={chartWrapperRef} style={pieStyles}>
            <svg style={{ height: "100%", width: "100%", overflow: 'visible' }} ref={svgRef}></svg>
        </div>
    )
})


export default function CHART_7() {

    const ctx = useContext(DealChartsCtx)
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        const { isMounted, date } = ctx
        const firstDay = new Date(date.y, date.m, 1)
        const lastDay = new Date(date.y, date.m + 1, 0)

        const initFetch = async (func) => {
            const res = await func().catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })
            if (isMounted.current && res) {
                if (res.size !== 0) {
                    const total = res.docs.map(doc => doc.get('value') ?? 0).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr))
                    return {
                        amount: total,
                        total: res.size
                    }
                }
            }
            return {
                amount: 0,
                total: 0
            }
        }

        const results = async () => {
            setIsFetching(true)
            const completes = await initFetch(() => getMonthlyCompleted(firstDay, lastDay))
            const losts = await initFetch(() => getMonthlyLost(firstDay, lastDay))
            setIsFetching(false)

            const arrays = [completes,losts]
            if (isMounted.current) {
                setData(arrays)
            }
        }
        results()
    }, [ctx])

    const chartStyles = {
        height: '100%',
        width: '50%'
    }

    return (

        <div style={chartStyles} className={styles.container}>
            <p className={styles.title}>Closed Deals</p>
            {error && <div style={{ width: '60%', margin: "0 auto" }} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {isFetching ?
                <Loading /> :
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Chart data={data} />
                </div>}
            <p style={{ paddingTop: '0' }} className={styles.tips}>Closed deals are calculated from <strong>monthly deals completion and monthly deals lost</strong>.</p>
        </div>
    )
}
