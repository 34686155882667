import React from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'

export default function ViewUserPfp({ link, name, onClose }) {

    const imgStyles = {
        height: '400px',
        width: '400px',
        objectFit:'cover'
    }

    return (
        <DialogTemplate title={name} toggle={true} height="fit-content">
            <div style={
                {
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center'
                }
            }>
                <img style={imgStyles} src={link ?? './img/user_placeholder.png'} alt="User profile" />
            </div>

            <div className="buttons-dialog__form" >
                <button type="submit" className="btn-white"
                    onClick={() => onClose()}
                    style={{marginLeft:'auto'}}
                >
                    Close
                </button>
            </div>

        </DialogTemplate>
    )
}
