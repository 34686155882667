import React, { useEffect, useContext, useState, useRef } from 'react'
import { LocationServicesChartsCtx } from '../LocationServices'
import styles from '../dashboard.module.css'
import Loading from '../Loading'
import { getMonthlyCompleted, getMonthlyLost, getMonthlyOngoing } from '../../../../controllers/dashboardController'
import useResizeObserver from '../../../../hooks/useResizeObserver'
import { select, axisBottom, axisRight, scaleBand, scaleLinear, min, max } from 'd3'
import { randomColor } from '../../../helper'

//Services status by Cities chart

const Chart = React.memo(({ data }) => {
    const chartWrapperRef = useRef()
    const svgRef = useRef()
    const dimensions = useResizeObserver(chartWrapperRef)

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const won = data.won
        const lost = data.lost
        const ongoing = data.ongoing

        const maxLength = max([won.length, lost.length, ongoing.length]);

        const longestArr = won.length === maxLength ? won :
            lost.length === maxLength ? lost :
                ongoing

        const xScale = scaleBand()
            .domain(longestArr.map(d => d[0]))
            .range([0, dimensions.width])
            .padding(0.5)

        const maxCount = max([...won.map(d => d[1]), ...lost.map(d => d[1]), ...ongoing.map(d => d[1])]);
        const minCount = min([...won.map(d => d[1]), ...lost.map(d => d[1]), ...ongoing.map(d => d[1])]);

        const yScale = scaleLinear()
            .domain([minCount - 10, maxCount + 10])//hardcoded number is for offset
            .range([dimensions.height, 0])

        const xAxis = axisBottom(xScale).ticks(longestArr.length)

        svg.select(".x-axis")
            .style("transform", `translateY(${dimensions.height}px)`)
            .call(xAxis)
            .selectAll("text")//text styles
            .style("text-anchor", "end")
            .style("font", "13px sans-serif")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-30)");

        const yAxis = axisRight(yScale);

        svg.select(".y-axis")
            .style("transform", `translateX(${dimensions.width}px)`)
            .call(yAxis)
            .selectAll("text")//text styles
            .style("font", "13px sans-serif")

        const barWidth = xScale.bandwidth() / 3
        const colors = [randomColor(),randomColor(),randomColor()]

        svg.selectAll(".won")
            .data(won.map(d => d[1]))
            .join("rect")
            .attr("class", "won")
            .style("transform", "scale(1,-1)")
            .attr("x", (value, index) => xScale(won.map(d => d[0])[index]))
            .attr("y", -dimensions.height)
            .attr("width", barWidth)
            .transition()
            .attr("height", value => dimensions.height - yScale(value))
            .attr("fill", colors[0])
            .each(function (d, i) {
                select(this)
                    .on("mouseenter", () => {
                        svg.selectAll(".tooltip")
                            .data([d])
                            .join("text")
                            .attr("class", "tooltip")
                            .text(d)
                            .attr("x", xScale(won.map(d => d[0])[i]) + (barWidth / 2))
                            .attr("y", yScale(d) - 8)
                            .attr("text-anchor", "middle")
                            .style("font", "14px sans-serif")
                            .transition()
                            .attr("opacity", 1)
                    })
                    .on("mouseleave", () => svg.select(".tooltip").remove())
            })

        svg.selectAll(".lost")
            .data(lost.map(d => d[1]))
            .join("rect")
            .attr("class", "lost")
            .style("transform", "scale(1,-1)")
            .attr("x", (value, index) => xScale(lost.map(d => d[0])[index]) + barWidth)
            .attr("y", -dimensions.height)
            .attr("width", barWidth)
            .transition()
            .attr("height", value => dimensions.height - yScale(value))
            .attr("fill", colors[1])
            .each(function (d, i) {
                select(this)
                    .on("mouseenter", () => {
                        svg.selectAll(".tooltip")
                            .data([d])
                            .join("text")
                            .attr("class", "tooltip")
                            .text(d)
                            .attr("x", xScale(lost.map(d => d[0])[i]) + (barWidth / 2) + barWidth)
                            .attr("y", yScale(d) - 8)
                            .attr("text-anchor", "middle")
                            .style("font", "14px sans-serif")
                            .transition()
                            .attr("opacity", 1)
                    })
                    .on("mouseleave", () => svg.select(".tooltip").remove())
            })


        svg.selectAll(".ongoing")
            .data(ongoing.map(d => d[1]))
            .join("rect")
            .attr("class", "ongoing")
            .style("transform", "scale(1,-1)")
            .attr("x", (value, index) => xScale(ongoing.map(d => d[0])[index]) + (barWidth * 2))
            .attr("y", -dimensions.height)
            .attr("width", barWidth)
            .transition()
            .attr("height", value => dimensions.height - yScale(value))
            .attr("fill", colors[2])
            .each(function (d, i) {
                select(this)
                    .on("mouseenter", () => {
                        svg.selectAll(".tooltip")
                            .data([d])
                            .join("text")
                            .attr("class", "tooltip")
                            .text(d)
                            .attr("x", xScale(ongoing.map(d => d[0])[i]) + (barWidth / 2) + (barWidth * 2))
                            .attr("y", yScale(d) - 8)
                            .attr("text-anchor", "middle")
                            .style("font", "14px sans-serif")
                            .transition()
                            .attr("opacity", 1)
                    })
                    .on("mouseleave", () => svg.select(".tooltip").remove())
            })

        const type = ['Won','Lost','Ongoing']
        // Add one dot in the legend for each name.
        svg.selectAll("mydots")
            .data(type)
            .enter()
            .append("circle")
            .attr("cx", (d,i) => 0 + i * 80)
            .attr("cy",  5 ) // 100 is where the first dot appears. 25 is the distance between dots
            .attr("r", 7)
            .style("fill", function (d, i) { return colors[i] })

        // Add one dot in the legend for each name.
        svg.selectAll("mylabels")
            .data(type)
            .enter()
            .append("text")
            .attr("x", (d,i) => 20 + i * 80)
            .attr("y", 5 ) // 100 is where the first dot appears. 25 is the distance between dots
            .style("fill", function (d, i) { return colors[i] })
            .text(function (d,i) { return type[i] })
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")



    }, [data, dimensions])

    const pieStyles = { width: "95%", height: '600px', overflow: 'visible' }

    return (
        <div ref={chartWrapperRef} style={pieStyles}>
            <svg style={{ height: "100%", width: "100%", overflow: 'visible' }} ref={svgRef}>
                <g className="x-axis" />
                <g className="y-axis" />
            </svg>
        </div>
    )
})


export default function CHART_4() {

    const ctx = useContext(LocationServicesChartsCtx)
    const [isFetching, setIsFetching] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        const { isMounted, date } = ctx
        const firstDay = date.firstDay
        const lastDay = date.lastDay

        const initFetch = async (func) => {
            const res = await func().catch(err => {
                if (isMounted.current) {
                    setError(err)
                    console.error(err)
                }
            })
            if (isMounted.current && res) {
                if (res.size !== 0) {
                    return {
                        arr: res.docs.map(doc => doc.get('dealServices'))
                    }
                }
            }
            return {
                arr: []
            }
        }

        const results = async () => {
            if (isMounted.current) setIsFetching(true)

            const completes = await initFetch(() => getMonthlyCompleted(firstDay, lastDay))
            const losts = await initFetch(() => getMonthlyLost(firstDay, lastDay))
            const ongoings = await initFetch(() => getMonthlyOngoing(firstDay, lastDay))

            if (isMounted.current) setIsFetching(false)

            //Categorize
            const counter1 = {}
            completes.arr.forEach(function (service) {
                var keyjson = JSON.stringify(service)
                const key = keyjson.replace(/"/g, '')
                counter1[key] = (counter1[key] || 0) + 1
            })

            const counter2 = {}
            losts.arr.forEach(function (service) {
                var keyjson = JSON.stringify(service)
                const key = keyjson.replace(/"/g, '')
                counter2[key] = (counter2[key] || 0) + 1
            })


            const counter3 = {}
            ongoings.arr.forEach(function (service) {
                var keyjson = JSON.stringify(service)
                const key = keyjson.replace(/"/g, '')
                counter3[key] = (counter3[key] || 0) + 1
            })

            const arrayPairs1 = Object.entries(counter1)
            const arrayPairs2 = Object.entries(counter2)
            const arrayPairs3 = Object.entries(counter3)

            if (isMounted.current) {
                setData({ won: arrayPairs1, lost: arrayPairs2, ongoing: arrayPairs3 })
            }
        }
        results()
    }, [ctx])

    const chartStyles = {
        height: '100%',
        width: '100%'
    }

    return (

        <div style={chartStyles} className={styles.container}>
            <p className={styles.title}>Services (Status)</p>
            {error && <div style={{ width: '60%', margin: "0 auto" }} className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            {isFetching ?
                <Loading /> :
                <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                    <Chart data={data} type={'total'} />
                </div>}
            <p style={{ paddingTop: '0' }} className={styles.tips}>Services (status) chart is generated from <strong>monthly deal completed and monthly deal lost and monthly ongoing deals (sorted by services)</strong>.</p>
        </div>
    )
}
