import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { activitiesRef, activitiesRefRaw, activitiesRefDef } from '../../../controllers/dealController'

const ActivityStat = React.memo(() => {
    const isMounted = useRef(false)
    const [data,setData] = useState({
        added:0,
        done:0
    })
 


    useEffect(() => {
        const d = new Date()
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 1)
        const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0)

        isMounted.current = true
        const fetchData = async () => {
            const addedRes = await activitiesRefDef.where("dateCreated",">=", firstDay).where("dateCreated", "<=" , lastDay)
                .get().catch(err => console.error(err))
            if(isMounted.current) setData(prev => ({...prev,added:addedRes.size}));
            const doneRes = await activitiesRefDef.where("dateCreated",">=", firstDay).where("dateCreated", "<=" , lastDay).where("status","==","Completed")
                .get().catch(err => console.error(err))
            if(isMounted.current) setData(prev => ({...prev,done:doneRes.size}));
        }
        fetchData()
        return () => isMounted.current = false
    }, [])

    return (
        <div className="activity-stats">
            <div>
                <p>Total activity/job added this month : </p>
                <p>{data.added}</p>
            </div>
            <div>
                <p>Total activity/job done this month : </p>
                <p>{data.done}</p>
            </div>

            <Link to="/crm/dashboard" style={{color:"var(--sky-blue)",textDecoration:"underline",marginLeft:"auto"}} >View more in Dashboard <span style={{verticalAlign:"bottom",fontSize:"16px"}} className="material-icons">launch</span></Link>
        </div>
    )
})

function ListLeft({ setFilter, setQueryRef }) {
    const currFilter = useRef("All")

    const handleOnClick = (e,filter) => {
        if (currFilter.current === filter) return null

        document.querySelector(".activity-filters div.filter.active").classList.remove("active");
        document.querySelector(`.activity-filters div.filter.${filter.toLowerCase()}`).classList.add("active");
        
        currFilter.current = filter
        setFilter(filter)
        const today = new Date()

        switch (filter) {
            case "Overdue":
                setQueryRef(activitiesRefRaw.where("end", "<", today).where("status", "==", "Ongoing").orderBy("end", "desc"))
                break;
            case "Upcoming":
                setQueryRef(activitiesRefRaw.where("start", ">=", today).orderBy("start", "desc"))
                break;
            case "Cancelled":
                setQueryRef(activitiesRefDef.where("status", "in", ["Lost", "Deleted"]).orderBy("dateCreated", "desc"))
                break;
            case "Done":
                setQueryRef(activitiesRef.where("status", "==", "Completed"))
                break;

            default:
                setQueryRef(activitiesRef)
                break;
        }
    }

    return (
        <div className="left-side-container">
            <div className="activity-filters">
                <div className="filter active all" onClick={(e)=>handleOnClick(e,"All")}>
                    <span>All</span>
                    <span></span>
                </div>
                <div className="filter overdue" onClick={(e)=>handleOnClick(e,"Overdue")}>
                    <span>Overdue</span>
                    <span></span>
                </div>
                <div className="filter upcoming" onClick={(e)=>handleOnClick(e,"Upcoming")}>
                    <span>Today & Upcoming</span>
                    <span></span>
                </div>
                <div className="filter cancelled" onClick={(e)=>handleOnClick(e,"Cancelled")}>
                    <span>Cancelled</span>
                    <span></span>
                </div>
                <div className="filter done" onClick={(e)=>handleOnClick(e,"Done")}>
                    <span>Done</span>
                    <span></span>
                </div>
            </div>

            <ActivityStat />

        </div>
    )
}

export default React.memo(ListLeft)