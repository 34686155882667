import React, { useState, useEffect, useRef, useCallback } from 'react'
import styles from './settingsComp.module.css'
import MiniSpinner from '../../components/Loading/MiniSpinner'
import { useUsers } from '../../contexts/UsersContext'
import { functions } from '../../firebase'
import DialogChangeRole from './DialogChangeRole'
import NoPerm from '../../components/ErrorDialog/NoPerm'

export default function Users({ isAdmin, isOperation }) {
    const [error, setError] = useState(null)
    const isMounted = useRef()
    const { users, setUsers } = useUsers()
    const passValidator = useRef()
    const [isCreatingUser, setIsCreatingUser] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [onChangeRole, setOnChangeRole] = useState(null)

    useEffect(() => {
        isMounted.current = true
        document.querySelector('title').textContent = 'Users - Settings'

        return () => isMounted.current = false
    }, [])

    const onCreateUser = async (e) => {
        e.preventDefault()
        setError(null)
        const validator = passValidator.current
        const form = e.target
        const username = form.cu_username.value
        const email = form.cu_email.value
        const password = form.cu_pass.value
        const confirmPass = form.cu_conf_pass.value
        const isSuperadmin = form.cb_superadmin.checked
        const isAdmin = form.cb_admin.checked
        const isOperation = form.cb_operation.checked

        if (password !== confirmPass) {
            setError({
                message: 'Password confirmation does not match.',
                code: 'Invalid-Input'
            })
            return
        }

        if (validator.textContent !== 'Strong') {
            setError({
                message: 'Password is weak',
                code: 'Invalid-Input'
            })
            return
        }

        if (!isSuperadmin && !isAdmin && !isOperation) {
            setError({
                message: 'Please select at least 1 role.',
                code: 'Invalid-Input'
            })
            return
        }

        const role = []
        if (isSuperadmin) {
            role.push('SuperAdmin')
        }
        if (isAdmin) {
            role.push('Admin')
        }
        if (isOperation) {
            role.push('Operation')
        }

        //Pass
        const data = {
            newUserEmail: email,
            newUserPassword: password,
            newUserUsername: username,
            role
        }

        const invcreateNewUser = functions.httpsCallable('createUser')

        setIsCreatingUser(true)
        const res = await invcreateNewUser(data).catch(err => {
            setError(err)
            return
        })

        if (res) {
            const data = res.data
            const newUID = data.uid

            const newUser = {
                id: newUID,
                name: username,
                email,
                role
            }

            if (data.status === 200) {
                setUsers(prev => ([newUser, ...prev]))
            }
            setIsCreatingUser(false)
            form.reset()
            validator.textContent = ''
        }

    }

    const onDeleteUser = async (id) => {
        //Delete the user
        const deleteUser = functions.httpsCallable('deleteUser')
        setIsDeleting(true)
        const res = await deleteUser({ uid: id }).catch(err => {
            setError(err)
            return
        })

        if (res) {
            const data = res.data
            if (data.status === 200) {
                const newArr = users.filter(d => d.id !== id)
                setUsers(newArr)
            }
            setIsDeleting(false)
        }

    }

    const passValidation = useCallback(
        (node) => {
            if (node) {
                const validator = passValidator.current
                const lowerCaseLetters = /[a-z]/g;
                const upperCaseLetters = /[A-Z]/g;
                const numbers = /[0-9]/g;
                const maxLen = 6;
                node.onkeyup = () => {
                    const value = node.value
                    if (!value.match(lowerCaseLetters)) {
                        validator.textContent = 'Must include lower case letter'
                    } else if (!value.match(upperCaseLetters)) {
                        validator.textContent = 'Must include upper case letter'
                    } else if (!value.match(numbers)) {
                        validator.textContent = 'Must include number'
                    } else if (value.length < maxLen) {
                        validator.textContent = 'Must be at least 6 characters long'
                    } else {
                        validator.textContent = 'Strong'
                    }
                }
            }
        },
        [],
    )

    const onChangeCb = (e) => {
        const id = e.target.id
        const ele = e.target
        if (id === 'cb_superadmin' || id === 'cb_admin') {
            if (ele.checked) {
                document.querySelector('#cb_operation').checked = false
                document.querySelector('#cb_operation').disabled = true
            } else {
                if (!document.querySelector('#cb_superadmin').checked && !document.querySelector('#cb_admin').checked) {
                    document.querySelector('#cb_operation').disabled = false
                }
            }
        } else {
            if (ele.checked) {
                document.querySelector('#cb_superadmin').checked = false
                document.querySelector('#cb_superadmin').disabled = true
                document.querySelector('#cb_admin').checked = false
                document.querySelector('#cb_admin').disabled = true
            } else {
                document.querySelector('#cb_superadmin').disabled = false
                document.querySelector('#cb_admin').disabled = false
            }
        }
    }

    return (
        <>
        {
            !isAdmin && !isOperation ?
            <div>

                {
                    onChangeRole &&
                    <DialogChangeRole
                        data={onChangeRole}
                        onCancel={() => setOnChangeRole(null)}
                        setUsers={setUsers}
                    />
                }

                <div className={styles.titleCont}>
                    <h2 className={styles.title}>Users</h2>
                </div>
                {error && <p className="error-bar">{`${error.message} --code: ${error.code}`}</p>}
                <h3 className={styles.subtitle}>Admin</h3>
                <h3 className={styles.subtitle}>Create User</h3>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ width: '70%', marginRight: '30px' }} className={styles.list}>

                        <form className={styles.form__user} onSubmit={onCreateUser}>
                            <div className={styles.listItem}>
                                <h4>Username</h4>
                                <input id="cu_username" type="text" required placeholder="Username" />
                            </div>
                            <div className={styles.listItem}>
                                <h4>Email</h4>
                                <input id="cu_email" type="email" required placeholder="Email" />
                            </div>
                            <div style={{ position: 'relative' }} className={styles.listItem}>
                                <h4>Password</h4>
                                <span ref={passValidator} style={{ position: 'absolute', right: '0' }}></span>
                                <input ref={passValidation} id="cu_pass" type="password" required placeholder="Password" min='6' />
                            </div>
                            <div className={styles.listItem}>
                                <h4>Confirm Password</h4>
                                <input id="cu_conf_pass" type="password" required placeholder="Confirm password" />
                            </div>
                            <div className={styles.listItem}  >
                                <h4>Role</h4>

                                <div className={styles.form__role}>
                                    <div >
                                        <label htmlFor="cb_superadmin">Super Admin</label>
                                        <input onChange={onChangeCb} type="checkbox" name="cb_superadmin" id="cb_superadmin" />
                                    </div>
                                    <div >
                                        <label htmlFor="cb_admin">Admin</label>
                                        <input onChange={onChangeCb} type="checkbox" name="cb_admin" id="cb_admin" />
                                    </div>
                                    <div >
                                        <label htmlFor="cb_operation">Operation</label>
                                        <input onChange={onChangeCb} type="checkbox" name="cb_operation" id="cb_operation" />
                                    </div>
                                </div>

                            </div>
                            <div style={{ margin: '50px auto', display: 'flex' }}>
                                <button disabled={isCreatingUser} type="submit" className="btn-green">Create User</button>
                                {
                                    isCreatingUser && <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '10px' }}>
                                        <MiniSpinner wh={30} /><span style={{ fontStyle: 'italic' }}>Creating user... Please don't refresh this page.</span>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                    <div style={{ width: '30%' }} className={styles.role} >
                        <h3 className={styles.role__header}>Roles</h3>
                        <div className={styles.role__items} style={{ padding: '10px' }}>
                            <h3>Super Admin</h3>
                            <p>Able to create,delete,update user settings.</p>
                            <h3>Admin</h3>
                            <p>Able to use all modules.</p>
                            <h3>Operation</h3>
                            <p>Access operation module only.</p>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <h3 className={styles.subtitle}>Manage User</h3>
                    {
                        isDeleting && <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '10px' }}>
                            <MiniSpinner wh={30} /><span style={{ fontStyle: 'italic' }}>Deleting user... Please don't refresh this page.</span>
                        </div>
                    }
                </div>

                <table className="table" cellPadding='0' cellSpacing='0'>
                    <thead>
                        <tr>
                            <th>Num</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((user, idx) =>
                                <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role.join(', ')}</td>
                                    <td style={{ display: 'flex', gap: '10px' }}>
                                        <button disabled={isDeleting} className="btn-orange" onClick={() => setOnChangeRole({ name: user.name, id: user.id, role: user.role })}>Change role</button>
                                        <button disabled={isDeleting} className="btn-red" onClick={() => onDeleteUser(user.id)}>Delete</button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
             : 
             <NoPerm/>
             }
        </>
    )
}
