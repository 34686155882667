import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
})

export const auth = app.auth()
export const db = app.firestore()
export const storage = app.storage()
export const timestamp = firebase.firestore.FieldValue.serverTimestamp()
export const increment = firebase.firestore.FieldValue.increment
export const EmailProvider = firebase.auth.EmailAuthProvider
export const convertTimestamp = firebase.firestore.Timestamp.fromDate
export const arrayMethod = {
    union: firebase.firestore.FieldValue.arrayUnion,
    remove: firebase.firestore.FieldValue.arrayRemove
}
export const functions = app.functions()
export const fb = firebase;
export default app