import React, { useState } from 'react'
import DialogTemplate from '../../components/Dialogs/DialogTemplate'
import { editDirectory } from '../../controllers/documentInventoryController'

export default function EditDirName({ onCancel, defValue }) {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [newDirName,setNewDirName] = useState(defValue.currName)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        await editDirectory(defValue.id,{name:newDirName}).catch(err => {
            setIsLoading(false)
            setError(err)
        })

        setIsLoading(false)
        onCancel()
    }

    const dialogStyles = {
        width:"20%",
        padding:"0",
        borderRadius:"5px",
        overflow:"hidden"
    }

    return (
        <DialogTemplate dialogStyles={dialogStyles} title={"Rename Directory"} toggle={true} height="fit-content">
            {error && <div className="error-bar"><p>{error.message} --code: {error.code}</p></div>}
            <form onSubmit={handleSubmit} className="layout-dialog__form">
                <label htmlFor="dirName">Enter new directory name:</label>
                <input placeholder={defValue.currName} value={newDirName} onChange={(e) => setNewDirName(e.target.value)} id="dirName" required type="text" />
                <div className="buttons-dialog__form">
                    <button
                        disabled={isLoading}
                        type="button"
                        className="btn-white"
                        onClick={() => { setError(null); onCancel() }}>Cancel</button>
                    <button disabled={isLoading} type="submit" className="btn-purple">
                        {isLoading ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </form>

        </DialogTemplate>
    )
}
